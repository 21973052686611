import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useIsOnSmallScreen } from "../../../common/hooks/mediaHooks";

export interface IOutlinedQuestionBoxProps {
    header: string;
    children: React.ReactNode;
    headerMarginBottom?: number;
    hasError?: boolean;
    tooltip?: string;
    isPdf?: boolean;
}

function OutlinedQuestionBox(props: IOutlinedQuestionBoxProps) {
    const isOnSmallScreen = useIsOnSmallScreen();
    const paddingStyle = {
        py: 1,
        px: isOnSmallScreen || props.isPdf ? 3 : 10,
    };

    return (
        <Box
            sx={(theme) => ({
                backgroundColor: "white",
                border: "1px solid",
                borderRadius: "10px",
                py: paddingStyle.py,
                px: paddingStyle.px,
                marginBottom: "16px",
                color: props.hasError ? theme.customPalette.errorRed : "inherit",
                pageBreakInside: "avoid",
            })}
        >
            <DotLegalHeader headerStyle={"medium"} marginBottom={props.headerMarginBottom ? props.headerMarginBottom : 0} toolTip={props.tooltip}>
                {props.header}
            </DotLegalHeader>
            {props.children}
        </Box>
    );
}

export default OutlinedQuestionBox;
