import trademarksIcon from "./Trademarks hvid.svg";
import { CaseStatus } from "./WorldMap.types";
import useWorldMapCaseStatusFilterStyles from "./WorldMapCaseStatusFilter.styles";

export interface WorldMapCaseStatusFilterProps {
    caseStatus: CaseStatus;
    totalCasesCount: number;
    active: boolean;
    onClick: (e: any) => void;
}

function WorldMapCaseStatusFilter(props: WorldMapCaseStatusFilterProps) {
    const classes = useWorldMapCaseStatusFilterStyles(props);

    return (
        <div
            role="button"
            onClick={props.onClick}
            onKeyPress={props.onClick}
            tabIndex={0}
            className={`${classes.caseStatusFilter} ${props.active ? "active" : ""}`}
        >
            <div className={classes.icon}>
                <img style={{ width: 29 }} alt="registered" src={trademarksIcon} />
            </div>
            <div className={classes.caseCount}>{props.totalCasesCount}</div>
            <div>{props.caseStatus.charAt(0).toUpperCase() + props.caseStatus.slice(1)}</div>
        </div>
    );
}

export default WorldMapCaseStatusFilter;
