import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import DotLegalModal from "../../common/modal/DotLegalModal";
import DotLegalSkeletonTable from "../../common/table/DotLegalTableSkeleton";
import CustomExcelExport from "../csvExport/CustomExcelExport";
import ExcelExport from "../csvExport/ExcelExport";
import useCasePageStyles from "./Case.styles";
import { Case, CaseFilter, CasesResponse, CaseTableArrangement, CaseType, CaseWithAdditionalInfo } from "./Case.types";
import CaseModalContent from "./CaseModalContent";
import {
    getCase,
    getCases,
    getDevice,
    getRenewals,
    initialCaseTable,
    initialCaseTableArrangement,
    initialCaseTableHeaders,
    saveRenewalPrice,
} from "./CaseService";
import { CaseSummarization } from "./CaseSummarizationPage";
import CaseTable from "./CaseTable";
import getCaseTableHeaders from "./CaseTableHeaderService";
import RenewalRemarkModal from "./RenewalRemarkModal";

export type CaseTableWrapperProps = {
    caseFilter: CaseFilter;
    caseSummarization?: CaseSummarization;
    downloadExcel: () => void;
    isLoadingExcel: boolean;
    onCaseFilterChanged?: (caseType: CaseType, filter: CaseFilter & CaseTableArrangement) => Promise<CasesResponse>;
    selectedCaseType: CaseType;
    showRenewalPrices: boolean;
    editRenewalPrice: boolean;
    editSetRenewalStatus: boolean;
    renewalRegistrationActive: boolean;
};

function CaseTableWrapper(props: CaseTableWrapperProps) {
    const classes = useCasePageStyles();
    const [caseModalIsOpen, setCaseModalIsOpen] = useState(false);
    const [caseTable, setCaseTable] = useState(initialCaseTable);
    const [caseTableHeaders, setCaseTableHeaders] = useState(initialCaseTableHeaders);
    const [caseTableArrangement, setCaseTableArrangement] = useState(initialCaseTableArrangement);
    const [initialTableArrangeIsComplete, setInitialTableArrangeIsComplete] = useState(false);
    const [initialLoadIsComplete, setInitialLoadIsComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCase, setSelectedCase] = useState<CaseWithAdditionalInfo | null>(null);
    const [remarkOpen, setRemarkOpen] = useState<boolean>(false);
    const [selectedRenewalRemark, setSelectedRenewalRemark] = useState<string>();
    const [customExcelOpen, setCustomExcelOpen] = useState(false);
    const history = useHistory();
    const onCaseFilterChanged = (): void => {
        (props.onCaseFilterChanged
            ? props.onCaseFilterChanged(props.selectedCaseType, {
                  ...props.caseFilter,
                  ...caseTableArrangement,
              })
            : props.caseSummarization === CaseSummarization.Renewals
            ? getRenewals(props.selectedCaseType, { ...props.caseFilter, ...caseTableArrangement })
            : getCases(props.selectedCaseType, {
                  ...props.caseFilter,
                  ...caseTableArrangement,
              })
        )
            .then((res) => {
                setCaseTable({ ...caseTable, cases: res.cases, count: res.totalCount });
                setIsLoading(false);
            })
            .catch(() => {
                setCaseTable({ ...caseTable, cases: [], count: 0 });
                setIsLoading(false);
            });
    };

    const onCaseSelected = (caseSelection: { caseId: number; country: string }): void => {
        setCaseModalIsOpen(true);
        getCase(caseSelection.caseId, caseSelection.country)
            .then((getCaseExtendedRes) => setSelectedCase(getCaseExtendedRes))
            .catch(() => {});
    };

    const onRenewalRemarkSelected = (internalId: string): void => {
        setRemarkOpen(true);
        setSelectedRenewalRemark(internalId);
    };

    const onCaseModalClosed = (): void => {
        setCaseModalIsOpen(false);
        setSelectedCase(null);
    };

    const onTableArrangementChanged = (pageNumber: number, orderByDescending: boolean, orderBy: keyof Case = "name"): void => {
        const newTableArrangement = { ...caseTableArrangement };
        newTableArrangement.orderBy = orderBy;
        newTableArrangement.orderByDescending = orderByDescending;
        newTableArrangement.pageNumber = pageNumber;
        setCaseTableArrangement(newTableArrangement);
    };

    const resetTableArrangement = (): void =>
        onTableArrangementChanged(1, false, props.caseSummarization === CaseSummarization.Renewals ? "renewalDate" : "name");

    useEffect(() => {
        if (initialTableArrangeIsComplete && initialLoadIsComplete) resetTableArrangement();
        // eslint-disable-next-line
    }, [props.caseFilter]);

    useEffect(() => {
        if (initialTableArrangeIsComplete) {
            onCaseFilterChanged();
            if (!initialLoadIsComplete) setInitialLoadIsComplete(true);
        }
        // eslint-disable-next-line
    }, [caseTableArrangement]);

    useEffect(() => {
        setCaseTableHeaders(getCaseTableHeaders(props.selectedCaseType, props.showRenewalPrices, props.renewalRegistrationActive, props.caseSummarization));
        resetTableArrangement();
        if (!initialTableArrangeIsComplete) setInitialTableArrangeIsComplete(true);
        // eslint-disable-next-line
    }, [props.selectedCaseType, props.caseSummarization]);

    useEffect(() => {
        const locationState = history.location.state as {
            caseId?: number;
            country?: string;
        };
        if (locationState && locationState.caseId && locationState.country) {
            onCaseSelected({
                caseId: locationState.caseId,
                country: locationState.country,
            });
        }
        // eslint-disable-next-line
    }, []);

    const onCaseUpdated = useCallback((updatedCase) => {
        setSelectedRenewalRemark(undefined);
        setCaseTable((prevState) => {
            const newCases = prevState.cases.map((x) => (x.internalId === updatedCase.internalId ? updatedCase : x));
            return {
                ...prevState,
                cases: newCases,
            };
        });
    }, []);

    const caseModalContent = useMemo(() => {
        return caseModalIsOpen && !remarkOpen ? (
            <DotLegalModal
                content={<CaseModalContent case={selectedCase} caseType={props.selectedCaseType} onSelectedDeviceChanged={getDevice} />}
                maxWidth="md"
                onClose={onCaseModalClosed}
                open={caseModalIsOpen}
                title={selectedCase?.name ?? ""}
            />
        ) : (
            <></>
        );
    }, [caseModalIsOpen, props.selectedCaseType, remarkOpen, selectedCase]);

    const renewalModalContent = useMemo(() => {
        return remarkOpen && !caseModalIsOpen ? (
            <RenewalRemarkModal internalId={selectedRenewalRemark} remarkOpen={remarkOpen} setRemarkOpen={setRemarkOpen} onCaseUpdated={onCaseUpdated} />
        ) : (
            <></>
        );
    }, [caseModalIsOpen, onCaseUpdated, remarkOpen, selectedRenewalRemark]);

    const customExcelExportContent = useMemo(() => {
        return (
            customExcelOpen && <CustomExcelExport caseType={props.selectedCaseType} onClose={() => setCustomExcelOpen(false)} caseFilter={props.caseFilter} />
        );
    }, [customExcelOpen, props.selectedCaseType, props.caseFilter]);

    return (
        <>
            <div className={classes.caseTableContainer}>
                {isLoading ? (
                    <DotLegalSkeletonTable />
                ) : (
                    <>
                        <CaseTable
                            headers={caseTableHeaders}
                            caseSummary={props.caseSummarization}
                            onCaseSelected={onCaseSelected}
                            onRenewalRemarkSelected={onRenewalRemarkSelected}
                            onTableArrangementChanged={onTableArrangementChanged}
                            pageNumber={caseTableArrangement.pageNumber}
                            rows={caseTable.cases}
                            selectedCaseType={props.selectedCaseType}
                            totalCases={caseTable.count}
                            showRenewalPrice={props.showRenewalPrices}
                            editRenewalPrice={props.editRenewalPrice}
                            editSetRenewalStatus={props.editSetRenewalStatus}
                            renewalRegistrationActive={props.renewalRegistrationActive}
                            onRenewalPriceChange={async (selectedCase) => {
                                const tempData = { ...caseTable };
                                const foundData = tempData.cases.find((x) => x.internalId === selectedCase.internalId);
                                if (foundData) {
                                    tempData.cases.find((x) => x.internalId === selectedCase.internalId)!.price = Number(selectedCase.price);
                                }
                                setCaseTable(tempData);
                                await saveRenewalPrice(selectedCase);
                            }}
                            onCaseUpdated={onCaseUpdated}
                        />
                        {(props.caseSummarization === undefined || props.caseSummarization === CaseSummarization.Disputes) && (
                            <ExcelExport name={"Export"} onClick={() => setCustomExcelOpen(true)} isLoading={false} />
                        )}
                        {!!props.caseSummarization &&
                            props.caseSummarization !== CaseSummarization.Dashboard &&
                            props.caseSummarization !== CaseSummarization.Disputes && (
                                <ExcelExport name={"Export"} onClick={props.downloadExcel} isLoading={props.isLoadingExcel} />
                            )}
                    </>
                )}
            </div>
            {caseModalContent}
            {renewalModalContent}
            {customExcelExportContent}
        </>
    );
}

export default CaseTableWrapper;
