import { useDotLegalBreadCrumbs } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useState } from "react";
import ContentCard from "../../common/contentCard/ContentCard";
import DotLegalSearch from "../../common/search/DotLegalSearch";
import OrganizationTable from "../../core/organizations/OrganizationTable";
import { useUrlProvider } from "../../useUrlProvider";

type OrganisationsProps = {
    onSelectedOrgCostAccessChanged: () => void;
};

function Organisations(props: OrganisationsProps) {
    const { getAdminUrl } = useUrlProvider();
    const [adminSearchText, setAdminSearchText] = useState("");
    useDotLegalBreadCrumbs([{ name: "Admin", link: getAdminUrl() }, { name: "Organisations" }]);

    return (
        <ContentCard>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <DotLegalSearch value={adminSearchText} onSearchTextChanged={setAdminSearchText} />
            </Box>
            <OrganizationTable onSelectedOrgCostAccessChanged={props.onSelectedOrgCostAccessChanged} searchText={adminSearchText} />
        </ContentCard>
    );
}

export default Organisations;
