import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { SurveyArea } from "../surveys/Surveys.types";
import { PriceSearchFilter, SurveyType, TrademarkPriceCalculationViewModel } from "./PriceCalculator.types";

export function usePriceCalculator() {
    const [searchOptions, setSearchOptions] = useState<PriceSearchFilter>({
        typeOfTransaction: SurveyType.NewApplication,
        country: undefined,
        numberOfClasses: undefined,
        addAdditionalCosts: false,
    });

    let url = `/Price-calculator/trademark?typeOfTransaction=${searchOptions.typeOfTransaction}&country=${searchOptions.country}&numberOfClasses=${searchOptions.numberOfClasses}&addAdditionalCosts=${searchOptions.addAdditionalCosts}`;
    let { isLoading, data } = useQuery(url, () => get<TrademarkPriceCalculationViewModel>(url), { enabled: shouldFetch() });

    function shouldFetch() {
        return !isNullOrWhitespace(searchOptions.country) && searchOptions.numberOfClasses !== undefined && searchOptions.numberOfClasses !== 0;
    }

    return { searchOptions, setSearchOptions, getCaseNumberOptions, getCountryOptions, isLoading, data };

    function getCaseNumberOptions() {
        let caseNumbers = [];

        for (let i = 1; i <= 45; i++) {
            const caseNumber = i.toString();
            caseNumbers.push({
                id: caseNumber,
                name: caseNumber,
            });
        }
        return caseNumbers;
    }
}

export function getCountryOptions(surveyType: SurveyArea) {
    const countries = [
        { name: "Australia", id: "au" },
        { name: "Canada", id: "ca" },
        { name: "China", id: "cn" },
        { name: "Hong Kong", id: "hk" },
        { name: "India", id: "in" },
        { name: "Japan", id: "jp" },
        { name: "New Zealand", id: "nz" },
        { name: "Norway", id: "no" },
        { name: "Singapore", id: "sg" },
        { name: "South Korea", id: "kr" },
        { name: "Switzerland", id: "ch" },
        { name: "United Arab Emirates", id: "ae" },
        { name: "United Kingdom", id: "gb" },
        { name: "European Union", id: "em" },
        { name: "Argentina", id: "ar" },
        { name: "Benelux", id: "bx" },
        { name: "Brazil", id: "br" },
        { name: "Colombia", id: "co" },
        { name: "Egypt", id: "eg" },
        { name: "Germany", id: "ge" },
        { name: "Indonesia", id: "id" },
        { name: "Jordan", id: "jo" },
        { name: "Kuwait", id: "kw" },
        { name: "Lebanon", id: "lb" },
        { name: "Libya", id: "ly" },
        { name: "Malaysia", id: "my" },
        { name: "Mexico", id: "mx" },
        { name: "Paraguay", id: "py" },
        { name: "Peru", id: "pe" },
        { name: "Philippines", id: "ph" },
        { name: "Qatar", id: "qa" },
        { name: "Saudi Arabia", id: "sa" },
        { name: "South Africa", id: "za" },
        { name: "Sweden", id: "se" },
        { name: "Taiwan", id: "tw" },
        { name: "Thailand", id: "th" },
        { name: "Turkey", id: "tr" },
        { name: "Uruguay", id: "uy" },
        { name: "Venezuela", id: "ve" },
        { name: "Vietnam", id: "vn" },
        { name: "Albania", id: "al" },
        { name: "Andorra", id: "ad" },
        { name: "Austria", id: "at" },
        { name: "Bahrain", id: "bh" },
        { name: "Bolivia", id: "bo" },
        { name: "Bosnia and Herzegovina", id: "ba" },
        { name: "Bulgaria", id: "bg" },
        { name: "Chile", id: "cl" },
        { name: "Costa Rica", id: "cr" },
        { name: "Croatia", id: "hr" },
        { name: "Cyprus", id: "cy" },
        { name: "Czech Republic", id: "cz" },
        { name: "Denmark", id: "dk" },
        { name: "Ecuador", id: "ec" },
        { name: "El Salvador", id: "sv" },
        { name: "Estonia", id: "ee" },
        { name: "Finland", id: "fi" },
        { name: "France", id: "fr" },
        { name: "Greece", id: "gr" },
        { name: "Hungary", id: "hu" },
        { name: "Iceland", id: "is" },
        { name: "Italy", id: "it" },
        { name: "Latvia", id: "lv" },
        { name: "Lithuania", id: "lt" },
        { name: "Moldova", id: "md" },
        { name: "Montenegro", id: "me" },
        { name: "North Macedonia", id: "mk" },
        { name: "OAPI", id: "oa" },
        { name: "Panama", id: "pa" },
        { name: "Poland", id: "pl" },
        { name: "Portugal", id: "pt" },
        { name: "Puerto Rico", id: "pr" },
        { name: "Romania", id: "ro" },
        { name: "Serbia", id: "rs" },
        { name: "Slovakia", id: "sk" },
        { name: "Slovenia", id: "si" },
        { name: "Spain", id: "es" },
        { name: "Suriname", id: "sr" },
        { name: "Ukraine", id: "ua" },
    ];

    if (surveyType === SurveyArea.Trademarks) {
        countries.push({ name: "United States of America", id: "us" });
    } else if (surveyType === SurveyArea.Designs) {
        countries.push({ name: "United States of America (Small Entity)", id: "us1" });
        countries.push({ name: "United States of America (Medium Entity)", id: "us2" });
        countries.push({ name: "United States of America (Large Entity)", id: "us3" });
    }

    return countries.sort((one, two) => (one.name > two.name ? 1 : -1));
}
