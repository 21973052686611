import { DateType } from "@date-io/type";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import DotLegalAutocomplete, { AutocompleteOption } from "../../common/autocomplete/DotLegalAutocomplete";
import DotLegalDatePicker from "../../common/datePicker/DotLegalDatePicker";
import { CaseType } from "../cases/Case.types";
import { thousandSeparateNumber } from "../dashboard/DashboardWidgetService";
import totalCostsIcon from "../icons/Total costs icon.svg";
import { CostDisputeFilter, CostDisputeFilterOptions, CostPeriod, MaterialUICostsPeriod } from "./Cost.types";
import useCostsStyles from "./Costs.styles";
import { getCostFilterOptions, getCostsByPeriod, initialCostFilterOptions, initialCostPeriod } from "./CostService";

type TotalCostProps = {
    costFilter: CostPeriod & CostDisputeFilter;
    costSelection: { selectedCaseType: CaseType; disputeIsToggled: boolean };
    onFilterChanged: (filter: CostPeriod & CostDisputeFilter) => void;
    searchText: string;
};

function TotalCosts(props: TotalCostProps) {
    const classes = useCostsStyles();
    const [costPeriod, setCostPeriod] = useState<MaterialUICostsPeriod>(initialCostPeriod);
    const [costFilterOptions, setCostFilterOptions] = useState(initialCostFilterOptions);
    const [selectedFilterOption, setSelectedFilterOption] = useState(initialCostFilterOptions);
    const [costHasBeenLoaded, setCostHasBeenLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalCostsByPeriod, setTotalCostsByPeriod] = useState<number>();

    const GetTotalCosts = (modifiedFilter: CostPeriod & CostDisputeFilter, setLoadingState = false) => {
        if (setLoadingState) setIsLoading(true);
        getCostsByPeriod(props.costSelection.selectedCaseType, props.costSelection.disputeIsToggled, {
            ...props.costFilter,
            disputes: modifiedFilter.disputes,
            from: modifiedFilter.from,
            to: modifiedFilter.to,
            searchText: props.searchText,
            types: modifiedFilter.types,
        })
            .then((totalCostsResponse) => {
                setTotalCostsByPeriod(totalCostsResponse || 0);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const onAutocompleteChanged =
        <K extends keyof CostDisputeFilterOptions>(criteria: K) =>
        (selectedFilterOptions: CostDisputeFilterOptions[K]) => {
            const newSelectedFilterOptions = { ...selectedFilterOption };
            newSelectedFilterOptions[criteria] = selectedFilterOptions;
            setSelectedFilterOption(newSelectedFilterOptions);
            const newFilter = { ...props.costFilter };
            newFilter[criteria] = selectedFilterOptions
                .filter((selectedOption: AutocompleteOption<any>) => selectedOption != null)
                .map((selectedOption: AutocompleteOption<any>) => selectedOption.value);

            onFilterChanged(newFilter);
        };

    const onDateChange = (date: DateType | null, value: string | null, criteria: keyof CostPeriod): void => {
        if (date && (Number.isNaN(date.valueOf()) || date.valueOf() < 0)) return;
        if (criteria === "from") setCostPeriod({ ...costPeriod, from: date });
        if (criteria === "to") setCostPeriod({ ...costPeriod, to: date });
        const newFilter = { ...props.costFilter };
        newFilter[criteria] = value;

        onFilterChanged(newFilter);
    };

    const onFilterChanged = (filter: CostPeriod & CostDisputeFilter) => {
        props.onFilterChanged(filter);
        GetTotalCosts(filter, true);
        GetFilterOptions(filter);
    };

    const GetFilterOptions = (filter?: CostPeriod & CostDisputeFilter) => {
        getCostFilterOptions(props.costSelection.selectedCaseType, {
            ...(filter || props.costFilter),
            searchText: props.searchText,
        })
            .then((filterOptionsResponse) => {
                setCostFilterOptions(filterOptionsResponse);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    useEffect(() => {
        if (props.costSelection.disputeIsToggled) GetFilterOptions();
        GetTotalCosts(props.costFilter, !costHasBeenLoaded);
        setCostHasBeenLoaded(true);
        // eslint-disable-next-line
    }, [props.costSelection.disputeIsToggled, props.costSelection.selectedCaseType, props.searchText]);

    return (
        <div className={classes.costsDateFilter}>
            <div style={{ display: "flex", alignItems: "center", paddingRight: 24 }}>
                <div style={{ paddingRight: 28 }}>
                    <img src={totalCostsIcon} alt="" />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        fontWeight: "bold",
                    }}
                >
                    <div style={{ color: "#B2BBC7", fontSize: 12 }}>TOTAL COSTS</div>
                    {isLoading ? (
                        <Skeleton style={{ borderRadius: 0, width: 140 }} animation="pulse" variant="text" />
                    ) : (
                        <div style={{ color: "#002677", fontSize: 28 }}>{totalCostsByPeriod ? `${thousandSeparateNumber(totalCostsByPeriod)} DKK` : `0`}</div>
                    )}
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", gap: 16 }}>
                {props.costSelection.disputeIsToggled && (
                    <>
                        {props.costSelection.selectedCaseType !== CaseType.Design && (
                            <DotLegalAutocomplete
                                label="Case type"
                                onChange={onAutocompleteChanged("types")}
                                options={costFilterOptions.types}
                                value={selectedFilterOption.types}
                                width={200}
                            />
                        )}
                        <DotLegalAutocomplete
                            label="Dispute against"
                            onChange={onAutocompleteChanged("disputes")}
                            options={costFilterOptions.disputes}
                            value={selectedFilterOption.disputes}
                            width={200}
                        />
                    </>
                )}
                <DotLegalDatePicker
                    criteria="from"
                    disabled={isLoading || undefined}
                    label="From"
                    onDateChange={onDateChange}
                    selectedValue={costPeriod.from}
                    dateLimitations={{
                        earliestDate: new Date(new Date().getFullYear() - 4, 0, 1),
                        latestDate: new Date(),
                    }}
                />
                <DotLegalDatePicker
                    criteria="to"
                    disabled={isLoading || undefined}
                    label="To"
                    onDateChange={onDateChange}
                    selectedValue={costPeriod.to}
                    dateLimitations={{
                        earliestDate: new Date(new Date().getFullYear() - 4, 0, 1),
                        latestDate: new Date(),
                    }}
                />
            </div>
        </div>
    );
}

export default TotalCosts;
