import { DotLegalCheckbox, DotLegalHeader, DotLegalSelect, DotLegalTextField, DotLegalToolTip, useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { alpha, Box, Button, styled, Theme } from "@mui/material";
import { useIsOnMediumScreen, useIsOnSmallScreen } from "../../common/hooks/mediaHooks";
import Paper from "../../common/paper/Paper";
import { ReactComponent as CopyImage } from "../../core/icons/Copy icon.svg";
import { SurveyArea } from "../surveys/Surveys.types";
import { ReactComponent as CalculationImage } from "./calculatorImage.svg";
import NecessaryDocuments from "./necessaryDocuments/NecessaryDocuments";
import PriceCalculationCollapse from "./priceCalculationCollapse/PriceCalculationCollapse";
import { PriceCalculatorProps } from "./PriceCalculator";
import { usePriceCalculator } from "./PriceCalculator.hooks";
import { SurveyType } from "./PriceCalculator.types";

export const PriceBox = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    marginRight: 60,
});

function TrademarkPriceCalculator(props: PriceCalculatorProps) {
    const isOnMediumScreen = useIsOnMediumScreen();
    const isOnSmallScreen = useIsOnSmallScreen();
    const snackbar = useDotLegalSnackbar();
    const { searchOptions, setSearchOptions, getCaseNumberOptions, getCountryOptions, isLoading, data } = usePriceCalculator();

    let isNewApplication = searchOptions.typeOfTransaction === SurveyType.NewApplication;

    const getPriceHeader = (text: string) => {
        return (
            <DotLegalHeader fontWeight={500} marginBottom={1} headerStyle="small">
                {text}
            </DotLegalHeader>
        );
    };

    const getPriceWrapper = (text: string) => {
        return (
            <DotLegalHeader fontWeight={500} marginBottom={1} headerStyle="small">
                <Box sx={{ whiteSpace: "nowrap" }}>{text}</Box>
            </DotLegalHeader>
        );
    };

    return (
        <Box
            sx={(theme: Theme) => ({
                minHeight: `calc(100vh - ${theme.spacingAsNumber(theme.customSpacing.contentSpacing) * 2 + 55}px)`,
                display: "flex",
                flexDirection: "column",
            })}
        >
            <Paper
                style={{
                    minHeight: 116,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",
                    marginBottom: 32,
                }}
                background="blueWave"
            >
                <Box sx={{ marginLeft: 3 }}>
                    <DotLegalHeader headerStyle="small" marginBottom={0} fontWeight={500}>
                        The Price Calculator helps you estimate the cost of new applications and renewals for national trademark registrations.
                    </DotLegalHeader>
                </Box>
                <Box sx={{ marginRight: isOnSmallScreen ? 0 : 10 }}>
                    <CalculationImage />
                </Box>
            </Paper>

            <Paper fullHeight style={{ flex: 1 }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DotLegalHeader color="primary" headerStyle={"large"} marginTop={2}>
                        Mark information
                    </DotLegalHeader>
                    <DotLegalHeader color="primary" headerStyle={"small"}>
                        Please select which transaction you wish to estimate the cost of
                    </DotLegalHeader>

                    <Box
                        sx={{
                            width: isOnMediumScreen ? "100%" : "40%",
                            display: "flex",
                            flexDirection: "column",
                            "& .MuiAutocomplete-input": { width: "100% !Important" },
                        }}
                    >
                        <Box
                            sx={(theme) => ({
                                "& .MuiSvgIcon-root": {
                                    fill: theme.palette.primary.main,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: `${theme.palette.primary.main} !Important`,
                                },
                            })}
                        >
                            <DotLegalSelect
                                options={[
                                    { id: `${SurveyType.NewApplication}`, name: "New application" },
                                    { id: `${SurveyType.Renewal}`, name: "Renewal" },
                                ]}
                                onChange={(value) => {
                                    let tempModel = { ...searchOptions };
                                    tempModel.typeOfTransaction = Number(value);
                                    tempModel.addAdditionalCosts = false;
                                    setSearchOptions(tempModel);
                                }}
                                placeholder={"Type of transaction"}
                                label={"Type of transaction"}
                                noOptionsLabel={"No options"}
                                selectedItem={searchOptions.typeOfTransaction.toString()}
                                disableClearable
                            />

                            <DotLegalSelect
                                options={getCountryOptions(SurveyArea.Trademarks)}
                                onChange={(value) => {
                                    let tempModel = { ...searchOptions };
                                    tempModel.country = value ?? undefined;
                                    setSearchOptions(tempModel);
                                }}
                                placeholder={"Country"}
                                label={"Country"}
                                noOptionsLabel={"No options"}
                                selectedItem={searchOptions.country}
                            />

                            <DotLegalSelect
                                options={getCaseNumberOptions()}
                                onChange={(value) => {
                                    let tempModel = { ...searchOptions };
                                    tempModel.numberOfClasses = value !== null ? Number(value) : undefined;
                                    setSearchOptions(tempModel);
                                }}
                                placeholder={"Number of classes"}
                                label={"Number of classes"}
                                noOptionsLabel={"No options"}
                                selectedItem={searchOptions.numberOfClasses?.toString()}
                            />
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "space-between", "& .MuiCheckbox-root": { marginLeft: "-12px" } }}>
                            <DotLegalCheckbox
                                checked={searchOptions.addAdditionalCosts}
                                label={searchOptions.typeOfTransaction === SurveyType.NewApplication ? "Claim priority" : "Late renewal during a grace period"}
                                onChange={(value) => {
                                    let tempModel = { ...searchOptions };
                                    tempModel.addAdditionalCosts = value;
                                    setSearchOptions(tempModel);
                                }}
                            />
                            <Button
                                variant="contained"
                                onClick={() =>
                                    setSearchOptions({
                                        typeOfTransaction: SurveyType.NewApplication,
                                        country: undefined,
                                        numberOfClasses: undefined,
                                        addAdditionalCosts: false,
                                    })
                                }
                                sx={(theme: Theme) => ({
                                    alignSelf: "flex-end",
                                    boxShadow: "none",
                                    "&.MuiButton-root.Mui-disabled": { backgroundColor: alpha(theme.palette.primary.main, 0.5) },
                                })}
                            >
                                Clear
                            </Button>
                        </Box>

                        {data?.noData ? (
                            <Box sx={{ alignSelf: "center" }} marginTop={2}>
                                <DotLegalHeader headerStyle="medium">No data exists for the chosen country</DotLegalHeader>
                            </Box>
                        ) : (
                            <Box sx={{ marginTop: 2, "& > *": { marginBottom: 2 } }}>
                                <PriceCalculationCollapse hasData={data !== undefined} isLoading={isLoading}>
                                    <Box>
                                        <Box sx={{ borderBottom: "1px solid", marginBottom: 2 }}>
                                            <PriceBox>
                                                <DotLegalHeader headerStyle={"medium"}>Grand Total (Excl. of BB fee)</DotLegalHeader>
                                                <Box sx={{ whiteSpace: "nowrap" }}>
                                                    <DotLegalHeader headerStyle={"medium"}>
                                                        {data?.grandTotal?.total?.toDanishLocaleString()} DKK
                                                    </DotLegalHeader>
                                                </Box>
                                                <DotLegalToolTip text={"Copy grand total"}>
                                                    <Box
                                                        sx={{ position: "absolute", right: 50, top: 18, "&:hover": { cursor: "pointer" } }}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(data?.grandTotal?.total?.toString() ?? "");
                                                            snackbar.show(
                                                                <Box>
                                                                    Grand Total of <strong>{data?.grandTotal?.total?.toDanishLocaleString()}</strong> DKK was
                                                                    copied
                                                                </Box>,
                                                                "success"
                                                            );
                                                        }}
                                                    >
                                                        <CopyImage />
                                                    </Box>
                                                </DotLegalToolTip>
                                            </PriceBox>
                                        </Box>
                                        <PriceBox>
                                            {getPriceHeader(`${isNewApplication ? "Filing fees" : "Renewal fees"} (Official fees)`)}
                                            {getPriceWrapper(`${data?.grandTotal?.officialFees?.toDanishLocaleString()} DKK`)}
                                        </PriceBox>
                                        <PriceBox>
                                            {getPriceHeader(`${isNewApplication ? "Filing fees" : "Renewal fees"} (Attorney's fees)`)}
                                            {getPriceWrapper(`${data?.grandTotal?.attorneyFees?.toDanishLocaleString()} DKK`)}
                                        </PriceBox>

                                        {searchOptions.addAdditionalCosts && (
                                            <>
                                                <PriceBox>
                                                    {getPriceHeader(
                                                        `${
                                                            isNewApplication ? "Claim priority" : "Additional costs for late renewal during a grace period"
                                                        } (Official fees)`
                                                    )}
                                                    {getPriceWrapper(`${data?.grandTotal?.additionalOfficialFees?.toDanishLocaleString()} DKK`)}
                                                </PriceBox>
                                                <PriceBox>
                                                    {getPriceHeader(
                                                        `${
                                                            isNewApplication ? "Claim priority" : "Additional costs for late renewal during a grace period"
                                                        } (Attorney's fees)`
                                                    )}
                                                    {getPriceWrapper(`${data?.grandTotal?.additionalAttorneyFees?.toDanishLocaleString()} DKK`)}
                                                </PriceBox>
                                            </>
                                        )}
                                    </Box>
                                </PriceCalculationCollapse>

                                <PriceCalculationCollapse
                                    hasData={data?.publicationAndRegistration !== undefined && data?.publicationAndRegistration !== null}
                                    isLoading={isLoading}
                                >
                                    <Box>
                                        <PriceBox>
                                            <DotLegalHeader headerStyle={"medium"}>Publication & Registration Fees</DotLegalHeader>
                                            {getPriceWrapper(`${data?.publicationAndRegistration?.total?.toDanishLocaleString()} DKK`)}
                                        </PriceBox>
                                        <PriceBox>
                                            {getPriceHeader(`${isNewApplication ? "Filing fees" : "Renewal fees"} (Official fees)`)}
                                            {getPriceWrapper(`${data?.publicationAndRegistration?.officialFees?.toDanishLocaleString()} DKK`)}
                                        </PriceBox>
                                        <PriceBox>
                                            {getPriceHeader(`${isNewApplication ? "Filing fees" : "Renewal fees"} (Attorney's fees)`)}
                                            {getPriceWrapper(`${data?.publicationAndRegistration?.attorneyFees?.toDanishLocaleString()} DKK`)}
                                        </PriceBox>
                                    </Box>
                                </PriceCalculationCollapse>

                                <PriceCalculationCollapse
                                    hasData={
                                        (data?.remarks || data?.publicationRegistrationRemarks) !== null &&
                                        (data?.remarks || data?.publicationRegistrationRemarks) !== undefined
                                    }
                                    isLoading={isLoading}
                                >
                                    <Box
                                        sx={(theme) => ({
                                            "& .MuiInputBase-root": {
                                                backgroundColor: theme.customPalette.white,
                                            },
                                        })}
                                    >
                                        <PriceBox>
                                            <DotLegalHeader headerStyle={"medium"}>Remarks</DotLegalHeader>
                                        </PriceBox>
                                        <Box
                                            sx={{
                                                "& *": {
                                                    cursor: "default",
                                                    "&:hover": {
                                                        cursor: "default",
                                                    },
                                                },
                                            }}
                                        >
                                            {data?.remarks && (
                                                <DotLegalTextField
                                                    label={`Remarks - ${isNewApplication ? "filing" : "renewal"}`}
                                                    value={data.remarks}
                                                    debounce={false}
                                                    multiline
                                                />
                                            )}

                                            {data?.publicationRegistrationRemarks && (
                                                <DotLegalTextField
                                                    label={"Remarks - publication/registration"}
                                                    value={data.publicationRegistrationRemarks}
                                                    debounce={false}
                                                    multiline
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </PriceCalculationCollapse>

                                <PriceCalculationCollapse
                                    hasData={data?.necessaryDocuments !== null && data?.necessaryDocuments !== undefined}
                                    isLoading={isLoading}
                                >
                                    <PriceBox>
                                        <DotLegalHeader headerStyle={"medium"}>Necessary documents</DotLegalHeader>
                                    </PriceBox>
                                    <NecessaryDocuments
                                        documents={data?.necessaryDocuments!}
                                        trademarkSurveyType={searchOptions.typeOfTransaction}
                                        surveyArea={SurveyArea.Trademarks}
                                    />
                                </PriceCalculationCollapse>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

export default TrademarkPriceCalculator;
