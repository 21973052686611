import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { get, put } from "../../../../common/api/apiShared";
import { Result } from "../../../../common/api/result";
import { useOptimisticUpdate } from "../../../../common/hooks/useOptimisticUpdate";
import { useStateUrlParamsNumber } from "../../../../common/hooks/useStateUrlParams";
import { useValidator } from "../../../../common/hooks/validation";
import { SurveyType } from "../../../priceCalculator/PriceCalculator.types";
import { SurveyViewModel } from "../Survey.types";

export function useSurvey() {
    const snackbar = useDotLegalSnackbar();

    const { newaapplicationid } = useParams<{ newaapplicationid: string }>();
    const { renewalid } = useParams<{ renewalid: string }>();
    const [isSaving, setIsSaving] = useState(false);

    const [page, setPage] = useStateUrlParamsNumber("pageNo", 1);
    const { putOnQueueAndSetQueryData, isQueueEmpty } = useOptimisticUpdate();
    const history = useHistory();

    const key = `${page === 1 ? newaapplicationid : renewalid}`;
    const { isLoading, data } = useQuery(key, () => get<SurveyViewModel>(`/api/survey/${key}`));

    if (data) {
        if (data.isSubmitted) {
            history.push(`/survey-submitted/${newaapplicationid}/${renewalid}/trademark`);
        }
    }

    const updateMutation = useMutation(updateModel);
    function updateModel(model: SurveyViewModel) {
        return put<{}>(`/api/survey`, model);
    }

    const submitMutation = useMutation(submitSurveyModel, {
        onSuccess: (response: Result<any>) => {
            if (response.value()) {
                history.push(`/survey-submitted/${newaapplicationid}/${renewalid}/trademark`);
            } else {
                setIsSaving(false);
                snackbar.show("Not all required fields has been filled out", "error");
            }
        },
    });

    function submitSurveyModel() {
        return put<{ isValid: boolean }>(`/api/survey/submit/${newaapplicationid}/${renewalid}`, null);
    }

    async function updateSurvey(model: SurveyViewModel) {
        let temp = { ...data! };
        temp = model;

        putOnQueueAndSetQueryData(temp, key, updateMutation.mutateAsync, temp);
    }

    function handleNextClick() {
        if (surveyValidator.anyHasErrors) {
            surveyValidator.setShowErrors(true);
            scrollToTop();
            return;
        }

        if (page === 1) {
            scrollToTop();
            setPage(2);
            surveyValidator.setShowErrors(false);
        } else {
            handleSubmit();
        }
    }

    function handleSubmit() {
        if (isQueueEmpty(key)) {
            setIsSaving(true);
            submitMutation.mutateAsync();
        } else {
            snackbar.show(`We are currently still saving your data. Please try and submit your survey again`, "warning");
        }
    }

    const surveyValidator = useValidator<SurveyViewModel>((validators) => {
        return {
            currency: validators.validateNotEmpty(
                (item) => item.currency,
                "Field",
                (item) => item.type === SurveyType.Renewal
            ),
            firstClassCoversUpToThreeClasses: validators.validateCheckedIsNotNull((item) => item.firstClassCoversUpToThreeClasses, "Field"),
            officialFeesForFirstClass: validators.validateNotEmpty((item) => item.officialFeesForFirstClass, "Field"),
            attorneyFeesForFirstClass: validators.validateNotEmpty((item) => item.attorneyFeesForFirstClass, "Field"),
            officialFeesInEachAdditionalClass: validators.validateNotEmpty((item) => item.officialFeesInEachAdditionalClass, "Field"),
            attorneyFeesInEachAdditionalClass: validators.validateNotEmpty((item) => item.attorneyFeesInEachAdditionalClass, "Field"),
            additionalCostsForOfficialFeesForFirstClass: validators.validateNotEmpty((item) => item.additionalCostsForOfficialFeesForFirstClass, "Field"),
            additionalCostsForAttorneyFeesForFirstClass: validators.validateNotEmpty((item) => item.additionalCostsForAttorneyFeesForFirstClass, "Field"),
            additionalCostsForOfficialFeesInEachAdditionalClass: validators.validateNotEmpty(
                (item) => item.additionalCostsForOfficialFeesInEachAdditionalClass,
                "Field"
            ),
            additionalCostsForAttorneyFeesInEachAdditionalClass: validators.validateNotEmpty(
                (item) => item.additionalCostsForAttorneyFeesInEachAdditionalClass,
                "Field"
            ),
            officialPublicationAndRegistrationFeesFirstClass: validators.validateNotEmpty(
                (item) => item.officialPublicationAndRegistrationFeesFirstClass,
                "Field",
                (item) => item.type === SurveyType.Renewal
            ),
            attorneyPublicationAndRegistrationFeesFirstClass: validators.validateNotEmpty(
                (item) => item.attorneyPublicationAndRegistrationFeesFirstClass,
                "Field",
                (item) => item.type === SurveyType.Renewal
            ),
            officialPublicationAndRegistrationFeesInEachAdditionalClass: validators.validateNotEmpty(
                (item) => item.officialPublicationAndRegistrationFeesInEachAdditionalClass,
                "Field",
                (item) => item.type === SurveyType.Renewal
            ),
            attorneyPublicationAndRegistrationFeesInEachAdditionalClass: validators.validateNotEmpty(
                (item) => item.attorneyPublicationAndRegistrationFeesInEachAdditionalClass,
                "Field",
                (item) => item.type === SurveyType.Renewal
            ),
        };
    });

    let scrollRef = React.createRef<HTMLDivElement>();
    const scrollToTop = () => {
        scrollRef!.current!.scrollIntoView();
    };

    return { isLoading, data, updateSurvey, setPage, page, handleNextClick, surveyValidator, isSaving, scrollRef, scrollToTop };
}
