import { Checkbox, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useIsOnSmallScreen } from "../hooks/mediaHooks";
import useDotLegalTableStyles from "./DotLegalTable.styles";
import { TableHeader } from "./DotLegalTable.types";

export interface DotLegalTableProps<T> {
    headersAreSortable?: true;
    headers: TableHeader<T>[];
    rows: T[];
    renderRow: (row: T) => React.ReactElement;
    defaultOrderBy: keyof T;
    onSelectAllChanged?: (selected: T[]) => void;
    headerCheckBoxState?: "true" | "false" | "indeterminate";
    rowsPerPage?: number;
    totalRowCount: number;
    onTableArrangementChanged: (pageNumber: number, orderDescending: boolean, orderBy: any) => void;
    pageNr?: number;
}

function DotLegalTable<T>(props: DotLegalTableProps<T>) {
    const classes = useDotLegalTableStyles(props);
    const isOnSmallScreen = useIsOnSmallScreen();
    const [orderByDescending, setOrderByDescending] = useState<boolean>(false);
    const [orderBy, setOrderBy] = useState(props.defaultOrderBy);
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(props.rowsPerPage || 15);
    const [noOfPages, setNoOfPages] = useState(0);
    const [headers, setHeaders] = useState(props.headers);
    const [rows, setRows] = useState<React.ReactElement[]>([]);

    const onSelectAllChanged = (checked: boolean) => {
        if (props.onSelectAllChanged) {
            if (checked && typeof props.rows !== "string") props.onSelectAllChanged(props.rows);
            else props.onSelectAllChanged([]);
        }
    };

    useEffect(() => {
        const newNoOfPageS = props.totalRowCount / rowsPerPage;
        setNoOfPages(newNoOfPageS);
        setPage(props.pageNr || 1);
        // eslint-disable-next-line
    }, [props.totalRowCount, props.pageNr]);

    useEffect(() => {
        if (isOnSmallScreen) {
            setHeaders(props.headers.filter((h) => h.showOnMobile));
        } else setHeaders(props.headers);
        // eslint-disable-next-line
    }, [props.headers]);

    useEffect(() => {
        setRows(props.rows.map((r) => props.renderRow(r)));
        // eslint-disable-next-line
    }, [props.rows]);

    return (
        <>
            <TableContainer>
                <Table className={classes.dotLegalTable} size="small">
                    <TableHead>
                        <TableRow>
                            {props.onSelectAllChanged && (
                                <TableCell>
                                    <Checkbox
                                        color={"primary"}
                                        checked={props.headerCheckBoxState === "true"}
                                        indeterminate={!(props.headerCheckBoxState === "true" || props.headerCheckBoxState === "false")}
                                        onChange={(_, checked) => onSelectAllChanged(checked)}
                                    />
                                </TableCell>
                            )}

                            {headers.map((h, i) => (
                                <TableCell
                                    style={{ paddingLeft: i === 0 ? 6 : "" }}
                                    width={h.width}
                                    className={h.align === "center" ? classes.alignCenter : ""}
                                    key={h.property.toString()}
                                >
                                    {!h.notSortable && (
                                        <TableSortLabel
                                            disabled={props.headersAreSortable ? undefined : true}
                                            direction={orderByDescending ? "desc" : "asc"}
                                            active={orderBy === h.property}
                                            onClick={() => {
                                                if (h.property !== orderBy) {
                                                    setPage(1);
                                                    props.onTableArrangementChanged(1, false, h.property);
                                                    setOrderByDescending(false);
                                                } else {
                                                    props.onTableArrangementChanged(page, !orderByDescending, h.property);
                                                    setOrderByDescending(!orderByDescending);
                                                }
                                                setOrderBy(h.property);
                                            }}
                                        >
                                            {h.text}
                                        </TableSortLabel>
                                    )}
                                    {h.notSortable && <div>{h.text}</div>}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
                {noOfPages > 1 && (
                    <Pagination
                        count={Math.ceil(noOfPages)}
                        page={page || 1}
                        onChange={(e, p) => {
                            props.onTableArrangementChanged(p, orderByDescending, orderBy);
                            setPage(p);
                        }}
                    />
                )}
            </TableContainer>
        </>
    );
}

export default DotLegalTable;
