import { useDotLegalBreadCrumbs } from "@dotlegal/dotlegal-ui-components";
import { useEffect, useMemo, useState } from "react";
import { HttpRequestError } from "../../auth/HttpInterceptor";
import { CaseType } from "../../core/cases/Case.types";
import { CaseSummarization, CaseSummarizationPage } from "../../core/cases/CaseSummarizationPage";
import { YearlyCosts } from "../../core/costs/Cost.types";
import { getYearlyCosts } from "../../core/costs/CostService";
import { RegistrationsPerClass, WidgetConfig, YearlyRenewalsAndApplications } from "../../core/dashboard/Dashboard.types";
import DashboardGrid from "../../core/dashboard/DashboardGrid";
import { getRegistrationsPerClass, getYearlyRenewalsAndApplications } from "../../core/dashboard/DashboardService";
import {
    generateCostWidget,
    generateRegistrationsPerClassWidget,
    generateRenewalsAndApplicationsWidget,
    generateUpcomingRenewalsWidget,
} from "../../core/dashboard/DashboardWidgetService";
import { CaseSummarizationProps } from "../../Main";

export function Dashboard(props: CaseSummarizationProps) {
    useDotLegalBreadCrumbs([{ name: "Dashboard" }]);
    const editSetRenewalStatus = useMemo(() => {
        return props.userSession.verifiedUser?.userRoles.some((value) => value.name === "Employee" || value.name === "Admin") ?? false;
    }, [props.userSession.verifiedUser?.userRoles]);
    const [yearlyRenewalsAndApplications, setYearlyRenewalsAndApplications] = useState<WidgetConfig<YearlyRenewalsAndApplications[]>>({
        data: [{ year: new Date().getFullYear(), applications: 0, renewals: 0 }],
        isLoading: true,
        serviceUnavailable: false,
    });
    const [registrationsPerClass, setRegistrationsPerClass] = useState<WidgetConfig<RegistrationsPerClass>>({
        data: {
            totalCaseCount: 0,
            countByTop12Classes: [
                { class: "1", count: 0 },
                { class: "1", count: 0 },
                { class: "1", count: 0 },
                { class: "1", count: 0 },
                { class: "1", count: 0 },
                { class: "1", count: 0 },
                { class: "1", count: 0 },
                { class: "1", count: 0 },
                { class: "1", count: 0 },
                { class: "1", count: 0 },
                { class: "1", count: 0 },
                { class: "1", count: 0 },
            ],
        },
        isLoading: true,
        serviceUnavailable: false,
    });
    const [yearlyCosts, setYearlyCosts] = useState<WidgetConfig<YearlyCosts[]>>({
        data: [],
        isLoading: true,
        serviceUnavailable: false,
    });
    const [upcomingRenewals, setUpcomingRenewals] = useState(
        generateUpcomingRenewalsWidget("registered", props.selectedCaseType, props.renewalRegistrationActive, editSetRenewalStatus)
    );

    const updateDashboardData = (caseType: CaseType) => {
        if (!props.orgHasBeenSelected) return;
        setYearlyRenewalsAndApplications({
            ...yearlyRenewalsAndApplications,
            isLoading: true,
        });
        getYearlyRenewalsAndApplications(caseType)
            .then((res) =>
                setYearlyRenewalsAndApplications({
                    data: res || [],
                    isLoading: false,
                    serviceUnavailable: false,
                })
            )
            .catch(() =>
                setYearlyRenewalsAndApplications({
                    data: [],
                    isLoading: false,
                    serviceUnavailable: true,
                })
            );
        setRegistrationsPerClass({ ...registrationsPerClass, isLoading: true });
        getRegistrationsPerClass(caseType)
            .then((res) =>
                setRegistrationsPerClass({
                    data: res,
                    isLoading: false,
                    serviceUnavailable: false,
                })
            )
            .catch(() =>
                setRegistrationsPerClass({
                    data: { totalCaseCount: 0, countByTop12Classes: [] },
                    isLoading: false,
                    serviceUnavailable: true,
                })
            );
        setYearlyCosts({ ...yearlyCosts, isLoading: true });
        if (!!props.userCostAccess && props.userCostAccess !== "Hide costs") {
            getYearlyCosts(caseType, false, "")
                .then((res) => {
                    setYearlyCosts({
                        data: res || [],
                        isLoading: false,
                        serviceUnavailable: false,
                    });
                })
                .catch((e: HttpRequestError) => {
                    setYearlyCosts({
                        data: [],
                        isLoading: false,
                        serviceUnavailable: true,
                    });
                });
        }
    };

    useEffect(() => {
        updateDashboardData(props.selectedCaseType);
        // eslint-disable-next-line
    }, [props.selectedCaseType, props.orgHasBeenSelected]);

    useEffect(() => {
        setUpcomingRenewals(generateUpcomingRenewalsWidget("registered", props.selectedCaseType, props.renewalRegistrationActive, editSetRenewalStatus));
    }, [editSetRenewalStatus, props.renewalRegistrationActive, props.selectedCaseType]);

    return (
        <CaseSummarizationPage
            onCaseFilterKeyChange={props.onCaseFilterKeyChanged}
            onCaseTypeFilterConfigurationClear={props.onCaseTypeFilterConfigurationCleared}
            caseSummarization={CaseSummarization.Dashboard}
        >
            <DashboardGrid
                widgets={[
                    generateRenewalsAndApplicationsWidget(yearlyRenewalsAndApplications),
                    props.selectedCaseType === CaseType.Trademark ? generateRegistrationsPerClassWidget(registrationsPerClass) : null,
                    !!props.userCostAccess && props.userCostAccess !== "Hide costs" ? generateCostWidget(yearlyCosts, props.selectedCaseType) : null,
                    upcomingRenewals,
                ]}
            />
        </CaseSummarizationPage>
    );
}

export default Dashboard;
