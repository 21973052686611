import { DotLegalHeader, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { SingleValidator } from "../../../../../common/hooks/validation/SingleValidator";
import OutlinedQuestionBox from "../../../../priceCalculator/outlinedQuestionBox/OutlinedQuestionBox";
import { DesignSurveyIntervalArea } from "../../components/intervals/interval.types";
import OfficialAttorneyFeesBox from "../../components/officialAttorneyFeesBox/OfficialAttorneyFeesBox";
import Periods from "../../components/period/Periods";
import YesNoBox from "../../components/yesNoBox/YesNoBox";
import { DesignSurveyViewModel } from "../../Survey.types";
import { SectionHeader } from "../../trademarkSurvey/Survey";
import { useRenewalFees } from "./RenewalFees.hooks";

export interface RenewalFeesProps {
    data: DesignSurveyViewModel | undefined;
    onChange: (viewModel: DesignSurveyViewModel) => void;
    validator?: SingleValidator<DesignSurveyViewModel, DesignSurveyViewModel>;
    isPdf?: boolean;
}

function RenewalFees(props: RenewalFeesProps) {
    const { numberOfDesingsref, onPeriodChange, getPeriods, isChangingNumberOfPeriods } = useRenewalFees(props);

    function additionalCostsHasErrors() {
        return (
            props.validator?.showErrors &&
            (props.validator.hasErrors("additionalCostsForOfficialFeesForFirstDesign") ||
                props.validator.hasErrors("additionalCostsForAttorneyFeesForFirstDesign") ||
                props.validator.hasErrors("additionalCostsForOfficialFeesInEachAdditionalDesign") ||
                props.validator.hasErrors("additionalCostsForAttorneyFeesInEachAdditionalDesign"))
        );
    }

    return (
        <Box>
            <SectionHeader isPdf>
                <DotLegalHeader headerStyle={"large"} marginBottom={0}>
                    Fees
                </DotLegalHeader>
            </SectionHeader>

            <OutlinedQuestionBox
                header="Is it possible to renew design registrations?"
                headerMarginBottom={0}
                hasError={props.validator?.hasErrors("renewalIsPossible") && props.validator.showErrors}
                isPdf={props.isPdf}
            >
                <YesNoBox
                    value={props.data?.renewalIsPossible}
                    onValueChange={(value) => {
                        props.data!.renewalIsPossible = value;
                        props.onChange(props.data!);
                    }}
                    errorText={props.validator?.getErrorsForDisplay("renewalIsPossible")}
                />
            </OutlinedQuestionBox>

            {props.data && props.data.renewalIsPossible && (
                <>
                    <Box
                        sx={{
                            "& .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                                width: "100%",
                            },
                        }}
                    >
                        <OutlinedQuestionBox
                            header="What is the maximum of renewal periods?"
                            headerMarginBottom={0}
                            hasError={numberOfDesingsref.current === null && props.validator?.showErrors}
                            isPdf={props.isPdf}
                        >
                            <DotLegalSelect
                                options={getPeriods()}
                                onChange={onPeriodChange}
                                placeholder={""}
                                label={""}
                                noOptionsLabel={""}
                                selectedItem={numberOfDesingsref.current ?? undefined}
                            />
                        </OutlinedQuestionBox>
                    </Box>

                    {props.data.multipleFilingFees && props.data.multipleApplicationsPossible && (
                        <OutlinedQuestionBox
                            header="Renewal fees for multiple applications"
                            headerMarginBottom={0}
                            hasError={props.validator?.hasErrors("multipleFilingFees") && props.validator.showErrors}
                            isPdf={props.isPdf}
                        >
                            <Periods
                                data={props.data}
                                surveyId={props.data.currentSurveyId}
                                periods={props.data.multipleFilingFees.periods}
                                onPeriodChange={(period) => {
                                    let tempModel = { ...props.data! };
                                    let foundPeriod = tempModel.multipleFilingFees?.periods.find((x) => x.id === period.id);
                                    if (foundPeriod) {
                                        foundPeriod = period;
                                        props.onChange(tempModel);
                                    }
                                }}
                                intervalArea={DesignSurveyIntervalArea.MultipleFilingFees}
                                errorIdsString={props.validator?.getErrorsForDisplay("multipleFilingFees")}
                                isPdf={props.isPdf}
                                disabled={isChangingNumberOfPeriods}
                            />
                        </OutlinedQuestionBox>
                    )}

                    {props.data.singleFilingFees && (
                        <OutlinedQuestionBox
                            header="Renewal fees for single applications"
                            headerMarginBottom={0}
                            hasError={props.validator?.hasErrors("singleFilingFees") && props.validator.showErrors}
                            isPdf={props.isPdf}
                        >
                            <Periods
                                data={props.data}
                                surveyId={props.data.currentSurveyId}
                                periods={props.data.singleFilingFees.periods}
                                onPeriodChange={(period) => {
                                    let tempModel = { ...props.data! };
                                    let foundPeriod = tempModel.singleFilingFees?.periods.find((x) => x.id === period.id);

                                    if (foundPeriod) {
                                        foundPeriod = period;
                                        props.onChange(tempModel);
                                    }
                                }}
                                intervalArea={DesignSurveyIntervalArea.SingleFilingFees}
                                errorIdsString={props.validator?.getErrorsForDisplay("singleFilingFees")}
                                isPdf={props.isPdf}
                                disabled={isChangingNumberOfPeriods}
                            />
                        </OutlinedQuestionBox>
                    )}

                    <OutlinedQuestionBox
                        header="Is it possible to renew in grace period?"
                        headerMarginBottom={0}
                        hasError={props.validator?.hasErrors("isGracePeriodRenewalPossible") && props.validator.showErrors}
                        isPdf={props.isPdf}
                    >
                        <YesNoBox
                            value={props.data?.isGracePeriodRenewalPossible}
                            onValueChange={(value) => {
                                props.data!.isGracePeriodRenewalPossible = value;
                                props.onChange(props.data!);
                            }}
                            errorText={props.validator?.getErrorsForDisplay("isGracePeriodRenewalPossible")}
                        />
                    </OutlinedQuestionBox>

                    {props.data.isGracePeriodRenewalPossible && (
                        <OutlinedQuestionBox
                            header={"Additional costs for late renewal during a grace period"}
                            hasError={additionalCostsHasErrors()}
                            isPdf={props.isPdf}
                        >
                            <OfficialAttorneyFeesBox
                                header={"a) 1st design"}
                                officialFee={props.data.additionalCostsForOfficialFeesForFirstDesign?.toString()}
                                attorneyFee={props.data.additionalCostsForAttorneyFeesForFirstDesign?.toString()}
                                onOfficialFeeChange={(value) => {
                                    let temoModel = { ...props.data! };
                                    temoModel.additionalCostsForOfficialFeesForFirstDesign = value;
                                    props.onChange(temoModel);
                                }}
                                onAttorneyFeeChange={(value) => {
                                    let temoModel = { ...props.data! };
                                    temoModel.additionalCostsForAttorneyFeesForFirstDesign = value;
                                    props.onChange(temoModel);
                                }}
                                officaialFeesErrorText={props.validator?.getErrorsForDisplay("additionalCostsForOfficialFeesForFirstDesign")}
                                attorneyFeesErrorText={props.validator?.getErrorsForDisplay("additionalCostsForAttorneyFeesForFirstDesign")}
                            />

                            <OfficialAttorneyFeesBox
                                header={"b) Each additional design"}
                                officialFee={props.data.additionalCostsForOfficialFeesInEachAdditionalDesign?.toString()}
                                attorneyFee={props.data.additionalCostsForAttorneyFeesInEachAdditionalDesign?.toString()}
                                onOfficialFeeChange={(value) => {
                                    let temoModel = { ...props.data! };
                                    temoModel.additionalCostsForOfficialFeesInEachAdditionalDesign = value;
                                    props.onChange(temoModel);
                                }}
                                onAttorneyFeeChange={(value) => {
                                    let temoModel = { ...props.data! };
                                    temoModel.additionalCostsForAttorneyFeesInEachAdditionalDesign = value;
                                    props.onChange(temoModel);
                                }}
                                officaialFeesErrorText={props.validator?.getErrorsForDisplay("additionalCostsForOfficialFeesInEachAdditionalDesign")}
                                attorneyFeesErrorText={props.validator?.getErrorsForDisplay("additionalCostsForAttorneyFeesInEachAdditionalDesign")}
                            />
                        </OutlinedQuestionBox>
                    )}
                </>
            )}
        </Box>
    );
}

export default RenewalFees;
