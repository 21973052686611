import { DotLegalHeader, DotLegalRadioButton } from "@dotlegal/dotlegal-ui-components";
import { Box, RadioGroup, Typography } from "@mui/material";
import React from "react";
import OutlinedQuestionBox from "../../../../priceCalculator/outlinedQuestionBox/OutlinedQuestionBox";
import { CurrencyType } from "../../Survey.types";
import { convertRadioToNumberValue, SectionHeader } from "../../trademarkSurvey/Survey";

export interface ISurveyCurrency {
    currency: CurrencyType | undefined;
    onChange?: (type: number) => void;
    isPdf?: boolean;
    errorText?: string;
}

function SurveyCurrency(props: ISurveyCurrency) {
    const onChange = (type: number) => {
        if (props.onChange) {
            props.onChange!(type);
        }
    };

    return (
        <React.Fragment>
            <SectionHeader isPdf>
                <DotLegalHeader headerStyle={"large"} marginBottom={0}>
                    Currency
                </DotLegalHeader>
            </SectionHeader>

            <OutlinedQuestionBox header="Choose the currency of the prices entered by you." hasError={!!props.errorText} isPdf={props.isPdf}>
                <Box
                    sx={{
                        "& .MuiFormControlLabel-root": {
                            height: "28px",
                        },
                    }}
                >
                    <RadioGroup
                        value={props?.currency ?? ""}
                        onChange={(e) => {
                            onChange(convertRadioToNumberValue(e)!);
                        }}
                    >
                        <DotLegalRadioButton value={CurrencyType.USD} label="USD"></DotLegalRadioButton>
                        <DotLegalRadioButton value={CurrencyType.EUR} label="EUR"></DotLegalRadioButton>
                        <DotLegalRadioButton value={CurrencyType.GBP} label="GBP"></DotLegalRadioButton>
                    </RadioGroup>
                    <Typography sx={(theme) => ({ fontSize: 12, color: theme.customPalette.errorRed, marginTop: 0.5 })}>{props.errorText}</Typography>
                </Box>
            </OutlinedQuestionBox>
        </React.Fragment>
    );
}

export default SurveyCurrency;
