import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useContentCardStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.customPalette.white,
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(3),
        },
    })
);
export default useContentCardStyles;
