import { useEffect, useState } from "react";

export const useDebounceFunction = (func: Function, delay: number) => {
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    const debouncedFunction = (...args: any[]) => {
        if (timer) {
            clearTimeout(timer);
        }
        const newTimer = setTimeout(() => {
            func(...args);
        }, delay);
        setTimer(newTimer);
    };

    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    return debouncedFunction;
};
