import { isAfter, isEqual } from "date-fns";
import { isNullOrWhitespace } from "../../stringOperations";
import { ValidatorFn } from "./types";

export class Validators<TItem> {
    private urlRegex = RegExp(/^(https?|ftp):\/\/(-\.)?([^\s/?\.#-]+\.?)+([^\s]*)$/i);

    public validateNotEmpty(valueSelector: (item: TItem) => any, valueName: string, disableRule?: (item: TItem) => boolean): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            const ruleDisabled = disableRule?.(item);
            if (((value || value === 0) && !isNullOrWhitespace(value.toString())) || ruleDisabled) {
                return undefined;
            }

            return `${valueName} is mandatory`;
        };
    }
    public validateAnyNotEmpty(valueSelector: (item: TItem) => any[], valueName: string): ValidatorFn<TItem> {
        return (item) => {
            const values = valueSelector(item);
            const errors = values.map((value) => {
                if (!value || !value.toString().trim()) {
                    return `${valueName} is mandatory`;
                }
                return undefined;
            });
            return errors.some((x) => x === undefined) ? undefined : errors.find((x) => x !== undefined);
        };
    }

    public validateCheckedIsNotNull(valueSelector: (item: TItem) => any, valueName: string): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            if (value === null) {
                return `${valueName} is mandatory`;
            }
            return undefined;
        };
    }

    public validateChecked(valueSelector: (item: TItem) => any, valueName: string): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            if (!value) {
                return `${valueName} must be checked`;
            }
            return undefined;
        };
    }

    public validateEmail(valueSelector: (item: TItem) => any): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            if (!value) return undefined;
            const emailValidator = RegExp(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
            return emailValidator.test(value.toString()) ? undefined : "The email entered is not valid";
        };
    }

    public validateUrl(valueSelector: (item: TItem) => any): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            if (!value) return undefined;
            return this.urlRegex.test(value.toString()) ? undefined : "The url entered is not valid. (The url has to start with http:// or https://)";
        };
    }

    public validateUrlAndNotEmpty(valueSelector: (item: TItem) => any, valueName: string, disableRule?: (item: TItem) => boolean): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            const ruleDisabled = disableRule?.(item);

            if (((value || value === 0) && !isNullOrWhitespace(value.toString())) || ruleDisabled) {
                return this.urlRegex.test(value.toString()) ? undefined : "The url entered is not valid. (The url has to start with http:// or https://)";
            }

            return `${valueName} is mandatory`;
        };
    }

    public validateAfterStart(
        startLabel: string,
        endLabel: string,
        valueSelector: (item: TItem) => { start?: Date | null; end?: Date | null }
    ): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            if (!value) return undefined;
            const { start, end } = value;
            if (!start || !end) return undefined;

            if (isAfter(end, start)) return undefined;

            return `${endLabel} must be after ${startLabel}`;
        };
    }

    public validateSameOrAfterStart(
        startLabel: string,
        endLabel: string,
        valueSelector: (item: TItem) => { start?: Date | null; end?: Date | null }
    ): ValidatorFn<TItem> {
        return (item) => {
            const value = valueSelector(item);
            if (!value) return undefined;
            const { start, end } = value;
            if (!start || !end) return undefined;

            if (isEqual(end, start) || isAfter(end, start)) return undefined;

            return `${endLabel} must be the same or after ${startLabel}`;
        };
    }
}
