import { useTheme } from "@mui/material";
import { createSxStyles } from "../createSxStyles";
import { commonInputStyles } from "./Input.styles";
import { IpPortalTextFieldProps } from "./IpPortalTextField";

export const useIpPortalTextFieldStyles = (props: IpPortalTextFieldProps) => {
    const theme = useTheme();
    return createSxStyles({
        inputField: () => {
            let isFilled = !!(props.value && props.value.length > 0 && props.disabled !== true);
            let commonStyles = commonInputStyles(theme, props.variant, isFilled, !!props.disabled, !!props.alwaysShowUnderline, props.textDecoration);
            return {
                ...commonStyles,
                width: "100%",
            };
        },
        textField: () => {
            return {
                marginTop: props.noMargin ? 0 : theme.spacing(2),
                marginBottom: props.noMargin ? 0 : theme.spacing(1),
                display: props.toolTipText ? "flex" : "",
                position: "relative",
            };
        },
        toolTip: () => {
            return {
                position: "absolute",
                right: -31,
                top: props.multiline ? 11 : 10,
            };
        },
    });
};
