import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const inputContainerWidth = 270;

export const useConfigureUserStyles = makeStyles((theme: Theme) => ({
    configureUserForm: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(5.5),
        alignItems: "center",
        paddingTop: "10px",
    },
    inputContainer: {
        width: inputContainerWidth,
    },
    phoneNumber: {
        display: "flex",
        justifyContent: "space-between",
        gap: theme.spacing(1.5),
        width: inputContainerWidth,
    },
}));

export default useConfigureUserStyles;
