import { Redirect } from "react-router-dom";
import { UserSession } from "./AuthService";

type SignInCallbackProps = {
    userSession: UserSession;
};
function SignInCallback(props: SignInCallbackProps) {
    let redirectTo = sessionStorage.getItem("location-before-sign-in");
    if (!redirectTo || redirectTo === "/") {
        redirectTo = "/dashboard";
    }
    if ((props.userSession.verifiedUser?.organizations.length ?? 0) > 1 && !props.userSession.selectedOrg?.id) {
        if (!redirectTo.startsWith("/change-organisation/")) {
            redirectTo = `/select-organisation?redirect=${encodeURIComponent(redirectTo)}`;
        }
    } else {
        if (
            props.userSession.verifiedUser &&
            props.userSession.verifiedUser.organizations.length === 0 &&
            props.userSession.verifiedUser.userRoles.some((r) => r.name === "Admin")
        ) {
            redirectTo = "/admin";
        }
    }

    return <Redirect to={redirectTo} />;
}

export default SignInCallback;
