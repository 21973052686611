import { alpha, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { NavLinkListProps } from "./NavLinkMainList";

const useNavLinkListStyles = makeStyles((theme: Theme) =>
    createStyles({
        navList: (props: NavLinkListProps) => ({
            listStyleType: "none",
            padding: 0,
            "& li": {
                display: props.isMinimized ? "flex" : "block",
                justifyContent: props.isMinimized ? "center" : undefined,
                marginBottom: theme.spacing(1),
                fontWeight: theme.typography.fontWeightBold,
                "& a": {
                    fontSize: theme.typography.pxToRem(14),
                    display: "block",
                    padding: theme.spacing(2),
                    paddingTop: theme.spacing(1.5),
                    paddingBottom: theme.spacing(1.5),
                    textDecoration: "none",
                    color: theme.palette.text.secondary,
                    "& div": {
                        display: "flex",
                        alignItems: "center",
                        "& img": {
                            marginRight: props.isMinimized ? theme.spacing(0) : theme.spacing(3),
                            width: theme.customSpacing.iconSize,
                        },
                    },
                    "&.active": {
                        backgroundColor: alpha(theme.palette.secondary.light, 0.5),
                        borderRadius: theme.spacing(2),
                        color: theme.palette.text.primary,
                        "&:hover": {
                            color: theme.palette.text.secondary,
                            backgroundColor: theme.palette.secondary.light,
                        },
                    },
                    "&:hover": {
                        borderRadius: theme.spacing(2),
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.grey.A100,
                    },
                },
            },
        }),
        bottomNavList: {
            marginTop: theme.spacing(10),
            backgroundColor: theme.customPalette.lightBlue,
            borderRadius: 20,
        },
    })
);

export default useNavLinkListStyles;
