import { Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getTotalCaseCount } from "../cases/CaseService";
import useWorldMapStyles from "./WorldMap.styles";
import { CaseStatus, CaseStatusCountryCases } from "./WorldMap.types";
import WorldMapCaseStatusFilter from "./WorldMapCaseStatusFilter";
import { createMap, updateMapData, zoomOnMap } from "./WorldMapService";
import WorldMapZoomButton from "./WorldMapZoomButton";

export interface WorldMapProps {
    cases: Partial<CaseStatusCountryCases> | undefined;
    mapIsExpanded: boolean;
    selectedCaseStatus: CaseStatus;
    onSelectedCaseStatusChanged(caseStatus: CaseStatus): void;
}

function WorldMap(props: WorldMapProps) {
    const { onSelectedCaseStatusChanged, mapIsExpanded } = props;
    const classes = useWorldMapStyles(props);

    const [map, setMap] = useState<any>(null);
    const mapContainerRef = React.createRef<HTMLDivElement>();
    const mapContainerElement = <div className={classes.mapContainer} ref={mapContainerRef} />;
    const [worldMapIsOpen, setWorldMapIsOpen] = useState(mapIsExpanded ?? true);

    const onCaseStatusFilterClicked = (caseStatus: CaseStatus) => {
        if (caseStatus === props.selectedCaseStatus) return;
        onSelectedCaseStatusChanged(caseStatus);
    };

    useEffect(() => {
        if (!map)
            setMap(
                createMap({
                    countryCasesConfig: {
                        countryCases: props.cases?.[props.selectedCaseStatus] ?? [],
                        CaseStatus: props.selectedCaseStatus ?? "registered",
                    },
                    container: mapContainerRef.current!,
                })
            );
        else {
            setMap(
                updateMapData(map, {
                    countryCasesConfig: {
                        countryCases: props.cases?.[props.selectedCaseStatus]!,
                        CaseStatus: props.selectedCaseStatus,
                    },
                    container: mapContainerRef.current!,
                })
            );
        }
        // eslint-disable-next-line
    }, [props.cases]);

    return (
        <div className={`${classes.worldMapContainer}`}>
            <div className={`${classes.collapseToggleContainer}`}>
                {!worldMapIsOpen && <div style={{ color: "#B8C0CB", fontWeight: "bold" }}>World map</div>}
                <div
                    role="button"
                    onKeyPress={() => setWorldMapIsOpen(!worldMapIsOpen)}
                    onClick={() => setWorldMapIsOpen(!worldMapIsOpen)}
                    tabIndex={0}
                    style={{
                        background: "#707070",
                        borderRadius: "100%",
                        height: 20,
                        width: 20,
                        alignSelf: "end",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        marginLeft: "auto",
                    }}
                >
                    <hr
                        style={{
                            margin: "0 auto",
                            width: 10,
                            height: 2,
                            border: "none",
                            color: "#DFDFDF",
                            backgroundColor: "#DFDFDF",
                        }}
                    />
                    {!worldMapIsOpen && (
                        <hr
                            style={{
                                margin: "0 auto",
                                position: "absolute",
                                width: 2,
                                height: 10,
                                border: "none",
                                color: "#DFDFDF",
                                backgroundColor: "#DFDFDF",
                            }}
                        />
                    )}
                </div>
            </div>
            <Collapse in={worldMapIsOpen}>
                <div className={classes.worldMapWrapper}>
                    {mapContainerElement}
                    <div className={classes.caseStatusFilterContainer}>
                        {props.cases &&
                            (Object.keys(props.cases) as CaseStatus[]).map((status) => (
                                <WorldMapCaseStatusFilter
                                    totalCasesCount={getTotalCaseCount(props.cases?.[status]!)}
                                    caseStatus={status}
                                    active={props.selectedCaseStatus === status}
                                    onClick={() => onCaseStatusFilterClicked(status)}
                                    key={status}
                                />
                            ))}
                    </div>
                    <WorldMapZoomButton onZoomInClick={() => zoomOnMap(map, 1)} onZoomOutClick={() => zoomOnMap(map, -1)} />
                </div>
            </Collapse>
        </div>
    );
}

export default WorldMap;
