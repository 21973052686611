import { Box, Button, CircularProgress, CSSObject, TextField } from "@mui/material";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import DotLegalModal from "../../common/modal/DotLegalModal";
import { Case, RenewalRemarkModalViewModel, RenewalStatus } from "./Case.types";
import { getRenewal, updateRenewalRemark, updateRenewalStatus } from "./CaseService";

export interface RenewalRemarkModalProps {
    internalId?: string;
    remarkOpen: boolean;
    setRemarkOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isNewExpireOrRenew?: boolean;
    newStatus?: RenewalStatus;
    setStatus?: React.Dispatch<React.SetStateAction<RenewalStatus>>;
    onCaseUpdated?: (updatedCase: Case) => void;
}

const getTitle = (status?: RenewalStatus) => {
    let text = "";
    switch (status) {
        case RenewalStatus.Awaiting:
            text = "Await Registration ";
            break;
        case RenewalStatus.Renew:
            text = "Renew Registration ";
            break;
        case RenewalStatus.Expire:
            text = "No Renewal ";
            break;
        default:
            text = "Renewal Remark ";
            break;
    }

    return (
        <Box display={"flex"} alignItems={"center"}>
            {text}
        </Box>
    );
};

const getTypeRenewalText = (status: RenewalStatus) => {
    switch (status) {
        case RenewalStatus.Awaiting:
            return `Are you sure you want to await the following registration:`;
        case RenewalStatus.Renew:
            return `Are you sure you want to renew the following registration:`;
        case RenewalStatus.Expire:
            return `Are you sure you want to let the following registration expire:`;
    }
};

const getActionButtonText = (status: RenewalStatus) => {
    switch (status) {
        case RenewalStatus.Awaiting:
            return "Save";
        case RenewalStatus.Renew:
            return "Renew";
        case RenewalStatus.Expire:
            return "Expire";
    }
};

const RenewalRemarkModal: FunctionComponent<RenewalRemarkModalProps> = (props) => {
    const [caseObj, setCaseObj] = useState<RenewalRemarkModalViewModel>();
    const [remark, setRemark] = useState<string>();

    useEffect(() => {
        if (caseObj || !props.internalId) return;
        getRenewal(props.internalId).then((data) => {
            if (data) {
                setCaseObj(data);
                setRemark(data.renewalRemark);
            }
        });
    }, [caseObj, props.internalId]);

    const onCloseModal = useCallback(() => {
        setCaseObj(undefined);
        setRemark(undefined);
        props.setRemarkOpen(false);
    }, [props]);

    const saveStatusAndRemarkMutation = useMutation(updateRenewalStatus, {
        onSuccess: (data) => {
            if (props.setStatus && props.newStatus) props.setStatus(props.newStatus);
            const updatedCases = data.value();
            if (props && props.onCaseUpdated && updatedCases && updatedCases.length > 0) {
                updatedCases.forEach((x) => {
                    props.onCaseUpdated?.(x);
                });
            }
            onCloseModal();
        },
    });

    const saveRemarkMutation = useMutation(updateRenewalRemark, {
        onSuccess: (data) => {
            if (props.isNewExpireOrRenew && props.setStatus && props.newStatus) {
                props.setStatus(props.newStatus);
            }
            const updatedCase = data.value();
            if (props.onCaseUpdated && updatedCase) props.onCaseUpdated(updatedCase);
            onCloseModal();
        },
    });

    const onClickSaveRemark = () => {
        if (remark !== undefined && props.internalId)
            saveRemarkMutation
                .mutateAsync({
                    internalId: props.internalId,
                    remark: remark,
                })
                .then();
    };

    const onClickSaveStatusAndRemark = () => {
        if (remark !== undefined && props.internalId && props.isNewExpireOrRenew && props.setStatus && props.newStatus) {
            saveStatusAndRemarkMutation
                .mutateAsync({
                    internalId: props.internalId,
                    remark: remark,
                    status: props.newStatus,
                })
                .then();
        }
    };

    const modalTitle = useMemo(() => {
        return getTitle(props.newStatus);
    }, [props.newStatus]);

    const content = useMemo(() => {
        return caseObj ? (
            <Box>
                {props.isNewExpireOrRenew && props.newStatus ? (
                    <Box color={(theme) => theme.palette.primary.main} textAlign={"center"}>
                        {getTypeRenewalText(props.newStatus)}
                    </Box>
                ) : (
                    <></>
                )}
                {caseObj && (
                    <Box marginBottom={(theme) => theme.spacing(2)} color={(theme) => theme.palette.secondary.main} textAlign={"center"}>
                        <Box component={"span"} fontWeight={"bold"}>
                            {caseObj?.name}
                            {caseObj?.country ? `, ${caseObj.country}` : ""}
                            {caseObj.classes && caseObj.classes?.length > 0 ? `, Classes ${caseObj?.classes?.map((x) => x.value)?.join(",")}` : ""}
                        </Box>
                        {props.isNewExpireOrRenew && (
                            <Box component={"span"} color={(theme) => theme.palette.primary.main}>
                                ?
                            </Box>
                        )}
                    </Box>
                )}
                <TextField
                    value={remark}
                    color={"primary"}
                    onChange={(value) => {
                        setRemark(value.currentTarget.value);
                    }}
                    label={"Remark"}
                    placeholder={"Insert a remark..."}
                    size={"medium"}
                    rows={10}
                    multiline
                    sx={(theme) => ({ width: "100%" } as CSSObject)}
                />
                <Box marginTop={(theme) => theme.spacing(1)} color={(theme) => theme.customPalette.darkBlue}>{`${remark?.length ?? 0}/1000`}</Box>
            </Box>
        ) : (
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <CircularProgress />
            </Box>
        );
    }, [caseObj, props, remark]);

    return (
        <>
            <DotLegalModal
                open={props.remarkOpen}
                onClose={onCloseModal}
                title={modalTitle}
                titleToContentPadding={"slim"}
                content={content}
                action={
                    <>
                        {!caseObj ? (
                            <></>
                        ) : saveRemarkMutation.isLoading || saveStatusAndRemarkMutation.isLoading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                color={"primary"}
                                onClick={props.isNewExpireOrRenew && props.setStatus && props.newStatus ? onClickSaveStatusAndRemark : onClickSaveRemark}
                                value={"Submit"}
                                variant={"outlined"}
                            >
                                {getActionButtonText(props.newStatus ?? RenewalStatus.Awaiting)}
                            </Button>
                        )}
                    </>
                }
            />
        </>
    );
};

export default RenewalRemarkModal;
