import { DotLegalHeader, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import React from "react";

export interface OfficialAttorneyFeesBoxProps {
    header: string;
    officialFee: string | undefined;
    attorneyFee: string | undefined;
    officaialFeesErrorText?: string;
    attorneyFeesErrorText?: string;
    onOfficialFeeChange: (fee: number | undefined) => void;
    onAttorneyFeeChange: (fee: number | undefined) => void;
}

function OfficialAttorneyFeesBox(props: OfficialAttorneyFeesBoxProps) {
    const convertValueToNumber = (value: string) => {
        return value ? Number(value) : undefined;
    };

    return (
        <React.Fragment>
            <DotLegalHeader headerStyle="small" marginBottom={0}>
                {props.header}
            </DotLegalHeader>

            <DotLegalTextField
                label={"Official fees"}
                value={props.officialFee ?? null}
                errorText={props.officaialFeesErrorText}
                debounce
                onChange={(value) => {
                    props.onOfficialFeeChange(convertValueToNumber(value));
                }}
                handleNumber
                thousandSeperator="en"
            />
            <DotLegalTextField
                label={"Attorney's fees"}
                value={props.attorneyFee ?? null}
                debounce
                errorText={props.attorneyFeesErrorText}
                onChange={(value) => {
                    props.onAttorneyFeeChange(convertValueToNumber(value));
                }}
                handleNumber
                thousandSeperator="en"
            />
        </React.Fragment>
    );
}

export default OfficialAttorneyFeesBox;
