import { Theme } from "@mui/material";

export type inputVariant = "standard" | "outlined";
export type textDecoration = "line-through";

export function commonInputStyles(
    theme: Theme,
    variant: inputVariant | undefined,
    isFilled: boolean,
    disabled: boolean,
    alwaysShowUnderline: boolean,
    textDecoration: textDecoration | undefined
) {
    const fontSize = 14;
    return {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: disabled ? theme.palette.text.disabled : isFilled ? theme.palette.primary.main : theme.palette.grey.A400,
            fontSize: theme.typography.pxToRem(fontSize),
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #b4b4b4",
        },
        "& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "1px solid #b4b4b4",
            borderBottomColor: "#b4b4b4",
        },
        "& .MuiInputBase-root.MuiInput-root::before": {
            transition: "none",
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: disabled ? theme.palette.text.disabled : isFilled ? theme.palette.primary.main : theme.palette.grey.A400,
        },
        "& .MuiFormLabel-root": {
            fontSize: variant === "standard" ? theme.typography.pxToRem(16) : undefined,
            fontWeight: variant === "standard" ? theme.typography.fontWeightBold : undefined,
            color: variant === "standard" ? theme.palette.primary.dark : isFilled ? theme.palette.primary.dark : theme.palette.grey.A400,
        },
        "& .MuiInputBase-input": {
            color: isFilled ? theme.palette.primary.dark : "inherit",
            fontSize: theme.typography.pxToRem(fontSize),
            "&::placeholder": {
                color: theme.palette.grey["400"],
                opacity: 1,
            },
            textDecoration: textDecoration,
        },
        "& .MuiInputBase-root::before": {
            border: alwaysShowUnderline ? undefined : 0,
        },
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.38)",
        },
        "& .Mui-focused": {
            color: theme.palette.primary.dark,
        },
    };
}
