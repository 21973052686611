import useContentCardStyles from "./ContentCard.styles";

export interface IContentCardProps {
    children: any;
}

function ContentCard(props: IContentCardProps) {
    const classes = useContentCardStyles();
    return <div className={classes.root}>{props.children}</div>;
}

export default ContentCard;
