import { DotLegalInformationTooltip } from "@dotlegal/dotlegal-ui-components";
import { Box, Skeleton, TextField } from "@mui/material";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { isNullOrWhitespace } from "../stringOperations";
import { inputVariant, textDecoration } from "./Input.styles";
import { useIpPortalTextFieldStyles } from "./IpPortalTextField.styles";

export interface IpPortalTextFieldProps {
    label: string;
    value: string | null;
    disabled?: boolean;
    onChange?: (value: string) => void;
    multiline?: boolean;
    expandToMultilineOnFocus?: boolean;
    rows?: number;
    placeholder?: string;
    errorText?: string;
    size?: "small" | "medium";
    className?: string;
    toolTipText?: string;
    isLoading?: boolean;
    handleNumber?: boolean;
    noMargin?: boolean;
    debounce: boolean;
    helperText?: string;
    hasError?: boolean;
    variant?: inputVariant;
    id?: string;
    alwaysShowUnderline?: boolean;
    textDecoration?: textDecoration;
    inputRef?: MutableRefObject<HTMLTextAreaElement | undefined>;
    pdfClassName?: string;
    maxLength?: number;
    maxLengthErrorText?: string;
    thousandSeperator?: "da" | "en";
    startAdorment?: React.ReactNode;
    endAdorment?: React.ReactNode;
    disableClearable?: boolean;
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    thousandSeperator?: "da" | "en";
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    let thousandSeparator = ",";
    let decimalSeparator = ".";
    if (props.thousandSeperator === "da") {
        thousandSeparator = ".";
        decimalSeparator = ",";
    }

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            allowNegative={false}
            decimalScale={0}
            fixedDecimalScale
            valueIsNumericString
        />
    );
});

function IpPortalTextField(props: IpPortalTextFieldProps) {
    const styles = useIpPortalTextFieldStyles(props);
    const timerRef: { current: NodeJS.Timeout | null } = useRef(null);
    const [text, setText] = useState(props.value);
    const [hasFocus, setHasFocus] = useState(false);
    const re = /^[0-9\b]+$/;
    const formatNumberWithSeperator = props.handleNumber === true && props.thousandSeperator !== undefined;
    useEffect(() => {
        if (text !== props.value && props.onChange) {
            if (props.debounce) {
                timerRef.current = setTimeout(() => {
                    onChange();
                }, 1000);

                // return a cleanup function that clears the timeout
                return () => {
                    clearTimeout(timerRef.current as NodeJS.Timeout);
                };
            } else {
                onChange();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);

    useEffect(() => {
        if (text !== props.value) {
            if (!timerRef.current) {
                // Hvis der er en debounce timer i gang, så er man ved at skrive i feltet, og derfor
                // skal vi ikke overskrive feltets indhold med noget udefra
                setTextValue(props.value);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const IsValidNumber = (value: string) => {
        if (props.handleNumber === true && (re.test(value) || value === "")) return true;

        return false;
    };

    const handleInput = (eventValue: string) => {
        /* const disableClear = props.disableClearable === true && isNullOrWhitespace(eventValue); */
        if (props.onChange) {
            if (IsValidNumber(eventValue)) {
                setText(eventValue);
            } else if (props.handleNumber === false || props.handleNumber === undefined) {
                setTextValue(eventValue);
            }
        }
    };

    const doInsertText = (eventValue: string | null) => {
        if (eventValue) {
            if (props.maxLength) {
                if (props.maxLength < eventValue.length) {
                    return false;
                }
            }
        }
        return true;
    };

    const setTextValue = (eventValue: string | null) => {
        let shouldInsertText = doInsertText(eventValue);
        if (!shouldInsertText) {
            eventValue = eventValue!.substring(0, props.maxLength);
        }

        setText(eventValue);
    };

    const onBlur = () => {
        if (props.debounce && props.onChange) {
            if (IsValidNumber(text ?? "")) {
                onChange();
            } else if (props.handleNumber === false || props.handleNumber === undefined) {
                onChange();
            }
        }

        if (props.expandToMultilineOnFocus) {
            setHasFocus(false);
        }
    };

    const onFocus = () => {
        if (props.expandToMultilineOnFocus) {
            setHasFocus(true);
        }
    };

    const onChange = () => {
        if (text !== props.value) {
            props.onChange!(text ?? "");
        }

        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    };

    const getHelperText = () => {
        if (props.errorText) {
            return props.errorText;
        }

        if (props.maxLength && text) {
            if (props.maxLength <= text.length) {
                return props.maxLengthErrorText;
            }
        }

        return "";
    };

    let additionalInputProps = {};
    if (formatNumberWithSeperator) {
        additionalInputProps = {
            inputComponent: NumericFormatCustom as any,
        };
    }

    const variant = props.variant ?? "outlined";

    let textField = (
        <TextField
            InputProps={{
                ...additionalInputProps,
                startAdornment: props.startAdorment,
                endAdornment: props.endAdorment,
            }}
            inputProps={{
                maxLength: props.handleNumber && formatNumberWithSeperator === false ? 9 : "",
                thousandSeperator: props.thousandSeperator,
            }}
            size={props.size === undefined ? "small" : props.size}
            sx={styles.inputField}
            // needed for pdf export
            className={props.pdfClassName}
            label={props.label}
            margin={"none"}
            variant={variant}
            value={text ?? ""}
            disabled={props.disabled}
            multiline={props.multiline || (props.expandToMultilineOnFocus && hasFocus) || (props.expandToMultilineOnFocus && (text ?? "").length > 0)}
            rows={props.rows}
            onChange={(e: any) => {
                handleInput(e.target.value);
            }}
            placeholder={props.placeholder}
            error={!isNullOrWhitespace(props.errorText) || props.hasError}
            helperText={getHelperText()}
            onBlur={onBlur}
            onFocus={onFocus}
            InputLabelProps={{
                shrink: variant === "standard" ? true : undefined,
            }}
            id={props.id}
            inputRef={props.inputRef}
            autoComplete="off"
            autoFocus={hasFocus}
        />
    );

    return (
        <Box component="div" sx={styles.textField}>
            {props.isLoading ? (
                <Skeleton width={"100%"} sx={{ borderRadius: "10px" }} variant="rectangular">
                    {textField}
                </Skeleton>
            ) : (
                <>{textField}</>
            )}
            {props.toolTipText && (
                <Box sx={styles.toolTip}>
                    <DotLegalInformationTooltip text={props.toolTipText} />
                </Box>
            )}
        </Box>
    );
}

export default IpPortalTextField;
