import turquoiseArrowRight from "./arrow-right light blue.svg";

export interface DotLegalNavigationIconProps {
    onClick?: () => void;
}

function DotLegalNavigationIcon(props: DotLegalNavigationIconProps) {
    return (
        <div role="button" tabIndex={0} onClick={props.onClick} onKeyPress={props.onClick}>
            <img src={turquoiseArrowRight} alt="" />
        </div>
    );
}

export default DotLegalNavigationIcon;
