export interface SurveyOverviewModel {
    countryCode: string;
    latestSubmission?: Date;
    hasActiveSubmission: boolean;
    country: string;
    periodsAreValid?: boolean;
}

export enum SurveyArea {
    Trademarks = 1,
    Designs = 2,
}
