import { NecessaryDocumentsModel, SurveyType } from "../../priceCalculator/PriceCalculator.types";

export interface SurveyViewModel {
    id: string;
    type: SurveyType;
    currency: CurrencyType | undefined;
    firstClassCoversUpToThreeClasses: boolean | undefined;
    officialFeesForFirstClass: number | undefined;
    attorneyFeesForFirstClass: number | undefined;
    officialFeesInEachAdditionalClass: number | undefined;
    attorneyFeesInEachAdditionalClass: number | undefined;
    additionalCostsForOfficialFeesForFirstClass: number | undefined;
    additionalCostsForAttorneyFeesForFirstClass: number | undefined;
    additionalCostsForOfficialFeesInEachAdditionalClass: number | undefined;
    additionalCostsForAttorneyFeesInEachAdditionalClass: number | undefined;
    officialPublicationAndRegistrationFeesFirstClass: number | undefined;
    attorneyPublicationAndRegistrationFeesFirstClass: number | undefined;
    officialPublicationAndRegistrationFeesInEachAdditionalClass: number | undefined;
    attorneyPublicationAndRegistrationFeesInEachAdditionalClass: number | undefined;
    necessaryDocuments: NecessaryDocumentsModel | undefined;
    remarks: string | undefined;
    publicationOrRegistrationRemarks: string | undefined;
    isSubmitted: boolean;
}

export interface DesignSurveyViewModel {
    currentSurveyId: string;
    renewalSurveyId: string;

    type: SurveyType;
    currency: CurrencyType | undefined;

    multipleApplicationsPossible: boolean | undefined;
    renewalIsPossible: boolean | undefined;
    isGracePeriodRenewalPossible: boolean | undefined;
    isDefermentPossible: boolean | undefined;

    //filing fees
    singleFilingFees: DesignFilingFees | null;
    multipleFilingFees: DesignFilingFees | null;

    endOfDurationAmount: number | undefined;
    endOfDurationType: EndOfDurationType | undefined;

    //claiming priority
    additionalCostsForOfficialFeesForFirstDesign: number | undefined;
    additionalCostsForAttorneyFeesForFirstDesign: number | undefined;
    additionalCostsForOfficialFeesInEachAdditionalDesign: number | undefined;
    additionalCostsForAttorneyFeesInEachAdditionalDesign: number | undefined;

    defermentFees: DesignFeesIntervals | undefined;

    //publication and/or registration fees
    officialPublicationAndRegistrationFeesFirstDesign: number | undefined;
    attorneyPublicationAndRegistrationFeesFirstDesign: number | undefined;
    officialPublicationAndRegistrationFeesInEachAdditionalDesign: number | undefined;
    attorneyPublicationAndRegistrationFeesInEachAdditionalDesign: number | undefined;

    necessaryDocuments: NecessaryDocumentsModel | undefined;

    remarks: string | undefined;
    publicationOrRegistrationRemarks: string | undefined;

    isSubmitted: boolean;
}

export enum EndOfDurationType {
    Month = 3,
    Year = 4,
}

interface DesignFilingFees {
    id: string;
    periods: Array<DesignFeesIntervals>;
}

export interface DesignFeesIntervals {
    id: string;
    number: number;
    intervals: Array<DesignInterval>;
}

export interface DesignInterval {
    id: string;
    order: number;
    from: number | null;

    /// <summary>
    /// If this is null it means there is no end, and is valid for the last interval in a period
    /// </summary>
    to: number | null;

    officialFees: number | null;
    attorneyFees: number | null;
}

export enum CurrencyType {
    USD,
    EUR,
    DKK,
    GBP,
}
