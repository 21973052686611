import adminIcon from "../icons/Admin.svg";
import selectOrganizationIcon from "../icons/Change organisation.svg";
import signoutIcon from "../icons/Signout.svg";
import { userHasAccessToMultipleOrganizations, userIsAdmin } from "../users/UserService";
import useNavLinkListStyles from "./NavLinkList.styles";
import NavLinkListItem from "./NavLinkListItem";
import { NavLinkListProps } from "./NavLinkMainList";

export type NavLinkUtilityListProps = NavLinkListProps & {
    onOrgSelectorIsOpenChange: (isOpen: boolean) => void;
};

function NavLinkUtilityList(props: NavLinkUtilityListProps) {
    const classes = useNavLinkListStyles({ ...props });

    return (
        <ul className={`${classes.navList} ${classes.bottomNavList}`}>
            {userIsAdmin(props.userSession.verifiedUser) && <NavLinkListItem title="Admin" linkTo="/admin" icon={adminIcon} isMinimized={props.isMinimized} />}
            {userHasAccessToMultipleOrganizations(props.userSession.verifiedUser) && (
                <div role="button" tabIndex={0} onKeyPress={() => props.onOrgSelectorIsOpenChange(true)} onClick={() => props.onOrgSelectorIsOpenChange(true)}>
                    <NavLinkListItem title="Select organisation" linkTo="/select-organisation" icon={selectOrganizationIcon} isMinimized={props.isMinimized} />
                </div>
            )}
            <div role="button" tabIndex={0}>
                <NavLinkListItem title="Log out" linkTo={"/sign-out"} icon={signoutIcon} isMinimized={props.isMinimized} isActive={() => false} />
            </div>
        </ul>
    );
}

export default NavLinkUtilityList;
