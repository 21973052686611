import { useEffect } from "react";
import { invalidateUserSession } from "./AuthService";

export interface ISignOutProps {}

function SignOut(props: ISignOutProps) {
    useEffect(() => {
        invalidateUserSession();
    });

    return <div></div>;
}

export default SignOut;
