import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useCostsStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchTextContainer: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: 48,
            paddingLeft: 48,
        },
        widgetContainer: {
            height: 380,
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(4),
            paddingLeft: 12,
            paddingRight: 12,
        },
        widgetContentContainer: {
            height: 280,
            fontWeight: "bold",
            position: "relative",
        },
        widgetSkeleton: {
            justifyContent: "space-around",
            display: "flex",
        },
        costsDateFilter: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 88,
            paddingLeft: 48,
            paddingRight: 48,
            width: "100%",
        },
        costsTable: {
            display: "flex",
            paddingLeft: 42,
            paddingRight: 48,
        },
        switchContainer: {
            display: "flex",
            alignItems: "center",
            paddingLeft: 6,
        },
    })
);

export default useCostsStyles;
