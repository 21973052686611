import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { FunctionComponent } from "react";

export interface RotatableProps {
    isRotated?: boolean;
    sx?: SxProps<Theme>;
}
export const RotatableStyles = makeStyles(() =>
    createStyles({
        root: {
            transition: "transform 0.3s",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        rotated: {
            transform: "rotate(180deg)",
        },
    })
);

const Rotatable: FunctionComponent<RotatableProps> = (props) => {
    const classes = RotatableStyles();
    return (
        <Box sx={props.sx} className={`${classes.root}${props.isRotated ? " " + classes.rotated : ""}`}>
            {props.children}
        </Box>
    );
};

export default Rotatable;
