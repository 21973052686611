import { DotLegalFullScreenSpinner, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, Button, CircularProgress, lighten } from "@mui/material";
import { useIsOnMediumScreen } from "../../../../common/hooks/mediaHooks";
import bbLogo from "../../../../core/navMenu/BB logo.svg";
import NecessaryDocuments from "../../../priceCalculator/necessaryDocuments/NecessaryDocuments";
import { SurveyArea } from "../../Surveys.types";
import SurveyCurrency from "../components/surveyCurrency/SurveyCurrency";
import SurveyHeader from "../components/surveyHeader/SurveyHeader";
import SurveyRemarks from "../components/surveyRemarks/SurveyRemarks";
import { useSurveyStyles } from "../Survey.styles";
import { getPageHaderText, Logo } from "../trademarkSurvey/Survey";
import { useDesignSurvey } from "./DesignSurvey.hooks";
import NewApplicationFees from "./fees/NewApplicationFees";
import RenewalFees from "./fees/RenewalFees";

export interface DesignSurveyProps {}

function DesignSurvey() {
    const isOnMediumScreen = useIsOnMediumScreen();
    const styles = useSurveyStyles(isOnMediumScreen);

    const { isLoading, data, updateSurvey, setPage, page, handleNextClick, surveyValidator, isSaving, scrollRef, scrollToTop, surveyRenewalValidator } =
        useDesignSurvey();

    let isNotRenewal = page !== 2;

    surveyValidator?.check(data);
    surveyRenewalValidator?.check(data);

    return (
        <Box sx={styles.container}>
            <Box sx={styles.logo} ref={scrollRef}>
                <Logo alt="logo" src={bbLogo} />
            </Box>
            <Box sx={styles.content}>
                <SurveyHeader
                    isOnMediumScreen={isOnMediumScreen}
                    backgroundColor={isNotRenewal ? "blue" : "green"}
                    header={`Agents Survey - Design ${page === 1 ? "Applications" : "Renewals"}`}
                >
                    <DotLegalHeader headerStyle="small" fontWeight={500}>
                        {getPageHaderText(page)}
                    </DotLegalHeader>
                </SurveyHeader>

                <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    {isLoading ? (
                        <Box sx={{ height: "100%", display: "flex", flexDirection: "column", flex: 1, justifyContent: "center" }}>
                            <DotLegalFullScreenSpinner />
                        </Box>
                    ) : (
                        <>
                            {isNotRenewal && (
                                <>
                                    <SurveyCurrency
                                        currency={data?.currency}
                                        onChange={(model) => {
                                            let tempModel = { ...data! };
                                            tempModel.currency = model;
                                            updateSurvey(tempModel);
                                        }}
                                        errorText={surveyValidator?.getErrorsForDisplay("currency")}
                                    />
                                </>
                            )}

                            {isNotRenewal ? (
                                <NewApplicationFees
                                    data={data}
                                    onChange={(model) => {
                                        updateSurvey(model);
                                    }}
                                    validator={surveyValidator}
                                />
                            ) : (
                                <RenewalFees
                                    data={data}
                                    onChange={(model) => {
                                        updateSurvey(model);
                                    }}
                                    validator={surveyRenewalValidator}
                                />
                            )}

                            {(isNotRenewal || data?.renewalIsPossible) && (
                                <>
                                    <NecessaryDocuments
                                        documents={data?.necessaryDocuments}
                                        onChange={(model) => {
                                            data!.necessaryDocuments = model;
                                            updateSurvey(data!);
                                        }}
                                        trademarkSurveyType={data?.type}
                                        surveyArea={SurveyArea.Designs}
                                    />

                                    <SurveyRemarks
                                        isNotRenewal={isNotRenewal}
                                        onRemarkChange={(remarks) => {
                                            data!.remarks = remarks;
                                            updateSurvey(data!);
                                        }}
                                        onPublicationOrRegistrationRemarks={(remarks) => {
                                            data!.publicationOrRegistrationRemarks = remarks;
                                            updateSurvey(data!);
                                        }}
                                        remarks={data?.remarks}
                                        publicationOrRegistrationRemarks={data?.publicationOrRegistrationRemarks}
                                    />
                                </>
                            )}
                            <Box
                                sx={(theme) => ({
                                    display: "flex",
                                    justifyContent: "space-between",
                                    "& .Mui-disabled": {
                                        backgroundColor: lighten(theme.palette.primary.main, 0.5),
                                    },
                                })}
                            >
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        setPage(1);
                                        scrollToTop();
                                    }}
                                    disabled={page === 1}
                                >
                                    Previous
                                </Button>

                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        handleNextClick();
                                    }}
                                >
                                    {getButtonText(isSaving, page)}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );

    function getButtonText(isSaving: boolean, pageNo: number | null) {
        if (isSaving) {
            return <CircularProgress color="inherit" size={20} />;
        }

        return pageNo === 2 ? "Submit" : "Next";
    }
}

export default DesignSurvey;
