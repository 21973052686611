import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useCaseModalContentStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "unset",
        margin: "unset",
    },
    caseLink: {
        textDecoration: "underline",
        cursor: "pointer",
        "&:hover": {
            fontWeight: "bold",
            color: "#32DACB",
            textDecoration: "underline",
        },
    },
    caseLinkSelected: {
        fontWeight: "bold",
    },
}));

export default useCaseModalContentStyles;
