import { DotLegalFullScreenSpinner, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, Button, CircularProgress, lighten, styled } from "@mui/material";
import { ChangeEvent } from "react";
import { useIsOnMediumScreen, useIsOnSmallScreen } from "../../../../common/hooks/mediaHooks";
import bbLogo from "../../../../core/navMenu/BB logo.svg";
import NecessaryDocuments from "../../../priceCalculator/necessaryDocuments/NecessaryDocuments";
import { SurveyArea } from "../../Surveys.types";
import FeesSection from "../components/feesSection/FeesSection";
import SurveyCurrency from "../components/surveyCurrency/SurveyCurrency";
import SurveyHeader from "../components/surveyHeader/SurveyHeader";
import SurveyRemarks from "../components/surveyRemarks/SurveyRemarks";
import { useSurveyStyles } from "../Survey.styles";
import { useSurvey } from "./Survey.hooks";

export const convertRadioToNumberValue = (e: ChangeEvent<HTMLInputElement>) => {
    return Number((e.target as HTMLInputElement).value);
};

type SectionHeaderProps = {
    isPdf?: boolean;
};

export const SectionHeader = styled(Box)<SectionHeaderProps>(({ theme, isPdf }) => ({
    background: "#B6D5EF 0% 0% no-repeat padding-box",
    border: "1px solid #8694C0",
    borderRadius: "10px",
    opacity: 1,
    marginTop: theme.spacing(3),
    marginBottom: isPdf ? theme.spacing(1) : theme.spacing(3),
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: isPdf ? "45px" : "65px",
    pageBreakInside: "avoid",
}));

export const Logo = styled("img")({
    width: 189,
    height: 41,
});

export function getPageHaderText(pageNo: number | null) {
    if (pageNo === 1 || pageNo === null) {
        return "Please fill out all boxes. The amounts should be exclusive of tax, and please avoid any ranges (e.g. 300-400). If you have any special comments, please write such comments in the box “Remarks” at the end of this section.";
    } else {
        return "Please fill out all boxes. The amounts should be exclusive of tax, and please avoid any ranges (e.g. 300-400). If you have any special comments, please write such comments in the box “Remarks” at the end of this section.";
    }
}

function Survey() {
    const isOnMediumScreen = useIsOnMediumScreen();
    const isOnSmallScreen = useIsOnSmallScreen();
    const styles = useSurveyStyles(isOnMediumScreen);
    const { isLoading, data, updateSurvey, setPage, page, handleNextClick, surveyValidator, isSaving, scrollRef, scrollToTop } = useSurvey();

    const commonQuestionBoxProps = {
        outlinedBoxPaddingY: 1,
        outlinedBoxPaddingX: isOnSmallScreen ? 1 : 10,
        largeHeader: true,
    };

    let isNotRenewal = page !== 2;

    surveyValidator?.check(data);

    return (
        <Box sx={styles.container}>
            <Box sx={styles.logo} ref={scrollRef}>
                <Logo alt="logo" src={bbLogo} />
            </Box>
            <Box sx={styles.content}>
                <SurveyHeader
                    isOnMediumScreen={isOnMediumScreen}
                    backgroundColor={isNotRenewal ? "blue" : "green"}
                    header={`Agents Survey - Trademark ${page === 1 ? "Applications" : "Renewals"}`}
                >
                    <DotLegalHeader headerStyle="small" fontWeight={500}>
                        {getPageHaderText(page)}
                    </DotLegalHeader>
                </SurveyHeader>

                <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    {isLoading ? (
                        <Box sx={{ height: "100%", display: "flex", flexDirection: "column", flex: 1, justifyContent: "center" }}>
                            <DotLegalFullScreenSpinner />
                        </Box>
                    ) : (
                        <>
                            {isNotRenewal && (
                                <>
                                    <SurveyCurrency
                                        currency={data?.currency}
                                        onChange={(model) => {
                                            let tempModel = { ...data! };
                                            tempModel.currency = model;
                                            updateSurvey(tempModel);
                                        }}
                                        errorText={surveyValidator?.getErrorsForDisplay("currency")}
                                        {...commonQuestionBoxProps}
                                    />
                                </>
                            )}

                            <FeesSection
                                data={data}
                                onChange={(model) => {
                                    updateSurvey(model);
                                }}
                                validator={surveyValidator}
                            />

                            <NecessaryDocuments
                                documents={data?.necessaryDocuments}
                                onChange={(model) => {
                                    data!.necessaryDocuments = model;
                                    updateSurvey(data!);
                                }}
                                trademarkSurveyType={data?.type}
                                {...commonQuestionBoxProps}
                                surveyArea={SurveyArea.Trademarks}
                            />

                            <SurveyRemarks
                                isNotRenewal={isNotRenewal}
                                onRemarkChange={(remarks) => {
                                    data!.remarks = remarks;
                                    updateSurvey(data!);
                                }}
                                onPublicationOrRegistrationRemarks={(remarks) => {
                                    data!.publicationOrRegistrationRemarks = remarks;
                                    updateSurvey(data!);
                                }}
                                remarks={data?.remarks}
                                publicationOrRegistrationRemarks={data?.publicationOrRegistrationRemarks}
                                {...commonQuestionBoxProps}
                            />

                            <Box
                                sx={(theme) => ({
                                    display: "flex",
                                    justifyContent: "space-between",
                                    "& .Mui-disabled": {
                                        backgroundColor: lighten(theme.palette.primary.main, 0.5),
                                    },
                                })}
                            >
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        setPage(1);
                                        scrollToTop();
                                    }}
                                    disabled={page === 1}
                                >
                                    Previous
                                </Button>

                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        handleNextClick();
                                    }}
                                >
                                    {getButtonText(isSaving, page)}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

function getButtonText(isSaving: boolean, pageNo: number | null) {
    if (isSaving) {
        return <CircularProgress color="inherit" size={20} />;
    }

    return pageNo === 2 ? "Submit" : "Next";
}

export default Survey;
