import { Route, Switch } from "react-router";
import ErrorPicture from "./errorPage/dotLegal-errorpage.png";
import Error401Picture from "./errorPage/Error 401.png";
import ErrorPage from "./errorPage/ErrorPage";

export interface IErrorRouterSwitch {
    children: any;
}

function ErrorRouterSwitch(props: IErrorRouterSwitch) {
    return (
        <Switch>
            <Route path="/unauthorized">
                <ErrorPage img={Error401Picture} headerText="401 - Unauthorized Error">
                    <div style={{ fontSize: 20 }}>
                        <div style={{ marginBottom: "20px" }}>In order to solve the error, please try the following:</div>

                        <div style={{ marginBottom: "20px" }}>
                            Check for errors in the URL. The link you used might be pointing to the wrong URL. For example, it could be <br /> outdated or lead
                            to a page that no longer exists. Try to login again using this link:&nbsp;
                            <a style={{ color: "#2CD5C4" }} href="http://www.ipportal.bechbruun.com">
                                www.ipportal.bechbruun.com
                            </a>
                        </div>

                        <div>If you don’t notice any issues with the page URL, you can try to clear your browser’s cache.</div>
                    </div>
                </ErrorPage>
            </Route>
            <Route path="/forbidden">
                <ErrorPage img={ErrorPicture}>Your current user role does not give access to the requested feature</ErrorPage>
            </Route>
            <Route path="/error">
                <ErrorPage img={ErrorPicture}>
                    An unexpected problem has occured. <br /> Our team has been notified about the problem and will investigate further.
                </ErrorPage>
            </Route>
            <Route path="/resource-not-found">
                <ErrorPage img={ErrorPicture} headerText="The resource was not found" />
            </Route>
            <Route path="*">{props.children}</Route>
        </Switch>
    );
}

export default ErrorRouterSwitch;
