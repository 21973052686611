import { useHistory, useLocation } from "react-router-dom";
import { CaseType } from "../../core/cases/Case.types";

export function useStateUrlParamsArray(key: string, defaultValue: Array<string>): [Array<string>, (v: Array<string>) => void] {
    const [value, setValue] = useStateUrlParams(key, defaultValue.join(","));
    return [
        value === null || value === "" ? [] : value.split(","),
        (newValue) => {
            setValue(newValue.join(","));
        },
    ];
}

export function useStateUrlParams(key: string, defaultValue: string | null): [string | null, (v: string | null) => void] {
    return useStateUrlParamsString(
        key,
        defaultValue,
        (p) => p,
        (s) => s
    );
}

export function useStateUrlParamsCaseType(key: string, defaultValue: CaseType | null): [CaseType | null, (n: CaseType | null) => void] {
    return useStateUrlParamsString(
        key,
        defaultValue,
        (p) => p.toString(),
        (s) => CaseType[s as keyof typeof CaseType]
    );
}

export function useStateUrlParamsNumber(key: string, defaultValue: number | null): [number | null, (n: number | null) => void] {
    return useStateUrlParamsString(
        key,
        defaultValue,
        (p) => p.toString(),
        (s) => Number(s)
    );
}
export function useStateUrlParamsDate(key: string, defaultValue: Date | null): [Date | null, (n: Date | null) => void] {
    return useStateUrlParamsString(
        key,
        defaultValue,
        (p) => p.getFullYear() + "-" + (p.getMonth() + 1) + "-" + p.getDate(),
        (s) => {
            let date = new Date(s);
            return isNaN(date.getDate()) ? null : date;
        }
    );
}

function useStateUrlParamsString<T>(
    key: string,
    defaultValue: T | null,
    convertTo: (p: T) => string,
    convertFrom: (s: string) => T | null
): [T | null, (v: T | null) => void] {
    const history = useHistory();
    const location = useLocation();
    const valueFromParams = new URLSearchParams(location.search);
    let value = defaultValue;
    if (valueFromParams.has(key)) {
        let valueFromUrl = valueFromParams.get(key);
        value = valueFromUrl === null || valueFromUrl === "" ? null : convertFrom(valueFromUrl);
    }
    return [
        value,
        (newValue) => {
            const urlSearchParams = new URLSearchParams(location.search);
            urlSearchParams.set(key, newValue ? convertTo(newValue) : "");
            history.push({
                pathname: location.pathname,
                search: urlSearchParams.toString(),
            });
        },
    ];
}
