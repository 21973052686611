export function useUrlProvider() {
    function getAdminUrl() {
        return "/admin";
    }

    function getUsersUrl() {
        return "/admin/users";
    }

    function getOrginsationsUrl() {
        return "/admin/organisations";
    }

    return {
        getAdminUrl,
        getUsersUrl,
        getOrginsationsUrl,
    };
}
