import { MoreVertOutlined } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { FunctionComponent, useState } from "react";
import { Case } from "../Case.types";

export interface RenewalOverflowMenuProps {
    case: Case;
    onCaseSelected: (caseSelection: { caseId: number; country: string }) => void;
    onRenewalRemarkSelected: (internalId: string) => void;
    renewalRegistrationActive: boolean;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        menuItem: { color: "black", fontSize: 14 },
        button: { backgroundColor: "transparent", minWidth: "auto", borderRadius: "20px" },
    })
);

const RenewalOverflowMenu: FunctionComponent<RenewalOverflowMenuProps> = (props) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    return (
        <>
            <Button
                color="primary"
                className={classes.button}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setMenuOpen(true);
                }}
            >
                <MoreVertOutlined color={"secondary"} />
            </Button>
            <Menu
                open={menuOpen}
                onClose={() => {
                    setMenuOpen(false);
                }}
                anchorEl={anchorEl}
            >
                <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                        props.onCaseSelected({
                            caseId: props.case.id,
                            country: props.case.country,
                        });
                        setMenuOpen(false);
                    }}
                >
                    View details
                </MenuItem>
                {props.renewalRegistrationActive && (
                    <MenuItem
                        className={classes.menuItem}
                        onClick={(event) => {
                            props.onRenewalRemarkSelected(props.case.internalId);
                            setMenuOpen(false);
                            event.stopPropagation();
                        }}
                    >
                        Renewal remark
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default RenewalOverflowMenu;
