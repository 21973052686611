import { Route, Switch } from "react-router";
import DesignSurveyPDF from "./pages/surveys/PDF/designSurvey/DesignSurveyPDF";
import SurveyPDF from "./pages/surveys/PDF/SurveyPDF";
import DesignSurvey from "./pages/surveys/survey/designSurvey/DesignSurvey";
import SurveySubmitted from "./pages/surveys/survey/surveySubmitted/SurveySubmitted";
import Survey from "./pages/surveys/survey/trademarkSurvey/Survey";
import { SurveyArea } from "./pages/surveys/Surveys.types";

export interface IAnonymousRouterSwitchProps {
    children: React.ReactNode;
}

function AnonymousRouterSwitch(props: IAnonymousRouterSwitchProps) {
    return (
        <Switch>
            <Route path="/survey/:newaapplicationid/:renewalid/design">
                <DesignSurvey />
            </Route>
            <Route path="/survey/:newaapplicationid/:renewalid">
                <Survey />
            </Route>
            <Route path="/survey-submitted/:newaapplicationid/:renewalid/trademark">
                <SurveySubmitted area={SurveyArea.Trademarks} />
            </Route>
            <Route path="/survey-submitted/:newaapplicationid/:renewalid/design">
                <SurveySubmitted area={SurveyArea.Designs} />
            </Route>
            <Route path="/survey-pdf/:newapplicationid/:renewalid/trademark">
                <SurveyPDF />
            </Route>
            <Route path="/survey-pdf/:newapplicationid/:renewalid/design">
                <DesignSurveyPDF />
            </Route>
            <Route path="*">{props.children}</Route>
        </Switch>
    );
}

export default AnonymousRouterSwitch;
