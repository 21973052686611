type Defined<T> = Exclude<T, undefined | null>;
export function toArray<T extends Exclude<any, undefined | null>>(value?: T | T[] | undefined | null): Defined<T>[] {
    if (!value) return [];

    if (Array.isArray(value)) return value.filter((v) => !!v) as Defined<T>[];

    return [value as Defined<T>];
}

export function minBy<T, U>(array: T[], selector: (item: T) => U): T | undefined {
    if (array.length === 0) return undefined;

    return array.reduce((min, current) => {
        const minValue = selector(min);
        const currentValue = selector(current);
        return minValue < currentValue ? min : current;
    });
}
