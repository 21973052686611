import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useBarChartStyles = makeStyles((theme: Theme) =>
    createStyles({
        widgetContentContainer: {
            fontWeight: "bold",
            position: "relative",
            height: 280,
        },
        widgetSkeletonContainer: {
            display: "flex",
            alignItems: "flex-end",
            gap: 54,
            justifyContent: "space-evenly",
            position: "relative",
            paddingLeft: 48,
            paddingBottom: 48,
            height: "100%",
        },
        widgetSkeletonBar: {
            width: 16,
            height: "80%",
            transform: "scale(1, 1)",
            borderRadius: 0,
        },
        widgetSkeletonX: {
            width: "93%",
            height: 2,
            position: "absolute",
            bottom: 48,
            left: 74,
        },
        widgetSkeletonY: {
            width: 10,
            height: "100%",
            position: "absolute",
            bottom: 0,
            borderRadius: 0,
        },
    })
);

export default useBarChartStyles;
