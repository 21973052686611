import { useDotLegalBreadCrumbs } from "@dotlegal/dotlegal-ui-components";
import { useEffect, useState } from "react";
import { UserSession } from "../../auth/AuthService";
import ContentCard from "../../common/contentCard/ContentCard";
import { useStateUrlParamsArray, useStateUrlParamsDate } from "../../common/hooks/useStateUrlParams";
import { CaseFilter } from "../../core/cases/Case.types";
import CaseFilters from "../../core/cases/CaseFilters";
import { caseTableArrangementForCsv, exportRenewalCasesExcel, initialCaseFilter } from "../../core/cases/CaseService";
import { CaseSummarization, CaseSummarizationPage } from "../../core/cases/CaseSummarizationPage";
import CaseTableWrapper from "../../core/cases/CaseTableWrapper";
import { CaseSummarizationProps } from "../../Main";

type RenewalsProps = CaseSummarizationProps & {
    onRenewalsEntered: (active: boolean) => void;
    userSession: UserSession;
};

function getFromToYear() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    return currentMonth < 6 ? currentDate.getFullYear() : currentDate.getFullYear() + 1;
}

function Renewals(props: RenewalsProps) {
    useDotLegalBreadCrumbs([{ name: "Renewals" }]);
    const [caseSummarization] = useState<CaseSummarization>(CaseSummarization.Renewals);

    const fromToYear = getFromToYear();
    const [catchwords, setCatchwords] = useStateUrlParamsArray("catchwords", []);
    const [dateFilterFrom, setDateFilterFrom] = useStateUrlParamsDate("from", new Date(`${fromToYear}-01-01`));
    const [dateFilterTo, setDateFilterTo] = useStateUrlParamsDate("to", new Date(`${fromToYear}-12-31`));
    const [classes, setClasses] = useStateUrlParamsArray("classes", []);
    const [countries, setCountries] = useStateUrlParamsArray("countries", []);
    const [applicants, setApplicants] = useStateUrlParamsArray("applicants", []);
    const [statuses, setStatuses] = useStateUrlParamsArray("statuses", []);
    const [searchString, setSearchString] = useState("");

    const [isLoadingExcel, setIsLoadingExcel] = useState(false);

    let caseFilter = initialCaseFilter("registered", CaseSummarization.Renewals, dateFilterFrom, dateFilterTo);
    if (catchwords) {
        caseFilter.catchwords = catchwords;
    }
    caseFilter.renewalDateFrom = dateFilterFrom;
    caseFilter.renewalDateTo = dateFilterTo;
    caseFilter.classes = classes;
    caseFilter.countries = countries;
    caseFilter.applicants = applicants;
    caseFilter.renewalStatuses = statuses;
    caseFilter.searchText = searchString;

    const getRenewalsExcel = () => {
        const filter = {
            ...caseFilter,
            ...caseTableArrangementForCsv,
        };
        setIsLoadingExcel(true);
        exportRenewalCasesExcel(props.selectedCaseType, filter)
            .then(() => setIsLoadingExcel(false))
            .catch(() => setIsLoadingExcel(false));
    };

    useEffect(() => {
        props.onRenewalsEntered(true);
        return () => props.onRenewalsEntered(false);
        // eslint-disable-next-line
    }, []);

    function setCaseFilter(caseFilter: CaseFilter) {
        if (catchwords !== (caseFilter.catchwords ?? "")) {
            setCatchwords(caseFilter.catchwords);
        }
        if (dateFilterFrom !== caseFilter.renewalDateFrom) {
            setDateFilterFrom(caseFilter.renewalDateFrom ?? null);
        }
        if (dateFilterTo !== caseFilter.renewalDateTo) {
            setDateFilterTo(caseFilter.renewalDateTo ?? null);
        }
        if (classes !== caseFilter.classes) {
            setClasses(caseFilter.classes);
        }
        if (countries !== caseFilter.countries) {
            setCountries(caseFilter.countries);
        }
        if (applicants !== caseFilter.applicants) {
            setApplicants(caseFilter.applicants);
        }
        if (statuses !== caseFilter.renewalStatuses) {
            setStatuses(caseFilter.renewalStatuses);
        }
        if (searchString !== caseFilter.searchText) {
            setSearchString(caseFilter.searchText ?? "");
        }
    }

    return (
        <CaseSummarizationPage
            onCaseFilterKeyChange={props.onCaseFilterKeyChanged}
            onCaseTypeFilterConfigurationClear={props.onCaseTypeFilterConfigurationCleared}
            caseSummarization={caseSummarization}
        >
            <ContentCard>
                <CaseFilters
                    caseSummarization={caseSummarization}
                    caseFilter={caseFilter}
                    onCaseFilterChanged={setCaseFilter}
                    selectedCaseType={props.selectedCaseType}
                    renewalRegistrationActive={props.userSession.selectedOrg?.renewalRegistrationActive ?? false}
                />
            </ContentCard>
            <ContentCard>
                <CaseTableWrapper
                    caseFilter={caseFilter}
                    caseSummarization={caseSummarization}
                    downloadExcel={getRenewalsExcel}
                    isLoadingExcel={isLoadingExcel}
                    selectedCaseType={props.selectedCaseType}
                    showRenewalPrices={props.userSession.selectedOrg?.hasShowRenewalPriceRight ?? false}
                    editRenewalPrice={props.userSession.selectedOrg?.hasEditRenewalPriceRight ?? false}
                    renewalRegistrationActive={props.userSession.selectedOrg?.renewalRegistrationActive ?? false}
                    editSetRenewalStatus={
                        props.userSession.verifiedUser?.userRoles.some((value) => value.name === "Employee" || value.name === "Admin") ?? false
                    }
                />
            </ContentCard>
        </CaseSummarizationPage>
    );
}

export default Renewals;
