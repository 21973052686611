import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { deleteHttp, get, post } from "../../common/api/apiShared";
import { Result } from "../../common/api/result";
import { getCountryOptions } from "../priceCalculator/PriceCalculator.hooks";
import { SurveysProps } from "./Surveys";
import { SurveyOverviewModel } from "./Surveys.types";

export function useSurveys(props: SurveysProps) {
    const snackbar = useDotLegalSnackbar();
    const { isLoading, data, refetch } = useQuery("collection-of-prices" + props.type, () =>
        get<Array<SurveyOverviewModel>>(`/surveys?surveyArea=${props.type}`)
    );
    const [selectedCountry, setSelectedCountry] = useState("");

    if (data) {
        const countryList = getCountryOptions(props.type);
        data.forEach((x) => (x.country = countryList.find((c) => c.id === x.countryCode)?.name ?? ""));
    }

    const saveLatestSubmittedSurveyMutation = useMutation(saveLatestSubmittedSurvey, {
        onSuccess: (response: Result<{ url: string }>) => {
            onCopySuccess(response.value().url);
            refetch();
        },
    });

    const saveBlankSurveyMutation = useMutation(saveBlankSurvey, {
        onSuccess: (response: Result<{ url: string }>) => {
            onCopySuccess(response.value().url);
            refetch();
        },
    });

    const deleteActiveSubmissionMutation = useMutation(deleteActiveSubmissionFromSurvey, {
        onSuccess: () => {
            setSelectedCountry("");
            snackbar.show(`The active submission has been deleted`, "success");
            refetch();
        },
    });

    function saveBlankSurvey(countryCode: string) {
        return post<{ url: string }>("/api/adminsurvey/copyblanksurvey", { countryCode: countryCode, surveyArea: props.type });
    }

    function saveLatestSubmittedSurvey(countryCode: string) {
        return post<{ url: string }>("/api/adminsurvey/copyLatestSubmittedSurvey", { countryCode: countryCode, surveyArea: props.type });
    }

    function deleteActiveSubmissionFromSurvey(countryCode: string) {
        return deleteHttp(`/api/adminsurvey/deleteActiveSubmission/${countryCode}?surveyArea=${props.type}`);
    }

    const copyBlankSurvey = async (countryCode: string) => {
        setSelectedCountry(countryCode);
        await saveBlankSurveyMutation.mutateAsync(countryCode);
    };

    const copyLastestSubmittedSurvey = async (countryCode: string) => {
        setSelectedCountry(countryCode);
        await saveLatestSubmittedSurveyMutation.mutateAsync(countryCode);
    };

    const deleteActiveSubmission = async (countryCode: string) => {
        setSelectedCountry(countryCode);
        await deleteActiveSubmissionMutation.mutateAsync(countryCode);
    };

    const fetchLinkToActiveSubmission = async (countryCode: string) => {
        setSelectedCountry(countryCode);
        var response = await get<{ url: string }>(`/api/adminsurvey/activeSubmission/${countryCode}?surveyArea=${props.type}`);

        onCopySuccess(response.url);
    };

    function onCopySuccess(url: string) {
        var fullUrl = process.env.REACT_APP_APP_ROOT! + url;

        navigator.clipboard.writeText(fullUrl);
        snackbar.show(`The link ${fullUrl} was copied`, "success");
        setSelectedCountry("");
    }

    return {
        isLoading,
        data,
        copyBlankSurvey,
        copyLastestSubmittedSurvey,
        fetchLinkToActiveSubmission,
        deleteActiveSubmission,
        selectedCountry,
    };
}
