import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getSelectedOrganizationFromLocalStorage, removeSelectedOrgFromLocalStorage, UserSession } from "../../auth/AuthService";
import DotLegalAutocomplete, { AutocompleteOption } from "../../common/autocomplete/DotLegalAutocomplete";
import { Organization } from "../../core/organizations/Organization.types";
import { verifySelectedOrganization } from "../../core/organizations/OrganizationService";
import { getAllowedOrganizations } from "../../core/users/UserService";

const getSelectedOrg = (): Organization | null => {
    const organizationFromLocalStorage = getSelectedOrganizationFromLocalStorage();
    return organizationFromLocalStorage
        ? {
              id: organizationFromLocalStorage!.id,
              name: organizationFromLocalStorage!.name,
              costAccess: organizationFromLocalStorage!.costAccess,
              showRenewalPrice: organizationFromLocalStorage.showRenewalPrice,
              renewalContacts: organizationFromLocalStorage!.renewalContacts,
              renewalRegistrationActive: organizationFromLocalStorage!.renewalRegistrationActive,
              priceDisclaimer: organizationFromLocalStorage.priceDisclaimer,
          }
        : null;
};

export type SelectOrganizationProps = {
    userSession: UserSession;
};

function SelectOrganization(props: SelectOrganizationProps) {
    const [waitingForServer, setWaitingForServer] = useState(false);
    const history = useHistory();
    const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(getSelectedOrg());
    const [organizations, setOrganizations] = useState<AutocompleteOption<Organization>[]>([]);

    const chooseOrganization = async (organization: Organization): Promise<void> => {
        removeSelectedOrgFromLocalStorage();
        verifySelectedOrganization(organization.id)
            .then((selectedOrg) => {
                if (selectedOrg) {
                    setWaitingForServer(false);
                    props.userSession.onSelectedOrganizationChanged(selectedOrg);
                    const redirectTo = new URLSearchParams(window.location.search).get("redirect");
                    history.push(redirectTo ?? "/dashboard");
                }
            })
            .catch(() => {
                setWaitingForServer(false);
                props.userSession.onSelectedOrganizationChanged(null);
            });
    };

    useEffect(() => {
        if (props.userSession.verifiedUser) {
            getAllowedOrganizations()
                .then((allowedOrganizations) => setOrganizations(allowedOrganizations.map((org) => ({ label: org.name, value: org }))))
                .catch(() => {});
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div
            id="singleSelectDropdown"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 60,
                borderRadius: 10,
                background: "white",
                margin: 10,
            }}
        >
            <DotLegalAutocomplete
                getOptionDisabled={(option) => (selectedOrganization && option.value ? option.value.id !== selectedOrganization.id : false)}
                getOptionSelected={(option) => (selectedOrganization && option.value ? option.value.id === selectedOrganization.id : false)}
                label="Organisation"
                options={organizations}
                onChange={(selectedOptions) =>
                    !selectedOptions[0] || !selectedOptions[0].value ? setSelectedOrganization(null) : setSelectedOrganization(selectedOptions[0].value)
                }
                singleSelect
                value={
                    selectedOrganization
                        ? ({
                              label: selectedOrganization.name.toString(),
                              value: selectedOrganization,
                          } as any)
                        : null
                }
                width={200}
            />
            <Button
                color="primary"
                disabled={!selectedOrganization}
                onClick={() => (selectedOrganization ? chooseOrganization(selectedOrganization) : null)}
                variant="outlined"
            >
                Open
            </Button>
            {waitingForServer && <CircularProgress />}
        </div>
    );
}

export default SelectOrganization;
