declare global {
    interface Number {
        toDanishLocaleString(): String;
    }
    interface Boolean {
        toNumber(): Number | undefined;
    }
    interface Number {
        toDanishLocaleStringWithDecimal(): string;
        toEnglishLocaleStringWithDecimal(): string;
    }
}
// eslint-disable-next-line no-extend-native
Number.prototype.toDanishLocaleString = function (this: number): string {
    return this.toLocaleString("da");
};

// eslint-disable-next-line no-extend-native
Number.prototype.toDanishLocaleStringWithDecimal = function (this: number): string {
    return Number(this.toFixed(2)).toLocaleString("da");
};

// eslint-disable-next-line no-extend-native
Boolean.prototype.toNumber = function (this: boolean | null | undefined): number | undefined {
    return this !== undefined && this !== null ? (this ? 1 : 0) : undefined;
};

// eslint-disable-next-line no-extend-native
Number.prototype.toEnglishLocaleStringWithDecimal = function (this: number): string {
    return Number(this.toFixed(2)).toLocaleString("en");
};

export {};
