import { Grid } from "@mui/material";
import { History } from "history";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ContentCard from "../../common/contentCard/ContentCard";
import WorldMap from "../worldMap/WorldMap";
import { CaseStatus, CaseStatusCountryCases } from "../worldMap/WorldMap.types";
import { CaseFilter, CasesResponse, CaseTableArrangement, CaseType } from "./Case.types";
import CaseFilters from "./CaseFilters";
import { caseTableArrangementForCsv, exportCasesExcel, getWorldMapCases, getWorldMapDisputes, initialCaseFilter } from "./CaseService";
import { CaseSummarization } from "./CaseSummarizationPage";
import CaseTableWrapper from "./CaseTableWrapper";

export type CasePageProps = {
    caseType: CaseType;
    caseSummarization?: CaseSummarization;
    onCaseFilterChanged?: (caseType: CaseType, filter: CaseFilter & CaseTableArrangement) => Promise<CasesResponse>;
};

const getStatusFromHistoryLocation = (locationState: History.LocationState) => {
    const state = locationState as { caseStatus: CaseStatus };
    if (state && state.caseStatus) {
        return state.caseStatus;
    }
    return null;
};

const getDefaultCaseStatus = (caseSummarization?: CaseSummarization) => (caseSummarization === CaseSummarization.Disputes ? "pending" : "registered");

function CasePage({ caseSummarization, caseType, onCaseFilterChanged }: CasePageProps) {
    const history = useHistory();
    const [initialCaseStatus] = useState(
        getStatusFromHistoryLocation(history.location.state) ? getStatusFromHistoryLocation(history.location.state)! : getDefaultCaseStatus(caseSummarization)
    );
    const [caseFilter, setCaseFilter] = useState(initialCaseFilter(initialCaseStatus, caseSummarization));
    const [caseStatusCountryCases, setCaseStatusCountryCases] = useState<CaseStatusCountryCases>();
    const [initialLoadIsCompleted, setInitialLoadIsCompleted] = useState(false);
    const [isLoadingExcel, setIsLoadingExcel] = useState(false);
    const [mapIsExpanded] = useState(true);

    const getCasesExcel = () => {
        const filter = { ...caseFilter, ...caseTableArrangementForCsv };
        setIsLoadingExcel(true);
        exportCasesExcel(caseType, filter)
            .then(() => setIsLoadingExcel(false))
            .catch(() => setIsLoadingExcel(false));
    };

    const onSelectedCaseStatusChanged = (status: CaseStatus) => {
        if (initialLoadIsCompleted) setCaseFilter({ ...caseFilter, status });
    };

    useEffect(() => {
        if (initialLoadIsCompleted)
            (caseSummarization === CaseSummarization.Disputes ? getWorldMapDisputes(caseType, caseFilter) : getWorldMapCases(caseType, caseFilter))
                .then((worldMapCasesRes) => setCaseStatusCountryCases(worldMapCasesRes))
                .catch(() => {});
        setInitialLoadIsCompleted(true);
        // eslint-disable-next-line
    }, [caseFilter, caseType]);

    return (
        <>
            <ContentCard>
                <CaseFilters
                    selectedCaseType={caseType}
                    caseFilter={caseFilter}
                    caseSummarization={caseSummarization}
                    onCaseFilterChanged={setCaseFilter}
                    renewalRegistrationActive={false}
                />
            </ContentCard>
            <ContentCard>
                <WorldMap
                    cases={caseStatusCountryCases}
                    selectedCaseStatus={caseFilter.status ?? initialCaseStatus}
                    mapIsExpanded={mapIsExpanded ?? false}
                    onSelectedCaseStatusChanged={onSelectedCaseStatusChanged}
                />
            </ContentCard>
            <ContentCard>
                <CaseTableWrapper
                    showRenewalPrices={false}
                    selectedCaseType={caseType}
                    caseFilter={caseFilter}
                    caseSummarization={caseSummarization}
                    downloadExcel={getCasesExcel}
                    isLoadingExcel={isLoadingExcel}
                    onCaseFilterChanged={onCaseFilterChanged}
                    renewalRegistrationActive={false}
                    editRenewalPrice={false}
                    editSetRenewalStatus={false}
                />
            </ContentCard>
            {caseSummarization === CaseSummarization.Disputes && (
                <Grid container sm={12} justifyContent="flex-start">
                    *Be aware that the disputes are created from 2022 and forward. Disputes before this date will not be shown.
                </Grid>
            )}
        </>
    );
}

export default CasePage;
