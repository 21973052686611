import { Skeleton } from "@mui/material";

function DotLegalSkeletonTable() {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: 24,
                paddingLeft: 6,
                paddingBottom: 24,
                width: "100%",
            }}
        >
            <Skeleton width="100%" height={24} animation="pulse" variant="rectangular" />
            <Skeleton width="100%" height={352} animation="pulse" variant="rectangular" />
        </div>
    );
}

export default DotLegalSkeletonTable;
