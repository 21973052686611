import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get } from "../../../../common/api/apiShared";
import { DesignSurveyViewModel } from "../../survey/Survey.types";

export function useDesignSurveyPDFDataMapping() {
    const { newapplicationid } = useParams<{ newapplicationid: string }>();
    const { renewalid } = useParams<{ renewalid: string }>();

    const newapplicationQuery = useQuery(newapplicationid, () => get<DesignSurveyViewModel>(`/api/survey/design/${newapplicationid}`));
    const renewalQuery = useQuery(renewalid, () => get<DesignSurveyViewModel>(`/api/survey/design/${renewalid}`));

    return {
        newApplicationData: newapplicationQuery.data,
        isLoading: newapplicationQuery.isLoading || renewalQuery.isLoading,
        renewalData: renewalQuery.data,
    };
}
