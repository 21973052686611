import { CancelOutlined, CheckCircleOutlined, KeyboardArrowDown, Loop } from "@mui/icons-material";
import { Box, CSSObject, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { enumToArray } from "../../../common/api/helper";
import { Case, RenewalStatus } from "../Case.types";
import { updateRenewalStatus } from "../CaseService";
import RenewalRemarkModal from "../RenewalRemarkModal";
import Rotatable from "../Rotateable/Rotatable";

export type CaseWithMandatoryRenewalStatus = Omit<Case, "renewalStatus"> & { renewalStatus: RenewalStatus };
export interface RenewalStatusWidgetProps {
    selectedCase: CaseWithMandatoryRenewalStatus;
    onUpdatedCase?: (updatedCase: Case) => void;
    editSetRenewalStatus: boolean;
}

enum Editability {
    Editable,
    DisabledPermission,
    DisabledMainCase,
    DisabledDateFuture,
    DisabledDatePast,
}

function getEnumIcon(status: RenewalStatus) {
    switch (status) {
        case RenewalStatus.Awaiting:
            return <Loop fontSize={"small"} />;
        case RenewalStatus.Expire:
            return <CancelOutlined fontSize={"small"} />;
        case RenewalStatus.Renew:
            return <CheckCircleOutlined fontSize={"small"} />;
    }
}

function getEnumCSS(status: RenewalStatus, disabled?: boolean): CSSObject {
    switch (status) {
        case RenewalStatus.Expire:
            return { border: "1px solid #fa3a75", backgroundColor: "#fec2d5", color: "#fa3a75" };
        case RenewalStatus.Awaiting:
            return { border: `1px solid #033297`, backgroundColor: "#d1e0ff", color: "#033297" };
        case RenewalStatus.Renew:
            return { border: "1px solid #18d6c4", backgroundColor: "#edfaf9", color: "#18d6c4" };
    }
}

function getMenuButtonText(status: RenewalStatus) {
    switch (status) {
        case RenewalStatus.Expire:
            return "Expire";
        case RenewalStatus.Awaiting:
            return "Awaiting";
        case RenewalStatus.Renew:
            return "Renew";
    }
}

function getStatusPickerContent(status: RenewalStatus, isMenuPicker?: boolean, rightIcon?: JSX.Element, canEditStatus?: Editability) {
    return (
        <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={(theme) => theme.spacing(1)}
            paddingX={(theme) => theme.spacing(1)}
            sx={(theme) =>
                ({
                    fontSize: "12px",
                    borderRadius: "15px",
                    minWidth: "120px",
                    ...getEnumCSS(status),
                    opacity: canEditStatus === Editability.Editable ? 1 : 0.5,
                } as CSSObject)
            }
        >
            {getEnumIcon(status)}
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                {getMenuButtonText(status)}
                {rightIcon && canEditStatus === Editability.Editable ? rightIcon : <Box />}
            </Box>
        </Box>
    );
}

const RenewalStatusWidget: FunctionComponent<RenewalStatusWidgetProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [status, setStatus] = useState<RenewalStatus>(props.selectedCase.renewalStatus ?? RenewalStatus.Awaiting);
    const [newStatus, setNewStatus] = useState<RenewalStatus>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [remarkOpen, setRemarkOpen] = useState<boolean>(false);
    const [isNewExpireOrRenew, setIsNewExpireOrRenew] = useState<boolean>(false);
    const canEditStatus = useMemo(() => {
        if (!props.selectedCase.isMainCase && props.selectedCase.mainCaseRenewalStatus === RenewalStatus.Expire) return Editability.DisabledMainCase;
        if (!props.selectedCase.isMainCase && props.selectedCase.mainCaseRenewalStatus === RenewalStatus.Awaiting) return Editability.DisabledMainCase;

        const canEditBasedOnStatus =
            props.editSetRenewalStatus || props.selectedCase.renewalStatus === RenewalStatus.Awaiting || props.selectedCase.renewalStatus === null;

        if (!canEditBasedOnStatus) return Editability.DisabledPermission;

        /*
            cases that have already expired cannot be updated
            cases that expire in the current year can be updated
            after the first of august, cases that expire in the following year can be updated 
        */
        const now = new Date();
        const cutOff = new Date(now.getFullYear(), 7, 1);
        const renewalDate = new Date(props.selectedCase.renewalDate);
        let canEditBasedOnDate = true;

        if (renewalDate < now) return Editability.DisabledDatePast;

        if (now.getFullYear() === renewalDate.getFullYear()) {
            canEditBasedOnDate = true;
        } else {
            canEditBasedOnDate = now >= cutOff && renewalDate.getFullYear() === now.getFullYear() + 1;
        }

        if (!canEditBasedOnDate) return Editability.DisabledDateFuture;

        return Editability.Editable;
    }, [
        props.editSetRenewalStatus,
        props.selectedCase.isMainCase,
        props.selectedCase.mainCaseRenewalStatus,
        props.selectedCase.renewalStatus,
        props.selectedCase.renewalDate,
    ]);

    const saveStatusMutation = useMutation(updateRenewalStatus, {
        onSuccess: (data) => {
            const newCases = data.value();
            if (props.onUpdatedCase && newCases && newCases.length > 0) {
                newCases.forEach((x) => {
                    props.onUpdatedCase?.(x);
                });
            }
        },
    });

    useEffect(() => {
        if (props.selectedCase.internalId && newStatus === RenewalStatus.Awaiting)
            saveStatusMutation.mutateAsync({ internalId: props.selectedCase.internalId, status: status }).then();
        // eslint-disable-next-line
    }, [status]);

    useEffect(() => {
        setStatus(props.selectedCase.renewalStatus ?? RenewalStatus.Awaiting);
    }, [props.selectedCase.renewalStatus]);

    const tooltip = useMemo(() => {
        if (canEditStatus === Editability.DisabledPermission)
            return "If you wish to change the renewal status please write this in the renewal remark, and your caseworker will help proceed the action.";
        if (canEditStatus === Editability.DisabledMainCase)
            return props.selectedCase.mainCaseRenewalStatus === RenewalStatus.Awaiting
                ? `This is a ${props.selectedCase.mainCaseCountry} designation. Please choose the renewal status of the basic registration first`
                : `This is a ${props.selectedCase.mainCaseCountry} designation. Renewal status cannot be changed, while the basic registration's status is expired.`;
        if (canEditStatus === Editability.DisabledDateFuture) return "You cannot set a renewal status for this registration yet.";
        if (canEditStatus === Editability.DisabledDatePast)
            return "You cannot set a renewal status for this registration because the renewal date has expired.";
        return "";
    }, [canEditStatus, props.selectedCase.mainCaseCountry, props.selectedCase.mainCaseRenewalStatus]);

    return (
        <>
            <Tooltip title={tooltip} sx={(theme) => ({ fontSize: "12px" } as CSSObject)}>
                <Box
                    sx={(theme) => ({ fontSize: "12px" } as CSSObject)}
                    onClick={(event) => {
                        if (canEditStatus === Editability.Editable) setOpen(true);
                        setAnchorEl(event.currentTarget);
                    }}
                >
                    {getStatusPickerContent(
                        status,
                        true,
                        <Rotatable isRotated={open}>
                            <KeyboardArrowDown fontSize={"small"} />
                        </Rotatable>,
                        canEditStatus
                    )}
                </Box>
            </Tooltip>

            <Menu onClose={() => setOpen(false)} open={open} anchorEl={anchorEl} autoFocus={false}>
                {enumToArray(RenewalStatus)
                    .filter((s) => status !== s)
                    .map((status) => {
                        return (
                            <MenuItem
                                key={status.toString()}
                                onClick={() => {
                                    setNewStatus(status);
                                    if (status === RenewalStatus.Awaiting) setStatus(status);
                                    else {
                                        setIsNewExpireOrRenew(true);
                                        setRemarkOpen(true);
                                    }
                                    setOpen(false);
                                }}
                            >
                                {getStatusPickerContent(status, undefined, undefined, Editability.Editable)}
                            </MenuItem>
                        );
                    })}
            </Menu>
            {remarkOpen && (
                <RenewalRemarkModal
                    internalId={props.selectedCase.internalId}
                    remarkOpen={remarkOpen}
                    setRemarkOpen={setRemarkOpen}
                    isNewExpireOrRenew={isNewExpireOrRenew}
                    newStatus={newStatus}
                    setStatus={setStatus}
                    onCaseUpdated={props.onUpdatedCase}
                />
            )}
        </>
    );
};

export default RenewalStatusWidget;
