import { createSxStyles } from "../../../common/createSxStyles";

export const useSurveyStyles = (isOnMediumScreen: boolean) => {
    return createSxStyles({
        container: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            minHeight: "100vh",
            flexDirection: "column",
            padding: 3,
            "& .MuiFormControl-root": {
                width: 200,
            },
            "& .MuiFormControlLabel-root .MuiTypography-root": {
                fontWeight: "bold",
            },
        },
        logo: {
            marginBottom: 2,
        },
        content: {
            flex: 1,
            width: isOnMediumScreen ? "100%" : "55%",
            alignSelf: "center",
            "& .MuiFormControlLabel-root": {
                height: "28px",
            },
            display: "flex",
            flexDirection: "column",
        },
    });
};
