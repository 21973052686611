import useWorldMapZoomButtonStyles from "./WorldMapZoomButtonStyles";

type WorldMapZoomButtonProps = {
    onZoomInClick: () => void;
    onZoomOutClick: () => void;
};

function WorldMapZoomButton(props: WorldMapZoomButtonProps) {
    const classes = useWorldMapZoomButtonStyles(props);

    return (
        <div className={classes.root}>
            <div role="button" tabIndex={0} onClick={props.onZoomInClick} onKeyPress={props.onZoomInClick}>
                +
            </div>
            <hr />
            <div role="button" tabIndex={0} onClick={props.onZoomOutClick} onKeyPress={props.onZoomOutClick}>
                -
            </div>
        </div>
    );
}

export default WorldMapZoomButton;
