import { DotLegalCheckbox, DotLegalDialog } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { CaseFilter, CaseType } from "../cases/Case.types";
import { useCustomExcelExport } from "./CustomExcelExport.hooks";

export interface CustomExcelExportProps {
    caseType: CaseType;
    onClose: () => void;
    caseFilter: CaseFilter;
}

function CustomExcelExport(props: CustomExcelExportProps) {
    const { checkedStatuses, setCheckedStatuses, exportExcel, isLoading } = useCustomExcelExport(props);

    const [canExport, setCanExport] = useState(false);
    useEffect(() => {
        setCanExport(checkedStatuses.registered || checkedStatuses.pending || checkedStatuses.closed);
    }, [checkedStatuses]);
    return (
        <DotLegalDialog
            onDialogClose={props.onClose}
            buttonOkText={"Export"}
            open
            onOkClick={exportExcel}
            inProgress={isLoading}
            header={"Export"}
            okButtonDisabled={!canExport}
        >
            <Box marginBottom={2} marginLeft={2} marginRight={2}>
                Select the statuses you wish to include in your Excel export.
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%" }}>
                <Box sx={{ alignItems: "left" }}>
                    {props.caseFilter.isDispute ? (
                        <Box>
                            <DotLegalCheckbox
                                checked={checkedStatuses.won}
                                onChange={(value) => {
                                    let tempModel = { ...checkedStatuses };
                                    tempModel.won = value;
                                    setCheckedStatuses(tempModel);
                                }}
                                label={"Won"}
                                disabled={isLoading}
                            />
                            <DotLegalCheckbox
                                checked={checkedStatuses.partly}
                                onChange={(value) => {
                                    let tempModel = { ...checkedStatuses };
                                    tempModel.partly = value;
                                    setCheckedStatuses(tempModel);
                                }}
                                label={"Partly"}
                                disabled={isLoading}
                            />
                            <DotLegalCheckbox
                                checked={checkedStatuses.lost}
                                onChange={(value) => {
                                    let tempModel = { ...checkedStatuses };
                                    tempModel.lost = value;
                                    setCheckedStatuses(tempModel);
                                }}
                                label={"Lost"}
                                disabled={isLoading}
                            />
                            <DotLegalCheckbox
                                checked={checkedStatuses.pending}
                                onChange={(value) => {
                                    let tempModel = { ...checkedStatuses };
                                    tempModel.pending = value;
                                    setCheckedStatuses(tempModel);
                                }}
                                label={"Pending"}
                                disabled={isLoading}
                            />
                        </Box>
                    ) : (
                        <Box>
                            <DotLegalCheckbox
                                checked={checkedStatuses.registered}
                                onChange={(value) => {
                                    let tempModel = { ...checkedStatuses };
                                    tempModel.registered = value;
                                    setCheckedStatuses(tempModel);
                                }}
                                label={"Registered"}
                                disabled={isLoading}
                            />
                            <DotLegalCheckbox
                                checked={checkedStatuses.pending}
                                onChange={(value) => {
                                    let tempModel = { ...checkedStatuses };
                                    tempModel.pending = value;
                                    setCheckedStatuses(tempModel);
                                }}
                                label={"Pending"}
                                disabled={isLoading}
                            />
                            <DotLegalCheckbox
                                checked={checkedStatuses.closed}
                                onChange={(value) => {
                                    let tempModel = { ...checkedStatuses };
                                    tempModel.closed = value;
                                    setCheckedStatuses(tempModel);
                                }}
                                label={"Closed"}
                                disabled={isLoading}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </DotLegalDialog>
    );
}

export default CustomExcelExport;
