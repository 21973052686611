import { TableCell, TableRow } from "@mui/material";
import DotLegalTable, { DotLegalTableProps } from "../../common/table/DotLegalTable";
import DotLegalSkeletonTable from "../../common/table/DotLegalTableSkeleton";
import { generateNoDataDisplay } from "../dashboard/DashboardWidgetService";
import { MatterCosts } from "./Cost.types";
import useCostsStyles from "./Costs.styles";

type CostTableProps = {
    isLoading: boolean;
    onTableArrangementChanged: DotLegalTableProps<MatterCosts>["onTableArrangementChanged"];
    pageNr: DotLegalTableProps<MatterCosts>["pageNr"];
    rows: MatterCosts[];
    showCostAmount: boolean;
    totalRowCount: DotLegalTableProps<MatterCosts>["totalRowCount"];
};

function CostTable(props: CostTableProps) {
    const classes = useCostsStyles();

    return !props.isLoading && props.rows.length === 0 ? (
        generateNoDataDisplay("marginAuto", 400)
    ) : (
        <div className={classes.costsTable}>
            {props.isLoading ? (
                <DotLegalSkeletonTable />
            ) : (
                <DotLegalTable
                    defaultOrderBy="matterId"
                    headers={[
                        {
                            align: "left",
                            property: "matterId",
                            text: "Matter Id",
                            width: "175px",
                        },
                        { align: "left", property: "description", text: "Description" },
                        {
                            align: "left",
                            property: "amount",
                            text: props.showCostAmount ? "Costs" : "",
                            width: "250px",
                        },
                    ]}
                    onTableArrangementChanged={props.onTableArrangementChanged}
                    pageNr={props.pageNr}
                    renderRow={(r) => (
                        <TableRow>
                            <TableCell style={{ paddingLeft: 6 }}>{r.matterId}</TableCell>
                            <TableCell>{r.description}</TableCell>
                            <TableCell>{props.showCostAmount ? `${r.amount.toEnglishLocaleStringWithDecimal()} DKK` : ""}</TableCell>
                        </TableRow>
                    )}
                    rows={props.rows}
                    totalRowCount={props.totalRowCount}
                />
            )}
        </div>
    );
}

export default CostTable;
