import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useWorldMapZoomButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: 74,
            width: 35,
            background: "#F0F7FC",
            gap: theme.spacing(1.5),
            position: "absolute",
            bottom: 0,
            right: 0,
            marginRight: theme.spacing(1.5),
            marginBottom: theme.spacing(5.5),
            borderRadius: 5,
            "& div": {
                display: "flex",
                fontSize: 20,
                lineHeight: "15px",
                cursor: "pointer",
            },
            "& hr": {
                width: "66%",
                margin: "0 auto",
                color: "#032677",
                backgroundColor: "#032677",
                border: "none",
                height: 1,
            },
        },
    })
);

export default useWorldMapZoomButtonStyles;
