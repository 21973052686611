import { Box } from "@mui/material";
import { useState } from "react";
import DotLegalAutocomplete, { AutocompleteOption } from "../../common/autocomplete/DotLegalAutocomplete";
import { ApplicationUser } from "../users/User.types";
import { Organization } from "./Organization.types";

export interface IConfigureRenewalPrices {
    organization: Organization;
    users: Array<ApplicationUser>;
    onOrganizationChanged: (organization: Organization) => void;
}

export default function ConfigureRenewalPrices(props: IConfigureRenewalPrices) {
    const [userOptions] = useState<AutocompleteOption<number>[]>(
        props.users.map((u) => {
            return { label: u.name, value: u.id! };
        })
    );

    const [selectedUsers, setSelectedUsers] = useState<AutocompleteOption<number>[]>(
        userOptions.filter((option) => props.organization.renewalContacts.some((contact) => contact.userId === option.value))
    );

    const onRenewalContactsChange = (contacts: AutocompleteOption<number>[]) => {
        setSelectedUsers(contacts);
        props.onOrganizationChanged({
            ...props.organization,
            renewalContacts: contacts.map((x) => {
                return { organizationId: Number(props.organization.id!), userId: x.value! };
            }),
        });
    };

    return (
        <Box sx={(theme) => ({ display: "flex", width: "100%", flexDirection: "column", gap: theme.spacing(5.5), alignItems: "center" })}>
            <Box id="multiSelectDropDown" sx={{ width: 270 }}>
                <DotLegalAutocomplete
                    onChange={(values) => onRenewalContactsChange(values)}
                    label="Renewal Contacts"
                    value={selectedUsers}
                    width={"100%"}
                    options={userOptions}
                />
            </Box>
        </Box>
    );
}
