import React from "react";
import { AutocompleteOption } from "../../common/autocomplete/DotLegalAutocomplete";
import { CaseStatus } from "../worldMap/WorldMap.types";

export enum CaseType {
    Trademark = "Trademark",
    Design = "Design",
    Copyright = "Copyright",
}
type CaseTooltip = { tooltip?: React.ReactElement };

export type CaseWithTooltip<T> = CaseTooltip & { value: T };

export type CaseRelation = {
    caseId: number;
    catchword: string;
    country: string;
    isDispute: boolean;
    status: CaseStatus;
    type: CaseType;
};

export type Case = {
    id: number;
    internalId: string;
    applicant: string;
    caseNo: string;
    classes?: CaseWithTooltip<string>[];
    correspondence: string;
    counterParty: string;
    counterPartyAddress: string;
    country: string;
    disputeRelations: CaseRelation[];
    image?: number;
    isMainCase: boolean;
    isDesignated: boolean;
    isDispute: boolean;
    matterId: string;
    name: string;
    registrationNo: string;
    applicationDate: string;
    renewalDate: string;
    status: string;
    useDate: string;
    applicationNo?: string;
    dateOfOrder?: string;
    dispute?: string;
    disputeDecision: string;
    type?: string;
    remark?: string;
    endOfDurationDate?: string;
} & ExtendedCaseData &
    CaseMainCaseDto;

export type CaseMainCaseDto = {
    mainCaseRenewalStatus?: RenewalStatus;
    mainCaseCountry?: string;
};

export type ExtendedCaseData = {
    internalId: string;
    renewalStatus?: RenewalStatus;
    renewalRemark?: string;
    price: number;
    currencyCode: string;
    latestPrice?: number;
};

export enum RenewalStatus {
    Awaiting = 0,
    Renew = 1,
    Expire = 2,
}

export type CaseWithAdditionalInfo = {
    applicationNo: string;
    registrationDate: string;
    devices: Device[];
    documents: Document[];
    renewalPrice?: number;
    latestRenewalPrice?: number;
    renewalPriceGivenAt?: string;
} & Case;

export type Device = {
    base64: string;
    name: string;
    id: number;
    isMain: boolean;
};

export type Document = {
    name: string;
    id: number;
};

export type CaseFilter = {
    applicants: string[];
    catchwords: string[];
    classes: string[];
    countries: string[];
    isDispute: boolean;
    isRenewals: boolean;
    status?: CaseStatus;
    searchText?: string;
    types: string[];
    renewalStatuses: string[];
} & Partial<CaseRenewalDateFilter>;

export type CaseTableArrangement = {
    pageNumber: number;
    pageSize: number | null;
    orderBy: string;
    orderByDescending: boolean;
};

export type CaseRenewalDateFilter = {
    renewalDateFrom: Date | null;
    renewalDateTo: Date | null;
};

export type CaseFilterOptions = CaseAutocompleteOptions & {
    searchText?: string;
};

export type CaseAutocompleteOptions = {
    applicants: AutocompleteOption<string>[];
    classes: AutocompleteOption<string>[];
    countries: AutocompleteOption<string>[];
    catchwords: AutocompleteOption<string>[];
    types: AutocompleteOption<string>[];
    renewalStatuses: AutocompleteOption<string>[];
};

export type CaseTableData = {
    cases: Case[];
    count: number;
};

export type CasesResponse = { cases: Case[]; totalCount: number };
export type DisputesResponse = { disputes: Case[] } & CasesResponse;

export type RenewalRemarkModalViewModel = {
    internalId: string;
    classes?: CaseWithTooltip<string>[];
    country: string;
    name: string;
    renewalRemark: string;
};
