import { DotLegalBreadCrumbContext, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import MenuIcon from "@mui/icons-material/Menu";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, IconButton } from "@mui/material";
import { useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import DotLegalAutocomplete, { AutocompleteOption } from "../../common/autocomplete/DotLegalAutocomplete";
import { useIsOnSmallScreen } from "../../common/hooks/mediaHooks";
import { CaseType } from "../cases/Case.types";
import useTopBarStyles, { useDotLegalBreadCrumbStyles } from "./TopBar.styles";

export type TopBarDropdownConfig<T> = {
    disabled?: true;
    key: string;
    onChange: (selectedValue?: T) => void;
    options: AutocompleteOption<T | undefined>[];
    value?: AutocompleteOption<T | undefined>;
    selectAllLabel?: string;
    saveValueToLocalstorage?: boolean;
};

export interface TopBarProps {
    onDrawerExpanded: () => void;
    caseTypeFilterConfig?: TopBarDropdownConfig<CaseType>;
}

export function useHistoryWithReferer() {
    const location = useLocation();
    const history = useHistory();
    function push(url: string) {
        let state: RefererState = {
            refererPath: location.pathname,
            refererSearch: location.search,
        };
        history.push(addSearchIfReferer(url), state);
    }

    /*
    Adds search (filter) parameters if they were applied at the previous page. Ensures that filters eg. set up are persisted when navigating back
    */
    function addSearchIfReferer(url: string) {
        const refererState = location.state as RefererState;
        if (refererState) {
            if (url === refererState.refererPath) {
                url = url + refererState.refererSearch;
            }
        }
        return url;
    }

    return {
        push,
        addSearchIfReferer,
    };
}

interface RefererState {
    refererPath: string;
    refererSearch: string;
}

function TopBar(props: TopBarProps) {
    const classes = useTopBarStyles();
    const styles = useDotLegalBreadCrumbStyles();
    const isOnSmallScreen = useIsOnSmallScreen();
    const breadCrumbsContext = useContext(DotLegalBreadCrumbContext);
    const breadCrumbs = breadCrumbsContext.getBreadCrumbs();
    const historyWithReferer = useHistoryWithReferer();

    return (
        <div className={classes.topBar}>
            <div className={classes.leftSide}>
                {isOnSmallScreen && (
                    <IconButton color="inherit" aria-label="open drawer" onClick={() => props.onDrawerExpanded()} className={classes.drawerIcon} size="large">
                        <MenuIcon />
                    </IconButton>
                )}
                {breadCrumbs.length > 0 ? (
                    <Box
                        sx={(theme) => ({
                            "& .MuiTypography-root": {
                                fontSize: theme.typography.pxToRem(24),
                            },
                            "& a": {
                                fontSize: `${theme.typography.pxToRem(24)} !Important`,
                            },
                            "& .MuiSvgIcon-root": {
                                width: "1.5em",
                                height: "1.5em",
                            },
                        })}
                    >
                        <Box sx={styles.root}>
                            <Breadcrumbs separator={<NavigateNextIcon style={styles.nextIcon} fontSize="medium" />} aria-label="breadcrumb">
                                {breadCrumbs.map((crumb, index) => {
                                    if (breadCrumbs.length === index + 1) {
                                        return (
                                            <DotLegalHeader key={index} headerStyle="medium" marginBottom={0} toolTip={crumb.tooltip ?? ""}>
                                                {crumb?.name}
                                            </DotLegalHeader>
                                        );
                                    } else {
                                        return (
                                            <Link
                                                style={styles.crumbStyling}
                                                key={index}
                                                to={crumb.link ? historyWithReferer.addSearchIfReferer(crumb.link) : ""}
                                            >
                                                {crumb?.name}
                                            </Link>
                                        );
                                    }
                                })}
                            </Breadcrumbs>
                        </Box>
                    </Box>
                ) : undefined}
            </div>
            {props.caseTypeFilterConfig && props.caseTypeFilterConfig.options.length > 1 && generateTopBarDropdown(props.caseTypeFilterConfig!)}
        </div>
    );
}

const generateTopBarDropdown = <T,>(dropdownConfig: TopBarDropdownConfig<T>) => {
    const onDropdownChange = (value: any) => {
        if (!dropdownConfig) return;
        dropdownConfig.onChange(value);
    };

    return (
        <div id="singleSelectDropdown" style={{ paddingRight: 32 }}>
            <DotLegalAutocomplete
                disabled={dropdownConfig.disabled}
                onChange={(selectedValues) =>
                    !selectedValues[0] || !selectedValues[0].value ? onDropdownChange(undefined) : onDropdownChange(selectedValues[0].value)
                }
                key={dropdownConfig.key}
                options={dropdownConfig.options || []}
                selectAllLabel={dropdownConfig.selectAllLabel}
                selectAllOption={dropdownConfig.selectAllLabel ? true : undefined}
                singleSelect
                // @ts-ignore
                value={dropdownConfig.value}
                width={200}
            />
        </div>
    );
};

export default TopBar;
