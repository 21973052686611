import { DotLegalSnackBarProvider } from "@dotlegal/dotlegal-ui-components";
import { CssBaseline, StyledEngineProvider, Theme, ThemeProvider } from "@mui/material";
import mapboxgl from "mapbox-gl";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerHttpInterceptor from "./auth/HttpInterceptor";
import ipPortalTheme from "./ipPortalTheme";
import { setupi18n, TranslationProvider } from "./localization/LocalizationContext";
import reportWebVitals from "./reportWebVitals";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_TOKEN!;
mapboxgl.accessToken = mapboxAccessToken;
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

registerHttpInterceptor();

setupi18n();

ReactDOM.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={ipPortalTheme}>
                <TranslationProvider>
                    <CssBaseline />
                    <DotLegalSnackBarProvider>
                        <App />
                    </DotLegalSnackBarProvider>
                </TranslationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
