import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { DotLegalAutocompleteProps } from "./DotLegalAutocomplete";

export const useDotLegalAutocompleteStyles = makeStyles((theme: Theme) =>
    createStyles({
        filter: (props: DotLegalAutocompleteProps<any>) => ({
            width: props.width,
            borderRadius: 5,
            borderColor: theme.customPalette.darkBlue,
            fontSize: theme.typography.pxToRem(14),
        }),
    })
);

export default useDotLegalAutocompleteStyles;
