import { useEffect, useState } from "react";
import arrowLeft from "../icons/arrow-left.svg";
import arrowRight from "../icons/arrow-right.svg";
import bbInitialsLogo from "./BB logo lille.svg";
import bbLogo from "./BB logo.svg";
import useNavMenuLogoStyles from "./NavMenuHeader.styles";

export type NavMenuHeaderProps = {
    isMinimized: boolean;
    navIsHovered: boolean;
    onClose: () => void;
};

function NavMenuHeader(props: NavMenuHeaderProps) {
    const classes = useNavMenuLogoStyles(props);
    const [isMinimized, setIsMinimized] = useState(false);

    useEffect(() => setIsMinimized(props.isMinimized), [props.isMinimized]);

    return (
        <div className={classes.logoWrapper}>
            <a href="/dashboard">
                {isMinimized ? (
                    <img className={`${classes.logo} ${classes.sizeIsMinimized}`} alt="logo" src={bbInitialsLogo} />
                ) : (
                    <img className={`${classes.logo} ${classes.size}`} alt="logo" src={bbLogo} />
                )}
            </a>
            {props.navIsHovered && (
                <div role="button" tabIndex={0} className={classes.backButton} onClick={props.onClose} onKeyPress={props.onClose}>
                    <img src={props.isMinimized ? arrowRight : arrowLeft} alt="close" />
                </div>
            )}
        </div>
    );
}

export default NavMenuHeader;
