import { DateType } from "@date-io/type";
import { CSSObject, Icon, InputAdornment, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";

export type DotLegalDatePickerProps<T> = {
    criteria: keyof T;
    dateLimitations?: { earliestDate?: Date; latestDate?: Date };
    disabled?: boolean;
    label: string;
    onDateChange: (date: DateType | null, value: string | null, criteria: keyof T) => void;
    selectedValue: DateType | null;
};

function DotLegalDatePicker<T>(props: DotLegalDatePickerProps<T>) {
    const [datePicked, setDatePicked] = useState(false);
    const [selectedValue, setSelectedValue] = useState(props.selectedValue);
    const [selectedDateAsString, setSelectedDateAsString] = useState<string | null>(null);
    const [pickerOpen, setPickerOpen] = useState<boolean>(false);

    useEffect(() => {
        if (datePicked) props.onDateChange(selectedValue, selectedDateAsString, props.criteria);
        setDatePicked(false);
        // eslint-disable-next-line
    }, [datePicked]);

    return (
        <DatePicker
            // Commented out lines are from MuiV4
            // autoOk
            // color="primary"
            disabled={props.disabled}
            // error={false}
            inputFormat="dd-MM-yyyy"
            // invalidDateMessage=""
            // KeyboardButtonProps={{
            //   'aria-label': 'change date',
            // }}
            minDate={props.dateLimitations && props.dateLimitations.earliestDate ? props.dateLimitations.earliestDate : undefined}
            maxDate={props.dateLimitations && props.dateLimitations.latestDate ? props.dateLimitations.latestDate : undefined}
            label={props.label}
            // maxDateMessage=""
            // minDateMessage=""
            onChange={(date: any, value: any) => {
                if (!datePicked) {
                    let isValidDate = !isNaN(date?.getDate()) && date.getFullYear() > 1900;
                    props.onDateChange(isValidDate ? date : null, value || null, props.criteria);
                }
                setSelectedDateAsString(value || null);
                setSelectedValue(date);
                setPickerOpen(false);
            }}
            onClose={() => {
                setDatePicked(true);
                setPickerOpen(false);
            }}
            openTo="day"
            autoFocus={false}
            componentsProps={{ actionBar: { color: "primary " } }}
            PaperProps={{
                sx: (theme) => ({
                    "& .MuiPickersDay-root": {
                        "&.Mui-selected": {
                            backgroundColor: theme.palette.secondary.main,
                            "&:hover": {
                                backgroundColor: theme.palette.secondary.dark,
                            },
                        },
                    },
                }),
            }}
            renderInput={(params: any) => (
                <TextField
                    color="primary"
                    {...params}
                    size="small"
                    style={{ margin: 0, width: 200 }}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={"end"}>
                                <Icon
                                    sx={(_) => ({ cursor: "pointer", marginRight: 1 } as CSSObject)}
                                    onClick={(_) => {
                                        setPickerOpen((prevState) => !prevState);
                                    }}
                                    color="primary"
                                >
                                    event
                                </Icon>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            open={pickerOpen}
            value={selectedValue}
            views={["year", "day"]}
        />
    );
}

export default DotLegalDatePicker;
