import { Skeleton } from "@mui/material";
import { CaseType } from "../cases/Case.types";
import { initialCaseFilter } from "../cases/CaseService";
import { CaseSummarization } from "../cases/CaseSummarizationPage";
import CaseTableWrapper from "../cases/CaseTableWrapper";
import BarChart from "../charts/BarChart";
import DonutChart from "../charts/DonutChart";
import { YearlyCosts } from "../costs/Cost.types";
import noDataIcon from "../icons/No data.svg";
import serviceUnavailableIcon from "../icons/Service unavailable.svg";
import { CaseStatus } from "../worldMap/WorldMap.types";
import BarChartSkeleton from "./BarChartSkeleton";
import { RegistrationsPerClass, WidgetConfig, YearlyRenewalsAndApplications } from "./Dashboard.types";
import { DashboardWidgetGridItem } from "./DashboardGrid";
import { getUpcomingRenewals } from "./DashboardService";

export const thousandSeparateNumber = (number: number): string => (number !== 0 ? number.toLocaleString("en") : "0");

export const findMax = (array: number[]) => array.reduce((prev, current) => (prev > current ? prev : current));

export const getDigitCount = (number: number) => number.toString().split(".")[0].length;

export const generateRenewalsAndApplicationsWidget = (widgetConfig: WidgetConfig<YearlyRenewalsAndApplications[]>): DashboardWidgetGridItem => {
    const keys = widgetConfig.data.length > 0 ? Object.keys(widgetConfig.data[0]) : [];
    const indexBy: keyof YearlyRenewalsAndApplications = "year";
    const chartKeys = keys.filter((k) => (k as keyof YearlyRenewalsAndApplications) !== indexBy);
    const tickValues = Math.max(
        ...chartKeys
            .filter((k) => widgetConfig.data.some((d) => d[k as keyof YearlyRenewalsAndApplications]))
            .map((key) => Math.max(...widgetConfig.data.map((o) => o[key as keyof YearlyRenewalsAndApplications])))
    );
    const legend = chartKeys.length > 0 ? chartKeys.map((ck) => ck.charAt(0).toUpperCase() + ck.slice(1)) : [];
    const yDigits =
        widgetConfig.data.length > 0 ? getDigitCount(findMax(widgetConfig.data.map((c) => (c.renewals > c.applications ? c.renewals : c.applications)))) : 1;

    return {
        content:
            widgetConfig.data.length === 0 ? (
                generateNoDataDisplay()
            ) : (
                <BarChart
                    colors={(data) => {
                        if (data.id === "applications") return "#002677";
                        if (data.id === "renewals") return "#2CD5C4";
                        return "#002677";
                    }}
                    data={widgetConfig.data}
                    digitsForLeftMargin={yDigits}
                    indexBy={indexBy}
                    keys={chartKeys.filter((k) => widgetConfig.data.some((d) => d[k as keyof YearlyRenewalsAndApplications]))}
                    tickValues={tickValues < 6 ? tickValues : 6}
                    xAxisTitle="Years"
                    yAxisTitle="Renewals and new applications"
                />
            ),
        loadingContent: <BarChartSkeleton />,
        header: "Renewals and New Applications",
        isLoading: widgetConfig.isLoading,
        legend: {
            colors: ["#002677", "#2CD5C4"],
            legends: widgetConfig.data.length === 0 ? [] : legend,
        },
        serviceUnavailable: widgetConfig.serviceUnavailable,
    };
};

export const generateRegistrationsPerClassWidget = (widgetConfig: WidgetConfig<RegistrationsPerClass>): DashboardWidgetGridItem => ({
    content:
        widgetConfig.data.totalCaseCount === 0 ? (
            generateNoDataDisplay()
        ) : (
            <DonutChart
                isLoading={false}
                colors={colors}
                chartData={{
                    totalCount: widgetConfig.data.totalCaseCount,
                    chartItems: widgetConfig.data.countByTop12Classes.map((c) => ({
                        id: c.class,
                        value: c.count,
                    })),
                }}
            />
        ),
    loadingContent: (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                height: 260,
                width: 260,
            }}
        >
            <Skeleton
                style={{
                    height: "100%",
                    width: "100%",
                }}
                animation="pulse"
                variant="circular"
            />
        </div>
    ),
    header: "Registrations per class",
    isLoading: widgetConfig.isLoading,
    legend: {
        colors,
        legends: widgetConfig.data.countByTop12Classes.map((c) => c.class),
    },
    serviceUnavailable: widgetConfig.serviceUnavailable,
});

export const generateCostWidget = (widgetConfig: WidgetConfig<YearlyCosts[]>, caseType: CaseType): DashboardWidgetGridItem => {
    const keys = widgetConfig.data.length > 0 ? Object.keys(widgetConfig.data[0]) : [];
    const indexBy: keyof YearlyCosts = "year";
    const chartKeys = keys.length > 0 ? keys.filter((k) => (k as keyof YearlyCosts) !== indexBy) : [];
    const yDigits = widgetConfig.data.length > 0 ? getDigitCount(findMax(widgetConfig.data.map((c) => c.total))) : 1;

    return {
        content:
            widgetConfig.data.length === 0 ? (
                generateNoDataDisplay()
            ) : (
                <BarChart
                    colors="#002677"
                    data={widgetConfig.data}
                    digitsForLeftMargin={yDigits}
                    indexBy={indexBy}
                    keys={chartKeys}
                    unit="kr."
                    xAxisTitle="Years"
                    yAxisTitle="Amount in DKK"
                />
            ),
        loadingContent: <BarChartSkeleton />,
        footerChildren: (
            <div style={{ color: "#707070", fontSize: "10px" }}>
                {!widgetConfig.isLoading && widgetConfig.data.length > 0
                    ? `*The costs are not inclusive of VAT and do not cover costs for authorities and liaisons. The
        costs correspond to the invoiced amount and may, consequently, include amounts not yet paid.`
                    : ``}
            </div>
        ),
        header: "Costs",
        isLoading: widgetConfig.isLoading,
        legend: {
            colors: ["#002677"],
            legends:
                !widgetConfig.isLoading && widgetConfig.data.length === 0
                    ? []
                    : [`Total ${caseType.toLocaleLowerCase()} costs  - renewals and new applications`],
        },
        serviceUnavailable: widgetConfig.serviceUnavailable,
    };
};

export const generateUpcomingRenewalsWidget = (
    caseStatus: CaseStatus,
    caseType: CaseType,
    renewalRegistrationActive: boolean,
    editSetRenewalStatus: boolean
): DashboardWidgetGridItem => ({
    content: (
        <CaseTableWrapper
            showRenewalPrices={false}
            editRenewalPrice={false}
            editSetRenewalStatus={editSetRenewalStatus}
            caseFilter={initialCaseFilter(caseStatus, CaseSummarization.Dashboard)}
            caseSummarization={CaseSummarization.Dashboard}
            downloadExcel={() => {}}
            isLoadingExcel={false}
            onCaseFilterChanged={getUpcomingRenewals}
            selectedCaseType={caseType}
            renewalRegistrationActive={renewalRegistrationActive}
        />
    ),
    loadingContent: <div />,
    isLoading: false,
    header: `${caseType === CaseType.Copyright ? `Expiries` : `Upcoming Renewals`}`,
    serviceUnavailable: false,
});

const colors = ["#002677", "#E0E0E1", "#8C91D8", "#BDDFF2", "#2963EBD8", "#009E93", "#A3A3A5", "#29F4DC", "#FCDAFC", "#C5FFFF", "#FC88CF", "#E5CABE"];

const generateWidgetDisplay = (marginAuto: "marginAuto" | undefined = undefined, height: number | string | undefined = undefined, icon: any) => (
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: height || "100%",
            margin: marginAuto ? "auto" : undefined,
        }}
    >
        <img src={icon} alt="" />
    </div>
);

export const generateNoDataDisplay = (marginAuto: "marginAuto" | undefined = undefined, height: number | string | undefined = undefined) =>
    generateWidgetDisplay(marginAuto, height, noDataIcon);

export const generateServiceUnavailableDisplay = (marginAuto: "marginAuto" | undefined = undefined, height: number | string | undefined = undefined) =>
    generateWidgetDisplay(marginAuto, height, serviceUnavailableIcon);
