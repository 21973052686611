import { useDotLegalBreadCrumbs } from "@dotlegal/dotlegal-ui-components";
import { useEffect, useState } from "react";
import { CaseType } from "../../core/cases/Case.types";
import { CaseSummarization, CaseSummarizationPage } from "../../core/cases/CaseSummarizationPage";
import { CaseSummarizationProps } from "../../Main";
import { SurveyArea } from "../surveys/Surveys.types";
import DesignPriceCalculator from "./DesignPriceCalculator";
import TrademarkPriceCalculator from "./TrademarkPriceCalculator";

export type PriceCalculatorProps = CaseSummarizationProps & {
    onPriceCalculatorEntered: (active: boolean) => void;
};

function PriceCalculator(props: PriceCalculatorProps) {
    useDotLegalBreadCrumbs([{ name: "Price calculator" }]);
    const [caseSummarization] = useState<CaseSummarization>(CaseSummarization.PriceCalculator);

    useEffect(() => {
        props.onPriceCalculatorEntered(true);
        return () => props.onPriceCalculatorEntered(false);
        // eslint-disable-next-line
    }, []);

    function getSurveyArea(): SurveyArea {
        if (props.selectedCaseType === CaseType.Design) return SurveyArea.Designs;
        return SurveyArea.Trademarks;
    }

    return (
        <CaseSummarizationPage
            onCaseFilterKeyChange={props.onCaseFilterKeyChanged}
            onCaseTypeFilterConfigurationClear={props.onCaseTypeFilterConfigurationCleared}
            caseSummarization={caseSummarization}
        >
            {getSurveyArea() === SurveyArea.Trademarks ? (
                <TrademarkPriceCalculator
                    onCaseFilterKeyChanged={props.onCaseFilterKeyChanged}
                    onCaseTypeFilterConfigurationCleared={props.onCaseTypeFilterConfigurationCleared}
                    selectedCaseType={props.selectedCaseType}
                    userSession={props.userSession}
                    renewalRegistrationActive={props.renewalRegistrationActive}
                    onPriceCalculatorEntered={props.onPriceCalculatorEntered}
                />
            ) : (
                <DesignPriceCalculator
                    onCaseFilterKeyChanged={props.onCaseFilterKeyChanged}
                    onCaseTypeFilterConfigurationCleared={props.onCaseTypeFilterConfigurationCleared}
                    selectedCaseType={props.selectedCaseType}
                    userSession={props.userSession}
                    renewalRegistrationActive={props.renewalRegistrationActive}
                    onPriceCalculatorEntered={props.onPriceCalculatorEntered}
                />
            )}
        </CaseSummarizationPage>
    );
}

export default PriceCalculator;
