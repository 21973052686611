import { DotLegalHeader, useDotLegalBreadCrumbs } from "@dotlegal/dotlegal-ui-components";
import { Box, Button, Grid, GridSize, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { useIsOnSmallScreen } from "../../common/hooks/mediaHooks";
import useAdminStyles from "./Admin.styles";
import CollectionOfPricesIcon from "./Icons/CollectionOfPrices.svg";
import CollectionOfPricesDesign from "./Icons/CollectionOfPricesDesign.svg";
import organizationsIcon from "./Icons/Organisations.svg";
import usersIcon from "./Icons/Users.svg";

const Paper = styled("div")({
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 10px #E7E7E7",
    borderRadius: "20px",
    opacity: 1,
    minHeight: "200px",
});

function Admin() {
    useDotLegalBreadCrumbs([{ name: "Admin" }]);
    const styles = useAdminStyles();
    const isOnSmallScreen = useIsOnSmallScreen();

    const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize } = {
        item: true,
        xs: 12,
        sm: 12,
        md: 12,
        lg: 6,
    };

    const getGridSection = (header: string, info: string, buttonTxt: string, image: string, path: string) => {
        return (
            <Grid {...gridProps}>
                <Paper sx={styles.paper}>
                    <Grid item md={8} sx={styles.paperInfoContainer}>
                        <Box>
                            <DotLegalHeader headerStyle={"small"}>{header}</DotLegalHeader>
                            <DotLegalHeader headerStyle={"small"} fontWeight={500}>
                                {info}
                            </DotLegalHeader>
                        </Box>
                        <Box>
                            <Button variant="outlined" component={Link} to={path} onClick={() => {}}>
                                {buttonTxt}
                            </Button>
                        </Box>
                    </Grid>
                    {!isOnSmallScreen && <img style={styles.img} src={image} alt="" />}
                </Paper>
            </Grid>
        );
    };

    return (
        <Grid container spacing={4}>
            {getGridSection("MANAGE USERS", "Create and edit users.", "Users", usersIcon, "/admin/users")}

            {getGridSection("MANAGE ORGANISATIONS", "Manage organisation settings.", "Organisations", organizationsIcon, "/admin/organisations")}

            {getGridSection(
                "COLLECTION OF PRICES - TRADEMARKS",
                "Request new prices for national applications and renewals from foreign agents.",
                "Request prices",
                CollectionOfPricesIcon,
                "/admin/collection-of-prices/trademarks"
            )}

            {getGridSection(
                "COLLECTION OF PRICES - DESIGNS",
                "Request new prices for national applications and renewals from foreign agents.",
                "Request prices",
                CollectionOfPricesDesign,
                "/admin/collection-of-prices/designs"
            )}
        </Grid>
    );
}

export default Admin;
