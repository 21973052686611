import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useUserTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterContainer: {
            display: "flex",
            justifyContent: "right",
        },
        select: {
            marginTop: theme.spacing(2),
            margin: theme.spacing(1),
            minWidth: 120,
        },
        display: {
            flex: "auto",
            borderRadius: 5,
        },
        notchedOutlineBorder: {
            borderWidth: 1,
            borderRadius: 10,
        },
        notchedOutlineBorderColor: {
            borderRadius: 10,
        },
        cssLabel: {
            opacity: 0.7,
        },
        cssLabelResize: {
            fontSize: 14,
        },
        inputContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            alignItems: "center",
        },
        userContentContainer: {
            gap: 24,
            display: "flex",
            flexDirection: "column",
        },
        ellipsisCell: {
            width: 300,
            display: "block",
            textDecoration: "none",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    })
);

export default useUserTableStyles;
