import { Organization, SelectedOrganization } from "../core/organizations/Organization.types";
import { verifySelectedOrganization } from "../core/organizations/OrganizationService";
import { ApplicationUser } from "../core/users/User.types";
import { oidcUserManager } from "./OidcConfig";

export type UserSessionInfo = {
    selectedOrg: SelectedOrganization | null;
    verifiedUser: ApplicationUser | null;
};

export type UserSession = UserSessionInfo & {
    onSelectedOrganizationChanged: (selectedOrganization: SelectedOrganization | null) => void;
};

type AuthHeader = {
    authorization: string;
};
type OrgHeader = {
    organizationId: string;
};

export const getSelectedOrganizationFromLocalStorage = (): SelectedOrganization | null => {
    const stringSelectedOrg = localStorage.getItem(process.env.REACT_APP_IPPORTAL_SELECTED_ORG!);
    if (stringSelectedOrg === null || stringSelectedOrg === "") return null;
    const selectedOrg = JSON.parse(stringSelectedOrg) as SelectedOrganization;
    return !selectedOrg.id ? null : selectedOrg;
};

export const getAccessTokenFromSessionStorage = (): string => {
    const oidcUserFromLocalStorage = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_IDP_AUTHORITY}:${process.env.REACT_APP_IDP_CLIENT_ID}`);
    if (!oidcUserFromLocalStorage) return "";
    return JSON.parse(oidcUserFromLocalStorage).access_token;
};
export const setSelectedOrgInLocalStorage = (selectedOrg: SelectedOrganization): void =>
    localStorage.setItem(process.env.REACT_APP_IPPORTAL_SELECTED_ORG!, JSON.stringify(selectedOrg));
export const setCostAccessLevelOnSelectedOrg = (changedOrganization: Organization, onSelectedOrgCostAccessChanged: () => void): void => {
    const selectedOrg = getSelectedOrganizationFromLocalStorage();
    if (!selectedOrg || selectedOrg.id !== changedOrganization.id) return;
    selectedOrg.costAccess = changedOrganization.costAccess;
    setSelectedOrgInLocalStorage(selectedOrg);
    onSelectedOrgCostAccessChanged();
};

const removeVerifiedUserFromLocalStorage = (): void => localStorage.removeItem(process.env.REACT_APP_IPPORTAL_USER!);

export const removeSelectedOrgFromLocalStorage = (): void => {
    localStorage.removeItem(process.env.REACT_APP_IPPORTAL_SELECTED_ORG!);
};

export const orgHeader = (orgId: string): OrgHeader => ({
    organizationId: orgId,
});

export const generateIDPAuthHeader = (): AuthHeader => ({
    authorization: `Bearer ${getAccessTokenFromSessionStorage()}`,
});

export const verifyIDPUser = (): Promise<any> =>
    fetch(`/api/users/me`)
        .then((res) => res.json())
        .then(async (verifiedUser: ApplicationUser) => {
            if (!verifiedUser || (verifiedUser && !verifiedUser.userRoles.some((role) => role.name === "Admin") && verifiedUser.organizations.length === 0)) {
                await invalidateUserSession();
            }

            if (verifiedUser.organizations.length === 1) {
                await verifySelectedOrganization(verifiedUser.organizations[0].id);
            }

            const org = getSelectedOrganizationFromLocalStorage();
            if (org?.id) {
                verifySelectedOrganization(org?.id).catch(() => {});
            }

            localStorage.removeItem("challenge-in-progress");
            return verifiedUser;
        })
        .catch((error) => {
            if (error.statusCode === 401) {
                window.location.href = "/unauthorized";
            }
        });

export const invalidateUserSession = (removeSelectedOrganization = true): Promise<any> => {
    removeVerifiedUserFromLocalStorage();
    if (removeSelectedOrganization) removeSelectedOrgFromLocalStorage();
    return oidcUserManager
        .getUser()
        .then((sessionUser) => oidcUserManager.signoutRedirect({ id_token_hint: sessionUser?.id_token }).catch())
        .catch(() => {});
};

export const challengeIdp = () => {
    if (!localStorage.getItem("challenge-in-progress")) {
        localStorage.setItem("challenge-in-progress", "true");
        return oidcUserManager.signinRedirect();
    }
};
