import { DotLegalHeader, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { SingleValidator } from "../../../../../common/hooks/validation/SingleValidator";
import OutlinedQuestionBox from "../../../../priceCalculator/outlinedQuestionBox/OutlinedQuestionBox";
import { DesignSurveyIntervalArea } from "../../components/intervals/interval.types";
import Intervals from "../../components/intervals/Intervals";
import OfficialAttorneyFeesBox from "../../components/officialAttorneyFeesBox/OfficialAttorneyFeesBox";
import YesNoBox from "../../components/yesNoBox/YesNoBox";
import { DesignSurveyViewModel, EndOfDurationType } from "../../Survey.types";
import { SectionHeader } from "../../trademarkSurvey/Survey";
import { fillingFeeError } from "../DesignSurvey.hooks";

export interface FeesProps {
    data: DesignSurveyViewModel | undefined;
    onChange: (viewModel: DesignSurveyViewModel) => void;
    validator?: SingleValidator<DesignSurveyViewModel, DesignSurveyViewModel>;
    isPdf?: boolean;
}

function NewApplicationFees(props: FeesProps) {
    let data = props.data;

    function additionalCostsHasErrors() {
        return (
            props.validator?.showErrors &&
            (props.validator.hasErrors("additionalCostsForOfficialFeesForFirstDesign") ||
                props.validator.hasErrors("additionalCostsForAttorneyFeesForFirstDesign") ||
                props.validator.hasErrors("additionalCostsForOfficialFeesInEachAdditionalDesign") ||
                props.validator.hasErrors("additionalCostsForAttorneyFeesInEachAdditionalDesign"))
        );
    }

    function publicationAndRegistrationFeesHasErrors() {
        return (
            props.validator?.showErrors &&
            (props.validator.hasErrors("officialPublicationAndRegistrationFeesFirstDesign") ||
                props.validator.hasErrors("attorneyPublicationAndRegistrationFeesFirstDesign") ||
                props.validator.hasErrors("officialPublicationAndRegistrationFeesInEachAdditionalDesign") ||
                props.validator.hasErrors("attorneyPublicationAndRegistrationFeesInEachAdditionalDesign"))
        );
    }

    return (
        <Box>
            <SectionHeader isPdf>
                <DotLegalHeader headerStyle={"large"} marginBottom={0}>
                    Fees
                </DotLegalHeader>
            </SectionHeader>

            <OutlinedQuestionBox
                header="Is it possible to file multiple applications?"
                headerMarginBottom={0}
                hasError={props.validator?.hasErrors("multipleApplicationsPossible") && props.validator.showErrors}
                isPdf={props.isPdf}
            >
                <YesNoBox
                    value={props.data?.multipleApplicationsPossible}
                    onValueChange={(value) => {
                        props.data!.multipleApplicationsPossible = value;
                        if (!value) {
                            props.data!.multipleFilingFees = null;
                        }
                        props.onChange(props.data!);
                    }}
                    errorText={props.validator?.getErrorsForDisplay("multipleApplicationsPossible")}
                />
            </OutlinedQuestionBox>

            {data && data.multipleApplicationsPossible && data.multipleFilingFees && (
                <OutlinedQuestionBox
                    header="Filing fees for multiple applications"
                    hasError={props.validator?.hasErrors("multipleFilingFees") && props.validator.showErrors}
                    isPdf={props.isPdf}
                >
                    <Intervals
                        period={data.multipleFilingFees.periods[0]}
                        onPeriodChange={(period) => {
                            let tempModel = { ...data! };
                            tempModel.multipleFilingFees!.periods.find((x) => x.id === period.id)!.intervals = period.intervals;
                            props.onChange(tempModel);
                        }}
                        intervalArea={DesignSurveyIntervalArea.MultipleFilingFees}
                        surveyId={data!.currentSurveyId}
                        errorText={
                            props.validator?.getErrorsForDisplay("multipleFilingFees")?.includes(data.multipleFilingFees.periods[0].id) ? fillingFeeError : ""
                        }
                        isPdf={props.isPdf}
                        data={data}
                    />
                </OutlinedQuestionBox>
            )}

            <OutlinedQuestionBox
                header="Filing fees for single applications"
                hasError={props.validator?.hasErrors("singleFilingFees") && props.validator.showErrors}
                isPdf={props.isPdf}
            >
                <Intervals
                    data={data}
                    period={data?.singleFilingFees?.periods[0]}
                    onPeriodChange={(period) => {
                        let tempModel = { ...data! };
                        tempModel.singleFilingFees!.periods.find((x) => x.id === period.id)!.intervals = period.intervals;
                        props.onChange(tempModel);
                    }}
                    intervalArea={DesignSurveyIntervalArea.SingleFilingFees}
                    surveyId={data!.currentSurveyId}
                    errorText={
                        props.validator?.getErrorsForDisplay("singleFilingFees")?.includes(props.data?.singleFilingFees?.periods[0].id!) ? fillingFeeError : ""
                    }
                    isPdf={props.isPdf}
                />
            </OutlinedQuestionBox>

            <OutlinedQuestionBox header={"Additional costs for claiming priority"} hasError={additionalCostsHasErrors()} isPdf={props.isPdf}>
                <OfficialAttorneyFeesBox
                    header={"a) 1st design"}
                    officialFee={data?.additionalCostsForOfficialFeesForFirstDesign?.toString()}
                    attorneyFee={data?.additionalCostsForAttorneyFeesForFirstDesign?.toString()}
                    onOfficialFeeChange={(value) => {
                        let temoModel = { ...data! };
                        temoModel.additionalCostsForOfficialFeesForFirstDesign = value;
                        props.onChange(temoModel);
                    }}
                    onAttorneyFeeChange={(value) => {
                        let temoModel = { ...data! };
                        temoModel.additionalCostsForAttorneyFeesForFirstDesign = value;
                        props.onChange(temoModel);
                    }}
                    officaialFeesErrorText={props.validator?.getErrorsForDisplay("additionalCostsForOfficialFeesForFirstDesign")}
                    attorneyFeesErrorText={props.validator?.getErrorsForDisplay("additionalCostsForAttorneyFeesForFirstDesign")}
                />

                <OfficialAttorneyFeesBox
                    header={"b) Each additional design"}
                    officialFee={data?.additionalCostsForOfficialFeesInEachAdditionalDesign?.toString()}
                    attorneyFee={data?.additionalCostsForAttorneyFeesInEachAdditionalDesign?.toString()}
                    onOfficialFeeChange={(value) => {
                        let temoModel = { ...data! };
                        temoModel.additionalCostsForOfficialFeesInEachAdditionalDesign = value;
                        props.onChange(temoModel);
                    }}
                    onAttorneyFeeChange={(value) => {
                        let temoModel = { ...data! };
                        temoModel.additionalCostsForAttorneyFeesInEachAdditionalDesign = value;
                        props.onChange(temoModel);
                    }}
                    officaialFeesErrorText={props.validator?.getErrorsForDisplay("additionalCostsForOfficialFeesInEachAdditionalDesign")}
                    attorneyFeesErrorText={props.validator?.getErrorsForDisplay("additionalCostsForAttorneyFeesInEachAdditionalDesign")}
                />
            </OutlinedQuestionBox>

            <>
                <OutlinedQuestionBox
                    header="Is deferment of publication possible?"
                    headerMarginBottom={0}
                    hasError={props.validator?.hasErrors("isDefermentPossible") && props.validator.showErrors}
                    isPdf={props.isPdf}
                >
                    <YesNoBox
                        value={props.data?.isDefermentPossible}
                        onValueChange={(value) => {
                            let tempData = { ...props.data! };
                            tempData.isDefermentPossible = value;
                            if (!value) {
                                tempData.defermentFees = undefined;
                            }
                            props.onChange(tempData);
                        }}
                        errorText={props.validator?.getErrorsForDisplay("isDefermentPossible")}
                    />
                </OutlinedQuestionBox>

                {props.data?.isDefermentPossible && props.data.defermentFees && (
                    <OutlinedQuestionBox
                        header={"Deferment fee"}
                        hasError={props.validator?.hasErrors("defermentFees") && props.validator.showErrors}
                        isPdf={props.isPdf}
                    >
                        <Intervals
                            data={data}
                            period={props.data?.defermentFees}
                            onPeriodChange={(period) => {
                                let tempModel = { ...data! };
                                tempModel.defermentFees!.intervals = period.intervals;
                                props.onChange(tempModel);
                            }}
                            intervalArea={DesignSurveyIntervalArea.DefermentFees}
                            surveyId={data!.currentSurveyId}
                            errorText={props.validator?.getErrorsForDisplay("defermentFees")}
                            isPdf={props.isPdf}
                        />
                    </OutlinedQuestionBox>
                )}

                <OutlinedQuestionBox header={"Publication and/or registration fees"} hasError={publicationAndRegistrationFeesHasErrors()} isPdf={props.isPdf}>
                    <OfficialAttorneyFeesBox
                        header={"a) 1st design"}
                        officialFee={data?.officialPublicationAndRegistrationFeesFirstDesign?.toString()}
                        attorneyFee={data?.attorneyPublicationAndRegistrationFeesFirstDesign?.toString()}
                        onOfficialFeeChange={(value) => {
                            let temoModel = { ...data! };
                            temoModel.officialPublicationAndRegistrationFeesFirstDesign = value;
                            props.onChange(temoModel);
                        }}
                        onAttorneyFeeChange={(value) => {
                            let temoModel = { ...data! };
                            temoModel.attorneyPublicationAndRegistrationFeesFirstDesign = value;
                            props.onChange(temoModel);
                        }}
                        officaialFeesErrorText={props.validator?.getErrorsForDisplay("officialPublicationAndRegistrationFeesFirstDesign")}
                        attorneyFeesErrorText={props.validator?.getErrorsForDisplay("attorneyPublicationAndRegistrationFeesFirstDesign")}
                    />

                    <OfficialAttorneyFeesBox
                        header={"a) Each additional design"}
                        officialFee={data?.officialPublicationAndRegistrationFeesInEachAdditionalDesign?.toString()}
                        attorneyFee={data?.attorneyPublicationAndRegistrationFeesInEachAdditionalDesign?.toString()}
                        onOfficialFeeChange={(value) => {
                            let temoModel = { ...data! };
                            temoModel.officialPublicationAndRegistrationFeesInEachAdditionalDesign = value;
                            props.onChange(temoModel);
                        }}
                        onAttorneyFeeChange={(value) => {
                            let temoModel = { ...data! };
                            temoModel.attorneyPublicationAndRegistrationFeesInEachAdditionalDesign = value;
                            props.onChange(temoModel);
                        }}
                        officaialFeesErrorText={props.validator?.getErrorsForDisplay("officialPublicationAndRegistrationFeesInEachAdditionalDesign")}
                        attorneyFeesErrorText={props.validator?.getErrorsForDisplay("attorneyPublicationAndRegistrationFeesInEachAdditionalDesign")}
                    />
                </OutlinedQuestionBox>

                <OutlinedQuestionBox
                    header={"End of duration (Maximum length of registration)"}
                    hasError={props.validator?.hasErrors("multipleApplicationsPossible") && props.validator.showErrors}
                    isPdf={props.isPdf}
                >
                    <Box sx={{ display: "flex" }}>
                        <Box
                            sx={(theme) => ({
                                "& .MuiInputBase-root": {
                                    borderBottomRightRadius: 0,
                                    borderTopRightRadius: 0,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRight: 0,
                                    borderColor: `${theme.palette.primary.dark} !important`,
                                },
                            })}
                        >
                            <DotLegalTextField
                                label={"End of duration"}
                                value={data?.endOfDurationAmount?.toString() ?? ""}
                                debounce
                                errorText={props.validator?.getErrorsForDisplay("endOfDurationAmount")}
                                onChange={(value) => {
                                    let temoModel = { ...data! };
                                    temoModel.endOfDurationAmount = value ? Number(value) : undefined;
                                    props.onChange(temoModel);
                                }}
                                handleNumber
                                thousandSeperator="en"
                            />
                        </Box>
                        <Box
                            sx={{
                                "& .MuiInputBase-root": {
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                },
                                "& .MuiFormControl-root": {
                                    width: 120,
                                },
                                "& .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                                    width: "100%",
                                },
                            }}
                        >
                            <DotLegalSelect
                                options={[
                                    { id: EndOfDurationType.Year.toString(), name: "Years" },
                                    { id: EndOfDurationType.Month.toString(), name: "Months" },
                                ]}
                                onChange={(type) => {
                                    let temoModel = { ...data! };
                                    temoModel.endOfDurationType = type ? Number(type) : undefined;
                                    props.onChange(temoModel);
                                }}
                                selectedItem={props.data?.endOfDurationType?.toString() ?? EndOfDurationType.Year.toString()}
                                placeholder={""}
                                label={""}
                                noOptionsLabel={""}
                                disableClearable
                            />
                        </Box>
                    </Box>
                </OutlinedQuestionBox>
            </>
        </Box>
    );
}

export default NewApplicationFees;
