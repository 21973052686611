import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { DashboardWidgetLegendProps } from "./DashboardWidgetLegend";

const useDashboardWidgetLegendStyles = makeStyles((theme: Theme) =>
    createStyles({
        legendDivider: {
            width: "80%",
            color: theme.palette.grey.A100,
            opacity: 0.5,
        },
        legendText: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
        },
        legendDot: {
            height: theme.spacing(1),
            width: theme.spacing(1),
            borderRadius: 50,
            display: "inline-block",
            verticalAlign: "center",
            marginRight: 10,
        },
        legendContainer: (props: DashboardWidgetLegendProps) => ({
            display: "grid",
            gridTemplateColumns: calculateLegendGrid(props.legends.length),
            rowGap: 12,
            columnGap: `${props.legends.length <= 4 ? props.legends.length : 4}%`,
        }),
        legend: {
            width: "100%",
        },
    })
);

export default useDashboardWidgetLegendStyles;

const calculateLegendGrid = (legendLength: number) => {
    if (legendLength >= 4) return "22% 22% 22% 22%";
    if (legendLength === 1) return "88% 0% 0% 0%";
    let legendGrid = "";
    for (let i = 0; i < 4; i++) {
        const length = legendLength > i ? "22%" : "0%";
        legendGrid += `${length} `;
    }
    return legendGrid;
};
