import { get, post } from "../../common/api/apiShared";
import { Result } from "../../common/api/result";
import { TableHeader } from "../../common/table/DotLegalTable.types";
import { CaseStatus, CaseStatusCountryCases } from "../worldMap/WorldMap.types";
import { CountryCases, totalCasesPropertyKey } from "../worldMap/WorldMapService";
import {
    Case,
    CaseFilter,
    CaseFilterOptions,
    CasesResponse,
    CaseTableArrangement,
    CaseTableData,
    CaseType,
    CaseWithAdditionalInfo,
    Device,
    DisputesResponse,
    RenewalRemarkModalViewModel,
    RenewalStatus,
} from "./Case.types";
import { CaseSummarization } from "./CaseSummarizationPage";

const casesEndpointUri = "/api/cases";
const renewalsEndPointUri = "/api/renewals";
const renewalEndpointUri = "/api/renewals";

export const convertStringArrayToQueryParam = (stringArray: string[]): string => {
    return stringArray && stringArray.length === 0 ? "" : stringArray.map((x) => encodeURIComponent(x)).join("||");
};

const convertCaseTypeToQueryParam = (caseType: CaseType): string => `caseType=${mapCaseTypeToEnumValue(caseType)}`;

const convertCaseFilterToQueryParams = (filter: CaseFilter | null): string =>
    filter
        ? `applicants=${convertStringArrayToQueryParam(filter.applicants)}` +
          `&catchwords=${convertStringArrayToQueryParam(filter.catchwords)}` +
          `&classes=${convertStringArrayToQueryParam(filter.classes)}` +
          `&countries=${convertStringArrayToQueryParam(filter.countries)}` +
          `&renewalStatus=${convertStringArrayToQueryParam(filter.renewalStatuses)}` +
          `&renewalDateFrom=${translateDateToString(filter.renewalDateFrom)}` +
          `&renewalDateTo=${translateDateToString(filter.renewalDateTo)}` +
          `&searchText=${filter.searchText || ""}` +
          `${filter.status ? `&status=${getCaseStatusEnumValue(filter.status)}` : ""}` +
          `&types=${convertStringArrayToQueryParam(filter.types)}` +
          `${filter.status ? `&disputeDecision=${getDisputeDecisionEnumValue(filter.status)}` : ""}`
        : "";

const convertCaseFilterWithoutStatusToQueryParams = (filter: Omit<CaseFilter, "status">): string =>
    `applicants=${convertStringArrayToQueryParam(filter.applicants)}` +
    `&catchwords=${convertStringArrayToQueryParam(filter.catchwords)}` +
    `&classes=${convertStringArrayToQueryParam(filter.classes)}` +
    `&countries=${convertStringArrayToQueryParam(filter.countries)}` +
    `&renewalDateFrom=${translateDateToString(filter.renewalDateFrom)}` +
    `&renewalDateTo=${translateDateToString(filter.renewalDateTo)}` +
    `&searchText=${filter.searchText || ""}` +
    `&types=${convertStringArrayToQueryParam(filter.types)}`;

const convertCaseTableArrangementToQueryParams = (tableArrangement: CaseTableArrangement): string =>
    `orderBy=${tableArrangement.orderBy}` +
    `&orderByDescending=${tableArrangement.orderByDescending}` +
    `&pageSize=${tableArrangement.pageSize}` +
    `&pageNumber=${tableArrangement.pageNumber}`;

const convertCaseQueryToQueryParams = (caseType: CaseType, filter: CaseFilter & CaseTableArrangement) =>
    `${convertCaseTypeToQueryParam(caseType)}&${convertCaseFilterToQueryParams(filter)}&${convertCaseTableArrangementToQueryParams(filter)}&isDispute=${
        filter?.isDispute
    }&isRenewals=${filter?.isRenewals}`;

const convertExtendedCaseQueryParams = (caseType: CaseType, filter: CaseFilter & CaseTableArrangement) =>
    `${convertCaseTypeToQueryParam(caseType)}&${convertCaseFilterToQueryParams(filter)}&${convertCaseTableArrangementToQueryParams(filter)}`;

const convertCaseCountByCountryQueryToQueryParams = (caseType: CaseType, filter: Omit<CaseFilter, "status">) =>
    `${convertCaseTypeToQueryParam(caseType)}&${convertCaseFilterWithoutStatusToQueryParams(filter)}&isDispute=${filter?.isDispute}`;

const convertCaseFilterOptionsQueryToQueryParam = (caseType: CaseType, filter: CaseFilter | null) =>
    `${convertCaseTypeToQueryParam(caseType)}&${convertCaseFilterToQueryParams(filter)}&isDispute=${filter?.isDispute}`;

export const getCases = (caseType: CaseType, filter: CaseFilter & CaseTableArrangement): Promise<CasesResponse> =>
    fetch(`${casesEndpointUri}?${convertCaseQueryToQueryParams(caseType, filter)}`).then((casesResponse) => casesResponse.json());

export const getRenewals = (caseType: CaseType, filter: CaseFilter & CaseTableArrangement): Promise<CasesResponse> =>
    get<CasesResponse>(`${renewalEndpointUri}?${convertExtendedCaseQueryParams(caseType, filter)}`);

export const saveRenewalPrice = async (selectedCase: { internalId: string; price: string | null; remark: string; currencyCode: string }) =>
    fetch(`${renewalEndpointUri}/Change-price`, {
        method: "put",
        body: JSON.stringify(selectedCase),
        headers: {
            "Content-Type": "application/json",
        },
    }).then(() => {});

export const getDisputes = (caseType: CaseType, filter: CaseFilter & CaseTableArrangement): Promise<DisputesResponse> =>
    fetch(`${casesEndpointUri}/disputes?${convertCaseQueryToQueryParams(caseType, filter)}`).then((casesResponse) => casesResponse.json());

export const getWorldMapCases = (caseType: CaseType, filter: Omit<CaseFilter, "status">): Promise<CaseStatusCountryCases> =>
    fetch(`${casesEndpointUri}/count-by-country?${convertCaseCountByCountryQueryToQueryParams(caseType, filter)}`).then((worldMapCasesResponse) =>
        worldMapCasesResponse.json()
    );

export const getWorldMapDisputes = (caseType: CaseType, filter: Omit<CaseFilter, "status">): Promise<CaseStatusCountryCases> =>
    fetch(`${casesEndpointUri}/disputes/count-by-country?${convertCaseCountByCountryQueryToQueryParams(caseType, filter)}`).then((worldMapCasesResponse) =>
        worldMapCasesResponse.json()
    );

export const getCaseFilterOptions = (caseType: CaseType, filter: CaseFilter | null, isRenewals?: boolean): Promise<CaseFilterOptions> => {
    // Allow user to select multiple options, and across statuses
    const unfilteredFilter = {
        ...filter,
        status: isRenewals ? filter?.status : undefined, // just for renewal page, we only show registered cases, so the filter needs this status check as well - this works differently on the other pages
        countries: [],
        classes: [],
        applicants: [],
        catchwords: [],
        renewalStatuses: [],
        renewalDateFrom: isRenewals ? filter?.renewalDateFrom : null,
        renewalDateTo: isRenewals ? filter?.renewalDateTo : null,
    } as CaseFilter;
    return get<CaseFilterOptions>(
        `${isRenewals ? renewalsEndPointUri : casesEndpointUri}/filter-options?${convertCaseFilterOptionsQueryToQueryParam(caseType, unfilteredFilter)}`
    );
};

export const getCase = (id: number, country: string): Promise<CaseWithAdditionalInfo> =>
    fetch(`${casesEndpointUri}/${id}/country/${country}`).then((response) => response.json());

export const getRenewal = (internalId: string): Promise<RenewalRemarkModalViewModel> =>
    fetch(`${renewalsEndPointUri}/${internalId}`).then((response) => response.json());

export const updateRenewalRemark = (updateRenewalRemark: { internalId: string; remark: string }): Promise<Result<Case>> =>
    post<Case>(`${renewalsEndPointUri}/updateRenewalRemark`, updateRenewalRemark);

export const updateRenewalStatus = (updateRenewalStatus: { internalId: string; status?: RenewalStatus; remark?: string }): Promise<Result<Case[]>> =>
    post<Case[]>(`${renewalsEndPointUri}/updateRenewalStatus`, updateRenewalStatus);

export const getDevice = (deviceId: number, caseId: number): Promise<Device> =>
    fetch(`${casesEndpointUri}/${caseId}/device/${deviceId}`).then((deviceResponse) => deviceResponse.json());

export const downloadDocument = (documentId: number, documentName: string): Promise<void> =>
    fetch(`${casesEndpointUri}/document/${documentId}`)
        .then((documentResponse) => documentResponse.blob())
        .then((blobParts) => {
            const blob = new Blob([blobParts], {
                type: getMimeByFilename(documentName),
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = documentName;
            a.click();
        })
        .catch(() => {});

export const exportCasesExcel = (caseType: CaseType, filter: CaseFilter & CaseTableArrangement): Promise<void> =>
    fetch(`${casesEndpointUri}/export?${convertCaseQueryToQueryParams(caseType, filter)}`)
        .then((exportCsvResponse) => exportCsvResponse.blob())
        .then((blobResponse) => {
            const blob = new Blob([blobResponse], { type: ".xslx" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Export.xlsx";
            a.click();
        })
        .catch(() => {});

export const exportCustomCasesExcel = (caseType: CaseType, filter: CaseFilter & CaseTableArrangement, statuses: Array<string>): Promise<void> =>
    fetch(`${casesEndpointUri}/customexport?${convertCaseQueryToQueryParams(caseType, filter)}&selectedStatuses=${convertStringArrayToQueryParam(statuses)}`)
        .then((exportCsvResponse) => exportCsvResponse.blob())
        .then((blobResponse) => {
            const blob = new Blob([blobResponse], { type: ".xslx" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Export.xlsx";
            a.click();
        });

export const exportRenewalCasesExcel = (caseType: CaseType, filter: CaseFilter & CaseTableArrangement): Promise<void> =>
    fetch(`${renewalEndpointUri}/export?${convertExtendedCaseQueryParams(caseType, filter)}`)
        .then((exportCsvResponse) => exportCsvResponse.blob())
        .then((blobResponse) => {
            const blob = new Blob([blobResponse], { type: ".xslx" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Renewal-Export-${getCurrentDate()}.xlsx`;
            a.click();
        })
        .catch(() => {});

export function getCurrentDate(): string {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${"-"}${month < 10 ? `0${month}` : `${month}`}${"-"}${date}`;
}

export const initialCountryCases: CaseStatusCountryCases = {
    registered: [],
    pending: [],
    closed: [],
    won: [],
    partly: [],
    lost: [],
};

export const initialCaseFilter = (
    caseStatus?: CaseStatus,
    caseSummarization?: CaseSummarization,
    renewalDateFrom?: Date | null,
    renewalDateTo?: Date | null
): CaseFilter => ({
    applicants: [],
    catchwords: [],
    classes: [],
    countries: [],
    isDispute: false,
    isRenewals: false,
    status: caseStatus,
    types: [],
    renewalStatuses: [],
    renewalDateFrom: renewalDateFrom,
    renewalDateTo: renewalDateTo,
});

export const initialCaseFilterOptions: CaseFilterOptions = {
    applicants: [],
    catchwords: [],
    classes: [],
    countries: [],
    types: [],
    renewalStatuses: [],
};

// eslint-disable-next-line arrow-body-style
export const getTotalCaseCount = (countryCases: CountryCases[]): number => {
    return countryCases.length > 0 ? countryCases.map((r) => r[totalCasesPropertyKey]).reduce((sum, count) => sum + count) : 0;
};

export const mapCaseTypeToEnumValue = (caseType: CaseType): number => {
    switch (caseType) {
        case CaseType.Trademark:
            return 0;
        case CaseType.Design:
            return 1;
        case CaseType.Copyright:
            return 2;
        default:
            return 0;
    }
};

export const formatDate = (dateAsString?: string): string | undefined =>
    dateAsString && dateAsString !== ""
        ? new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
          })
              .format(new Date(dateAsString.split("-").join("/")))
              .split("/")
              .join("-")
        : undefined;

export const getCaseStatusEnumValue = (status: CaseStatus) => {
    if (status === "registered") {
        return 0;
    }
    if (status === "pending") {
        return 1;
    }
    if (status === "closed") {
        return 2;
    }
    if (status === "won") {
        return 2;
    }
    if (status === "partly") {
        return 2;
    }
    if (status === "lost") {
        return 2;
    }
    return 0;
};

export const getDisputeDecisionEnumValue = (status: CaseStatus) => {
    if (status === "won") {
        return 0;
    }
    if (status === "partly") {
        return 1;
    }
    if (status === "lost") {
        return 2;
    }
    return 0;
};

export const initialCaseTable: CaseTableData = {
    cases: [],
    count: 0,
};

export const initialCaseTableArrangement: CaseTableArrangement = {
    pageNumber: 0,
    pageSize: 15,
    orderBy: "",
    orderByDescending: false,
};
export const caseTableArrangementForCsv: CaseTableArrangement = {
    pageNumber: 0,
    pageSize: 0,
    orderBy: "",
    orderByDescending: false,
};

export const initialCaseTableHeaders: TableHeader<Case>[] = [];

type FileExtension = "pdf" | "doc" | "htm" | "rtf";

type FileExtensionMime = {
    [extension in FileExtension]: string;
};

const fileExtensionMimes: FileExtensionMime = {
    pdf: "data:application/pdf",
    doc: "data:application/msword",
    htm: "data:text/html",
    rtf: "data:application/rtf",
};

const getMimeByFilename = (filename: string): string => {
    const extension = filename.split(".").pop()?.toString();
    if (extension && Object.keys(fileExtensionMimes).includes(extension)) return fileExtensionMimes[extension as unknown as FileExtension];
    return "";
};

export const translateDateToString = (date: Date | null | undefined): string | null => {
    if (!date) {
        return "";
    }

    const localDate = new Date(date!);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(localDate);
    let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(localDate);
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(localDate);

    return `${da}-${mo}-${ye}`;
};
