import { createSxStyles } from "../../common/createSxStyles";

export const useAdminStyles = () => {
    return createSxStyles({
        paper: {
            minHeight: 245,
            display: "flex",
            padding: 4,
        },
        paperInfoContainer: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
        },
        img: {
            marginBottom: "-33px",
            maxWidth: "100%",
            minWidth: "270px",
            height: "auto",
            alignSelf: "flex-end",
        },
        button: {
            width: 200,
        },
    });
};

export default useAdminStyles;
