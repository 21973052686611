import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useDotLegalSearchStyles from "./DotLegalSearch.styles";

export interface DotLegalSearchProps {
    onSearchTextChanged: (newSearchText: string) => void;
    value?: string;
}

function DotLegalSearch(props: DotLegalSearchProps) {
    const classes = useDotLegalSearchStyles();
    const [searchText, setSearchText] = useState(props.value);
    const [searchTextInputTimer, setSearchTextInputTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

    const onSearchTextChanged = (newSearchText: string) => {
        setSearchText(newSearchText);
        if (searchTextInputTimer) {
            clearTimeout(searchTextInputTimer);
        }
        setSearchTextInputTimer(
            setTimeout(() => {
                props.onSearchTextChanged(newSearchText);
            }, 500)
        );
    };

    useEffect(() => {
        setSearchText(props.value);
    }, [props.value]);

    return (
        <TextField
            variant="outlined"
            placeholder="Search"
            color="primary"
            className={classes.dotLegalSearch}
            onChange={(event) => onSearchTextChanged(event.target.value)}
            size="small"
            value={searchText}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default DotLegalSearch;
