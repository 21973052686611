import { Tooltip } from "@mui/material";
import { NavLink } from "react-router-dom";
import useNavigationLinkStyles from "./NavigationLink.styles";

export interface INavigationLinkProps {
    isActive?: () => boolean;
    text: string;
    linkTo: string;
    exact: boolean;
    icon: string;
    isMinimized: boolean;
    onNavLinkClick: (page: string) => void;
}

function NavigationLink(props: INavigationLinkProps) {
    const classes = useNavigationLinkStyles();

    let content = (
        <div>
            <img alt={props.text} src={props.icon} />
            <span className={props.isMinimized ? classes.isMinimized : ""}>{props.text}</span>
        </div>
    );

    if (props.linkTo.startsWith("http")) {
        content = (
            <a href={props.linkTo} onClick={() => props.onNavLinkClick(props.text)}>
                {content}
            </a>
        );
    } else {
        content = (
            <NavLink exact={props.exact} isActive={props.isActive} to={props.linkTo} onClick={() => props.onNavLinkClick(props.text)}>
                {content}
            </NavLink>
        );
    }
    if (props.isMinimized) {
        return (
            <Tooltip
                classes={{
                    tooltip: classes.tooltip,
                }}
                title={<div className={classes.toolTipItem}>{props.text}</div>}
                placement="right"
            >
                {content}
            </Tooltip>
        );
    }
    return content;
}

export default NavigationLink;
