import { Box } from "@mui/system";
import useDashboardWidgetStyles from "./DashboardWidget.styles";

export interface DashboardWidgetProps {
    children: any;
    widgetCardShadow?: boolean;
    widgetCardHeader: string;
}

function DashboardWidget(props: DashboardWidgetProps) {
    const classes = useDashboardWidgetStyles(props);
    return (
        <Box sx={() => ({ height: "100%" })}>
            <h3 className={classes.header}>{props.widgetCardHeader}</h3>
            <Box className={classes.card} sx={() => ({ height: "100%" })}>
                {props.children}
            </Box>
        </Box>
    );
}

export default DashboardWidget;
