import { TableCell, TableRow } from "@mui/material";
import DotLegalNavigationIcon from "../../common/navigationIcon/DotLegalNavigationIcon";
import { Case, CaseType } from "./Case.types";
import { CaseSummarization } from "./CaseSummarizationPage";
import CaseTableRow from "./CaseTableRow";
import RenewalOverflowMenu from "./RenewalOverflowMenu/RenewalOverflowMenu";

export type CaseTableRowProps = {
    case: Case;
    onCaseSelected: (caseSelection: { caseId: number; country: string }) => void;
    onRenewalRemarkSelected: (internalId: string) => void;
    onRenewalPriceChange?: (selectedCase: { internalId: string; price: string | null; remark: string; currencyCode: string }) => void;
    onCaseUpdated?: (updatedCase: Case) => void;
    caseType: CaseType;
    caseSummarization?: CaseSummarization;
    showRenewalPrice?: boolean;
    editRenewalPrice?: boolean;
    editSetRenewalStatus: boolean;
    renewalRegistrationActive: boolean;
};

function CaseTableRowWrapper(props: CaseTableRowProps) {
    return (
        <TableRow key={props.case.id}>
            <CaseTableRow {...props} />
            <TableCell
                onClick={(event) => {
                    if (props.caseSummarization !== CaseSummarization.Renewals)
                        props.onCaseSelected({
                            caseId: props.case.id,
                            country: props.case.country,
                        });
                    event.stopPropagation();
                }}
                align="right"
            >
                {props.caseSummarization === CaseSummarization.Renewals ? <RenewalOverflowMenu {...props} /> : <DotLegalNavigationIcon />}
            </TableCell>
        </TableRow>
    );
}

export default CaseTableRowWrapper;
