import { Tooltip } from "@mui/material";
import React, { useState } from "react";

type DotLegalTableCellWithImageTooltipProps<T extends { base64: string }> = {
    children: React.ReactElement;
    hoveredCellId: number;
    onHover: () => Promise<T>;
};

function DotLegalTableCellWithImageTooltip<T extends { base64: string }>(props: DotLegalTableCellWithImageTooltipProps<T>) {
    const [base64, setBase64] = useState<string | undefined>("");
    const [hoveredCell, setHoveredCell] = useState<number[]>([]);

    const onHover = () => {
        const newHoveredCells = [...hoveredCell, props.hoveredCellId];
        setHoveredCell(newHoveredCells);
        props
            .onHover()
            .then((response) => {
                if (newHoveredCells.includes(props.hoveredCellId) && response?.base64) setBase64(response.base64);
            })
            .catch(() => {});
    };

    const onLeave = () => {
        const newHoveredCases = hoveredCell.filter((value) => value !== props.hoveredCellId);
        setHoveredCell(newHoveredCases);
        setBase64(undefined);
    };

    return (
        <div onMouseEnter={onHover} onMouseLeave={onLeave}>
            {base64 ? <Tooltip title={<img src={`data:image/jpeg;base64,${base64}`} alt="Device" />}>{props.children}</Tooltip> : props.children}
        </div>
    );
}

export default DotLegalTableCellWithImageTooltip;
