import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useNavigationLinkStyles = makeStyles((theme: Theme) =>
    createStyles({
        isMinimized: {
            display: "none",
            borderRadius: 20,
        },
        toolTipItem: {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
        },
        tooltip: {
            borderRadius: 15,
            overflow: "unset",
            padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
            lineHeight: 1,
        },
    })
);

export default useNavigationLinkStyles;
