import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Collapse, Skeleton } from "@mui/material";
import { useState } from "react";

export interface IPriceCalculationCollapseProps {
    children: any;
    hasData: boolean;
    isLoading: boolean;
}

function PriceCalculationCollapse(props: IPriceCalculationCollapseProps) {
    const [checked, setChecked] = useState(true);
    const collapsedSize = 56;

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const GetCollapseArrow = () => {
        return (
            <Box
                onClick={handleChange}
                sx={{
                    position: "absolute",
                    right: 12,
                    top: 13,
                    height: 30,
                    width: 30,
                    "&:hover": {
                        cursor: "pointer",
                    },
                    "& svg": {
                        width: 30,
                        height: 30,
                    },
                }}
            >
                {checked ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Box>
        );
    };

    const getCollapse = () => {
        return (
            <Collapse
                in={checked}
                collapsedSize={collapsedSize}
                sx={(theme) => ({
                    position: "relative",
                    backgroundColor: "#E8F3FE",
                    display: props.hasData || props.isLoading ? "block" : "none",
                })}
            >
                <Box sx={{ p: 2 }}>
                    {GetCollapseArrow()}
                    {props.children}
                </Box>
            </Collapse>
        );
    };

    return (
        <>
            {props.isLoading ? (
                <Skeleton variant="rounded" width={"100%"}>
                    {getCollapse()}
                </Skeleton>
            ) : (
                getCollapse()
            )}
        </>
    );
}

export default PriceCalculationCollapse;
