import { CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import DotLegalAutocomplete, { AutocompleteOption } from "../../common/autocomplete/DotLegalAutocomplete";
import { Organization } from "../organizations/Organization.types";
import { getOrganizations } from "../organizations/OrganizationService";
import useConfigureUserStyles from "./ConfigureUser.styles";
import { ConfigurableApplicationUser, UserRole } from "./User.types";
import { getUserRoles } from "./UserService";

const filterWidth = "100%";

export interface ConfigureUserProps {
    user: ConfigurableApplicationUser;
    OnUserChanged: (user: ConfigurableApplicationUser) => void;
    waitingForServer: boolean;
}

const userIsEmployee = (user: ConfigurableApplicationUser) => user.userRoles.some((r) => r?.name === "Employee");

function ConfigureUser(props: ConfigureUserProps) {
    const classes = useConfigureUserStyles();
    const [userRoles] = useState<AutocompleteOption<UserRole>[]>(getUserRoles.map((role) => ({ label: role.name, value: role })));
    const [selectedUserRoles, setSelectedUserRoles] = useState(props.user.userRoles.map((role) => ({ label: role.name, value: role })));
    const [organizations, setOrganizations] = useState<AutocompleteOption<Organization>[]>([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState<AutocompleteOption<Organization>[]>(
        props.user.organizations.map((org) => ({
            label: org.name,
            value: org,
        }))
    );

    useEffect(() => {
        getOrganizations()
            .then((organizationsFromDb) => setOrganizations(organizationsFromDb.map((org) => ({ label: org.name, value: org }))))
            .catch(() => {});
    }, []);

    useEffect(() => {
        setSelectedOrganizations(props.user.organizations.map((org) => ({ label: org.name, value: org })));
        setSelectedUserRoles(props.user.userRoles.map((role) => ({ label: role.name, value: role })));
    }, [props.user]);

    return (
        <div className={classes.configureUserForm}>
            <div className={classes.inputContainer}>
                <TextField
                    variant="outlined"
                    size="small"
                    color="primary"
                    fullWidth
                    label="Name"
                    value={props.user.name}
                    onChange={(e) => props.OnUserChanged({ ...props.user, name: e.target.value })}
                    InputLabelProps={{
                        classes: {
                            root: "",
                        },
                    }}
                />
            </div>
            <div className={classes.inputContainer}>
                <TextField
                    variant="outlined"
                    size="small"
                    color="primary"
                    fullWidth
                    label="Email"
                    value={props.user.email}
                    onChange={(e) => props.OnUserChanged({ ...props.user, email: e.target.value })}
                    InputLabelProps={{
                        classes: {
                            root: "",
                        },
                    }}
                />
            </div>
            <div className={classes.phoneNumber}>
                <div style={{ width: 65 }}>
                    <TextField
                        variant="outlined"
                        size="small"
                        color="primary"
                        placeholder="+45"
                        fullWidth
                        value={props.user.countryCode}
                        onChange={(e) =>
                            props.OnUserChanged({
                                ...props.user,
                                countryCode: e.target.value,
                            })
                        }
                        InputLabelProps={{
                            classes: {
                                root: "",
                            },
                        }}
                    />
                </div>
                <div>
                    <TextField
                        variant="outlined"
                        size="small"
                        color="primary"
                        label="Phone"
                        fullWidth
                        value={props.user.phoneNumber}
                        onChange={(e) =>
                            props.OnUserChanged({
                                ...props.user,
                                phoneNumber: e.target.value,
                            })
                        }
                        InputLabelProps={{
                            classes: {
                                root: "",
                            },
                        }}
                    />
                </div>
            </div>
            <div className={classes.inputContainer}>
                <DotLegalAutocomplete
                    onChange={(selectedRoles) => {
                        props.OnUserChanged({
                            ...props.user,
                            userRoles: selectedRoles.filter((r) => r.value !== null).map((r) => r.value) as UserRole[],
                        });
                    }}
                    label="User Role(s)"
                    options={userRoles}
                    value={selectedUserRoles}
                    width={filterWidth}
                />
            </div>
            <div className={classes.inputContainer}>
                <DotLegalAutocomplete
                    checkbox
                    onChange={(newSelectedOrganizations) => {
                        props.OnUserChanged({
                            ...props.user,
                            organizations: newSelectedOrganizations.filter((org) => org.value !== null).map((org) => org.value) as Organization[],
                        });
                    }}
                    label="Organisation(s)"
                    options={organizations}
                    selectAllOption={userIsEmployee(props.user)}
                    value={selectedOrganizations}
                    width={filterWidth}
                />
            </div>
            {props.waitingForServer && <CircularProgress />}
        </div>
    );
}

export default ConfigureUser;
