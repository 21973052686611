import { DateType } from "@date-io/type";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import DotLegalAutocomplete, { AutocompleteOption } from "../../common/autocomplete/DotLegalAutocomplete";
import DotLegalDatePicker from "../../common/datePicker/DotLegalDatePicker";
import DotLegalSearch from "../../common/search/DotLegalSearch";
import useCasePageStyles from "./Case.styles";
import { CaseAutocompleteOptions, CaseFilter, CaseRenewalDateFilter, CaseType } from "./Case.types";
import { getCaseFilterOptions, initialCaseFilter, initialCaseFilterOptions } from "./CaseService";
import { CaseSummarization } from "./CaseSummarizationPage";

const filterWidth = 200;

const initialSelectedFilterOptions: CaseAutocompleteOptions = {
    applicants: [],
    classes: [],
    catchwords: [],
    countries: [],
    types: [],
    renewalStatuses: [],
};

export interface FiltersProps {
    caseSummarization?: CaseSummarization;
    selectedCaseType: CaseType;
    caseFilter: CaseFilter;
    onCaseFilterChanged: (caseFilter: CaseFilter) => void;
    renewalRegistrationActive: boolean;
}

function CaseFilters(props: FiltersProps) {
    const classes = useCasePageStyles();

    const [filtersHasBeenLoaded, setFiltersHasBeenLoaded] = useState(false);
    const [filtersIsPristine, setFiltersIsPristine] = useState(true);

    const [filterOptions, setFilterOptions] = useState(initialCaseFilterOptions);

    const [selectedFilterOption, setSelectedFilterOption] = useState(initialSelectedFilterOptions);
    const onDateChange = (date: DateType | null, value: string | null, criteria: keyof CaseRenewalDateFilter): void => {
        const newFilter = { ...props.caseFilter };
        if (criteria === "renewalDateFrom") {
            newFilter.renewalDateFrom = date;
        }
        if (criteria === "renewalDateTo") {
            newFilter.renewalDateTo = date;
        }
        onFilterChanged(newFilter);
    };

    const onAutocompleteChanged =
        <K extends keyof CaseAutocompleteOptions>(criteria: K) =>
        (selectedFilterOptions: CaseAutocompleteOptions[K]) => {
            const newSelectedFilterOptions = { ...selectedFilterOption };
            newSelectedFilterOptions[criteria] = selectedFilterOptions;
            setSelectedFilterOption(newSelectedFilterOptions);
            if (filtersIsPristine) setFiltersIsPristine(false);
            const newFilter = { ...props.caseFilter };
            newFilter[criteria] = selectedFilterOptions
                .filter((selectedOption: AutocompleteOption<any>) => !!selectedOption)
                .map((selectedOption: AutocompleteOption<any>) => selectedOption.value);

            onFilterChanged(newFilter);
        };

    const onSearchTextChanged = (value: string) => {
        const newFilter = { ...props.caseFilter };
        newFilter.searchText = value;
        onFilterChanged(newFilter);
    };

    const onFilterChanged = (filter: CaseFilter) => {
        const newFilter = { ...filter };
        newFilter.isDispute = props.caseSummarization === CaseSummarization.Disputes;
        props.onCaseFilterChanged(newFilter);
        getCaseFilterOptionsByFilter(newFilter).then();
    };

    const generateAutocomplete = (label: string, criteria: keyof CaseAutocompleteOptions) => {
        if (!criteria) return <></>;
        let selected = filterOptions[criteria]?.filter((f) => props.caseFilter[criteria].includes(f.value ?? ""));

        return (
            <DotLegalAutocomplete
                label={label}
                onChange={onAutocompleteChanged(criteria)}
                options={filterOptions[criteria]}
                value={selected}
                width={filterWidth}
            />
        );
    };

    const getCaseFilterOptionsByFilter = async (filter: CaseFilter) => {
        const response = await getCaseFilterOptions(props.selectedCaseType, filter, props.caseSummarization === CaseSummarization.Renewals);
        if (response) setFilterOptions(response);
        return undefined;
    };

    const resetCaseFilter = (): void => {
        const newFilter = {
            ...initialCaseFilter(props.caseSummarization === CaseSummarization.Disputes ? "pending" : "registered", props.caseSummarization),
        };
        newFilter.status = props.caseFilter.status;
        newFilter.renewalDateFrom = props.caseFilter.renewalDateFrom;
        newFilter.renewalDateTo = props.caseFilter.renewalDateTo;
        newFilter.catchwords = props.caseFilter.catchwords;
        newFilter.classes = props.caseFilter.classes;
        newFilter.countries = props.caseFilter.countries;
        newFilter.applicants = props.caseFilter.applicants;
        newFilter.renewalStatuses = props.caseFilter.renewalStatuses;
        setSelectedFilterOption(initialSelectedFilterOptions);
        onFilterChanged(newFilter);
    };

    useEffect(() => {
        if (filtersHasBeenLoaded) getCaseFilterOptionsByFilter(props.caseFilter).then();
        // eslint-disable-next-line
    }, [props.caseFilter.status]);

    useEffect(() => {
        if (!filtersHasBeenLoaded) setFiltersHasBeenLoaded(true);
        getCaseFilterOptionsByFilter(props.caseFilter);
        // eslint-disable-next-line
    }, [props.selectedCaseType]);

    useEffect(() => {
        resetCaseFilter();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className={classes.filterContainer}>
                {generateAutocomplete(props.selectedCaseType, "catchwords")}
                {(props.caseSummarization === CaseSummarization.Renewals || props.caseSummarization === CaseSummarization.Costs) && (
                    <Box
                        sx={{
                            "& .MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#2CD5C4 !important",
                            },
                        }}
                    >
                        <DotLegalDatePicker
                            criteria="renewalDateFrom"
                            label="From"
                            onDateChange={onDateChange}
                            selectedValue={props.caseFilter.renewalDateFrom ?? null}
                        />
                    </Box>
                )}
                {(props.caseSummarization === CaseSummarization.Renewals || props.caseSummarization === CaseSummarization.Costs) && (
                    <DotLegalDatePicker
                        criteria="renewalDateTo"
                        label="To"
                        onDateChange={onDateChange}
                        selectedValue={props.caseFilter.renewalDateTo ?? null}
                    />
                )}
                {props.selectedCaseType !== CaseType.Copyright && generateAutocomplete("Class", "classes")}
                {generateAutocomplete("Country", "countries")}
                {props.caseSummarization !== CaseSummarization.Disputes && generateAutocomplete("Applicant", "applicants")}
                {props.caseSummarization === CaseSummarization.Renewals &&
                    props.renewalRegistrationActive &&
                    generateAutocomplete("Renewal status", "renewalStatuses")}
                {props?.caseSummarization === CaseSummarization.Disputes && generateAutocomplete("Case type", "types")}
                <div className={classes.searchContainer}>
                    <DotLegalSearch onSearchTextChanged={onSearchTextChanged} value={props.caseFilter.searchText} />
                </div>
            </div>
        </>
    );
}

export default CaseFilters;
