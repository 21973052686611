import { QueryClient, QueryClientProvider } from "react-query";
import { useHistory } from "react-router-dom";
import { ajaxQueue } from "./common/api/ajaxQuery";

export interface IQueryClientProviderPrivacyProps {
    children: any;
}

const queryClient = new QueryClient();

window.addEventListener("beforeunload", function (e) {
    if (queryClient.isMutating() || ajaxQueue().isItemsOnQueue()) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "";
    }
});

function QueryClientProviderIpPortal(props: IQueryClientProviderPrivacyProps) {
    const history = useHistory();

    queryClient.setDefaultOptions({
        mutations: {
            onError: async (error) => {
                await queryClient.cancelMutations();
                ajaxQueue().clear();
                history.push("/error");
            },
        },
        queries: {
            useErrorBoundary: true,
        },
    });

    return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
}

export default QueryClientProviderIpPortal;
