import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get } from "../../../common/api/apiShared";
import { SurveyViewModel } from "../survey/Survey.types";

export function useSurveyPDFDataMapping() {
    const { newapplicationid } = useParams<{ newapplicationid: string }>();
    const { renewalid } = useParams<{ renewalid: string }>();

    const newapplicationQuery = useQuery(newapplicationid, () => get<SurveyViewModel>(`/api/survey/${newapplicationid}`));
    const renewalQuery = useQuery(renewalid, () => get<SurveyViewModel>(`/api/survey/${renewalid}`));

    return {
        newApplicationData: newapplicationQuery.data,
        renewalData: renewalQuery.data,
    };
}
