import { createTheme } from "@mui/material/styles";
import { createBreakpoints } from "@mui/system";

const belowThisBreakpointIsMobile: number | "xs" | "sm" | "md" | "lg" | "xl" = "sm";

export function getBreakpointForMobile() {
    return belowThisBreakpointIsMobile;
}

const lightGrey = "#F7F7F7";
const veryLightGrey = "#B2BBC7";
const white = "#FFFFFF";
const lightTurquoise = "#D4F7F3";
const turquoise = "#2CD5C4";
const darkBlue = "#032677";
const lightBlue = "#F0F7FC";
const darkTurquise = "#3ACBBC";
const errorRed = "#D32F2F";

declare module "@mui/material/styles/createTheme" {
    interface Theme {
        customPalette: {
            white: string;
            lightBlue: string;
            darkBlue: string;
            errorRed: string;
        };
        customSpacing: {
            iconSize: number;
            contentSpacing: number;
        };
        spacingAsNumber(spacing: number): number;
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        customPalette?: {
            white: string;
            lightBlue: string;
            darkBlue: string;
            errorRed: string;
        };
        customSpacing: {
            iconSize: number;
            contentSpacing: number;
        };
        spacingAsNumber(spacing: number): number;
    }
}

const theme = createTheme();

const customBreakpointValues = {
    keys: [...theme.breakpoints.keys],
    values: {
        ...theme.breakpoints.values,
        xl: 1600,
    },
};

const breakpoints = createBreakpoints({ ...customBreakpointValues });

const ipPortalTheme = createTheme({
    spacingAsNumber: (spacing: number) => Number(theme.spacing(spacing).slice(0, -2)),
    breakpoints: { ...breakpoints },
    customPalette: {
        white,
        lightBlue,
        darkBlue,
        errorRed,
    },
    customSpacing: {
        iconSize: 18,
        contentSpacing: 4,
    },
    shape: {
        borderRadius: 20,
    },
    palette: {
        text: {
            primary: darkBlue,
            secondary: veryLightGrey,
        },
        grey: {
            A100: lightGrey,
        },
        primary: {
            light: lightBlue,
            main: darkBlue,
            dark: darkBlue /* Fixes DotLegalHeader. It uses primary.dark */,
        },
        secondary: {
            light: lightTurquoise,
            main: turquoise,
            dark: darkTurquise,
        },
    },
    typography: {
        fontFamily: ['"Open Sans"'].join(","),
        h5: {
            textTransform: "uppercase",
            color: darkBlue,
            fontSize: "0.75rem",
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: "0.875rem",
                    lineHeight: 1.43,
                    letterSpacing: "0.01071em",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltipPlacementRight: {
                    margin: "0 !important",
                },
                tooltip: {
                    backgroundColor: "white",
                    color: "#002677",
                    boxShadow: theme.shadows[1],
                    borderRadius: 10,
                    fontSize: theme.typography.pxToRem(14),
                    padding: theme.spacing(2),
                    maxHeight: 120,
                    maxWidth: 650,
                    letterSpacing: 0.01,
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    fill: `${turquoise} !important`,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paperWidthMd: {
                    maxWidth: 1200,
                    width: 1200,
                },
                paperWidthSm: {
                    maxWidth: 750,
                    width: 750,
                },
                paperWidthXs: {
                    maxWidth: 380,
                    width: 380,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: theme.typography.pxToRem(12),
                    padding: `${theme.spacing(1.5)} 0`,
                    borderColor: "#B6D5EF",
                    paddingRight: theme.spacing(1.5),
                },
                root: {
                    borderColor: "#B6D5EF !important",
                },
                head: {
                    paddingRight: `${theme.spacing(1.5)} !important`,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: theme.typography.pxToRem(14),
                    "&::placeholder": {
                        color: "#707070",
                        fontSize: theme.typography.pxToRem(14),
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: theme.typography.pxToRem(14),
                },
                shrink: {
                    color: darkBlue,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                adornedEnd: {
                    paddingRight: 0,
                },
                notchedOutline: {
                    borderColor: darkBlue,
                },
                root: {
                    borderRadius: 5,
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: darkBlue,
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "#707070",
                    fontSize: theme.typography.pxToRem(14),
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflowX: "unset",
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 40 - 8,
                    paddingBottom: theme.spacing(6.5),
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 20,
                },
                spacing: {
                    padding: 40 - 8,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h6: {
                    fontWeight: theme.typography.fontWeightBold,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: 20,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: darkBlue,
                    minWidth: "130px",
                    fontWeight: theme.typography.fontWeightBold,
                    textTransform: "none",
                    color: white,
                    "&.MuiButton-label": {
                        color: white,
                    },
                    "&.Mui-disabled": {
                        color: white,
                    },
                    borderRadius: "10px",
                },
                textPrimary: {
                    "&:hover": {
                        border: "none",
                        backgroundColor: white,
                    },
                },
                outlinedPrimary: {
                    borderColor: darkBlue,
                    backgroundColor: darkBlue,
                    "&:hover": {
                        borderColor: "#0D48C7",
                        backgroundColor: "#0D48C7",
                    },
                },
                outlinedSecondary: {
                    borderColor: darkBlue,
                    backgroundColor: white,
                    color: darkBlue,
                    "&:hover": {
                        borderColor: "#0D48C7",
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: darkBlue,
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: darkBlue,
                },
            },
        },
        // MuiPickersToolbar: {
        //   styleOverrides: {
        //     toolbar: {
        //       backgroundColor: '#D4F7F3',
        //     },
        //   },
        // },
        // MuiPickersModal: {},
        // MuiPickersToolbarButton: {
        //   styleOverrides: {
        //     toolbarBtn: {
        //       color: darkBlue,
        //       backgroundColor: '#D4F7F3',
        //     },
        //   },
        // },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    padding: 11,
                },
                track: {
                    backgroundColor: "#2CD5C4 !important",
                },
                thumb: {
                    height: 15,
                    width: 15,
                    backgroundColor: "#2CD5C4",
                },
            },
        },
        MuiCollapse: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                filledSuccess: {
                    background: `#32AA9E !important`,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    color: "black",
                },
            },
        },
    },
});

export default ipPortalTheme;
