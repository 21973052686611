import { UserSessionInfo } from "../../auth/AuthService";
import copyrightsIcon from "../icons/Copyrights.svg";
import costsIcon from "../icons/Costs.svg";
import dashboardIcon from "../icons/Dashboard.svg";
import designsIcon from "../icons/Designs.svg";
import priceCalculator from "../icons/Price calculator.svg";
import renewalsIcon from "../icons/Renewals.svg";
import trademarksIcon from "../icons/Trademarks.svg";
import { orgHasCases, userHasCostAccess } from "../organizations/OrganizationService";
import { userHasOrganizationAccess } from "../users/UserService";
import useNavLinkListStyles from "./NavLinkList.styles";
import NavLinkListItem from "./NavLinkListItem";

export type NavLinkListProps = {
    isMinimized: boolean;
    userSession: UserSessionInfo;
    location: string;
};

function NavLinkMainList(props: NavLinkListProps) {
    const classes = useNavLinkListStyles(props);
    return userHasOrganizationAccess(props.userSession.verifiedUser) ? (
        <ul className={classes.navList}>
            {orgHasCases(props.userSession.selectedOrg) && (
                <NavLinkListItem
                    title="Dashboard"
                    linkTo="/dashboard"
                    icon={dashboardIcon}
                    isMinimized={props.isMinimized}
                    isActive={() => props.location === "/" || props.location === "/dashboard"}
                />
            )}
            {props.userSession.selectedOrg?.hasTrademarks && (
                <NavLinkListItem title="Trademarks" linkTo="/trademarks" icon={trademarksIcon} isMinimized={props.isMinimized} />
            )}
            {props.userSession.selectedOrg?.hasDesigns && (
                <NavLinkListItem title="Designs" linkTo="/designs" icon={designsIcon} isMinimized={props.isMinimized} />
            )}
            {props.userSession.selectedOrg?.hasCopyrights && (
                <NavLinkListItem title="Copyrights" linkTo="/copyrights" icon={copyrightsIcon} isMinimized={props.isMinimized} />
            )}
            {orgHasCases(props.userSession.selectedOrg) && (
                <NavLinkListItem title="Renewals" linkTo="/renewals" icon={renewalsIcon} isMinimized={props.isMinimized} />
            )}
            {(props.userSession.selectedOrg?.hasTrademarkDisputes || props.userSession.selectedOrg?.hasDesignDisputes) && (
                <NavLinkListItem title="Disputes" linkTo="/disputes" icon={trademarksIcon} isMinimized={props.isMinimized} />
            )}
            {orgHasCases(props.userSession.selectedOrg) && userHasCostAccess(props.userSession.selectedOrg) && (
                <NavLinkListItem title="Costs" linkTo="/costs" icon={costsIcon} isMinimized={props.isMinimized} />
            )}
            {props.userSession.verifiedUser?.userRoles.some((x) => x.name === "Employee") && (
                <NavLinkListItem title="Price calculator" linkTo="/price-calculator" icon={priceCalculator} isMinimized={props.isMinimized} />
            )}
        </ul>
    ) : (
        <></>
    );
}

export default NavLinkMainList;
