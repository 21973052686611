import { useDotLegalBreadCrumbs } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useState } from "react";
import ContentCard from "../../common/contentCard/ContentCard";
import DotLegalSearch from "../../common/search/DotLegalSearch";
import UserTable from "../../core/users/UserTable";
import { useUrlProvider } from "../../useUrlProvider";

function Users() {
    const [adminSearchText, setAdminSearchText] = useState("");
    const { getAdminUrl } = useUrlProvider();
    useDotLegalBreadCrumbs([{ name: "Admin", link: getAdminUrl() }, { name: "Users" }]);

    return (
        <ContentCard>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <DotLegalSearch value={adminSearchText} onSearchTextChanged={setAdminSearchText} />
            </Box>
            <UserTable searchText={adminSearchText} />
        </ContentCard>
    );
}

export default Users;
