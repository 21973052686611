import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import DotLegalModal from "../../common/modal/DotLegalModal";
import DotLegalTable from "../../common/table/DotLegalTable";
import ConfigureUser from "../../core/users/ConfigureUser";
import ResendInvitation from "../../core/users/ResendInvitation";
import invitationIcon from "../icons/Invitation.svg";
import { ApplicationUser, ConfigurableApplicationUser, UserFilter, UsersResponse } from "./User.types";
import UserOverflowMenu from "./UserOverflowMenu";
import {
    configurableUserIsValid,
    deleteUser,
    getUsers,
    initialConfigurableUser,
    initialUserFilter,
    initialUserTableHeaders,
    mapApplicationUserToConfigurableUser,
    saveUser,
} from "./UserService";
import useUserTableStyles from "./UserTable.styles";

type UserTableProps = {
    searchText: string;
};

function UserTable(props: UserTableProps) {
    const classes = useUserTableStyles();
    const snackbar = useDotLegalSnackbar();
    const [configurableUser, setConfigurableUser] = useState<ConfigurableApplicationUser>({
        ...initialConfigurableUser,
    });
    const [configureUserModalIsOpen, setConfigureUserModalIsOpen] = useState<boolean>(false);
    const [resendInvitationModalIsOpen, setResendInvitationModalIsOpen] = useState<boolean>(false);
    const [userFilter, setUserFilter] = useState(initialUserFilter);
    const [usersTable, setUsersTable] = useState<UsersResponse>({
        users: [],
        totalCount: 0,
    });
    const [userTableHeaders] = useState(initialUserTableHeaders);
    const [waitingForServer, setWaitingForServer] = useState(false);
    const [deleteUseModelOpen, setDeleteUserModelOpen] = useState<boolean>(false);

    const onInvitationModalClosed = (): void => setResendInvitationModalIsOpen(false);
    const configureUser = (user: ConfigurableApplicationUser = { ...initialConfigurableUser }): void => {
        setConfigurableUser(user);
        setConfigureUserModalIsOpen(true);
    };

    const resendInvitation = (user: ApplicationUser): void => {
        setConfigurableUser(mapApplicationUserToConfigurableUser(user));
        setResendInvitationModalIsOpen(true);
    };

    const saveConfiguredUser = (): void => {
        if (!configurableUserIsValid(configurableUser)) return;
        setWaitingForServer(true);
        saveUser(configurableUser)
            .then(() => {
                snackbar.show("User saved successfully", "success");
                setWaitingForServer(false);
                setConfigureUserModalIsOpen(false);
                getUsersByFilter(userFilter);
            })
            .catch((reason) => {
                snackbar.show(reason.message, "error");
                setWaitingForServer(false);
            });
    };

    const configureDeleteUser = (user: ConfigurableApplicationUser = { ...initialConfigurableUser }): void => {
        setConfigurableUser(user);
        setDeleteUserModelOpen(true);
    };
    const deleteConfiguredUser = (): void => {
        if (!configurableUserIsValid(configurableUser)) return;
        setWaitingForServer(true);
        deleteUser(configurableUser.id!)
            .then(() => {
                snackbar.show("User deleted successfully", "success");
                setWaitingForServer(false);
                setDeleteUserModelOpen(false);
                getUsersByFilter(userFilter);
            })
            .catch((reason) => {
                snackbar.show(reason.message, "error");
                setWaitingForServer(false);
            });
    };

    const getUsersByFilter = (filter: UserFilter) => {
        getUsers(filter)
            .then((usersResponse) =>
                setUsersTable({
                    users: usersResponse.users,
                    totalCount: usersResponse.totalCount,
                })
            )
            .catch(() => {});
    };

    const onFilterChanged = (pageNumber: number, orderByDescending: boolean, orderBy: any): void => {
        userFilter.pageNumber = pageNumber;
        userFilter.orderByDescending = orderByDescending;
        userFilter.orderBy = orderBy;
        setUserFilter({ ...userFilter });
        getUsersByFilter(userFilter);
    };

    useEffect(() => {
        const newFilter = { ...userFilter };
        newFilter.searchText = props.searchText;
        setUserFilter(newFilter);
        getUsersByFilter(newFilter);
        // eslint-disable-next-line
    }, [props.searchText]);

    return (
        <div className={classes.userContentContainer}>
            <DotLegalTable
                headers={userTableHeaders}
                rows={usersTable.users}
                defaultOrderBy="name"
                onTableArrangementChanged={onFilterChanged}
                totalRowCount={usersTable.totalCount}
                renderRow={(r) => (
                    <TableRow
                        key={r.id}
                        onClick={(event) => {
                            configureUser(mapApplicationUserToConfigurableUser(r));
                        }}
                    >
                        <TableCell style={{ paddingLeft: 6 }}>{r.name}</TableCell>
                        <TableCell>{r.email}</TableCell>
                        <TableCell>
                            {r.countryCode} {r.phoneNumber}
                        </TableCell>
                        <TableCell>{r.userRoles.map((role) => role.name).join(", ")}</TableCell>
                        <TableCell>
                            <div className={classes.ellipsisCell}>{r.organizations.map((org) => org.name).join(", ")}</div>
                        </TableCell>
                        <TableCell width={160} align="center">
                            <div
                                role="button"
                                tabIndex={0}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    resendInvitation(r);
                                }}
                                onKeyPress={() => {
                                    resendInvitation(r);
                                }}
                            >
                                <img src={invitationIcon} alt="" />
                            </div>
                        </TableCell>
                        <TableCell align="right">
                            <UserOverflowMenu
                                user={configurableUser}
                                openEditModal={() => configureUser(mapApplicationUserToConfigurableUser(r))}
                                openDeleteModal={() => configureDeleteUser(mapApplicationUserToConfigurableUser(r))}
                            />
                        </TableCell>
                    </TableRow>
                )}
            />
            <DotLegalModal
                open={configureUserModalIsOpen}
                onClose={() => {
                    setConfigureUserModalIsOpen(false);
                    return undefined;
                }}
                title={configurableUser.new ? "Add user" : "Edit user"}
                content={<ConfigureUser user={configurableUser} OnUserChanged={setConfigurableUser} waitingForServer={waitingForServer} />}
                action={
                    configurableUser.new ? (
                        <>
                            <Button
                                color="primary"
                                variant={"outlined"}
                                disabled={!configurableUserIsValid(configurableUser)}
                                onClick={() => saveConfiguredUser()}
                            >
                                Send invite
                            </Button>
                        </>
                    ) : (
                        <Button color="secondary" variant={"outlined"} disabled={!configurableUserIsValid(configurableUser)} onClick={saveConfiguredUser}>
                            Save
                        </Button>
                    )
                }
            />
            <DotLegalModal
                open={deleteUseModelOpen}
                onClose={() => setDeleteUserModelOpen(false)}
                title={`Delete User`}
                content={
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <React.Fragment>
                            <Typography sx={(theme) => ({ fontSize: theme.typography.pxToRem(14) })}>
                                {"Are you sure you want to delete "} <strong>{configurableUser.name}?</strong>
                            </Typography>
                        </React.Fragment>
                    </div>
                }
                action={
                    <React.Fragment>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: "40px",
                            }}
                        >
                            <Button
                                sx={{ marginRight: "30px", borderWidth: "medium", border: "1px solid" }}
                                color="secondary"
                                variant="outlined"
                                onClick={() => setDeleteUserModelOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button style={{ backgroundColor: "#F7447A" }} onClick={deleteConfiguredUser}>
                                Delete
                            </Button>
                        </Box>
                    </React.Fragment>
                }
            />
            <DotLegalModal
                content={<ResendInvitation invitee={configurableUser} onClose={onInvitationModalClosed} />}
                onClose={onInvitationModalClosed}
                open={resendInvitationModalIsOpen}
                title="Resend invitation"
            />
            <div style={{ paddingTop: 40, display: "flex", justifyContent: "flex-end" }}>
                <Button color="primary" onClick={() => configureUser()} variant="outlined">
                    Add user
                </Button>
            </div>
        </div>
    );
}

export default UserTable;
