import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import { BarDatum, ComputedDatum, ResponsiveBar } from "@nivo/bar";
import { OrdinalColorScaleConfig } from "@nivo/colors";
import { thousandSeparateNumber } from "../dashboard/DashboardWidgetService";

export type BarChartProps = {
    colors: OrdinalColorScaleConfig<ComputedDatum<BarDatum>> | undefined;
    data: BarDatum[];
    digitsForLeftMargin?: number;
    keys: string[];
    unit?: string;
    indexBy: string;
    xAxisTitle?: string;
    yAxisTitle?: string;
    tickValues?: number;
};

const yAxisDigitsSpacing: { [key: number]: number } = {
    1: 25,
    2: 35,
    3: 42,
    4: 49,
    5: 56,
    6: 63,
    7: 70,
};

function BarChart(props: BarChartProps) {
    const calculateLeftMargin = () => {
        let leftMargin = yAxisDigitsSpacing[props.digitsForLeftMargin || 1];

        if (props.yAxisTitle) leftMargin += 25;

        return leftMargin;
    };

    const calculateYAxisOffset = () => (yAxisDigitsSpacing[props.digitsForLeftMargin || 1] + 20) * -1;

    const styles = makeStyles((theme: Theme) =>
        createStyles({
            barContainer: {
                height: 280,
                width: "100%",
                "& g rect": {
                    cursor: "pointer",
                },
            },
        })
    );

    return (
        <Box className={styles().barContainer} sx={() => ({ height: 280 })}>
            <ResponsiveBar
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 10,
                    legend: props.xAxisTitle ? props.xAxisTitle : props.indexBy.charAt(0).toUpperCase() + props.indexBy.slice(1),
                    legendPosition: "middle",
                    legendOffset: 40,
                }}
                axisLeft={{
                    format: (yTick) => thousandSeparateNumber(Number.parseInt(yTick, 10)),
                    tickPadding: 15,
                    tickRotation: 0,
                    tickSize: 0,
                    tickValues: props.tickValues ? props.tickValues : 6,
                    legend: props.yAxisTitle,
                    legendPosition: "middle",
                    legendOffset: calculateYAxisOffset(),
                }}
                gridYValues={props.tickValues ? props.tickValues : 6}
                colors={props.colors}
                data={props.data}
                enableLabel={false}
                groupMode="grouped"
                indexBy={props.indexBy}
                innerPadding={10}
                keys={props.keys}
                margin={{
                    top: 10,
                    right: 0,
                    bottom: 50,
                    left: calculateLeftMargin(),
                }}
                padding={0.75}
                theme={{
                    fontFamily: "Open Sans",
                    fontSize: 12,
                    textColor: "#002677",
                    axis: { ticks: { text: { fill: "#B2BBC7" } } },
                    grid: { line: { stroke: "#B6D5EF" } },
                    tooltip: {
                        container: { borderRadius: 10 },
                        basic: { fontWeight: "normal" },
                    },
                }}
                tooltipLabel={(data) => {
                    const tooltip = data.id.toString();
                    return `${tooltip.charAt(0).toUpperCase() + tooltip.slice(1)}`;
                }}
                valueFormat={(value: number) => `${thousandSeparateNumber(value)} ${props.unit || ""}`}
            />
        </Box>
    );
}

export default BarChart;
