import { CasesResponse, CaseType } from "../cases/Case.types";
import { mapCaseTypeToEnumValue } from "../cases/CaseService";
import { RegistrationsPerClass, YearlyRenewalsAndApplications } from "./Dashboard.types";

const dashboardEndpointUri = "/api/dashboard";

export const getYearlyRenewalsAndApplications = (caseType: CaseType): Promise<YearlyRenewalsAndApplications[]> =>
    fetch(`${dashboardEndpointUri}/yearly-renewals-and-applications?caseType=${mapCaseTypeToEnumValue(caseType)}`).then(
        (yearlyRenewalsAndApplicationsResponse) => yearlyRenewalsAndApplicationsResponse.json()
    );

export const getRegistrationsPerClass = (caseType: CaseType): Promise<RegistrationsPerClass> =>
    fetch(`${dashboardEndpointUri}/registrations-per-class?caseType=${mapCaseTypeToEnumValue(caseType)}`).then((registrationsPerClassResponse) =>
        registrationsPerClassResponse.json()
    );

export const getUpcomingRenewals = (caseType: CaseType): Promise<CasesResponse> =>
    fetch(`${dashboardEndpointUri}/upcoming-renewals?caseType=${mapCaseTypeToEnumValue(caseType)}`)
        .then((upcomingRenewalsResponse) => upcomingRenewalsResponse.json())
        .then((res) => ({
            cases: res,
            totalCount: res.totalCount,
        }));
