import { useMutation, useQueryClient } from "react-query";
import { ajaxQueue } from "../../../../../common/api/ajaxQuery";
import { post, put } from "../../../../../common/api/apiShared";
import { useOptimisticUpdate } from "../../../../../common/hooks/useOptimisticUpdate";
import { DesignSurveyIntervalArea, IntervalDeleteModel, IntervalSaveModel } from "../../components/intervals/interval.types";
import { DesignInterval } from "../../Survey.types";
import { IntervalsProps } from "./Intervals";

export function useInterval(props: IntervalsProps) {
    const queryClient = useQueryClient();
    const { putOnQueueAndSetQueryData } = useOptimisticUpdate();

    const addIntervalMutation = useMutation(addIntervaApi, {
        onSuccess: (data) => {
            queryClient.setQueryData(props.surveyId, data.value());
        },
    });
    function addIntervaApi(data: IntervalSaveModel) {
        return post<{}>(`/api/survey/design/interval`, data);
    }

    const addInterval = async () => {
        await ajaxQueue(props.surveyId).addRequest(addIntervalMutation.mutateAsync, {
            area: props.intervalArea,
            surveyId: props.surveyId,
            periodId: props.period?.id,
        });
    };

    const removeIntervalMutation = useMutation(removeIntervaApi, {
        onSuccess: (data) => {
            queryClient.setQueryData(props.surveyId, data.value());
        },
    });
    function removeIntervaApi(data: IntervalDeleteModel) {
        return put<{}>(`/api/survey/design/delete-interval`, data);
    }

    const removeInterval = async (intervalId: string) => {
        const tempModel = { ...props.data! };
        if (props.intervalArea === DesignSurveyIntervalArea.SingleFilingFees) {
            const period = tempModel!.singleFilingFees?.periods.find((x) => x.intervals.filter((i) => i.id === intervalId).length > 0)!;
            period.intervals = period.intervals.filter((x) => x.id !== intervalId);
            await putOnQueueAndSetQueryData(tempModel, props.surveyId, removeIntervalMutation.mutateAsync, {
                area: props.intervalArea,
                surveyId: props.surveyId,
                periodId: props.period?.id,
                intervalId: intervalId,
            });
        } else if (props.intervalArea === DesignSurveyIntervalArea.MultipleFilingFees) {
            const period = tempModel!.multipleFilingFees?.periods.find((x) => x.intervals.filter((i) => i.id === intervalId).length > 0)!;
            period.intervals = period.intervals.filter((x) => x.id !== intervalId);
            await putOnQueueAndSetQueryData(tempModel, props.surveyId, removeIntervalMutation.mutateAsync, {
                area: props.intervalArea,
                surveyId: props.surveyId,
                periodId: props.period?.id,
                intervalId: intervalId,
            });
        } else {
            tempModel!.defermentFees!.intervals = tempModel!.defermentFees!.intervals.filter((x) => x.id !== intervalId);
            await putOnQueueAndSetQueryData(tempModel, props.surveyId, removeIntervalMutation.mutateAsync, {
                area: props.intervalArea,
                surveyId: props.surveyId,
                periodId: props.period?.id,
                intervalId: intervalId,
            });
        }
    };

    const onToChange = (intervalToUpdate: DesignInterval, value: string | undefined) => {
        let tempModel = { ...props.period! };

        let foundInterval = tempModel.intervals!.find((x) => x.id === intervalToUpdate!.id)!;
        foundInterval!.to = value ? Number(value) : null;

        if (value && foundInterval!.from! > foundInterval!.to! && intervalToUpdate.order !== 2) {
            foundInterval!.from = foundInterval!.to!;
        }

        if (value) {
            updateIntervalsToMatchUpdatedData(foundInterval.order, foundInterval.from!, foundInterval.to!, tempModel.intervals);
        }

        props.onPeriodChange(tempModel);
    };

    const onFromChange = (intervalToUpdate: DesignInterval, value: string | undefined) => {
        let tempModel = { ...props.period! };
        let foundInterval = tempModel.intervals!.find((x) => x.id === intervalToUpdate!.id)!;

        const maxInterval = maxByOrder(tempModel.intervals);
        foundInterval!.from = value ? Number(value) : null;
        if (value && foundInterval!.from! > foundInterval!.to! && intervalToUpdate.order !== maxInterval.order) {
            foundInterval!.to = foundInterval!.from!;
        }

        if (value) {
            updateIntervalsToMatchUpdatedData(foundInterval.order, foundInterval.from!, foundInterval.to!, tempModel.intervals);
        }

        props.onPeriodChange(tempModel);
    };

    return {
        isAddingInterval: addIntervalMutation.isLoading,
        isRemovingInterval: removeIntervalMutation.isLoading,
        addInterval,
        removeInterval,
        onToChange,
        onFromChange,
    };
}

export function updateIntervalsToMatchUpdatedData(
    updatedIntervalOrder: number,
    updatedFrom: number,
    updatedTo: number | null,
    intervals: Array<DesignInterval>
) {
    updateIntervalWithLowerOrderThanUpdatedInterval(updatedIntervalOrder, updatedFrom, intervals);
    updateIntervalWithHigerOrderThanUpdatedInterval(updatedIntervalOrder, updatedTo, intervals);
}

function updateIntervalWithLowerOrderThanUpdatedInterval(updatedIntervalOrder: number, updatedFrom: number, intervals: Array<DesignInterval>) {
    let intervalsBeforeChangedInterval = intervals.filter((x) => x.order < updatedIntervalOrder && x.order !== 1).sort((a, b) => b.order - a.order);

    const minumumValue = 2;
    let newFrom = updatedFrom!;
    intervalsBeforeChangedInterval.forEach((interval) => {
        interval.to! = newFrom <= minumumValue ? minumumValue : newFrom - 1;

        if (interval.order !== 2) {
            if (interval.from! > interval.to!) {
                interval.from = newFrom < minumumValue ? minumumValue : interval.to;
            }
            newFrom = newFrom < minumumValue ? minumumValue : interval.from!;
        }
    });
}

function updateIntervalWithHigerOrderThanUpdatedInterval(updatedIntervalOrder: number, updatedTo: number | null, intervals: Array<DesignInterval>) {
    if (updatedTo) {
        let intervalsAfterChangedInterval = intervals.filter((x) => x.order > updatedIntervalOrder && x.order !== 1);
        const maxInterval = maxByOrder(intervalsAfterChangedInterval);
        let newTo = updatedTo;
        intervalsAfterChangedInterval.forEach((interval) => {
            interval.from = newTo + 1;

            if (interval.order !== maxInterval.order) {
                if (interval.to! < interval.from!) {
                    interval.to = interval.from;
                }
                newTo = interval.to!;
            }
        });
    }
}

function maxByOrder(interval: Array<DesignInterval>) {
    return interval.reduce((max, current) => (current.order > max.order ? current : max), interval[0]);
}
