import DateFnsUtils from "@date-io/date-fns";
import { DotLegalBreadCrumbProvider } from "@dotlegal/dotlegal-ui-components";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AuthProvider } from "oidc-react";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AnonymousRouterSwitch from "./AnonymousRouterSwitch";
import useAppStyles from "./App.styles";
import { getSelectedOrganizationFromLocalStorage, UserSessionInfo } from "./auth/AuthService";
import ChangeOrganisation from "./auth/ChangeOrganisation";
import { oidcConfig } from "./auth/OidcConfig";
import SignInCallback from "./auth/SignInCallback";
import SignOut from "./auth/SignOut";
import UserLoader from "./auth/UserLoader";
import DotLegalModal from "./common/modal/DotLegalModal";
import DrawerMenu from "./core/drawer/DrawerMenu";
import { SelectedOrganization } from "./core/organizations/Organization.types";
import { setupErrorHandling } from "./errorHandling";
import ErrorRouterSwitch from "./ErrorRouterSwitch";
import "./extensions";
import Main from "./Main";
import SelectOrganization from "./pages/selectOrganization/SelectOrganization";
import "./pdf.css";
import QueryClientProviderIpPortal from "./QueryClientProviderIpPortal";

setupErrorHandling();
function App() {
    const classes = useAppStyles();
    const [mobileDrawerIsOpen, setMobileDrawerIsOpen] = useState(false);
    const [orgSelectorIsOpen, setOrgSelectorIsOpen] = useState(false);
    const [userSession, setUserSession] = useState<UserSessionInfo>({
        selectedOrg: getSelectedOrganizationFromLocalStorage(),
        verifiedUser: null,
    });

    const onOrgSelectorIsOpenChanged = (isOpen: boolean) => {
        setOrgSelectorIsOpen(isOpen);
    };

    const onSelectedOrganizationChanged = (selectedOrg: SelectedOrganization | null) => {
        setUserSession({ ...userSession, selectedOrg });
    };

    const onSelectedOrgCostAccessChanged = () => {
        const selectedOrg = getSelectedOrganizationFromLocalStorage();
        if (selectedOrg) setUserSession({ ...userSession, selectedOrg });
    };

    useEffect(() => {
        onSelectedOrgCostAccessChanged();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <BrowserRouter>
                <QueryClientProviderIpPortal>
                    <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils}>
                        <ErrorRouterSwitch>
                            <AnonymousRouterSwitch>
                                <DotLegalBreadCrumbProvider>
                                    <AuthProvider {...oidcConfig}>
                                        <UserLoader
                                            onUserSessionLoaded={(user) => {
                                                setUserSession({
                                                    verifiedUser: user,
                                                    selectedOrg: getSelectedOrganizationFromLocalStorage(),
                                                });
                                            }}
                                            applicationUser={userSession.verifiedUser}
                                        >
                                            <div className={classes.root}>
                                                <DrawerMenu
                                                    mobileDrawerIsOpen={mobileDrawerIsOpen}
                                                    onMobileDrawerClose={() => setMobileDrawerIsOpen(false)}
                                                    onOrgSelectorIsOpenChange={onOrgSelectorIsOpenChanged}
                                                    userSession={userSession}
                                                />
                                                <Switch>
                                                    <Route path="/select-organisation">
                                                        <DotLegalModal
                                                            content={
                                                                <SelectOrganization
                                                                    userSession={{
                                                                        ...userSession,
                                                                        onSelectedOrganizationChanged,
                                                                    }}
                                                                />
                                                            }
                                                            onClose={() => {
                                                                onOrgSelectorIsOpenChanged(false);
                                                                return undefined;
                                                            }}
                                                            maxWidth="xs"
                                                            onCloseDisabled
                                                            open={orgSelectorIsOpen}
                                                            title="Select Organisation"
                                                        />
                                                    </Route>
                                                    <Route path="/change-organisation/:id">
                                                        <ChangeOrganisation
                                                            onOrgChanged={(org) =>
                                                                setUserSession({
                                                                    verifiedUser: userSession.verifiedUser,
                                                                    selectedOrg: org,
                                                                })
                                                            }
                                                        />
                                                    </Route>
                                                    <Route path="/sign-in-callback">
                                                        <SignInCallback
                                                            userSession={{
                                                                ...userSession,
                                                                onSelectedOrganizationChanged,
                                                            }}
                                                        />
                                                    </Route>
                                                    <Route path="/sign-out">
                                                        <SignOut />
                                                    </Route>
                                                    <Route path="*">
                                                        <Main
                                                            onDrawerExpandedMobile={() => setMobileDrawerIsOpen(true)}
                                                            onOrgSelectorIsOpenChange={onOrgSelectorIsOpenChanged}
                                                            onSelectedOrgCostAccessChanged={onSelectedOrgCostAccessChanged}
                                                            userSession={{
                                                                ...userSession,
                                                                onSelectedOrganizationChanged,
                                                            }}
                                                        />
                                                    </Route>
                                                </Switch>
                                            </div>
                                        </UserLoader>
                                    </AuthProvider>
                                </DotLegalBreadCrumbProvider>
                            </AnonymousRouterSwitch>
                        </ErrorRouterSwitch>
                    </LocalizationProvider>
                </QueryClientProviderIpPortal>
            </BrowserRouter>
        </>
    );
}

export default App;
