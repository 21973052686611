import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, CSSObject, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { useState } from "react";
import { useDotLegalAutocompleteStyles } from "./DotLegalAutocomplete.styles";

const defaultChooseAllLabel = "Choose All";

export type AutocompleteOption<T> = {
    label: string;
    value: T | null;
};

export interface DotLegalAutocompleteProps<T> {
    checkbox?: boolean;
    disabled?: true;
    onChange: (selectedValues: T[]) => void;
    getOptionDisabled?: (option: T) => boolean;
    getOptionLabel?: (option: T) => string;
    getOptionSelected?: (option: T, value: T) => boolean;
    label?: string;
    limitTags?: number;
    options: T[];
    selectAllOption?: boolean;
    selectAllLabel?: string;
    singleSelect?: true;
    value?: T[];
    width: string | number;
}

function DotLegalAutocomplete<T>(props: DotLegalAutocompleteProps<AutocompleteOption<T>>) {
    const classes = useDotLegalAutocompleteStyles(props);
    const [chooseAllLabel] = useState(props.selectAllOption && props.selectAllLabel ? props.selectAllLabel : defaultChooseAllLabel);
    const getOptionLabel = props.getOptionLabel ? props.getOptionLabel : (option: AutocompleteOption<T>) => option.label;
    const getOptionSelected = props.getOptionSelected
        ? props.getOptionSelected
        : (option: AutocompleteOption<T>, val: AutocompleteOption<T>) => option.label === val.label;
    const getOptionDisabled = props.getOptionDisabled ? props.getOptionDisabled : () => false;

    const chooseAllIsSelected = (changedValue: AutocompleteOption<T>[]) =>
        changedValue.some((v) => v.label === chooseAllLabel) && props.value && props.value.length === 0;

    const chooseAllOption: AutocompleteOption<T> = {
        label: chooseAllLabel,
        value: null,
    };
    const popupIcon = !props.disabled && (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.885" height="6.503" viewBox="0 0 10.885 6.503">
            <path
                id="Path_2060"
                data-name="Path 2060"
                d="M0,0,4.382,4.382,8.764,0"
                transform="translate(1.061 1.061)"
                fill="none"
                stroke="#002677"
                strokeLinecap="round"
                strokeWidth="1.5"
            />
        </svg>
    );

    return (
        <Autocomplete
            className={classes.filter}
            disabled={props.disabled}
            disableCloseOnSelect={props.checkbox}
            filterOptions={
                props.selectAllOption
                    ? (options, state) => {
                          if (state.inputValue)
                              return createFilterOptions({
                                  stringify: (option: AutocompleteOption<T>) => option.label,
                              })(options, state);
                          if (props.singleSelect) return [chooseAllOption, ...options];
                          if (props.value && props.value.length > 0) return options;
                          return [chooseAllOption, ...options];
                      }
                    : (options, state) =>
                          createFilterOptions({
                              stringify: (option: AutocompleteOption<T>) => option.label,
                          })(options, state)
            }
            filterSelectedOptions={!props.checkbox}
            getOptionDisabled={getOptionDisabled}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            limitTags={props.limitTags ? props.limitTags : 1}
            multiple={props.singleSelect ? undefined : true}
            onChange={(event, changedValue) => {
                if (props.singleSelect) return props.onChange([changedValue as unknown as AutocompleteOption<T>]);

                return !props.singleSelect && chooseAllIsSelected(changedValue) ? props.onChange(props.options) : props.onChange(changedValue);
            }}
            options={props.options}
            popupIcon={popupIcon}
            renderOption={
                props.checkbox
                    ? (props, option, state) => (
                          <li {...props}>
                              <Checkbox
                                  color="primary"
                                  icon={<CheckBoxOutlineBlankIcon color="primary" fontSize="small" />}
                                  checkedIcon={<CheckBoxIcon color="primary" fontSize="small" />}
                                  sx={(theme) => ({ marginRight: "8px" } as CSSObject)}
                                  checked={state.selected}
                              />
                              {getOptionLabel(option)}
                          </li>
                      )
                    : undefined
            }
            renderInput={(params) => (
                <TextField
                    color="primary"
                    {...params}
                    variant="outlined"
                    style={{ margin: 0 }}
                    fullWidth
                    label={props.label}
                    placeholder={props.singleSelect && props.selectAllLabel ? props.selectAllLabel : undefined}
                    InputLabelProps={{
                        classes: {
                            root: "",
                        },
                    }}
                />
            )}
            size="small"
            value={props.value}
        />
    );
}

export default DotLegalAutocomplete;
