import { useEffect } from "react";

export enum CaseSummarization {
    Dashboard = "Dashboard",
    Renewals = "Renewals",
    Costs = "Costs",
    Disputes = "Disputes",
    PriceCalculator = "PriceCalculator",
}

export type CaseSummarizationProps = {
    children: any;
    onCaseFilterKeyChange: (caseFilterKeyChange: string) => void;
    onCaseTypeFilterConfigurationClear: () => void;
    caseSummarization: CaseSummarization;
};

export function CaseSummarizationPage(props: CaseSummarizationProps) {
    useEffect(() => {
        props.onCaseFilterKeyChange(props.caseSummarization);
        return () => props.onCaseTypeFilterConfigurationClear();
        // eslint-disable-next-line
    }, []);

    return <>{props.children}</>;
}
