import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const margin = { top: 30, right: 35, bottom: 0, left: 30 };
const useDonutChartStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: "center",
            position: "relative",
            height: 280,
            "& g path": {
                cursor: "pointer",
            },
        },
        overlay: {
            position: "absolute",
            top: 0,
            right: margin.right,
            bottom: margin.bottom,
            left: margin.left,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 50,
            color: "#000000",
            textAlign: "center",
            pointerEvents: "none",
        },
        totalCount: {
            color: "#002677",
            fontSize: theme.typography.pxToRem(40),
            fontWeight: "bold",
            lineHeight: 1,
        },
        totalCountLabel: {
            color: "#B2BBC7",
            fontSize: theme.typography.pxToRem(16),
            fontWeight: "normal",
        },
        theme: {
            fontSize: theme.typography.pxToRem(17),
        },
        skeletonCircleGrid: {
            justifyContent: "space-around",
            padding: theme.spacing(3),
            display: "flex",
        },
        skeletonCircle: {
            width: 302,
            height: 302,
        },
    })
);

export default useDonutChartStyles;
