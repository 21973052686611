import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, Paper } from "@mui/material";
import React from "react";

export interface ISurveyHeader {
    children: React.ReactNode;
    header: string;
    backgroundColor: "green" | "blue" | "lightBlue";
    isOnMediumScreen: boolean;
    isPdf?: boolean;
}

function SurveyHeader(props: ISurveyHeader) {
    return (
        <Paper
            sx={(theme) => ({
                backgroundColor: props.backgroundColor === "blue" ? "#8694C0" : props.backgroundColor === "green" ? theme.palette.secondary.main : "#BDD3EC",
                minHeight: props.isPdf ? 150 : 250,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                p: 4,
                "& .MuiTypography-root": {
                    color: theme.customPalette.white,
                },
                position: "relative",
                borderRadius: "10px",
                boxShadow: "none",
            })}
        >
            <Box
                sx={{
                    "& .MuiTypography-root": {
                        fontSize: 28,
                    },
                }}
            >
                <DotLegalHeader headerStyle={"extraLarge"}>{props.header}</DotLegalHeader>
            </Box>
            <Box
                sx={{
                    width: props.isOnMediumScreen ? "100%" : "60%",
                    whiteSpace: "pre-line",
                    height: "100%",
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {props.children}
            </Box>
        </Paper>
    );
}

export default SurveyHeader;
