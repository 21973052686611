import { Box } from "@mui/material";
import { useState } from "react";
import DotLegalAutocomplete, { AutocompleteOption } from "../../common/autocomplete/DotLegalAutocomplete";
import { CostAccessLevel, Organization } from "./Organization.types";

const filterWidth = "100%";

export interface ConfigureUserProps {
    organization: Organization;
    onOrganizationChanged: (organization: Organization) => void;
}

function ConfigureOrganization(props: ConfigureUserProps) {
    const [organizationCostLevels] = useState<AutocompleteOption<CostAccessLevel>[]>([
        { label: "Brief overview", value: "Brief overview" },
        { label: "Detailed overview", value: "Detailed overview" },
        { label: "Hide costs", value: "Hide costs" },
    ]);
    const [selectedOrganization, setSelectedOrganization] = useState<AutocompleteOption<CostAccessLevel>>(
        organizationCostLevels.find((costLevel) => costLevel.value === props.organization.costAccess) ?? { label: "", value: null }
    );

    const onCostAccessLevelChanged = (newAccess: AutocompleteOption<CostAccessLevel>[]) => {
        let changedCostAccess: AutocompleteOption<CostAccessLevel> = {
            label: "",
            value: null,
        };
        if (newAccess != null && newAccess.length > 0 && newAccess[0]?.value) {
            changedCostAccess = newAccess[0];
        }
        setSelectedOrganization(changedCostAccess);
        props.onOrganizationChanged({
            ...props.organization,
            costAccess: changedCostAccess.value,
        });
    };

    return (
        <Box sx={(theme) => ({ display: "flex", width: "100%", flexDirection: "column", gap: theme.spacing(5.5), alignItems: "center" })}>
            <Box id="singleSelectDropdown" sx={{ width: 270 }}>
                <DotLegalAutocomplete
                    onChange={onCostAccessLevelChanged}
                    label="Costs"
                    options={organizationCostLevels}
                    singleSelect
                    // @ts-ignore
                    value={selectedOrganization}
                    width={filterWidth}
                />
            </Box>
        </Box>
    );
}

export default ConfigureOrganization;
