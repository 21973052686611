import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useDotLegalModalStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        padding: "8px 48px",
    },
    modalCloseButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.customPalette.darkBlue,
        "&:hover": {
            backgroundColor: "unset",
        },
    },
}));

export default useDotLegalModalStyles;
