type CountryGroup = {
    [iso3166Country: string]: {
        iso3166Countries: string[];
        iso3166CountryCenter: string;
    };
};

type CountryCenterMap = {
    [iso3166Country: string]: { iso3166CountryCenter: string };
};

const beneluxISO3166Countries = ["NL", "BE", "LU"];
const oapiISO3166Countries = ["DY", "BF", "CM", "CF", "TD", "KM", "CD", "GQ", "GA", "GN", "GW", "ML", "MR", "NE", "SN", "TG"];
const euISO3166Countries = [
    "BG",
    "CY",
    "DK",
    "EE",
    "FI",
    "PL",
    "DE",
    "PT",
    "FR",
    "ES",
    "SE",
    "HU",
    "IE",
    "IT",
    "LT",
    "LV",
    "CH",
    "CZ",
    "SK",
    "SI",
    "RO",
    "MT",
    "GR",
    ...beneluxISO3166Countries,
];
const dkISO3166Countries = ["DK", "FO", "GL"];

const countryGroups: CountryGroup = {
    DK: { iso3166Countries: dkISO3166Countries, iso3166CountryCenter: "DK" },
    OA: { iso3166Countries: oapiISO3166Countries, iso3166CountryCenter: "CM" },
    BX: { iso3166Countries: beneluxISO3166Countries, iso3166CountryCenter: "BE" },
    EU: { iso3166Countries: euISO3166Countries, iso3166CountryCenter: "ES" },
};

const countriesWithAlternativeCenters: CountryCenterMap = {
    ...countryGroups,
    C5: { iso3166CountryCenter: "CY" },
    IP: { iso3166CountryCenter: "CH" },
    KO: { iso3166CountryCenter: "XK" },
    WE: { iso3166CountryCenter: "PS" },
    GZ: { iso3166CountryCenter: "PS" },
    T1: { iso3166CountryCenter: "TZ" },
    Z1: { iso3166CountryCenter: "TZ" },
};

const hasAlternativeCenter = (country: string) => !!countriesWithAlternativeCenters[country] || false;

const getCountryAlternativeCenter = (groupKey: string): string => countriesWithAlternativeCenters[groupKey].iso3166CountryCenter;

export const getISO3166Countries = (groupName: string): string[] => {
    const countryGroup = countryGroups[groupName];
    return countryGroup && countryGroup.iso3166Countries
        ? countryGroup.iso3166Countries
        : [hasAlternativeCenter(groupName) ? getCountryAlternativeCenter(groupName) : groupName];
};

export default getISO3166Countries;
