export enum DesignSurveyIntervalArea {
    SingleFilingFees = 1,
    MultipleFilingFees = 2,
    DefermentFees = 3,
}

export interface IntervalSaveModel {
    surveyId: string;
    area: DesignSurveyIntervalArea;
    periodId?: string;
}

export interface IntervalDeleteModel {
    surveyId: string;
    area: DesignSurveyIntervalArea;
    intervalId: string;
    periodId?: string;
}
