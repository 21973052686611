import fetchIntercept, { FetchInterceptorResponse } from "fetch-intercept";
import { challengeIdp, generateIDPAuthHeader, getSelectedOrganizationFromLocalStorage, orgHeader } from "./AuthService";

export class HttpRequestError {
    constructor(public statusCode: number, public response: Response) {
        this.response = response;
        this.statusCode = statusCode;
    }
}

function registerHttpInterceptor(): void {
    fetchIntercept.register({
        // eslint-disable-next-line no-undef
        request(url: RequestInfo, config: RequestInit) {
            const apiRoot: string = process.env.REACT_APP_API_ROOT!;
            const urlIsString = typeof url === "string";
            const urlIsRequest = !urlIsString;
            const requestIsExternal = urlIsString ? urlIsExternal(url as string) : urlIsExternal((url as Request).url);
            const requestIsLocal = urlIsString ? urlIsLocal(url as string) : urlIsLocal((url as Request).url);

            if (requestIsExternal) return [url, config];
            if (urlIsRequest) return [url, config];
            if (requestIsLocal) return [url, config];

            return [apiRoot + url, addIDPAuthHeadersToRequest(config || {})];
        },

        requestError(error: Error) {
            return Promise.reject(error);
        },

        response(response: FetchInterceptorResponse) {
            if (response && response.url.indexOf(process.env.REACT_APP_API_ROOT!) !== -1 && (response.status === 401 || response.status === 403)) {
                challengeIdp();
            }

            if (response.status === 404) window.location.href = "/resource-not-found";

            if (response.status >= 400) throw new HttpRequestError(response.status, response);

            return response;
        },

        responseError(error: Error) {
            return Promise.reject(error);
        },
    });
}

const urlIsExternal = (requestUrl: string) => requestUrl.indexOf("https://") > -1 && requestUrl.indexOf(process.env.REACT_APP_APP_ROOT!) === -1;

const urlIsLocal = (requestUrl: string) => requestUrl.includes("http://") && requestUrl.includes(process.env.REACT_APP_APP_ROOT!);

// eslint-disable-next-line no-undef
const addIDPAuthHeadersToRequest = (requestConfig: RequestInit): RequestInit => {
    const requestConfigWithAuthHeader = { ...requestConfig };
    const authHeader = generateIDPAuthHeader();
    const headerWithAuth = new Headers(requestConfigWithAuthHeader.headers);

    headerWithAuth.set(Object.keys(authHeader)[0], authHeader.authorization);
    const selectedOrgFromLocalstorage = getSelectedOrganizationFromLocalStorage();
    if (selectedOrgFromLocalstorage) {
        const requestedOrgHeader = orgHeader(selectedOrgFromLocalstorage.id ? selectedOrgFromLocalstorage.id!.toString() : "");
        headerWithAuth.set(Object.keys(requestedOrgHeader)[0], requestedOrgHeader.organizationId);
    }
    requestConfigWithAuthHeader.headers = headerWithAuth;
    requestConfigWithAuthHeader.credentials = "include";

    return requestConfigWithAuthHeader;
};

export default registerHttpInterceptor;
