import { Paper as MuiPaper } from "@mui/material";
import React, { MouseEventHandler } from "react";
import { useDotLegalPaperStyles } from "./Paper.styles";

export type backgroundWaves = "blueWave" | "purpleWave" | "greenWave" | "purpleWave1" | "smallPurpleWave";
export type backgroundColors = "lightGreen" | "lightGrey" | "lightBlue" | "darkLightBlue" | "lightPurple" | "orange" | "limeGreen" | "darkGreen" | "pink";

export interface IPaperProps {
    children: any;
    className?: string;
    background?: backgroundWaves;
    backgroundCover?: boolean;
    backgroundColor?: backgroundColors;
    onClick?: () => void;
    clickableRows?: boolean;
    fullHeight?: boolean;
    removePadding?: boolean;
    style?: React.CSSProperties | undefined;
    onMouseEnter?: MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: MouseEventHandler<HTMLDivElement>;
}

function Paper(props: IPaperProps) {
    const classes = useDotLegalPaperStyles(props);
    return (
        <MuiPaper
            style={props.style}
            elevation={0}
            className={props.className + " " + classes.root + " " + classes.paperBackground}
            onClick={props.clickableRows ? props.onClick : undefined}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            {props.children}
        </MuiPaper>
    );
}

export default Paper;
