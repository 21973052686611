import { useMutation, useQueryClient } from "react-query";
import { ajaxQueue } from "../../../../../common/api/ajaxQuery";
import { put } from "../../../../../common/api/apiShared";
import { minBy } from "../../../../../common/utilities";
import { PeriodCopyModel } from "./Period.types";
import { PeriodsProps } from "./Periods";

export function usePeriodsHook(props: PeriodsProps) {
    const queryClient = useQueryClient();
    const firstPeriod = minBy(props?.periods ?? [], (x) => x.number);

    const copyPeriodMutation = useMutation(copyPeriodAPI, {
        onSuccess: (data) => {
            queryClient.setQueryData(props.surveyId!, data.value());
        },
    });
    function copyPeriodAPI(data: PeriodCopyModel) {
        return put<{}>(`/api/survey/design/periods/copy`, data);
    }

    const onPeriodCopy = async () => {
        await ajaxQueue(props.surveyId).addRequest(copyPeriodMutation.mutateAsync, {
            surveyId: props.surveyId,
            intervalArea: props.intervalArea,
        });
    };

    return { firstPeriod, onPeriodCopy, isCopying: copyPeriodMutation.isLoading };
}
