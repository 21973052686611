import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { DashboardWidgetProps } from "./DashboardWidget";

const useDashboardWidgetStyles = makeStyles((theme: Theme) => {
    const widgetSpacing = theme.spacing(4);
    return createStyles({
        card: (props: DashboardWidgetProps) => ({
            height: "100%",
            padding: widgetSpacing,
            backgroundColor: theme.customPalette.white,
            borderRadius: theme.shape.borderRadius,
            boxShadow: props.widgetCardShadow === false ? "" : "0px 3px 10px #E7E7E7;",
        }),
        header: {
            marginTop: 0,
            marginBottom: theme.spacing(1.5),
            textTransform: "uppercase",
            fontSize: theme.typography.pxToRem(14),
        },
    });
});

export default useDashboardWidgetStyles;
