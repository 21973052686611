import { MoreVertOutlined } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { FunctionComponent, useState } from "react";
import { ConfigurableApplicationUser } from "./User.types";

export interface UserOverflowMenuProps {
    user: ConfigurableApplicationUser;
    openEditModal: () => void;
    openDeleteModal: () => void;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        menuItem: { color: "black", fontSize: 14 },
        button: { backgroundColor: "transparent", minWidth: "auto", borderRadius: "20px" },
    })
);

const UserOverflowMenu: FunctionComponent<UserOverflowMenuProps> = (props) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    return (
        <>
            <Button
                color="primary"
                className={classes.button}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setMenuOpen(true);
                    event.stopPropagation();
                }}
            >
                <MoreVertOutlined color={"secondary"} />
            </Button>
            <Menu
                open={menuOpen}
                onClose={() => {
                    setMenuOpen(false);
                }}
                anchorEl={anchorEl}
            >
                <MenuItem
                    className={classes.menuItem}
                    onClick={(event) => {
                        props.openEditModal();
                        event.stopPropagation();
                        setMenuOpen(false);
                    }}
                >
                    Edit user
                </MenuItem>
                <MenuItem
                    className={classes.menuItem}
                    onClick={(event) => {
                        props.openDeleteModal();
                        event.stopPropagation();
                        setMenuOpen(false);
                    }}
                >
                    Delete user
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserOverflowMenu;
