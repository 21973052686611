import { fillingFeeError } from "../../designSurvey/DesignSurvey.hooks";
import { DesignFeesIntervals, DesignSurveyViewModel } from "../../Survey.types";
import { DesignSurveyIntervalArea } from "../intervals/interval.types";
import Period from "./Period";
import { usePeriodsHook } from "./Periods.hooks";

export interface PeriodsProps {
    data: DesignSurveyViewModel | undefined;
    surveyId: string;
    periods: Array<DesignFeesIntervals>;
    onPeriodChange: (period: DesignFeesIntervals) => void;
    intervalArea: DesignSurveyIntervalArea;
    errorIdsString?: string;
    isPdf?: boolean;
    disabled: boolean;
}

function Periods(props: PeriodsProps) {
    const { firstPeriod, onPeriodCopy, isCopying } = usePeriodsHook(props);

    return (
        <>
            {props.periods.map((period) => {
                return (
                    <Period
                        data={props.data}
                        key={period.id}
                        period={period}
                        onPeriodChange={props.onPeriodChange}
                        intervalArea={props.intervalArea}
                        surveyId={props.surveyId!}
                        errorText={props.errorIdsString?.includes(period.id!) ? fillingFeeError : ""}
                        canCopyPeriod={firstPeriod?.id === period.id && props.periods.length > 1}
                        onCopyPeriod={onPeriodCopy}
                        isCopying={isCopying}
                        isPdf={props.isPdf}
                        disabled={props.disabled}
                    />
                );
            })}
        </>
    );
}

export default Periods;
