import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useNavMenuStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {},
        isMinimized: {
            width: "33px",
        },
    })
);

export default useNavMenuStyles;
