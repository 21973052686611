import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useWorldMapStyles = makeStyles((theme: Theme) =>
    createStyles({
        "@global": {
            ".map-popup": {
                marginLeft: 15,
                fontFamily: "Open Sans",
            },
            ".map-popup .mapboxgl-popup-tip": {
                borderWidth: 7.5,
            },
            ".map-popup .mapboxgl-popup-content": {
                width: 160,
                margin: 0,
                padding: 10,
                borderRadius: 10,
                display: "flex",
                flexDirection: "column",
                gap: 8,
                boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
            },
            "a.mapboxgl-ctrl-logo": {
                backgroundColor: "white",
            },
            ".mapboxgl-canvas": {
                width: "100% !important",
            },
        },
        worldMapWrapper: {
            height: "100%",
            position: "relative",
        },
        mapContainer: {
            minHeight: 250,
            height: "60vh",
            maxHeight: 800,
        },
        caseStatusFilterContainer: {
            display: "flex",
            gap: theme.spacing(1.5),
            position: "absolute",
            bottom: 0,
            marginLeft: theme.spacing(1.5),
            marginBottom: theme.spacing(1.5),
        },
        worldMapContainer: {
            display: "flex",
            flexDirection: "column",
            position: "relative",
            gap: 8,
            minHeight: 52,
        },
        collapseToggleContainer: {
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            width: "100%",
            zIndex: 1000,
            paddingRight: 16,
            top: 16,
        },
    })
);

export default useWorldMapStyles;
