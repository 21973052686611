import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import useBarChartStyles from "./BarChartSkeleton.styles";

function BarChartSkeleton() {
    const classes = useBarChartStyles();

    return (
        <>
            <Box className={classes.widgetContentContainer}>
                <Skeleton style={{ bottom: 80, height: "50%" }} className={classes.widgetSkeletonY} animation="pulse" />
                <Skeleton style={{ left: 44, bottom: 15 }} className={classes.widgetSkeletonY} animation="pulse" />
                <div className={`${classes.widgetSkeletonContainer}`}>
                    <Skeleton className={classes.widgetSkeletonBar} animation="pulse" />
                    <Skeleton className={classes.widgetSkeletonBar} animation="pulse" />
                    <Skeleton className={classes.widgetSkeletonBar} animation="pulse" />
                    <Skeleton className={classes.widgetSkeletonBar} animation="pulse" />
                    <Skeleton className={classes.widgetSkeletonBar} animation="pulse" />
                    <Skeleton className={classes.widgetSkeletonX} animation="pulse" />
                    <Skeleton style={{ bottom: 48 + 40 }} className={classes.widgetSkeletonX} animation="pulse" />
                    <Skeleton style={{ bottom: 48 + 40 * 2 }} className={classes.widgetSkeletonX} animation="pulse" />
                    <Skeleton style={{ bottom: 48 + 40 * 3 }} className={classes.widgetSkeletonX} animation="pulse" />
                    <Skeleton style={{ bottom: 48 + 40 * 4 }} className={classes.widgetSkeletonX} animation="pulse" />
                    <Skeleton style={{ bottom: 48 + 40 * 5 }} className={classes.widgetSkeletonX} animation="pulse" />
                </div>
            </Box>
        </>
    );
}

export default BarChartSkeleton;
