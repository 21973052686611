import { CaseType } from "../cases/Case.types";
import { convertStringArrayToQueryParam, mapCaseTypeToEnumValue } from "../cases/CaseService";
import { findMax, getDigitCount } from "../dashboard/DashboardWidgetService";
import {
    CostDisputeFilter,
    CostDisputeFilterOptions,
    CostFilter,
    CostPeriod,
    CostsLoading,
    CostTableInfo,
    MaterialUICostsPeriod,
    MatterCosts,
    YearlyCosts,
} from "./Cost.types";

const costsEndpointUri = "/api/costs";

const convertCostsFilterToQueryParams = (
    caseType: CaseType,
    filter: CostPeriod &
        CostDisputeFilter & {
            pageNumber: number;
            pageSize: number | null;
            orderBy: string;
            orderByDescending: boolean;
            searchText: string;
        }
) =>
    `caseType=${mapCaseTypeToEnumValue(caseType)}` +
    `&disputes=${convertStringArrayToQueryParam(filter.disputes)}` +
    `&from=${filter.from || ""}` +
    `&to=${filter.to || ""}` +
    `&pageSize=${filter.pageSize || 15}` +
    `&pageNumber=${filter.pageNumber || 1}` +
    `&orderBy=${filter.orderBy}` +
    `&orderByDescending=${filter.orderByDescending}` +
    `&searchText=${filter.searchText}` +
    `&types=${convertStringArrayToQueryParam(filter.types)}`;

const convertCostDisputeFilterToQueryParams = (caseType: CaseType, filter: CostPeriod & CostDisputeFilter & { searchText: string }) =>
    `caseType=${mapCaseTypeToEnumValue(caseType)}` +
    `&disputes=${convertStringArrayToQueryParam(filter.disputes)}` +
    `&from=${filter.from || ""}` +
    `&to=${filter.to || ""}` +
    `&searchText=${filter.searchText}` +
    `&types=${convertStringArrayToQueryParam(filter.types)}`;

export const getMatterCostsByFilter = async (
    caseType: CaseType,
    filter: CostFilter,
    isDispute: boolean
): Promise<{ matterCosts: MatterCosts[]; totalCount: number }> =>
    fetch(`${costsEndpointUri}/matter-costs?${convertCostsFilterToQueryParams(caseType, filter)}&isDispute=${isDispute}`).then((matterCostsResponse) =>
        matterCostsResponse.json()
    );

export const getCostsByPeriod = async (
    caseType: CaseType,
    isDispute: boolean,
    filter: CostPeriod & CostDisputeFilter & { searchText: string }
): Promise<number> =>
    fetch(`${costsEndpointUri}/total-costs?${convertCostDisputeFilterToQueryParams(caseType, filter)}&isDispute=${isDispute}`).then((totalCostsResponse) =>
        totalCostsResponse.json()
    );

export const getYearlyCosts = async (caseType: CaseType, isDispute: boolean, searchText: string): Promise<YearlyCosts[]> =>
    fetch(`${costsEndpointUri}/yearly-costs?caseType=${mapCaseTypeToEnumValue(caseType)}&searchText=${searchText}&isDispute=${isDispute}`).then(
        (yearlyCostsResponse) => yearlyCostsResponse.json()
    );

export const exportCostsExcel = async (caseType: CaseType, filter: CostFilter, isDispute: boolean): Promise<Blob> =>
    fetch(`${costsEndpointUri}/export?${convertCostsFilterToQueryParams(caseType, filter)}&isDispute=${isDispute}`).then((costsCsvExport) =>
        costsCsvExport.blob()
    );

export const getYearlyCostsKeys = (yearlyCosts: YearlyCosts[]) => (yearlyCosts.length > 0 ? Object.keys(yearlyCosts[0]) : []);

export const getYearlyCostYDigits = (yearlyCosts: YearlyCosts[]) => (yearlyCosts.length > 0 ? getDigitCount(findMax(yearlyCosts.map((c) => c.total))) : 1);

export const costsIndexBy: keyof YearlyCosts = "year";

export const getYearlyCostChartKeys = (yearlyCosts: YearlyCosts[]) => {
    const keys = getYearlyCostsKeys(yearlyCosts);
    return keys.length > 0 ? (keys.filter((k) => (k as keyof YearlyCosts) !== costsIndexBy) as (keyof YearlyCosts)[]) : [];
};

export const getCostFilterOptions = (caseType: CaseType, filter: CostPeriod & CostDisputeFilter & { searchText: string }): Promise<CostDisputeFilterOptions> =>
    fetch(`${costsEndpointUri}/filter-options?${convertCostDisputeFilterToQueryParams(caseType, filter)}&isDispute=true`).then((caseFilterOptionsResponse) =>
        caseFilterOptionsResponse.json()
    );

export const initialCostPeriod: MaterialUICostsPeriod = {
    from: new Date(new Date().getFullYear(), 0, 1),
    to: null,
};

export const initialCostFilterOptions: CostDisputeFilterOptions = {
    disputes: [],
    types: [],
};

export const initialCostsIsLoading: CostsLoading = {
    export: false,
    matterCosts: false,
};

export const initialCostTableState: CostTableInfo = {
    costs: [],
    count: 0,
    pageNr: 1,
};

export const initialCostFilterState: CostFilter = {
    disputes: [],
    from: `01-01-${new Date().getFullYear()}`,
    to: initialCostPeriod.to ? initialCostPeriod.to.toISOString().split("T")[0] : null,
    pageNumber: 1,
    pageSize: 15,
    orderBy: "matterId",
    searchText: "",
    orderByDescending: false,
    types: [],
};
