export interface PriceSearchFilter {
    typeOfTransaction: SurveyType;
    country: string | undefined;
    numberOfClasses: number | undefined;
    addAdditionalCosts: boolean;
}

export interface DesignPriceSearchFilter {
    typeOfTransaction: SurveyType;
    country: string | undefined;
    numberOfDesigns: number | null;
    addAdditionalCosts: boolean;
    periodNumber: number | null;
    feeType: DesignFilingFeeType | undefined;
    addDefermentFees: boolean;
}

export enum SurveyType {
    NewApplication = 1,
    Renewal = 2,
}

export interface TrademarkPriceCalculationViewModel extends PriceCalculationViewModelBase {
    grandTotal: GrandTotalViewModelBase;
}

export interface DesignPriceCalculationViewModel extends PriceCalculationViewModelBase {
    grandTotal: DesignGrandTotalViewModel;
    endOfDuration: EndOfDurationViewModel | null;
}

export interface DesignGrandTotalViewModel extends GrandTotalViewModelBase {
    designDefermentFee: number;
}

export interface DesignPriceCalculationOptions {
    noData: boolean;
    availableFeeTypes: Array<DesignFilingFeeType>;
    numberOfPeriods: number;
    renewalIsPossible: boolean;
    defermentIsPossible: boolean;
    lateRenewalIsPossible: boolean;
}

export interface PriceCalculationViewModelBase {
    noData: boolean;
    publicationAndRegistration: PublicationAndRegistrationViewModel | null;
    remarks: string | null;
    publicationRegistrationRemarks: string | null;
    necessaryDocuments: NecessaryDocumentsModel | null;
}

export interface NecessaryDocumentsModel {
    poaRequired: boolean | null;
    poaRequirement: DocumentRequirement | null;
    poaRequiresDocumentType: DocumentType | null;
    isLateFilingOfThePOAAvailable: boolean | null;
    certifiedCopyOfThePriorityDocumentRequired: boolean | null;
    priorityDocumentRequiredForEachItem: boolean | null;
    priorityDocumentRequirement: DocumentRequirement | null;
    priorityDocumentRequiresDocumentType: DocumentType | null;
    lateFilingOfPOAIncurAdditionalCosts: boolean | null;
    lateFilingOfPOAAdditionalCosts: number | null;
    pointInTimeForSubmissionOfPOA: string | null;
    lateFilingOfPriorityDocumentIncurAdditionalCosts: boolean | null;
    lateFilingOfPriorityDocumentAdditionalCosts: number | null;
    pointInTimeForSubmissionOfPriorityDocument: string | null;
}

interface GrandTotalViewModelBase {
    total: number;
    officialFees: number;
    attorneyFees: number;
    additionalOfficialFees: number;
    additionalAttorneyFees: number;
}

interface PublicationAndRegistrationViewModel {
    total: number;
    officialFees: number;
    attorneyFees: number;
}

export enum DocumentRequirement {
    LegalizationAndNotarization,
    Notarization,
    Neither,
}

export enum DocumentType {
    OriginalCopy,
    ScannedCopy,
}

export interface EndOfDurationViewModel {
    amount: number;
    type: EndOfDurationType;
}

export enum EndOfDurationType {
    Month = 3,
    Year = 4,
}

export enum DesignFilingFeeType {
    Single = 1,
    Multiple = 2,
}
