import { useDotLegalBreadCrumbs } from "@dotlegal/dotlegal-ui-components";
import { useEffect, useState } from "react";
import CasePage from "../../core/cases/CasePage";
import { getDisputes } from "../../core/cases/CaseService";
import { CaseSummarization, CaseSummarizationPage } from "../../core/cases/CaseSummarizationPage";
import { CaseSummarizationProps } from "../../Main";

function Disputes(
    props: CaseSummarizationProps & {
        onDisputesEntered: (active: boolean) => void;
    }
) {
    useDotLegalBreadCrumbs([{ name: "Disputes" }]);
    const [caseSummarization] = useState<CaseSummarization>(CaseSummarization.Disputes);

    useEffect(() => {
        props.onDisputesEntered(true);
        return () => props.onDisputesEntered(false);
        // eslint-disable-next-line
    }, []);

    return (
        <CaseSummarizationPage
            onCaseFilterKeyChange={props.onCaseFilterKeyChanged}
            onCaseTypeFilterConfigurationClear={props.onCaseTypeFilterConfigurationCleared}
            caseSummarization={caseSummarization}
        >
            <CasePage
                caseType={props.selectedCaseType}
                caseSummarization={caseSummarization}
                onCaseFilterChanged={(caseType, filter) =>
                    getDisputes(caseType, filter).then((res) => ({
                        cases: res.disputes,
                        totalCount: res.totalCount,
                    }))
                }
            />
        </CaseSummarizationPage>
    );
}

export default Disputes;
