import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useAppStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        "@global": {
            "*:focus": {
                outline: "none",
            },
            '.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                paddingRight: "45px !important",
            },
            ".MuiAutocomplete-tagSizeSmall": {
                maxWidth: "calc(100% - 42px) !important",
            },
            ".MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                flexGrow: "0 !important",
                minWidth: "unset !important",
            },
            ".Mui-focused .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                flexGrow: "1 !important",
                width: "50px !important",
                minWidth: "50px !important",
            },
            ".MuiAutocomplete-option": {
                fontSize: theme.typography.pxToRem(14),
            },
            ".MuiPagination-root": {
                marginTop: theme.spacing(4),
                display: "flex",
                justifyContent: "center",
            },
            ".MuiPaginationItem-icon": {
                color: theme.palette.secondary.main,
                height: "2em",
                width: "2em",
            },
            ".MuiAutocomplete-paper": {
                fontSize: `${theme.typography.pxToRem(14)} !important`,
            },
            "#singleSelectDropdown input:focus::placeholder": {
                color: "transparent",
            },
            "#singleSelectDropdown .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                flexGrow: "0 !important",
                minWidth: "unset !important",
                width: "100% !important",
            },
            "#singleSelectDropdown ::placeholder": {
                color: `${theme.customPalette.darkBlue}`,
                opacity: 1,
                fontSize: theme.typography.pxToRem(14),
                fontWeight: "bold",
            },
            "#singleSelectDropdown .MuiInputBase-root": {
                // fontWeight: 'bold',
            },
            "#singleCatchwordDropdown input:focus::placeholder": {
                color: "transparent",
            },
            "#singleCatchwordDropdown .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                flexGrow: "0 !important",
                minWidth: "unset !important",
                width: "100% !important",
            },
        },
    })
);

export default useAppStyles;
