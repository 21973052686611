import React, { useState } from "react";
import DotLegalTable from "../../common/table/DotLegalTable";
import { TableHeader } from "../../common/table/DotLegalTable.types";
import { generateNoDataDisplay } from "../dashboard/DashboardWidgetService";
import { Case, CaseType } from "./Case.types";
import { CaseSummarization } from "./CaseSummarizationPage";
import CaseTableRowWrapper from "./CaseTableRowWrapper";

export type CaseTableProps = {
    headers: TableHeader<Case>[];
    caseSummary?: CaseSummarization;
    onCaseSelected: (caseSelection: { caseId: number; country: string }) => void;
    onRenewalRemarkSelected: (internalId: string) => void;
    onTableArrangementChanged: (pageNumber: number, orderByDescending: boolean, orderBy?: keyof Case) => void;
    pageNumber: number;
    rows: Case[];
    selectedCaseType: CaseType;
    totalCases: number;
    showRenewalPrice?: boolean;
    editRenewalPrice?: boolean;
    editSetRenewalStatus: boolean;
    renewalRegistrationActive: boolean;
    onRenewalPriceChange?: (selectedCase: { internalId: string; price: string | null; remark: string; currencyCode: string }) => void;
    onCaseUpdated?: (updatedCase: Case) => void;
};

function CaseTable(props: CaseTableProps) {
    const [orderBy] = useState<keyof Case>(
        props.caseSummary === CaseSummarization.Renewals || props.caseSummary === CaseSummarization.Dashboard ? "renewalDate" : "name"
    );
    const [headersIsSortable] = useState<true | undefined>(props.caseSummary === CaseSummarization.Dashboard ? undefined : true);

    return props.rows.length === 0 ? (
        generateNoDataDisplay()
    ) : (
        <React.Fragment>
            <DotLegalTable
                defaultOrderBy={orderBy}
                headers={props.headers}
                headersAreSortable={headersIsSortable}
                totalRowCount={props.totalCases}
                onTableArrangementChanged={props.onTableArrangementChanged}
                rows={props.rows}
                pageNr={props.pageNumber}
                renderRow={(r) => (
                    <CaseTableRowWrapper
                        key={`${r.id}${r.country}`}
                        case={r}
                        onCaseSelected={props.onCaseSelected}
                        onRenewalRemarkSelected={props.onRenewalRemarkSelected}
                        caseType={props.selectedCaseType}
                        caseSummarization={props.caseSummary}
                        showRenewalPrice={props.showRenewalPrice}
                        editRenewalPrice={props.editRenewalPrice}
                        editSetRenewalStatus={props.editSetRenewalStatus}
                        renewalRegistrationActive={props.renewalRegistrationActive}
                        onRenewalPriceChange={props.onRenewalPriceChange}
                        onCaseUpdated={props.onCaseUpdated}
                    />
                )}
            />
        </React.Fragment>
    );
}

export default CaseTable;
