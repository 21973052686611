import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useState } from "react";
import { caseTableArrangementForCsv, exportCustomCasesExcel } from "../cases/CaseService";
import { CustomExcelExportProps } from "./CustomExcelExport";

export function useCustomExcelExport(props: CustomExcelExportProps) {
    const [checkedStatuses, setCheckedStatuses] = useState<{
        registered: boolean;
        pending: boolean;
        closed: boolean;
        won: boolean;
        partly: boolean;
        lost: boolean;
    }>({
        registered: true,
        pending: true,
        closed: true,
        lost: true,
        partly: true,
        won: true,
    });

    const [isLoading, setIsLoading] = useState(false);
    const snackbar = useDotLegalSnackbar();

    //for disputes in the format of "2,0||2,1" = "closed,won||closed,partly" - caseStatus,caseDisputeDecision
    //non disputes in the format of "1||2" = "pending||closed" - pure caseStatus
    function convertCheckedStatusesToArray() {
        let res: Array<string> = [];
        if (props.caseFilter.isDispute) {
            if (checkedStatuses.won) res.push("2,0");
            if (checkedStatuses.partly) {
                res.push("2,1");
                res.push("2,3"); //3 is a settled disputeDecision, shown under partly
            }
            if (checkedStatuses.lost) res.push("2,2");
            if (checkedStatuses.pending) res.push("1,0");
        } else {
            if (checkedStatuses.registered) res.push("0");
            if (checkedStatuses.pending) res.push("1");
            if (checkedStatuses.closed) res.push("2");
        }
        return res;
    }

    function exportExcel() {
        setIsLoading(true);
        const filter = { ...props.caseFilter, ...caseTableArrangementForCsv };
        exportCustomCasesExcel(props.caseType, filter, convertCheckedStatusesToArray())
            .then(() => {
                setIsLoading(false);
                props.onClose();
            })
            .catch(() => {
                setIsLoading(false);
                snackbar.show("Could not export cases", "error");
                props.onClose();
            });
    }

    return { checkedStatuses, setCheckedStatuses, exportExcel, isLoading };
}
