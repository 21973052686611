import { useDotLegalBreadCrumbs } from "@dotlegal/dotlegal-ui-components";
import { Button, CircularProgress, CSSObject, Switch } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { HttpRequestError } from "../../auth/HttpInterceptor";
import ContentCard from "../../common/contentCard/ContentCard";
import DotLegalSearch from "../../common/search/DotLegalSearch";
import { CaseType } from "../../core/cases/Case.types";
import { CaseSummarization, CaseSummarizationPage } from "../../core/cases/CaseSummarizationPage";
import { CostDisputeFilter, CostFilter, CostPeriod } from "../../core/costs/Cost.types";
import CostChart from "../../core/costs/CostChart";
import useCostsStyles from "../../core/costs/Costs.styles";
import { exportCostsExcel, getMatterCostsByFilter, initialCostFilterState, initialCostsIsLoading, initialCostTableState } from "../../core/costs/CostService";
import CostTable from "../../core/costs/CostTable";
import TotalCosts from "../../core/costs/TotalCosts";
import { generateServiceUnavailableDisplay } from "../../core/dashboard/DashboardWidgetService";
import microsoftExcelIcon from "../../core/icons/microsoft-excel.svg";
import { CaseSummarizationProps } from "../../Main";

function Costs(
    props: CaseSummarizationProps & {
        orgHasDisputes: boolean;
    }
) {
    useDotLegalBreadCrumbs([{ name: "Costs" }]);
    const classes = useCostsStyles();
    const [costFilter, setCostFilter] = useState(initialCostFilterState);
    const [costsIsLoading, setCostsIsLoading] = useState(initialCostsIsLoading);
    const [costServiceIsAvailable, setCostServiceIsAvailable] = useState(true);
    const [costTable, setCostTable] = useState(initialCostTableState);
    const [costHasBeenLoaded, setCostsHasBeenLoaded] = useState(false);
    const [caseSummarization] = useState<CaseSummarization>(CaseSummarization.Costs);
    const [costSelection, setCostSelection] = useState<{
        selectedCaseType: CaseType;
        disputeIsToggled: boolean;
    }>({
        selectedCaseType: props.selectedCaseType,
        disputeIsToggled: false,
    });

    const getMatterCosts = (newFilter: CostFilter, disputeToggled: boolean, setLoadingStatus = true) => {
        if (setLoadingStatus) setCostsIsLoading({ ...costsIsLoading, matterCosts: true });
        getMatterCostsByFilter(props.selectedCaseType, newFilter, disputeToggled)
            .then((res) => {
                setCostTable({
                    ...costTable,
                    costs: res.matterCosts,
                    count: res.totalCount,
                    pageNr: newFilter.pageNumber,
                });
                setCostsIsLoading({ ...costsIsLoading, matterCosts: false });
            })
            .catch((e: HttpRequestError) => {
                if (e.statusCode >= 500) setCostServiceIsAvailable(false);
                setCostsIsLoading({ ...costsIsLoading, matterCosts: false });
            });
    };

    const onCostFilterChanged = (newFilter: CostPeriod & CostDisputeFilter) => {
        setCostFilter({
            ...costFilter,
            disputes: newFilter.disputes,
            from: newFilter.from,
            to: newFilter.to,
            // searchText: '',
            types: newFilter.types,
        });
    };

    const onTableArrangementChanged = (pageNumber: number, orderByDescending: boolean, orderBy: any) => {
        const newFilter = { ...costFilter };
        newFilter.orderBy = orderBy;
        newFilter.orderByDescending = orderByDescending;
        newFilter.pageNumber = pageNumber;
        setCostFilter(newFilter);

        getMatterCosts(newFilter, costSelection.disputeIsToggled, false);
    };

    const onSearchTextChanged = (newSearchText: string, disputeToggled?: boolean) => {
        const newCostFilter: CostFilter = { ...costFilter };
        newCostFilter.searchText = newSearchText;
        setCostFilter(newCostFilter);
        getMatterCosts(newCostFilter, disputeToggled ?? costSelection.disputeIsToggled);
    };

    // TODO: Make "export" generic method
    const downloadExcel = () => {
        setCostsIsLoading({ ...costsIsLoading, export: true });
        const newFilter = { ...costFilter };
        newFilter.pageNumber = 0;
        newFilter.pageSize = null;
        exportCostsExcel(props.selectedCaseType, newFilter, costSelection.disputeIsToggled)
            .then(async (response) => {
                const blob = await response;
                const docType = ".xlsx";
                const oBlob = new Blob([blob], { type: docType });
                const url = window.URL.createObjectURL(oBlob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "Export.xlsx";
                a.click();
                setCostsIsLoading({ ...costsIsLoading, export: false });
            })
            .catch(() => setCostsIsLoading({ ...costsIsLoading, export: false }));
    };

    const onDisputeToggleChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setCostSelection({
            ...costSelection,
            disputeIsToggled: event.target.checked,
        });
        onSearchTextChanged("", event.target.checked);
    };

    useEffect(() => {
        const newFilter: CostFilter = { ...costFilter };
        newFilter.searchText = "";
        newFilter.pageNumber = 1;
        let disputeToggled = costSelection.disputeIsToggled;
        if (disputeToggled) disputeToggled = props.orgHasDisputes;
        setCostSelection({
            ...costSelection,
            selectedCaseType: props.selectedCaseType,
            disputeIsToggled: disputeToggled,
        });
        setCostFilter(newFilter);

        getMatterCosts(newFilter, disputeToggled, !costHasBeenLoaded);
        setCostsHasBeenLoaded(true);
        // eslint-disable-next-line
    }, [props.selectedCaseType]);

    useEffect(() => {
        if (!costHasBeenLoaded) return;
        const newFilter: CostFilter = { ...costFilter };
        newFilter.pageNumber = 1;
        setCostFilter(newFilter);
        getMatterCosts(newFilter, costSelection.disputeIsToggled);
        // eslint-disable-next-line
    }, [costFilter.from, costFilter.to, costFilter.disputes, costFilter.types]);

    // TODO: Make "export to csv" button
    return (
        <CaseSummarizationPage
            onCaseFilterKeyChange={props.onCaseFilterKeyChanged}
            onCaseTypeFilterConfigurationClear={props.onCaseTypeFilterConfigurationCleared}
            caseSummarization={caseSummarization}
        >
            <ContentCard>
                <div className={classes.searchTextContainer}>
                    {props.orgHasDisputes ? (
                        <div className={classes.switchContainer}>
                            {costSelection.disputeIsToggled ? <div>{props.selectedCaseType}</div> : <b>{props.selectedCaseType}</b>}
                            <Switch color="primary" checked={costSelection.disputeIsToggled} onChange={onDisputeToggleChanged} />
                            {costSelection.disputeIsToggled ? (
                                <b>{props.selectedCaseType.toString()} disputes</b>
                            ) : (
                                <div>{props.selectedCaseType.toString()} disputes</div>
                            )}
                        </div>
                    ) : (
                        <div />
                    )}

                    {props.userCostAccess === "Detailed overview" && <DotLegalSearch value={costFilter.searchText} onSearchTextChanged={onSearchTextChanged} />}
                </div>
                <CostChart costSelection={costSelection} searchText={costFilter.searchText} />
            </ContentCard>
            <ContentCard>
                <TotalCosts costFilter={costFilter} costSelection={costSelection} onFilterChanged={onCostFilterChanged} searchText={costFilter.searchText} />
            </ContentCard>
            <ContentCard>
                <>
                    {!costServiceIsAvailable ? (
                        generateServiceUnavailableDisplay()
                    ) : (
                        <CostTable
                            isLoading={costsIsLoading.matterCosts}
                            onTableArrangementChanged={onTableArrangementChanged}
                            rows={costTable.costs}
                            pageNr={costTable.pageNr}
                            totalRowCount={costTable.count}
                            showCostAmount={props.userCostAccess === "Detailed overview"}
                        />
                    )}
                    {costTable.costs.length > 0 && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 10,
                                alignItems: "flex-end",
                            }}
                        >
                            <Button
                                color="primary"
                                onClick={() => downloadExcel()}
                                sx={(theme) => ({ minWidth: 130, color: theme.customPalette.white } as CSSObject)}
                                variant="outlined"
                            >
                                <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                                    <img src={microsoftExcelIcon} alt="" />
                                    <span>Export</span>
                                    {costsIsLoading.export && <CircularProgress size={28} color="secondary" />}
                                </div>
                            </Button>
                        </div>
                    )}
                </>
            </ContentCard>
        </CaseSummarizationPage>
    );
}

export default Costs;
