import { AuthProviderProps, UserManager } from "oidc-react";

const idpBasicInfo = {
    authority: process.env.REACT_APP_IDP_AUTHORITY,
    clientId: process.env.REACT_APP_IDP_CLIENT_ID,
    responseType: "code",
    redirectUri: process.env.REACT_APP_IDP_REDIRECT_URI + "/sign-in-callback",
    scope: "openid profile",
};

export const oidcUserManager = new UserManager({
    authority: idpBasicInfo.authority,
    client_id: idpBasicInfo.clientId,
    post_logout_redirect_uri: process.env.REACT_APP_IDP_REDIRECT_URI,
    redirect_uri: idpBasicInfo.redirectUri,
    response_type: idpBasicInfo.responseType,
    revokeAccessTokenOnSignout: true,
    scope: idpBasicInfo.scope,
});

export const oidcConfig: AuthProviderProps = {
    authority: idpBasicInfo.authority,
    automaticSilentRenew: true,
    autoSignIn: true,
    clientId: idpBasicInfo.clientId,
    responseType: idpBasicInfo.responseType,
    redirectUri: idpBasicInfo.redirectUri,
    silentRedirectUri: idpBasicInfo.redirectUri,
    scope: idpBasicInfo.scope,
    onBeforeSignIn: () => {
        sessionStorage.setItem("location-before-sign-in", window.location.pathname + window.location.search);
    },
    userManager: oidcUserManager,
};
