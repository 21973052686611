import { Result } from "./result";

export async function post<T>(url: string, body: any): Promise<Result<T>> {
    const response = await doFetch(url, "POST", body);
    return await handleResponse(response);
}

export async function get<T>(url: string): Promise<T> {
    const response = await doFetch(url);
    return await response.json();
}

export async function getWithResult<T>(url: string): Promise<Result<T>> {
    const response = await doFetch(url);
    return await handleResponse(response);
}

export async function getRaw(url: string, method?: string, body?: any): Promise<Response> {
    return await doFetch(url, method, body);
}

export async function put<T>(url: string, body: any): Promise<Result<T>> {
    const response = await doFetch(url, "PUT", body);
    return await handleResponse(response);
}

export async function deleteHttp<T>(url: string): Promise<Result<T>> {
    const response = await doFetch(url, "DELETE");
    return await handleResponse(response);
}

async function doFetch(url: string, method?: string, body?: any, requestJwtOnUnauthorized: boolean = true): Promise<Response> {
    const headers: HeadersInit = new Headers();

    if (method === "POST" || method === "PUT") {
        headers.set("Content-Type", "application/json");
    }

    const response = await fetch(url, { method: method, body: JSON.stringify(body), headers: headers });

    if (response.status === 401) {
        window.location.href = "/unauthorized";
    }

    if (response.status === 403) {
        window.location.href = "/forbidden";
    }

    if (response.status === 404) {
        window.location.href = "/resource-not-found";
    }

    if (!isOkOrClientErrorStatus(response.status)) {
        throw new Error(`Error calling API. Status code: ${response.status}. URL: ${response.url}`);
    }

    sessionStorage.removeItem("challengeIdp");
    return response;
}

function isOkOrClientErrorStatus(status: number) {
    return (status >= 200 && status < 300) || (status >= 400 && status < 500);
}

async function handleResponse<T>(response: Response): Promise<Result<T>> {
    if (response.status === 400) {
        return new Promise<Result<T>>(async (resolve) => resolve(new Result<T>(undefined, await response.json())));
    }
    const responseBody = await response.text();
    if (responseBody) {
        return new Promise<Result<T>>(async (resolve) => {
            return resolve(new Result<T>(await JSON.parse(responseBody)));
        });
    }
    return new Promise<any>((resolve, reject) => resolve(""));
}
