import { useMediaQuery, useTheme } from "@mui/material";
import { getBreakpointForMobile } from "../../ipPortalTheme";

export function useIsOnSmallScreen() {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down(getBreakpointForMobile()), {
        noSsr: true,
    });
}

export function useIsOnMediumScreen() {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("lg"), { noSsr: true });
}
