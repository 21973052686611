import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const drawerWidth = 250;
export const useDrawerMenuStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            borderRight: 0,
            width: `${drawerWidth}px`,
            flexShrink: 0,
            whiteSpace: "nowrap",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxShadow: "-2px 3px 5px #00000029",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(3),
            zIndex: 100,
        },
        drawerMinimized: {
            flexShrink: 0,
            width: `${theme.spacingAsNumber(10) + theme.customSpacing.iconSize}px`,
            overflowY: "unset",
        },
    })
);
