import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { ApplicationUser } from "./User.types";
import { resendInvitationMail } from "./UserService";

export interface ResendInvitationProps {
    invitee: ApplicationUser;
    onClose: () => React.MouseEventHandler<HTMLAnchorElement> | undefined | void;
}

function ResendInvitation(props: ResendInvitationProps) {
    const [waitingForServer, setWaitingForServer] = useState(false);
    const snackbar = useDotLegalSnackbar();
    const onResendInvitationMailClicked = () => {
        setWaitingForServer(true);
        resendInvitationMail(props.invitee)
            .then(() => {
                setWaitingForServer(false);
                props.onClose();
            })
            .catch(() => {
                snackbar.show("The invitation cannot be resent because the user is already activated.", "error");
                setWaitingForServer(false);
                props.onClose();
            });
    };
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 40,
                alignItems: "center",
            }}
        >
            <div>Do you want to resend an invitation to {props.invitee.name}?</div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 30,
                }}
            >
                <Button color="primary" variant="outlined" onClick={props.onClose}>
                    No
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => onResendInvitationMailClicked()}>
                    Yes
                </Button>
            </div>
            {waitingForServer && <CircularProgress />}
        </div>
    );
}

export default ResendInvitation;
