import { Feature, FeatureCollection, GeoJsonProperties, Geometry, Point, Position } from "geojson";
import { AnyLayer, AnySourceData, GeoJSONSourceOptions, LngLatLike, Map, MapboxGeoJSONFeature, MapboxOptions, Popup } from "mapbox-gl";
import { getISO3166Countries } from "./CountryService";
import { CaseStatus } from "./WorldMap.types";

export const getCaseStatusColor = (caseStatus: CaseStatus) => {
    switch (caseStatus) {
        case "registered":
            return "#9FEDE2";
        case "pending":
            return "#8BBEE8";
        case "closed":
            return "#707070";
        case "won":
            return "#9FEDE2";
        case "partly":
            return "#707070";
        case "lost":
            return "#FA9CAE";
        default:
            return "#9FEDE2";
    }
};

export const getCaseStatusBackgroundColor = (caseStatus: CaseStatus) => {
    switch (caseStatus) {
        case "registered":
            return "#E7F9F7";
        case "pending":
            return "#ECF3F9";
        case "closed":
            return "#EBEBEB";
        case "won":
            return "#E7F9F7";
        case "partly":
            return "#EBEBEB";
        case "lost":
            return "#FDEAEE";
        default:
            return "#E7F9F7";
    }
};

const mapboxStylesheetURI: string = process.env.REACT_APP_MAPBOX_STYLESHEET!;
const defaultMapCenter: LngLatLike = [5, 25];
const defaultZoom: number = 1.6;
const defaultMaxZoom: number = 3.75;
const defaultMinZoom: number = 1.5;

const caseClustersSourceId = "caseClusters";
const countryBoundariesSourceId = "countryBoundaries";
const circleLayerId = "caseClusterCircles";
const circleTextLayerId = "caseClusterCount";
const fillLayerId = "HasCases";
export const totalCasesPropertyKey = "totalCases";
export const countryISO3166FormattedPropertyKey = "countryISO3166Formatted";
export const countryPropertyKey = "country";
const clusterIdPropertyKey = "cluster_id";

const createClusterLayersConfig = (
    caseStatus: CaseStatus
): {
    defaultCircleLayer: AnyLayer;
    defaultCircleTextLayer: AnyLayer;
} => ({
    defaultCircleLayer: {
        id: circleLayerId,
        type: "circle",
        source: caseClustersSourceId,
        filter: ["has", totalCasesPropertyKey],
        paint: {
            "circle-color": getCaseStatusBackgroundColor(caseStatus),
            "circle-radius": 12,
            "circle-stroke-color": "#FFFFFF",
            "circle-stroke-width": 3,
        },
    },

    defaultCircleTextLayer: {
        id: circleTextLayerId,
        type: "symbol",
        source: caseClustersSourceId,
        filter: ["has", totalCasesPropertyKey],
        layout: {
            "text-field": `{${totalCasesPropertyKey}}`,
            "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
            "text-size": 8,
            "text-letter-spacing": 0.075,
        },
    },
});

const addLayerToMap = <T extends AnyLayer>(map: Map, layerConfig: T): Map => map.addLayer(layerConfig);

const addSourceToMap = (map: Map, mapSourceConfig: MapSourceConfig): Map => map.addSource(mapSourceConfig.sourceId, mapSourceConfig.source);

const fillCountry = (map: Map, iso3166Country: string, caseStatus: CaseStatus): void => {
    const countryGroupLiteralFilter: any[] = ["literal", getISO3166Countries(iso3166Country)];
    addLayerToMap(map, {
        id: `${iso3166Country}${fillLayerId}`,
        type: "fill",
        source: countryBoundariesSourceId,
        "source-layer": "country_boundaries",
        filter: ["in", ["get", "iso_3166_1"], countryGroupLiteralFilter],
        paint: {
            "fill-color": getCaseStatusColor(caseStatus),
            "fill-outline-color": "#F6F6F6",
        },
    });
};

/**
 * It calls Mapbox addSourceToMap method.
 * This method makes the necessary Mapbox API calls to load the map with correct boundaries for countries, seas, etc.
 * It then loops through each country with any information available and calls Mapbox addLayerToMap with the required parameters and filters to add each country to the map.
 * @param map
 * @param countryCasesConfig
 */
const fillCountriesWithCases = (map: Map, countryCasesConfig: CountryCasesConfig) => {
    addSourceToMap(map, {
        sourceId: countryBoundariesSourceId,
        source: {
            type: "vector",
            url: "mapbox://mapbox.country-boundaries-v1",
        },
    });

    countryCasesConfig.countryCases.forEach((ac) => {
        if (ac[totalCasesPropertyKey] > 0) fillCountry(map, ac[countryISO3166FormattedPropertyKey], countryCasesConfig.CaseStatus);
    });
};

const mapCountryClustersToGeoJSON = async (countryClusters: CountryCases[]): Promise<FeatureCollection<Point, GeoMapGeoJSONProperties>> => {
    const clusterGeoJSON: FeatureCollection<Point, GeoMapGeoJSONProperties> = {
        type: "FeatureCollection",
        features: [],
    };

    countryClusters.forEach((ac) => {
        const caseFeature: Feature<Point, GeoMapGeoJSONProperties> = {
            type: "Feature",
            properties: {
                [totalCasesPropertyKey]: ac[totalCasesPropertyKey],
                [countryISO3166FormattedPropertyKey]: ac[countryISO3166FormattedPropertyKey],
                [countryPropertyKey]: ac[countryPropertyKey],
            },
            geometry: {
                type: "Point",
                coordinates: ac.countryCenter,
            },
        };
        clusterGeoJSON.features.push(caseFeature);
    });

    return clusterGeoJSON;
};

const createClusterSource = async (clusterData: CountryCases[]): Promise<MapSourceConfig> => {
    const geoData: FeatureCollection<Geometry, GeoJsonProperties> = await mapCountryClustersToGeoJSON(clusterData);
    const clusterProperties = {
        [totalCasesPropertyKey]: ["+", ["get", totalCasesPropertyKey]],
        ...createClusterProperties(clusterData),
    };

    return {
        sourceId: caseClustersSourceId,
        source: {
            type: "geojson",
            data: geoData,
            cluster: true,
            generateId: true,
            clusterMaxZoom: 100, // Max zoom to cluster points on
            clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
            clusterProperties,
        },
    };
};

/**
 * It makes a call to Mapbox addSourceToMap, which adds all the clusters as a source to the map.
 * It then calls Mapbox addLayerToMap to add a circle to the map, and again calls Mapbox addLayerToMap to add the text for each circle to the map.
 * This will ultimately display the individual clusters at the required position.
 * It also makes a call to Mapbox map.on mouseenter and Mapbox map.on mouseleave, which registers the required hovering mechanism and rendering for each cluster.
 * @param map
 * @param countryCasesConfig
 */
const showCaseClusters = async (map: Map, countryCasesConfig: CountryCasesConfig): Promise<void> => {
    addSourceToMap(map, await createClusterSource(countryCasesConfig.countryCases));
    addLayerToMap(map, createClusterLayersConfig(countryCasesConfig.CaseStatus).defaultCircleLayer);
    addLayerToMap(map, createClusterLayersConfig(countryCasesConfig.CaseStatus).defaultCircleTextLayer);

    const tooltip = new Popup({
        closeButton: false,
        closeOnClick: false,
        className: "map-popup",
        anchor: "left",
    });

    map.on("mouseenter", circleLayerId, (e) => {
        map.getCanvas().style.setProperty("cursor", "pointer");

        const hoveredFeature = map.queryRenderedFeatures(e.point, {
            layers: [circleLayerId],
        })[0];

        const coordinates: Position = (hoveredFeature.geometry as Point).coordinates.slice();
        const tooltipText: string = createClusterTooltipHTML(hoveredFeature, countryCasesConfig);

        coordinates[0] = getPopupCoordinates(e, coordinates[0]);
        tooltip
            .setLngLat(coordinates as LngLatLike)
            .setHTML(tooltipText)
            .addTo(map);
    });

    map.on("mouseleave", circleLayerId, () => {
        map.getCanvas().style.setProperty("cursor", "");
        tooltip.remove();
    });
};

type CountryCasesConfig = {
    CaseStatus: CaseStatus;
    countryCases: CountryCases[];
};

const startMap = (map: Map, startConfig: CountryCasesConfig): void => {
    map.on("load", async () => {
        fillCountriesWithCases(map, startConfig);
        await showCaseClusters(map, startConfig);
    });
};

const createMapOptions = (geoMapOptions: MapConfig): MapboxOptions => ({
    container: geoMapOptions.container,
    style: mapboxStylesheetURI,
    center: geoMapOptions.center || defaultMapCenter,
    pitchWithRotate: false,
    dragRotate: false,
    zoom: geoMapOptions.zoom || defaultZoom,
    attributionControl: false,
    minZoom: defaultMinZoom,
    maxZoom: defaultMaxZoom,
    logoPosition: "bottom-right",
});

export const createMap = (geoMapOptions: MapConfig): Map => {
    const mapboxOptions = createMapOptions(geoMapOptions);
    const map = new Map(mapboxOptions);

    startMap(map, geoMapOptions.countryCasesConfig);
    return map;
};

export const updateMapData = (map: Map, geoMapOptions: MapConfig) => {
    if (map) destroyMap(map);
    return createMap(geoMapOptions);
    // await GetCountryCenters(countryClusters);
    // fillCountriesWithApplications(map, countryClusters);

    // const currentSource = map.getSource(applicationClustersSourceId) as GeoJSONSource;
    // currentSource.setData(mapCountryClustersToGeoJSON(countryClusters));
};
type ZoomLevelChange = 1 | -1;

export const zoomOnMap = (map: Map, zoomLevelChange: ZoomLevelChange): void => {
    if (!map || !map.zoomIn || !map.zoomOut) return;
    if (zoomLevelChange === 1) map.zoomIn();
    if (zoomLevelChange === -1) map.zoomOut();
};

export const destroyMap = (map: Map) => (): void => map && map.remove ? map.remove() : undefined;

export interface CountryCases {
    [countryPropertyKey]: string;
    [countryISO3166FormattedPropertyKey]: string;
    countryCenter: Position;
    [totalCasesPropertyKey]: number;
}

export interface MapConfig {
    countryCasesConfig: CountryCasesConfig;
    container: HTMLElement;
    center?: LngLatLike;
    zoom?: number;
}

type MapSourceConfig = {
    source: GeoJSONSourceOptions & AnySourceData;
    sourceId: string;
};

type GeoMapGeoJSONProperties = GeoJsonProperties & {
    [totalCasesPropertyKey]: number;
    [countryPropertyKey]: string;
    [countryISO3166FormattedPropertyKey]: string;
};

type GeoMapClusterProperties = GeoMapGeoJSONProperties & {
    [clusterIdPropertyKey]: string;
};

const createClusterTooltipHTML = (clusterFeature: MapboxGeoJSONFeature, countryCasesConfig: CountryCasesConfig) => {
    const feature = clusterFeature as GeoJsonProperties as GeoMapClusterProperties;
    let clusteredCountries: CountryCases[] = [];
    if (feature.properties[clusterIdPropertyKey])
        clusteredCountries = countryCasesConfig.countryCases.filter((cc) => feature.properties[cc[countryISO3166FormattedPropertyKey]] > 0);
    else clusteredCountries = [feature.properties];
    let tooltipText: string = "";
    clusteredCountries.forEach((cf) => {
        tooltipText = tooltipText.concat(`<div style="width: 100%; display: flex; align-items: center; justify-items: auto;">`);
        tooltipText = tooltipText.concat('<div style="width: 100%; display: flex; align-items: center;">');
        tooltipText = tooltipText.concat(
            `<div style='background-color: ${getCaseStatusColor(countryCasesConfig.CaseStatus)}; height: 10px; width: 10px; margin-right: 5px;'></div>`
        );
        tooltipText = tooltipText.concat(`<div style='max-width:100px;'>${cf[countryPropertyKey]}</div>`);
        tooltipText = tooltipText.concat("</div>");
        tooltipText = tooltipText.concat('<div style="font-weight: bold; margin-left: 5px;">');
        tooltipText = tooltipText.concat(cf[totalCasesPropertyKey].toString());
        tooltipText = tooltipText.concat("</div>");
        tooltipText = tooltipText.concat("</div>");
    });
    return tooltipText;
};

const getPopupCoordinates = (e: any, coordinate: number): number => {
    // Ensures that if the map is zoomed out such that multiple copies of the feature are visible,
    // the popup appears over the copy being pointed to.
    const adjustedCoordinate = coordinate;

    if (Math.abs(e.lngLat.lng - coordinate) > 180 && e.lngLat.lng < 0) return adjustedCoordinate + -360;

    if (Math.abs(e.lngLat.lng - coordinate) < 180 && e.lngLat.lng > 0) return adjustedCoordinate + 360;

    if (Math.abs(e.lngLat.lng - coordinate) > 180) return adjustedCoordinate + 360;

    return adjustedCoordinate;
};

const createClusterProperties = (countryClusters: CountryCases[]) => {
    const clusterProperties: { [key: string]: any[] } = {};
    countryClusters.forEach((cc) => {
        clusterProperties[cc[countryISO3166FormattedPropertyKey]] = [
            "+",
            ["case", ["==", ["get", countryISO3166FormattedPropertyKey], cc[countryISO3166FormattedPropertyKey]], ["get", totalCasesPropertyKey], 0],
        ];
    });

    return clusterProperties;
};
