import i18n from "i18next";
import React, { createContext, useCallback } from "react";
import { initReactI18next, useTranslation } from "react-i18next";
import enLang from "./en.json";

interface ITranslationFile {
    language: string;
    translation: any;
}

export const setupi18n = () => {
    const resources: any = {};

    const files: ITranslationFile[] = [{ language: "en", translation: enLang }];

    const defaultLanguage = "en";

    files.forEach((f) => {
        resources[f.language] = { translation: f.translation };
    });

    i18n.use(initReactI18next).init({
        debug: false,
        lng: defaultLanguage,
        resources,
        fallbackLng: "en",
    });
};

export const TranslationContext = createContext<{
    translateString: (key: string, interpolation?: any) => string;
    translateNumber: (num: number) => string;
    translateDate: (date: Date, options?: Intl.DateTimeFormatOptions) => string;
    translateCountry: (countryCode: string) => string;
    CountryList: () => string[];
    translateExcludeReason: (reason: string) => string;
    changeLanguage: (languageCode: string) => void;
    getBrowserLanguage: () => string;
    getChosenLanguage: () => string;
}>({
    translateString: () => "",
    translateNumber: () => "",
    translateDate: () => "",
    translateCountry: () => "",
    CountryList: () => [],
    translateExcludeReason: () => "",
    changeLanguage: () => {},
    getBrowserLanguage: () => "",
    getChosenLanguage: () => "",
});

export const TranslationProvider: React.FunctionComponent<{
    onLanguageChanged?: (languageCode: string) => void;
}> = ({ children, onLanguageChanged }) => {
    const { t } = useTranslation("", { useSuspense: false });

    const getBrowserLanguage = () => navigator.language ?? "en";
    const getChosenLanguage = () => i18n.language;

    const translateNumber = useCallback((num: number) => num.toLocaleString(getBrowserLanguage()), []);

    const translateDate = useCallback((date: Date, options?: Intl.DateTimeFormatOptions) => {
        const localDate = new Date(date);
        return localDate.toLocaleDateString(getChosenLanguage(), options);
    }, []);

    const changeLanguage = (code: string) => {
        i18n.changeLanguage(code);
        onLanguageChanged?.(code);
    };

    const translateCountry = (countryCode: string) => {
        const json: any = t("countries", { returnObjects: true });
        return json[countryCode];
    };

    const translateExcludeReason = (reason: string) => {
        const json: any = t("excludeReasons", { returnObjects: true });
        return json[reason];
    };

    const CountryList = () => {
        const json: Array<string> = t("countries", { returnObjects: true });
        return json;
    };

    return (
        <TranslationContext.Provider
            value={{
                translateString: t,
                translateNumber,
                translateDate,
                translateCountry,
                CountryList,
                translateExcludeReason,
                changeLanguage,
                getBrowserLanguage,
                getChosenLanguage,
            }}
        >
            {children}
        </TranslationContext.Provider>
    );
};
