import { Box } from "@mui/material";
import { useState } from "react";
import DotLegalAutocomplete, { AutocompleteOption } from "../../common/autocomplete/DotLegalAutocomplete";
import { Organization, RenewalPriceAccess } from "./Organization.types";

export interface IConfigureRenewalPrices {
    organization: Organization;
    onOrganizationChanged: (organization: Organization) => void;
    renewalPriceAccessMap: Map<number, string>;
}

export default function ConfigureRenewalPrice(props: IConfigureRenewalPrices) {
    const priceAccessOptions: AutocompleteOption<RenewalPriceAccess>[] = new Array(props.renewalPriceAccessMap.size);
    props.renewalPriceAccessMap.forEach((v, key) => (priceAccessOptions[key] = { label: v, value: key }));

    const [selectedRenewalPrice, setSelectedRenewalPrice] = useState<AutocompleteOption<RenewalPriceAccess>>(
        priceAccessOptions.find((accessLevel) => accessLevel.value === props.organization.showRenewalPrice)!
    );

    const onRenewalPriceChange = (newValue: AutocompleteOption<RenewalPriceAccess>[]) => {
        setSelectedRenewalPrice(newValue[0] ?? { label: "Hidden", value: 0 });
        props.onOrganizationChanged({
            ...props.organization,
            showRenewalPrice: newValue[0]?.value ?? 0,
        });
    };

    return (
        <Box sx={(theme) => ({ display: "flex", width: "100%", flexDirection: "column", gap: theme.spacing(5.5), alignItems: "center" })}>
            <Box id="singleSelectDropdown" sx={{ width: 270 }}>
                <DotLegalAutocomplete
                    onChange={onRenewalPriceChange}
                    label="Renewal price"
                    options={priceAccessOptions}
                    singleSelect
                    // @ts-ignore
                    value={selectedRenewalPrice}
                    width={"100%"}
                />
            </Box>
        </Box>
    );
}
