import { DotLegalFullScreenSpinner } from "@dotlegal/dotlegal-ui-components";
import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import downloadIcon from "../icons/Download.svg";
import { CaseStatus } from "../worldMap/WorldMap.types";
import { CaseType, CaseWithAdditionalInfo, Device } from "./Case.types";
import useCaseModalContentStyles from "./CaseModalContent.styles";
import { downloadDocument, formatDate } from "./CaseService";

export interface CaseModalContentProps {
    case: CaseWithAdditionalInfo | null;
    caseType: CaseType;
    onSelectedDeviceChanged: (deviceId: number, caseId: number) => Promise<Device>;
}

function CaseModalContentRow(props: { label: string; value: string | undefined }) {
    return (
        <Grid item container md spacing={1}>
            <Grid item sm={12}>
                <Typography variant="h5">{props.label}</Typography>
            </Grid>
            <Grid item sm={12}>
                {props.value}
            </Grid>
        </Grid>
    );
}

// TODO: HTML return needs some refactoring and split into more understandable components
function CaseModalContent(props: CaseModalContentProps) {
    const classes = useCaseModalContentStyles();
    const [selectedDevice, setSelectedDevice] = useState<Device>();
    const history = useHistory();

    const onCaseLinkClicked = (caseId: number, caseCountry: string, caseType: CaseType, caseStatus: CaseStatus, isDispute: boolean = false): void => {
        if (!props.case) return;
        const country = caseCountry === "International Protocol (Madrid)" ? props.case.country : caseCountry;
        if (isDispute) {
            history.push("/disputes", { caseId, country, caseStatus, types: caseType });
            return;
        }
        switch (caseType) {
            case CaseType.Trademark:
                history.push("/trademarks", { caseId, country, caseStatus });
                break;
            case CaseType.Design:
                history.push("/designs", { caseId, country, caseStatus });
                break;
            case CaseType.Copyright:
                history.push("/copyrights", { caseId, country, caseStatus });
                break;
            default:
                break;
        }
    };

    const onSelectedDeviceChanged = (deviceId: number, caseId: number) => {
        props
            .onSelectedDeviceChanged(deviceId, caseId)
            .then((getDeviceRes) => setSelectedDevice(getDeviceRes))
            .catch(() => setSelectedDevice(undefined));
    };

    useEffect(() => {
        if (!props.case) return;
        const mainDevice = props.case.devices.find((device) => device.isMain);
        if (mainDevice) onSelectedDeviceChanged(mainDevice.id, props.case.id);
        // eslint-disable-next-line
    }, [props.case]);

    if (!props.case) return <DotLegalFullScreenSpinner />;

    return (
        <Grid className={classes.root} container alignItems="center" spacing={6}>
            <Grid item container sm={12} spacing={1}>
                <CaseModalContentRow label={props.caseType} value={props.case.name} />
                <CaseModalContentRow label="Case no." value={props.case.caseNo} />
                <CaseModalContentRow label="Matter ID" value={props.case.matterId} />
                <CaseModalContentRow label="Country" value={props.case.country} />
                <CaseModalContentRow
                    label="Status"
                    value={
                        props.case.isDispute && props.case.status !== "Pending"
                            ? props.case.disputeDecision[0].toUpperCase() + props.case.disputeDecision.substr(1).toLowerCase()
                            : props.case.status
                    }
                />
            </Grid>
            <Grid item container sm={12} spacing={1}>
                <CaseModalContentRow label="Application No." value={props.case.applicationNo} />
                <CaseModalContentRow label="Application Date" value={props.case.applicationDate && formatDate(props.case.applicationDate)} />
                <CaseModalContentRow label="Registration No." value={props.case.registrationNo} />
                <CaseModalContentRow label="Registration Date" value={props.case.registrationDate && formatDate(props.case.registrationDate)} />
                {!props.case.isDispute && props.case.status !== "Closed" ? (
                    <CaseModalContentRow
                        label={props.caseType === CaseType.Copyright ? "Expiry date" : "Renewal Date"}
                        value={props.case.renewalDate && formatDate(props.case.renewalDate)}
                    />
                ) : (
                    <CaseModalContentRow label="Case creation date" value={props.case.dateOfOrder && formatDate(props.case.dateOfOrder)} />
                )}
            </Grid>
            <Grid item container sm={12} spacing={1}>
                {props.caseType === CaseType.Trademark && !props.case.isDispute && (
                    <CaseModalContentRow label="Use Date" value={props.case.useDate && formatDate(props.case.useDate)} />
                )}
                {!props.case.isDispute && <CaseModalContentRow label="Applicant" value={props.case.applicant} />}
                {!props.case.isDispute && <CaseModalContentRow label="Correspondence" value={props.case.correspondence} />}
                {props.caseType !== CaseType.Copyright && (
                    <CaseModalContentRow label="Classes" value={props.case.classes?.map((tooltip) => tooltip.value).join(", ")} />
                )}
                {props.caseType === CaseType.Design && (
                    <CaseModalContentRow label="End of duration" value={props.case.endOfDurationDate && formatDate(props.case.endOfDurationDate)} />
                )}
                {!props.case.isDispute && props.caseType !== CaseType.Copyright && (
                    <CaseModalContentRow
                        label="Latest estimated renewal price"
                        value={
                            props.case.latestRenewalPrice
                                ? `${props.case.latestRenewalPrice.toEnglishLocaleStringWithDecimal()} DKK ${
                                      props.case.renewalPriceGivenAt ? `(${formatDate(props.case.renewalPriceGivenAt)})` : ``
                                  }`
                                : ""
                        }
                    />
                )}
                {props.caseType === CaseType.Copyright && <CaseModalContentRow label="" value="" />}
                {props.case.isDispute && <CaseModalContentRow label="Case type" value={props.case.type} />}
                {props.case.isDispute && <CaseModalContentRow label="Dispute against" value={props.case.dispute} />}
                {props.case.isDispute && (
                    <>
                        <CaseModalContentRow label="Counterparty" value={props.case.counterParty} />
                        <CaseModalContentRow label="Address" value={props.case.counterPartyAddress} />
                    </>
                )}
            </Grid>
            {props.case.remark && (
                <Grid item container sm={12} spacing={1}>
                    <Grid item sm={12}>
                        <Typography variant="h5">Remark</Typography>
                    </Grid>
                    <Grid item sm={12}>
                        {props.case.remark}
                    </Grid>
                </Grid>
            )}
            {props.caseType === CaseType.Trademark && props.case.classes && props.case.classes.length > 0 && (
                <Grid item container sm={12} spacing={1}>
                    <>
                        <Grid item sm={12}>
                            <Typography variant="h5">Goods & Services</Typography>
                        </Grid>
                        {props.case.classes?.map((caseData) => (
                            <Grid item sm={12} style={{ fontSize: 12 }} key={caseData.value}>
                                <strong>{caseData.value}: </strong>
                                {caseData.tooltip}
                            </Grid>
                        ))}
                    </>
                </Grid>
            )}
            {props.case.disputeRelations && props.case.disputeRelations.length > 0 && (
                <Grid item container sm={12} spacing={1}>
                    <>
                        {props.case.isDispute ? (
                            <>
                                <Grid item sm={12}>
                                    <Typography variant="h5">Link to registration case</Typography>
                                </Grid>
                                {props.case.disputeRelations?.map((disputeRelation) => (
                                    <Grid
                                        item
                                        sm={12}
                                        className={classes.caseLink}
                                        key={disputeRelation.caseId}
                                        onClick={() =>
                                            onCaseLinkClicked(disputeRelation.caseId, disputeRelation.country, disputeRelation.type, disputeRelation.status)
                                        }
                                    >
                                        {disputeRelation.catchword}
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <>
                                {props.case.disputeRelations?.filter(
                                    (disputeRelation) => disputeRelation.status === "pending" || disputeRelation.status === "registered"
                                ).length > 0 && (
                                    <>
                                        <Grid item sm={12}>
                                            <Typography variant="h5">Link to pending disputes</Typography>
                                        </Grid>
                                        {props.case.disputeRelations
                                            ?.filter((disputeRelation) => disputeRelation.status === "pending" || disputeRelation.status === "registered")
                                            .map((disputeRelation) => (
                                                <Grid
                                                    item
                                                    sm={12}
                                                    className={classes.caseLink}
                                                    key={disputeRelation.caseId}
                                                    onClick={() =>
                                                        onCaseLinkClicked(
                                                            disputeRelation.caseId,
                                                            disputeRelation.country,
                                                            disputeRelation.type,
                                                            disputeRelation.status,
                                                            true
                                                        )
                                                    }
                                                >
                                                    {disputeRelation.catchword}
                                                </Grid>
                                            ))}
                                    </>
                                )}
                                {props.case.disputeRelations?.filter(
                                    (disputeRelation) => disputeRelation.status !== "pending" && disputeRelation.status !== "registered"
                                ).length > 0 && (
                                    <>
                                        <Grid item sm={12}>
                                            <Typography variant="h5">Link to past disputes</Typography>
                                        </Grid>
                                        {props.case.disputeRelations
                                            ?.filter((disputeRelation) => disputeRelation.status !== "pending" && disputeRelation.status !== "registered")
                                            .map((disputeRelation) => (
                                                <Grid
                                                    item
                                                    sm={12}
                                                    className={classes.caseLink}
                                                    key={disputeRelation.caseId}
                                                    onClick={() =>
                                                        onCaseLinkClicked(
                                                            disputeRelation.caseId,
                                                            disputeRelation.country,
                                                            disputeRelation.type,
                                                            disputeRelation.status,
                                                            true
                                                        )
                                                    }
                                                >
                                                    {disputeRelation.catchword}
                                                </Grid>
                                            ))}
                                    </>
                                )}
                            </>
                        )}
                    </>
                </Grid>
            )}
            {props.case.documents && props.case.documents.length > 0 && (
                <Grid item container sm={12} spacing={1}>
                    <Grid item sm={12}>
                        <Typography variant="h5">Documents</Typography>
                    </Grid>
                    {props.case.documents.map((document) => (
                        <Grid key={document.id} item sm={12}>
                            <span
                                onClick={() => downloadDocument(document.id, document.name)}
                                onKeyDown={() => downloadDocument(document.id, document.name)}
                                role="button"
                                tabIndex={0}
                                className={classes.caseLink}
                            >
                                <img src={downloadIcon} alt="download" style={{ marginRight: 10 }} />
                                {document.name}
                            </span>
                        </Grid>
                    ))}
                </Grid>
            )}
            {selectedDevice && (
                <Grid item container sm={12}>
                    <Grid item sm={12}>
                        <Typography variant="h5">{props.caseType === CaseType.Trademark ? "Figurative mark" : "Image"}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <img src={`data:image/jpeg;base64,${selectedDevice.base64}`} alt="Device" />
                    </Grid>
                </Grid>
            )}
            {props.case && props.case.devices && props.case.devices.length > 1 && (
                <Grid item container sm={12} spacing={1}>
                    {props.case.devices?.map((device) => (
                        <Grid item sm={12} key={device.id}>
                            <span
                                onClick={() => onSelectedDeviceChanged(device.id, props.case!.id)}
                                onKeyDown={() => onSelectedDeviceChanged(device.id, props.case!.id)}
                                role="button"
                                tabIndex={0}
                                className={`${classes.caseLink} ${selectedDevice && selectedDevice.id === device.id ? classes.caseLinkSelected : null}`}
                            >
                                {device.name}
                            </span>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Grid>
    );
}

export default CaseModalContent;
