import { Skeleton } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import useDonutChartStyles from "./DonutChart.styles";
import { ChartData } from "./DonutChart.types";

export type DonutChartProps = {
    chartData?: ChartData;
    isLoading: boolean;
    colors: string[];
};

function DonutChart(props: DonutChartProps) {
    const classes = useDonutChartStyles();

    return (
        <>
            {props.isLoading ? (
                <div className={classes.skeletonCircleGrid}>
                    <Skeleton animation="pulse" variant="circular" className={classes.skeletonCircle} />
                </div>
            ) : (
                <>
                    <div className={classes.root}>
                        <ResponsivePie
                            activeOuterRadiusOffset={8}
                            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                            borderWidth={1}
                            colors={props.colors}
                            cornerRadius={2.5}
                            data={props.chartData!.chartItems}
                            innerRadius={0.6}
                            padAngle={1}
                            enableArcLinkLabels={false}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor="#FFFFFF"
                            arcLinkLabelsOffset={24}
                            arcLinkLabelsDiagonalLength={0}
                            arcLinkLabelsColor={{ from: "color" }}
                            enableArcLabels
                            arcLabel={(item: { value: any }) => item.value}
                            arcLinkLabelsTextColor="#FFFFFF"
                            margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
                            theme={{
                                fontSize: 14,
                                tooltip: {
                                    basic: { fontWeight: "normal" },
                                },
                            }}
                            isInteractive
                        />
                        {props.chartData!.totalCount && (
                            <div className={classes.overlay}>
                                <span className={classes.totalCount}>{props.chartData!.totalCount}</span>
                                <span className={classes.totalCountLabel}>Registrations</span>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
}

export default DonutChart;
