import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { isNullOrWhitespace } from "../stringOperations";
import blueWave from "./blueWave.svg";
import greenWave from "./greenWave.svg";
import { IPaperProps } from "./Paper";
import purpleWave from "./purpleWave.svg";
import purpleWave1 from "./purpleWave1.svg";
import smallPurpleWave from "./smallPurpleWave.svg";

export const useDotLegalPaperStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: IPaperProps) => {
            let heightObject = {};
            if (props.fullHeight) heightObject = { height: "100%" };
            if (props.onClick && props.clickableRows) {
                return {
                    padding: props.removePadding ? 0 : theme.spacing(2),
                    boxShadow: "0px 3px 10px #E7E7E7",
                    borderRadius: 20,
                    cursor: "pointer",
                    ...heightObject,
                    ...getBackgroundColor(props),
                };
            }
            return {
                padding: props.removePadding ? 0 : theme.spacing(2),
                boxShadow: "0px 3px 10px #E7E7E7",
                borderRadius: 20,
                ...heightObject,
                ...getBackgroundColor(props),
            };
        },
        paperBackground: getBackgroundImage,
    })
);

function getBackgroundColor(props: IPaperProps) {
    let backgroundColor = "";
    let textColor = "";
    if (props.backgroundColor) {
        switch (props.backgroundColor) {
            case "lightGreen":
                backgroundColor = "#E6FAF8";
                textColor = "#258E9F";
                break;
            case "lightGrey":
                backgroundColor = "#F0F0F0";
                textColor = "#707070";
                break;
            case "darkLightBlue":
                backgroundColor = "#DCE3F1";
                textColor = "#3C63AD";
                break;
            case "lightBlue":
                backgroundColor = "#E9F2FA";
                textColor = "#032478";
                break;
            case "lightPurple":
                backgroundColor = "#F3F3FB";
                textColor = "#7284FA";
                break;
            case "orange":
                backgroundColor = "#FDC6A2";
                textColor = "#FF7317";
                break;
            case "darkGreen":
                backgroundColor = "#AADEC6";
                textColor = "#479370";
                break;
            case "limeGreen":
                backgroundColor = "#E2F3C2";
                textColor = "#7DA532";
                break;
            case "pink":
                backgroundColor = "#F5D3E8";
                textColor = "#E880C0";
                break;
        }
        return {
            backgroundColor: isNullOrWhitespace(backgroundColor) ? "inherit" : backgroundColor + " !important",
            "& > *": {
                color: textColor + " !important",
            },
        };
    }
    return {};
}

function getBackgroundImage(props: IPaperProps) {
    if (props.background) {
        let backgroundImage = "";

        switch (props.background) {
            case "blueWave":
                backgroundImage = blueWave;
                break;
            case "purpleWave":
                backgroundImage = purpleWave;
                break;
            case "greenWave":
                backgroundImage = greenWave;
                break;
            case "purpleWave1":
                backgroundImage = purpleWave1;
                break;
            case "smallPurpleWave":
                backgroundImage = smallPurpleWave;
                break;
        }

        return {
            backgroundImage: `url("${backgroundImage}")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: props.backgroundCover ? "cover" : "contain",
        };
    }
    return {};
}
