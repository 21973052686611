import { Box, Button, CircularProgress, styled } from "@mui/material";
import React from "react";
import { useIsOnMediumScreen, useIsOnSmallScreen } from "../../../../common/hooks/mediaHooks";
import bbLogo from "../../../../core/navMenu/BB logo.svg";
import icon from "../../icons/SurveySubmitted.svg";
import { SurveyArea } from "../../Surveys.types";
import SurveyHeader from "../components/surveyHeader/SurveyHeader";
import { useSurveyStyles } from "../Survey.styles";
import { useSurveySubmittedDataMapping } from "./SurveySubmitted.hooks";

export interface SurveySubmittedProps {
    area: SurveyArea;
}

function SurveySubmitted(props: SurveySubmittedProps) {
    const isOnSmallScreen = useIsOnSmallScreen();
    const isOnMediumScreen = useIsOnMediumScreen();
    const styles = useSurveyStyles(isOnMediumScreen);
    const { onDownloadPdfClick, downloading } = useSurveySubmittedDataMapping(props);

    const Logo = styled("img")({
        width: 189,
        height: 41,
    });

    return (
        <Box sx={styles.container}>
            <Box sx={styles.logo}>
                <Logo alt="logo" src={bbLogo} />
            </Box>
            <Box sx={styles.content}>
                <SurveyHeader isOnMediumScreen={isOnMediumScreen} backgroundColor="lightBlue" header="Thank you for your submission!">
                    <React.Fragment>
                        <Box sx={{ height: "100%" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                <Button sx={{ width: 190 }} color="primary" variant="outlined" onClick={() => onDownloadPdfClick()}>
                                    {downloading ? <CircularProgress color="inherit" size={20} /> : "Download submission"}
                                </Button>
                            </Box>

                            {!isOnSmallScreen && (
                                <Box sx={{ position: "absolute", bottom: 20, right: isOnMediumScreen ? 0 : 80 }}>
                                    <img src={icon} alt="" />
                                </Box>
                            )}
                        </Box>
                    </React.Fragment>
                </SurveyHeader>
            </Box>
        </Box>
    );
}

export default SurveySubmitted;
