import NavigationLink from "./NavigationLink";

export type NavLinkListItemProps = {
    title: string;
    linkTo: string;
    icon: string;
    isMinimized: boolean;
    isActive?: () => boolean;
    onNavLinkClick?: (page: string) => void;
};

function NavLinkListItem(props: NavLinkListItemProps) {
    return (
        <li>
            <NavigationLink
                isActive={props.isActive}
                text={props.title}
                linkTo={props.linkTo}
                exact
                icon={props.icon}
                isMinimized={props.isMinimized}
                onNavLinkClick={props.onNavLinkClick || (() => {})}
            />
        </li>
    );
}

export default NavLinkListItem;
