import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useCasePageStyles = makeStyles((theme: Theme) =>
    createStyles({
        caseTableContainer: {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(3),
        },
        filterContainer: {
            display: "flex",
            alignItems: "center",
            gap: 10,
            paddingLeft: 6,
        },
        filter: {
            width: 160,
        },
        searchContainer: {
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-end",
        },
    })
);

export default useCasePageStyles;
