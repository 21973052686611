import { Box, Button, CircularProgress, Divider } from "@mui/material";
import { DesignFeesIntervals, DesignSurveyViewModel } from "../../Survey.types";
import { textButtonStyle } from "../period/Period";
import Interval from "./Interval";
import { useInterval } from "./Interval.hooks";
import { DesignSurveyIntervalArea } from "./interval.types";

export interface IntervalsProps {
    data: DesignSurveyViewModel | undefined;
    period: DesignFeesIntervals | undefined;
    onPeriodChange: (period: DesignFeesIntervals) => void;
    intervalArea: DesignSurveyIntervalArea;
    surveyId: string;
    errorText?: string;
    disabled?: boolean;
    isPdf?: boolean;
}

function Intervals(props: IntervalsProps) {
    const { addInterval, isAddingInterval, removeInterval, onToChange, onFromChange } = useInterval(props);

    const firstInterval = props.period?.intervals[0];
    const secondInterval = props.period?.intervals[1];

    return (
        <Box>
            <Interval
                header={"a) Price for 1st design"}
                interval={firstInterval!}
                disableInterval
                onOfficialFeeChange={(fee) => {
                    let tempModel = { ...props.period! };
                    tempModel.intervals!.find((x) => x.id === firstInterval!.id)!.officialFees = fee ?? null;
                    props.onPeriodChange(tempModel);
                }}
                onAttorneyFeeChange={(fee) => {
                    let tempModel = { ...props.period! };
                    tempModel.intervals!.find((x) => x.id === firstInterval!.id)!.attorneyFees = fee ?? null;
                    props.onPeriodChange(tempModel);
                }}
                hideAttorneyFee={props.intervalArea === DesignSurveyIntervalArea.DefermentFees}
                disableFees={props.disabled || isAddingInterval}
                isPdf={props.isPdf}
            />
            <Divider sx={{ marginTop: 1, marginBottom: 2 }} />

            <Interval
                header={
                    <>
                        b){" "}
                        <Box component={"span"} sx={{ textDecoration: "underline" }}>
                            Additional price
                        </Box>{" "}
                        per extra design from 2nd design to onwards
                    </>
                }
                interval={secondInterval!}
                onOfficialFeeChange={(fee) => {
                    let tempModel = { ...props.period! };
                    tempModel.intervals!.find((x) => x.id === secondInterval!.id)!.officialFees = fee ?? null;
                    props.onPeriodChange(tempModel);
                }}
                onAttorneyFeeChange={(fee) => {
                    let tempModel = { ...props.period! };
                    tempModel.intervals!.find((x) => x.id === secondInterval!.id)!.attorneyFees = fee ?? null;
                    props.onPeriodChange(tempModel);
                }}
                hideAttorneyFee={props.intervalArea === DesignSurveyIntervalArea.DefermentFees}
                isLastInterval={props.period?.intervals.length === 2}
                disableFees={props.disabled || isAddingInterval}
                disableInterval={props.disabled || isAddingInterval}
                onToChange={(value) => {
                    onToChange(secondInterval!, value);
                }}
                isPdf={props.isPdf}
            />
            {props.period?.intervals?.map((interval, index) => {
                if (index === 0 || index === 1) {
                    return null;
                } else {
                    const isLastInterval = index === props.period?.intervals.length! - 1;
                    return (
                        <Interval
                            key={interval.id}
                            header={undefined}
                            interval={interval}
                            onOfficialFeeChange={(fee) => {
                                let tempModel = { ...props.period! };
                                tempModel.intervals!.find((x) => x.id === interval!.id)!.officialFees = fee ?? null;
                                props.onPeriodChange(tempModel);
                            }}
                            onAttorneyFeeChange={(fee) => {
                                let tempModel = { ...props.period! };
                                tempModel.intervals!.find((x) => x.id === interval!.id)!.attorneyFees = fee ?? null;
                                props.onPeriodChange(tempModel);
                            }}
                            canRemoveInterval={isLastInterval}
                            isLastInterval={isLastInterval}
                            onRemoveInterval={() => removeInterval(interval.id)}
                            hideAttorneyFee={props.intervalArea === DesignSurveyIntervalArea.DefermentFees}
                            hideHeaders
                            onFromChange={(value) => {
                                onFromChange(interval!, value);
                            }}
                            onToChange={(value) => {
                                onToChange(interval!, value);
                            }}
                            disableFees={props.disabled || isAddingInterval}
                            disableInterval={props.disabled || isAddingInterval}
                            isPdf={props.isPdf}
                        />
                    );
                }
            })}
            <Box>
                <Button
                    sx={(theme) => ({
                        ...textButtonStyle(theme, isAddingInterval),
                    })}
                    onClick={() => (isAddingInterval ? {} : addInterval())}
                    disabled={props.disabled}
                >
                    Add new interval
                    {isAddingInterval && <CircularProgress sx={{ position: "absolute" }} size={18} />}
                </Button>
            </Box>
            <Box sx={(theme) => ({ color: theme.palette.error.main })}>{props.errorText}</Box>
        </Box>
    );
}

export default Intervals;
