import { TableHeader } from "../../common/table/DotLegalTable.types";
import { Case, CaseType } from "./Case.types";
import { CaseSummarization } from "./CaseSummarizationPage";

const createTableHeader = <T>(
    property: keyof T,
    text: string,
    sortable: boolean = true,
    align: "left" | "center" = "left",
    showOnMobile: true | undefined = true
): TableHeader<T> => ({
    property,
    text,
    align,
    showOnMobile,
    notSortable: !sortable,
});

const getDefaultCaseTableHeaders = (caseType: CaseType): TableHeader<Case>[] => [
    createTableHeader<Case>("name", caseType),
    createTableHeader<Case>("image", caseType === CaseType.Trademark ? "Fig. Mark" : "Image"),
    createTableHeader<Case>("caseNo", "Case No."),
    createTableHeader<Case>("matterId", "Matter Id"),
    createTableHeader<Case>("registrationNo", "Registration NO."),
    createTableHeader<Case>("country", "Country"),
    createTableHeader<Case>("classes", "Classes", false),
];

const getCaseTableHeadersByCaseType = (caseType: CaseType): TableHeader<Case>[] => {
    const defaultHeaders = [
        ...getDefaultCaseTableHeaders(caseType),
        createTableHeader<Case>("status", "Status"),
        createTableHeader<Case>("applicant", "Applicant"),
        createTableHeader<Case>("applicationDate", "Application Date"),
    ];

    switch (caseType) {
        case CaseType.Trademark:
            defaultHeaders.splice(defaultHeaders.length - 1, 0, createTableHeader<Case>("useDate", "Use Date"));
            return defaultHeaders;
        case CaseType.Copyright:
            return defaultHeaders.filter((h) => h.property !== "classes");
        default:
            return defaultHeaders;
    }
};

const getDashboardCaseTableHeaders = (caseType: CaseType): TableHeader<Case>[] => [
    createTableHeader<Case>("name", caseType),
    createTableHeader<Case>("image", caseType === CaseType.Trademark ? "Fig. Mark" : "Image"),
    createTableHeader<Case>("country", "Country"),
    createTableHeader<Case>("renewalDate", caseType === CaseType.Copyright ? "Expiry Date" : "Renewal Date"),
    createTableHeader<Case>("matterId", "Matter Id"),
];

const getDashboardRenewalCaseTableHeaders = (caseType: CaseType): TableHeader<Case>[] => [
    createTableHeader<Case>("name", caseType),
    createTableHeader<Case>("image", caseType === CaseType.Trademark ? "Fig. Mark" : "Image"),
    createTableHeader<Case>("country", "Country"),
    createTableHeader<Case>("renewalDate", caseType === CaseType.Copyright ? "Expiry Date" : "Renewal Date"),
    createTableHeader<Case>("renewalStatus", "Renewal Status"),
];

const getDaskboardRenewalCaseTableHeadersWithoutStatus = (caseType: CaseType): TableHeader<Case>[] => [
    createTableHeader<Case>("name", caseType),
    createTableHeader<Case>("image", caseType === CaseType.Trademark ? "Fig. Mark" : "Image"),
    createTableHeader<Case>("country", "Country"),
    createTableHeader<Case>("renewalDate", caseType === CaseType.Copyright ? "Expiry Date" : "Renewal Date"),
];

const getRenewalsCaseTableHeaders = (caseType: CaseType): TableHeader<Case>[] => [
    ...getDefaultCaseTableHeaders(caseType),
    createTableHeader<Case>("applicant", "Applicant"),
    createTableHeader<Case>("renewalDate", "Renewal Date"),
    createTableHeader<Case>("renewalStatus", "Renewal Status", false),
    createTableHeader<Case>("renewalRemark", "Renewal Remark", false),
];
const getRenewalsCaseTableHeadersWithPrice = (caseType: CaseType): TableHeader<Case>[] => [
    ...getDefaultCaseTableHeaders(caseType),
    createTableHeader<Case>("price", "Estimated renewal price"),
    createTableHeader<Case>("applicant", "Applicant"),
    createTableHeader<Case>("renewalDate", "Renewal Date"),
    createTableHeader<Case>("renewalStatus", "Renewal Status", false),
    createTableHeader<Case>("renewalRemark", "Renewal Remark", false),
];

const getRenewalsCaseTableHeadersWithPriceOnly = (caseType: CaseType): TableHeader<Case>[] => [
    ...getDefaultCaseTableHeaders(caseType),
    createTableHeader<Case>("price", "Estimated renewal price"),
    createTableHeader<Case>("applicant", "Applicant"),
    createTableHeader<Case>("renewalDate", "Renewal Date"),
];

const getRenewalsCaseTableHeadersWithStatusAndRemarkOnly = (caseType: CaseType): TableHeader<Case>[] => [
    ...getDefaultCaseTableHeaders(caseType),
    createTableHeader<Case>("applicant", "Applicant"),
    createTableHeader<Case>("renewalDate", "Renewal Date"),
    createTableHeader<Case>("renewalStatus", "Renewal Status", false),
    createTableHeader<Case>("renewalRemark", "Renewal Remark", false),
];

const getDisputesCaseTableHeaders = (caseType: CaseType): TableHeader<Case>[] => [
    createTableHeader<Case>("name", caseType),
    createTableHeader<Case>("image", caseType === CaseType.Trademark ? "Fig. Mark" : "Image"),
    createTableHeader<Case>("type", "Case Type"),
    createTableHeader<Case>("dispute", "Dispute against"),
    createTableHeader<Case>("matterId", "Matter Id"),
    createTableHeader<Case>("country", "Country"),
    createTableHeader<Case>("classes", "Classes", false),
    createTableHeader<Case>("disputeDecision", "Status"),
    createTableHeader<Case>("applicationNo", "Application NO.", false),
    createTableHeader<Case>("dateOfOrder", "Case creation date"),
    createTableHeader<Case>("counterParty", "Counterparty"),
];

const getRenewalTableHeadersWithoutRegistrationActive = (caseType: CaseType): TableHeader<Case>[] => [
    ...getDefaultCaseTableHeaders(caseType),
    createTableHeader<Case>("applicant", "Applicant"),
    createTableHeader<Case>("renewalDate", "Renewal Date"),
];

const getCaseTableHeaders = (caseType: CaseType, showRenewalPrice: boolean, renewalRegistrationActive: boolean, key?: CaseSummarization) => {
    switch (key) {
        case CaseSummarization.Dashboard:
            return caseType !== CaseType.Copyright
                ? !renewalRegistrationActive
                    ? getDaskboardRenewalCaseTableHeadersWithoutStatus(caseType)
                    : getDashboardRenewalCaseTableHeaders(caseType)
                : getDashboardCaseTableHeaders(caseType);
        case CaseSummarization.Renewals: {
            if (!renewalRegistrationActive && !showRenewalPrice) return getRenewalTableHeadersWithoutRegistrationActive(caseType);
            if (renewalRegistrationActive && showRenewalPrice) return getRenewalsCaseTableHeadersWithPrice(caseType);
            if (showRenewalPrice) return getRenewalsCaseTableHeadersWithPriceOnly(caseType);
            if (renewalRegistrationActive) return getRenewalsCaseTableHeadersWithStatusAndRemarkOnly(caseType);
            return getRenewalsCaseTableHeaders(caseType);
        }
        case CaseSummarization.Disputes:
            return getDisputesCaseTableHeaders(caseType);
        default:
            return getCaseTableHeadersByCaseType(caseType);
    }
};

export default getCaseTableHeaders;
