import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { SingleValidator } from "../../../../../common/hooks/validation/SingleValidator";
import OutlinedQuestionBox from "../../../../priceCalculator/outlinedQuestionBox/OutlinedQuestionBox";
import { SurveyType } from "../../../../priceCalculator/PriceCalculator.types";
import { SurveyViewModel } from "../../Survey.types";
import { SectionHeader } from "../../trademarkSurvey/Survey";
import OfficialAttorneyFeesBox from "../officialAttorneyFeesBox/OfficialAttorneyFeesBox";
import YesNoBox from "../yesNoBox/YesNoBox";

export interface IFeesSectionProps {
    data: SurveyViewModel | undefined;
    onChange: (viewModel: SurveyViewModel) => void;
    validator?: SingleValidator<SurveyViewModel, SurveyViewModel>;
    isPdf?: boolean;
}

function FeesSection(props: IFeesSectionProps) {
    let data = props.data;

    let isNewApplication = props.data?.type === SurveyType.NewApplication;

    function fillingFeesHasErrors() {
        return (
            props.validator?.showErrors &&
            (props.validator.hasErrors("officialFeesForFirstClass") ||
                props.validator.hasErrors("attorneyFeesForFirstClass") ||
                props.validator.hasErrors("officialFeesInEachAdditionalClass") ||
                props.validator.hasErrors("attorneyFeesInEachAdditionalClass"))
        );
    }

    function additionalCostsHasErrors() {
        return (
            props.validator?.showErrors &&
            (props.validator.hasErrors("additionalCostsForOfficialFeesForFirstClass") ||
                props.validator.hasErrors("additionalCostsForAttorneyFeesForFirstClass") ||
                props.validator.hasErrors("additionalCostsForOfficialFeesInEachAdditionalClass") ||
                props.validator.hasErrors("additionalCostsForAttorneyFeesInEachAdditionalClass"))
        );
    }

    function publicationAndRegistrationFeesHasErrors() {
        return (
            props.validator?.showErrors &&
            (props.validator.hasErrors("officialPublicationAndRegistrationFeesFirstClass") ||
                props.validator.hasErrors("attorneyPublicationAndRegistrationFeesFirstClass") ||
                props.validator.hasErrors("officialPublicationAndRegistrationFeesInEachAdditionalClass") ||
                props.validator.hasErrors("attorneyPublicationAndRegistrationFeesInEachAdditionalClass"))
        );
    }

    return (
        <>
            <Box>
                <SectionHeader isPdf>
                    <DotLegalHeader headerStyle={"large"} marginBottom={0}>
                        Fees
                    </DotLegalHeader>
                </SectionHeader>

                <OutlinedQuestionBox
                    header="Do official fees and attorney's fees for 1st class cover up to 3 classes? (As they do in many European countries)."
                    headerMarginBottom={0}
                    hasError={props.validator?.hasErrors("firstClassCoversUpToThreeClasses") && props.validator.showErrors}
                    isPdf={props.isPdf}
                >
                    <YesNoBox
                        value={data?.firstClassCoversUpToThreeClasses}
                        onValueChange={(value) => {
                            props.data!.firstClassCoversUpToThreeClasses = value;
                            props.onChange(props.data!);
                        }}
                        errorText={props.validator?.getErrorsForDisplay("firstClassCoversUpToThreeClasses")}
                    />
                </OutlinedQuestionBox>

                <OutlinedQuestionBox
                    header="Filing fees (word marks) (if, in the event of other types of marks additional costs are incurred please state this in “Remarks“)"
                    hasError={fillingFeesHasErrors()}
                    isPdf={props.isPdf}
                >
                    <OfficialAttorneyFeesBox
                        header={data?.firstClassCoversUpToThreeClasses ? "a) 1st to 3rd class" : "a) 1st class"}
                        officialFee={data?.officialFeesForFirstClass?.toString()}
                        attorneyFee={data?.attorneyFeesForFirstClass?.toString()}
                        onOfficialFeeChange={(value) => {
                            let tempModel = { ...data! };
                            tempModel.officialFeesForFirstClass = value;
                            props.onChange(tempModel);
                        }}
                        onAttorneyFeeChange={(value) => {
                            let tempModel = { ...data! };
                            tempModel.attorneyFeesForFirstClass = value;
                            props.onChange(tempModel);
                        }}
                        officaialFeesErrorText={props.validator?.getErrorsForDisplay("officialFeesForFirstClass")}
                        attorneyFeesErrorText={props.validator?.getErrorsForDisplay("attorneyFeesForFirstClass")}
                    />

                    <OfficialAttorneyFeesBox
                        header={"b) Each additional class"}
                        officialFee={data?.officialFeesInEachAdditionalClass?.toString()}
                        attorneyFee={data?.attorneyFeesInEachAdditionalClass?.toString()}
                        onOfficialFeeChange={(value) => {
                            let temoModel = { ...data! };
                            temoModel.officialFeesInEachAdditionalClass = value;
                            props.onChange(temoModel);
                        }}
                        onAttorneyFeeChange={(value) => {
                            let temoModel = { ...data! };
                            temoModel.attorneyFeesInEachAdditionalClass = value;
                            props.onChange(temoModel);
                        }}
                        officaialFeesErrorText={props.validator?.getErrorsForDisplay("officialFeesInEachAdditionalClass")}
                        attorneyFeesErrorText={props.validator?.getErrorsForDisplay("attorneyFeesInEachAdditionalClass")}
                    />
                </OutlinedQuestionBox>

                <OutlinedQuestionBox
                    isPdf={props.isPdf}
                    header={
                        isNewApplication
                            ? "Additional costs for claiming priority (word marks)"
                            : "Additional costs for late renewal during a grace period (if available)"
                    }
                    hasError={additionalCostsHasErrors()}
                    tooltip={
                        !isNewApplication ? "Please state zero (0) if no additional costs are incurred for late renewal during the grace period." : undefined
                    }
                >
                    <OfficialAttorneyFeesBox
                        header={"a) 1st class"}
                        officialFee={data?.additionalCostsForOfficialFeesForFirstClass?.toString()}
                        attorneyFee={data?.additionalCostsForAttorneyFeesForFirstClass?.toString()}
                        onOfficialFeeChange={(value) => {
                            let temoModel = { ...data! };
                            temoModel.additionalCostsForOfficialFeesForFirstClass = value;
                            props.onChange(temoModel);
                        }}
                        onAttorneyFeeChange={(value) => {
                            let temoModel = { ...data! };
                            temoModel.additionalCostsForAttorneyFeesForFirstClass = value;
                            props.onChange(temoModel);
                        }}
                        officaialFeesErrorText={props.validator?.getErrorsForDisplay("additionalCostsForOfficialFeesForFirstClass")}
                        attorneyFeesErrorText={props.validator?.getErrorsForDisplay("additionalCostsForAttorneyFeesForFirstClass")}
                    />

                    <OfficialAttorneyFeesBox
                        header={"b) Each additional class"}
                        officialFee={data?.additionalCostsForOfficialFeesInEachAdditionalClass?.toString()}
                        attorneyFee={data?.additionalCostsForAttorneyFeesInEachAdditionalClass?.toString()}
                        onOfficialFeeChange={(value) => {
                            let temoModel = { ...data! };
                            temoModel.additionalCostsForOfficialFeesInEachAdditionalClass = value;
                            props.onChange(temoModel);
                        }}
                        onAttorneyFeeChange={(value) => {
                            let temoModel = { ...data! };
                            temoModel.additionalCostsForAttorneyFeesInEachAdditionalClass = value;
                            props.onChange(temoModel);
                        }}
                        officaialFeesErrorText={props.validator?.getErrorsForDisplay("additionalCostsForOfficialFeesInEachAdditionalClass")}
                        attorneyFeesErrorText={props.validator?.getErrorsForDisplay("additionalCostsForAttorneyFeesInEachAdditionalClass")}
                    />
                </OutlinedQuestionBox>

                {isNewApplication && (
                    <div className="page-break-inside">
                        <OutlinedQuestionBox
                            isPdf={props.isPdf}
                            header="Publication and/or registration fees (if, in the event of other types of marks additional costs are incurred please state this in “Remarks“)"
                            hasError={publicationAndRegistrationFeesHasErrors()}
                        >
                            <OfficialAttorneyFeesBox
                                header={"Price for 1st design"}
                                officialFee={data?.officialPublicationAndRegistrationFeesFirstClass?.toString()}
                                attorneyFee={data?.attorneyPublicationAndRegistrationFeesFirstClass?.toString()}
                                onOfficialFeeChange={(value) => {
                                    let temoModel = { ...data! };
                                    temoModel.officialPublicationAndRegistrationFeesFirstClass = value;
                                    props.onChange(temoModel);
                                }}
                                onAttorneyFeeChange={(value) => {
                                    let temoModel = { ...data! };
                                    temoModel.attorneyPublicationAndRegistrationFeesFirstClass = value;
                                    props.onChange(temoModel);
                                }}
                                officaialFeesErrorText={props.validator?.getErrorsForDisplay("officialPublicationAndRegistrationFeesFirstClass")}
                                attorneyFeesErrorText={props.validator?.getErrorsForDisplay("attorneyPublicationAndRegistrationFeesFirstClass")}
                            />

                            <OfficialAttorneyFeesBox
                                header={"b) Each additional class"}
                                officialFee={data?.officialPublicationAndRegistrationFeesInEachAdditionalClass?.toString()}
                                attorneyFee={data?.attorneyPublicationAndRegistrationFeesInEachAdditionalClass?.toString()}
                                onOfficialFeeChange={(value) => {
                                    let temoModel = { ...data! };
                                    temoModel.officialPublicationAndRegistrationFeesInEachAdditionalClass = value;
                                    props.onChange(temoModel);
                                }}
                                onAttorneyFeeChange={(value) => {
                                    let temoModel = { ...data! };
                                    temoModel.attorneyPublicationAndRegistrationFeesInEachAdditionalClass = value;
                                    props.onChange(temoModel);
                                }}
                                officaialFeesErrorText={props.validator?.getErrorsForDisplay("officialPublicationAndRegistrationFeesInEachAdditionalClass")}
                                attorneyFeesErrorText={props.validator?.getErrorsForDisplay("attorneyPublicationAndRegistrationFeesInEachAdditionalClass")}
                            />
                        </OutlinedQuestionBox>
                    </div>
                )}
            </Box>
        </>
    );
}

export default FeesSection;
