import { DotLegalHeader, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import React from "react";
import OutlinedQuestionBox from "../../../../priceCalculator/outlinedQuestionBox/OutlinedQuestionBox";
import { SectionHeader } from "../../trademarkSurvey/Survey";

export interface ISurveyRemarks {
    remarks: string | undefined;
    publicationOrRegistrationRemarks: string | undefined;
    isNotRenewal: boolean;
    onRemarkChange?: (remark: string) => void;
    onPublicationOrRegistrationRemarks?: (remark: string) => void;
    isPdf?: boolean;
}

function SurveyRemarks(props: ISurveyRemarks) {
    return (
        <div className={"avoid-page-break"}>
            <React.Fragment>
                <SectionHeader isPdf>
                    <DotLegalHeader headerStyle={"large"} marginBottom={0}>
                        Remarks
                    </DotLegalHeader>
                </SectionHeader>

                <Box
                    sx={{
                        "& .MuiFormControl-root": {
                            width: "100%",
                        },
                    }}
                >
                    <OutlinedQuestionBox header={`Remarks - ${props.isNotRenewal ? "filing" : "renewals"} (if any)`} isPdf={props.isPdf}>
                        <DotLegalTextField
                            multiline
                            rows={3}
                            value={props?.remarks ?? null}
                            label={"Remarks - filing"}
                            debounce
                            onChange={(remark) => {
                                if (props.onRemarkChange) {
                                    props.onRemarkChange(remark);
                                }
                            }}
                        />
                    </OutlinedQuestionBox>

                    {props.isNotRenewal && (
                        <OutlinedQuestionBox header="Remarks - publication/registration (if any)" isPdf={props.isPdf}>
                            <DotLegalTextField
                                multiline
                                rows={3}
                                value={props.publicationOrRegistrationRemarks ?? null}
                                label={"Remarks - publication/registration"}
                                debounce
                                onChange={(publicationRemark) => {
                                    if (props.onPublicationOrRegistrationRemarks) {
                                        props.onPublicationOrRegistrationRemarks!(publicationRemark);
                                    }
                                }}
                            />
                        </OutlinedQuestionBox>
                    )}
                </Box>
            </React.Fragment>
        </div>
    );
}

export default SurveyRemarks;
