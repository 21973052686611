import { DotLegalHeader, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import React from "react";
import IpPortalTextField from "../../../../../common/components/IpPortalTextField";
import { DesignInterval } from "../../Survey.types";

export interface IntervalBoxProps {
    header: string | React.ReactNode | undefined;
    officaialFeesErrorText?: string;
    attorneyFeesErrorText?: string;
    disableFees?: boolean;
    disableInterval?: boolean;
    hideHeaders?: boolean;
    interval: DesignInterval;
    canRemoveInterval?: boolean;
    hideAttorneyFee?: boolean;
    isLastInterval?: boolean;
    isPdf?: boolean;
    onFromChange?: (fee: string | undefined) => void;
    onToChange?: (fee: string | undefined) => void;
    onOfficialFeeChange?: (fee: number | undefined) => void;
    onAttorneyFeeChange?: (fee: number | undefined) => void;
    onRemoveInterval?: () => void;
}

function Interval(props: IntervalBoxProps) {
    const convertValueToNumber = (value: string) => {
        return value ? Number(value) : undefined;
    };

    const headerContainerStyle = { display: "flex", flexDirection: "column", justifyContent: "space-between" };

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "stretch",
                    gap: 6,
                    "& .MuiFormControl-root": {
                        width: "100%",
                    },
                }}
            >
                <Box sx={{ ...headerContainerStyle, flex: 1 }}>
                    {!props.hideHeaders && (
                        <DotLegalHeader headerStyle="small" marginBottom={0}>
                            {props.header}
                        </DotLegalHeader>
                    )}
                    <Box sx={{ display: "flex", gap: props.isPdf ? 1 : 2 }}>
                        <Box>
                            <IpPortalTextField
                                disabled={(props.disableInterval || !props.onFromChange) && !props.isPdf}
                                label={"Design"}
                                value={props.interval?.from ? props.interval.from.toString() : null}
                                errorText={props.officaialFeesErrorText}
                                debounce
                                onChange={(value) => {
                                    if (props.onFromChange) {
                                        props.onFromChange(value);
                                    }
                                }}
                                handleNumber
                            />
                        </Box>

                        <Box mt={2} mb={1} sx={(theme) => ({ alignSelf: "center", color: theme.palette.primary.main })}>
                            to
                        </Box>

                        <Box sx={{ alignSelf: "flex-end" }}>
                            <IpPortalTextField
                                disabled={(props.disableInterval || props.isLastInterval) && !props.isPdf}
                                label={"Design"}
                                value={props.isLastInterval ? "Onwards" : props.interval?.to ? props.interval.to!.toString() : null}
                                errorText={props.officaialFeesErrorText}
                                debounce
                                onChange={(value) => {
                                    if (props.onToChange) {
                                        props.onToChange(value);
                                    }
                                }}
                                handleNumber={!props.isLastInterval}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", gap: 2, position: "relative", flex: 1, justifyContent: "flex-end" }}>
                    <Box sx={{ ...headerContainerStyle }}>
                        {!props.hideHeaders && (
                            <DotLegalHeader headerStyle="small" marginBottom={0}>
                                Official fees
                            </DotLegalHeader>
                        )}
                        <DotLegalTextField
                            label={"Official fees"}
                            value={props.interval.officialFees?.toString() ?? null}
                            errorText={props.officaialFeesErrorText}
                            debounce
                            onChange={(fee) => {
                                if (props.onOfficialFeeChange) {
                                    props.onOfficialFeeChange(convertValueToNumber(fee));
                                }
                            }}
                            handleNumber
                            thousandSeperator="en"
                            disabled={props.disableFees && !props.isPdf}
                        />
                    </Box>
                    <Box
                        sx={{
                            visibility: props.hideAttorneyFee ? "hidden" : "visisble",
                            ...headerContainerStyle,
                        }}
                    >
                        {!props.hideHeaders && (
                            <DotLegalHeader headerStyle="small" marginBottom={0}>
                                Attorney fees
                            </DotLegalHeader>
                        )}
                        <DotLegalTextField
                            label={"Attorney fees"}
                            value={props.interval.attorneyFees?.toString() ?? null}
                            errorText={props.officaialFeesErrorText}
                            debounce
                            onChange={(fee) => {
                                if (props.onAttorneyFeeChange) {
                                    props.onAttorneyFeeChange(convertValueToNumber(fee));
                                }
                            }}
                            handleNumber
                            thousandSeperator="en"
                            disabled={props.disableFees && !props.isPdf}
                        />
                    </Box>
                    {props.canRemoveInterval && !props.disableFees && !props.disableInterval && (
                        <IconButton sx={{ position: "absolute", right: "-45px", top: "16px" }} onClick={props.onRemoveInterval}>
                            <CloseIcon color="error" />
                        </IconButton>
                    )}
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default Interval;
