import { Box, Typography } from "@mui/material";

export interface IErrorPage {
    children?: any;
    img: any;
    headerText?: string;
}

export default function ErrorPage(props: IErrorPage) {
    return (
        <Box sx={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", alignContent: "center", marginTop: "50px" }}>
            <Box
                sx={(theme) => ({
                    height: "100%",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                })}
            >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img src={props.img} alt={""} />
                </Box>
                {props.headerText && (
                    <Typography
                        sx={(theme) => ({ display: "flex", justifyContent: "center", color: theme.palette.grey[400], fontWeight: "bold", fontSize: 30 })}
                    >
                        {props.headerText}
                    </Typography>
                )}
                <Typography
                    sx={(theme) => ({
                        whiteSpace: "pre-line",
                        fontSize: theme.typography.pxToRem(15),
                        fontWeight: theme.typography.fontWeightBold,
                        paddingLeft: theme.typography.pxToRem(200),
                        paddingRight: theme.typography.pxToRem(200),
                        textAlign: "left",
                        marginTop: 5,
                    })}
                >
                    {props.children}
                </Typography>
            </Box>
        </Box>
    );
}
