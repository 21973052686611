import { getRaw } from "./apiShared";

export async function downloadFile(url: string, fileName: string, method?: string, body?: any) {
    const response = await getRaw(url, method, body);
    const blob = await response.blob();

    // THE 2021-08-04 Dette er et hack for at få browseren til at downloade en fil der er hentet via et ajax kald
    // til UI API'et. Data fra kaldet sættes ind i et link på siden, et click på linket udføres programmatisk og
    // efter filen er hentet, slettes linket igen
    const tempUrl = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = tempUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    link.parentNode?.removeChild(link);
}
