import { Skeleton } from "@mui/material";
import useDashboardWidgetLegendStyles from "./DashboardWidgetLegend.styles";

export type DashboardWidgetLegendProps = {
    legends: string[];
    isLoading: boolean;
    colors: string[];
};

function WidgetLegend(props: DashboardWidgetLegendProps) {
    const classes = useDashboardWidgetLegendStyles(props);

    const getColor = (index: number) => {
        let result = 0;
        const colorLength = props.colors.length;
        const modulus = (index + 1) % colorLength;
        if (modulus === 0) {
            result = props.colors.length - 1;
        } else {
            result = modulus - 1;
        }
        return props.colors[result];
    };

    return (
        <div className={classes.legendContainer}>
            {props.legends.map((item, i) => (
                <div key={i.toString()} className={classes.legend}>
                    {props.isLoading ? (
                        <div style={{ display: "flex", gap: "7.5%", width: "100%" }}>
                            <Skeleton style={{ borderRadius: 0, maxWidth: 28 }} width="12.5%" animation="pulse" variant="text" />
                            <Skeleton style={{ borderRadius: 0, width: "80%", maxWidth: 180 }} animation="pulse" variant="text" />
                        </div>
                    ) : (
                        <>
                            <span className={classes.legendDot} style={{ backgroundColor: getColor(i) }} />
                            {item}
                        </>
                    )}
                </div>
            ))}
        </div>
    );
}

export default WidgetLegend;
