import { DotLegalFullScreenSpinner } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useAuth } from "oidc-react";
import React, { ReactNode, useEffect, useState } from "react";
import { ApplicationUser } from "../core/users/User.types";
import { verifyIDPUser } from "./AuthService";

export interface IUserLoaderProps {
    onUserSessionLoaded: (user: ApplicationUser) => void;
    children: ReactNode;
    applicationUser: ApplicationUser | null;
}

function UserLoader(props: IUserLoaderProps) {
    const auth = useAuth();
    const [isVerifyingUser, setIsVerifyingUser] = useState(true);

    useEffect(() => {
        if (auth.userData && props.applicationUser === null) {
            verifyIDPUser().then((user) => {
                setIsVerifyingUser(false);
                props.onUserSessionLoaded(user);
            });
        }
        // eslint-disable-next-line
    }, [auth.userData?.id_token]);

    return (
        <React.Fragment>
            {auth.isLoading || isVerifyingUser || props.applicationUser === null ? (
                <Box sx={{ height: "100vh" }}>
                    <DotLegalFullScreenSpinner />
                </Box>
            ) : (
                <React.Fragment>{props.children}</React.Fragment>
            )}
        </React.Fragment>
    );
}

export default UserLoader;
