import { Case, CaseType } from "./Case.types";

// eslint-disable-next-line import/prefer-default-export
export const getDesignatedText = (caseObject: Case) => {
    if (!caseObject.isDesignated) return "";
    switch (caseObject.type) {
        case CaseType.Design:
            return ", International Design Registration";
        case CaseType.Trademark:
            return ", International Protocol (Madrid)";
        default:
            return "";
    }
};
