import { useDotLegalBreadCrumbs } from "@dotlegal/dotlegal-ui-components";
import CasePage, { CasePageProps } from "../../core/cases/CasePage";

function Trademarks(props: CasePageProps) {
    useDotLegalBreadCrumbs([{ name: "Trademarks" }]);

    return <CasePage {...props} />;
}

export default Trademarks;
