import { Grid } from "@mui/material";
import React from "react";
import useDashboardGridStyles from "./DashboardGrid.styles";
import DashboardWidget from "./DashboardWidget";
import WidgetLegend from "./DashboardWidgetLegend";
import { generateServiceUnavailableDisplay } from "./DashboardWidgetService";

export type DashboardWidgetLegend = {
    colors: string[];
    legends: string[];
};

export type DashboardWidgetGridItem = {
    content: React.ReactElement;
    footerChildren?: any;
    header: string;
    isLoading: boolean;
    legend?: DashboardWidgetLegend;
    loadingContent: React.ReactElement;
    serviceUnavailable: boolean;
};

export type DashboardGridProps = {
    widgets: (DashboardWidgetGridItem | null)[];
};

function DashboardGrid(props: DashboardGridProps) {
    const classes = useDashboardGridStyles();
    return (
        <Grid container spacing={4} rowGap={4} marginBottom={4}>
            {props.widgets
                .filter((w) => !!w)
                .map((w) => w as DashboardWidgetGridItem)
                .map((widget) => (
                    <Grid style={{ width: "100%" }} key={widget.header} item lg={12} xl={6}>
                        <DashboardWidget widgetCardHeader={widget.header}>
                            <div className={classes.widgetContainer}>
                                {widget.serviceUnavailable ? (
                                    generateServiceUnavailableDisplay()
                                ) : (
                                    <>{widget.isLoading ? widget.loadingContent : <div className={classes.widgetContentContainer}>{widget.content}</div>}</>
                                )}
                                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                    {widget.legend && (
                                        <WidgetLegend isLoading={widget.isLoading} colors={widget.legend.colors} legends={widget.legend.legends} />
                                    )}
                                    {widget.footerChildren}
                                </div>
                            </div>
                        </DashboardWidget>
                    </Grid>
                ))}
        </Grid>
    );
}

export default DashboardGrid;
