import { DotLegalFullScreenSpinner } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { SelectedOrganization } from "../core/organizations/Organization.types";
import { verifySelectedOrganization } from "../core/organizations/OrganizationService";
import { removeSelectedOrgFromLocalStorage } from "./AuthService";

export interface IChangeOrganisationProps {
    onOrgChanged: (org: SelectedOrganization) => void;
}

function ChangeOrganisation(props: IChangeOrganisationProps) {
    const { id } = useParams<{ id: string }>();
    const [verifyingOrg, setVerifyingOrg] = useState(true);

    useEffect(() => {
        removeSelectedOrgFromLocalStorage();
        verifySelectedOrganization(id)
            .then((org) => {
                if (org) {
                    props.onOrgChanged(org);
                }
                setVerifyingOrg(false);
            })
            .catch(() => {
                sessionStorage.removeItem("location-before-sign-in");
                window.location.replace("/");
            });
        // eslint-disable-next-line
    }, [id]);

    const redirectTo = new URLSearchParams(window.location.search).get("redirect");
    return <Box sx={{ flex: 1, height: "100vh" }}>{verifyingOrg ? <DotLegalFullScreenSpinner /> : <Redirect to={redirectTo ?? "/"} />}</Box>;
}

export default ChangeOrganisation;
