import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserSessionInfo } from "../../auth/AuthService";
import NavLinkMainList from "./NavLinkMainList";
import NavLinkUtilityList from "./NavLinkUtilityList";
import useNavMenuStyles from "./NavMenu.styles";
import NavMenuHeader from "./NavMenuHeader";

export interface NavMenuProps {
    onClose: () => void;
    isMinimized: boolean;
    userSession: UserSessionInfo;
    onOrgSelectorIsOpenChange: (isOpen: boolean) => void;
}

function NavMenu(props: NavMenuProps) {
    const classes = useNavMenuStyles(props);
    const history = useHistory();
    const [navIsHovered, setNavIsHovered] = useState(false);

    const showMinimizeButton = (show: boolean) => {
        if (props.isMinimized) setNavIsHovered(true);
        else setNavIsHovered(show);
    };

    useEffect(() => {
        const pathName = history.location.pathname.replace("/", "").replace("-", " ");
        if (pathName.toLocaleLowerCase() === "select organisation") props.onOrgSelectorIsOpenChange(true);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={classes.wrapper} onMouseEnter={() => showMinimizeButton(true)} onMouseLeave={() => showMinimizeButton(false)}>
            <NavMenuHeader isMinimized={props.isMinimized} navIsHovered={navIsHovered} onClose={props.onClose} />
            <NavLinkMainList isMinimized={props.isMinimized} userSession={props.userSession} location={history.location.pathname} />
            <NavLinkUtilityList
                isMinimized={props.isMinimized}
                userSession={props.userSession}
                onOrgSelectorIsOpenChange={props.onOrgSelectorIsOpenChange}
                location={history.location.pathname}
            />
        </div>
    );
}

export default NavMenu;
