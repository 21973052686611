import CloseIcon from "@mui/icons-material/Close";
import { CSSObject, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import useDotLegalModalStyles from "./DotLegalModal.styles";

export interface DotLegalModalProps {
    open: boolean;
    maxWidth?: "sm" | "xs" | "md" | false;
    onClose: () => React.MouseEventHandler<HTMLAnchorElement> | undefined | void;
    title: string | JSX.Element;
    subTitle?: string;
    content: React.ReactElement;
    action?: React.ReactElement;
    onCloseDisabled?: true;
    titleToContentPadding?: "normal" | "slim";
}

function DotLegalModal(props: DotLegalModalProps) {
    const classes = useDotLegalModalStyles();
    return (
        <Dialog
            maxWidth={props.maxWidth}
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            disableEscapeKeyDown={props.onCloseDisabled}
        >
            <DialogTitle
                id="form-dialog-title"
                sx={(theme) => ({ paddingBottom: props.titleToContentPadding === "slim" ? theme.spacing(4) : undefined } as CSSObject)}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                    }}
                >
                    {props.title}
                    {props.subTitle ? <p style={{ padding: 0, margin: 0, fontSize: 14 }}>{props.subTitle}</p> : <></>}
                </div>
                {!props.onCloseDisabled && (
                    <IconButton disableRipple aria-label="close" className={classes.modalCloseButton} onClick={props.onClose} size="large">
                        <CloseIcon />
                    </IconButton>
                )}
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>{props.content}</DialogContent>
            <DialogActions>{props.action}</DialogActions>
        </Dialog>
    );
}

export default DotLegalModal;
