import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { UserSession } from "./auth/AuthService";
import { useStateUrlParamsCaseType } from "./common/hooks/useStateUrlParams";
import { CaseType } from "./core/cases/Case.types";
import { CostAccessLevel, SelectedOrganization } from "./core/organizations/Organization.types";
import { userHasCostAccess } from "./core/organizations/OrganizationService";
import TopBar, { TopBarDropdownConfig } from "./core/topBar/TopBar";
import useMainStyles from "./Main.styles";
import Admin from "./pages/admin/Admin";
import Copyrights from "./pages/copyrights/Copyrights";
import Costs from "./pages/costs/Costs";
import Dashboard from "./pages/dashboard/Dashboard";
import Designs from "./pages/designs/Designs";
import Disputes from "./pages/disputes/Disputes";
import Organisations from "./pages/organisations/Organisations";
import PriceCalculator from "./pages/priceCalculator/PriceCalculator";
import Renewals from "./pages/renewals/Renewals";
import Surveys from "./pages/surveys/Surveys";
import { SurveyArea } from "./pages/surveys/Surveys.types";
import Trademarks from "./pages/trademarks/Trademarks";
import Users from "./pages/users/Users";

type MainProps = {
    onDrawerExpandedMobile: () => void;
    onOrgSelectorIsOpenChange: (isOpen: boolean) => void;
    onSelectedOrgCostAccessChanged: () => void;
    userSession: UserSession;
};

export type CaseSummarizationProps = {
    onCaseFilterKeyChanged: (caseFilterKeyChange: string) => void;
    onCaseTypeFilterConfigurationCleared: () => void;
    selectedCaseType: CaseType;
    orgHasBeenSelected?: boolean;
    userCostAccess?: CostAccessLevel | null;
    userSession: UserSession;
    renewalRegistrationActive: boolean;
};

const getLastPathName = (location: { pathname: string }): string => location.pathname.replace("/", "").replace(/\//g, " > ").replace(/-/g, " ");

const getAvailableCaseTypesFromOrg = (
    selectedOrg: SelectedOrganization | null,
    renewalsIsActive: boolean,
    disputesIsActive: boolean,
    priceCalculatorIsActive: boolean
): CaseType[] => {
    let caseTypes: CaseType[] = [];
    if (!selectedOrg) return caseTypes;
    if (selectedOrg.hasTrademarks) caseTypes.push(CaseType.Trademark);
    if (selectedOrg.hasDesigns) caseTypes.push(CaseType.Design);
    if (priceCalculatorIsActive) return caseTypes;
    if (disputesIsActive && !selectedOrg.hasTrademarkDisputes) caseTypes = caseTypes.filter((caseType) => caseType !== CaseType.Trademark);
    if (disputesIsActive && !selectedOrg.hasDesignDisputes) caseTypes = caseTypes.filter((caseType) => caseType !== CaseType.Design);
    if (selectedOrg.hasCopyrights && !renewalsIsActive && !disputesIsActive) caseTypes.push(CaseType.Copyright);
    return caseTypes;
};

function Main(props: MainProps) {
    const classes = useMainStyles();
    const history = useHistory();

    const [caseType, setSelectedCaseType] = useStateUrlParamsCaseType("caseType", CaseType.Trademark);
    const [caseTypeFilterKey, setCaseTypeFilterKey] = useState<string>();
    const [disputesIsActive, setDisputesIsActive] = useState(false);
    const [orgHasDisputes, setOrgHasDisputes] = useState(false);
    const [renewalsIsActive, setRenewalsIsActive] = useState(false);
    const [priceCalculatorIsActive, setPriceCalculatorIsActive] = useState(false);

    const selectedCaseType = caseType ?? CaseType.Trademark;
    let caseTypeFilterConfig: TopBarDropdownConfig<CaseType> | undefined = undefined;

    const availableCaseTypes = getAvailableCaseTypesFromOrg(props.userSession.selectedOrg, renewalsIsActive, disputesIsActive, priceCalculatorIsActive);

    const onCaseTypeFilterCleared = () => {
        setCaseTypeFilterKey(undefined);
    };

    useEffect(() => {
        toggleOrgHasDisputes(selectedCaseType);
        // eslint-disable-next-line
    }, []);

    const toggleOrgHasDisputes = (caseType: CaseType) => {
        switch (caseType) {
            case CaseType.Trademark:
                setOrgHasDisputes(!!props.userSession.selectedOrg?.hasTrademarkDisputes);
                break;
            case CaseType.Design:
                setOrgHasDisputes(!!props.userSession.selectedOrg?.hasDesignDisputes);
                break;
            case CaseType.Copyright:
                setOrgHasDisputes(false);
                break;
            default:
                setOrgHasDisputes(false);
        }
    };

    const onSelectedCaseTypeChanged = (changedCaseType: CaseType) => {
        toggleOrgHasDisputes(changedCaseType);
        setSelectedCaseType(changedCaseType);
    };

    useEffect(() => {
        history.listen((location) => {
            const locationState = location.state as { caseType: CaseType };
            if (locationState && locationState.caseType) setSelectedCaseType(locationState.caseType);
        });
        // eslint-disable-next-line
    }, []);

    if (caseTypeFilterKey) {
        caseTypeFilterConfig = {
            disabled: availableCaseTypes.length === 1 ? true : undefined,
            key: caseTypeFilterKey || "",
            onChange: (selectedValue) => onSelectedCaseTypeChanged(selectedValue || CaseType.Trademark),
            options: availableCaseTypes.map((caseType) => ({
                label: caseType,
                value: caseType,
            })),
            value: {
                label: selectedCaseType,
                value: selectedCaseType,
            },
        };
    }

    useEffect(() => {
        return history.listen((location) => {
            props.onOrgSelectorIsOpenChange(getLastPathName(location) === "select organisation");
        });
        // eslint-disable-next-line
    }, []);

    // TODO: Refactor route names into obj
    // TODO: Refactor into loop and / or component + sub component
    let dashBoard = (
        <>
            {props.userSession.selectedOrg ? (
                <Dashboard
                    onCaseTypeFilterConfigurationCleared={onCaseTypeFilterCleared}
                    onCaseFilterKeyChanged={setCaseTypeFilterKey}
                    orgHasBeenSelected={!!props.userSession.selectedOrg}
                    selectedCaseType={selectedCaseType}
                    userCostAccess={props.userSession.selectedOrg?.costAccess ?? null}
                    userSession={props.userSession}
                    renewalRegistrationActive={props.userSession.selectedOrg?.renewalRegistrationActive ?? false}
                />
            ) : (
                <Redirect to="/" />
            )}
        </>
    );
    return (
        <main className={classes.main}>
            <TopBar onDrawerExpanded={props.onDrawerExpandedMobile} /* pageTitle={pageTitle} */ caseTypeFilterConfig={caseTypeFilterConfig} />
            <div className={classes.content}>
                <Switch>
                    <Route exact path="/">
                        {dashBoard}
                    </Route>
                    <Route path="/dashboard">{dashBoard}</Route>
                    <Route path="/trademarks">{props.userSession.selectedOrg ? <Trademarks caseType={CaseType.Trademark} /> : <Redirect to="/" />}</Route>
                    <Route path="/designs">{props.userSession.selectedOrg ? <Designs caseType={CaseType.Design} /> : <Redirect to="/" />}</Route>
                    <Route path="/copyrights">{props.userSession.selectedOrg ? <Copyrights caseType={CaseType.Copyright} /> : <Redirect to="/" />}</Route>
                    <Route path="/renewals">
                        {props.userSession.selectedOrg ? (
                            <Renewals
                                onCaseTypeFilterConfigurationCleared={onCaseTypeFilterCleared}
                                onCaseFilterKeyChanged={setCaseTypeFilterKey}
                                onRenewalsEntered={setRenewalsIsActive}
                                selectedCaseType={selectedCaseType}
                                userSession={props.userSession}
                                renewalRegistrationActive={props.userSession.selectedOrg?.renewalRegistrationActive ?? false}
                            />
                        ) : (
                            <Redirect to="/" />
                        )}
                    </Route>
                    <Route path="/costs">
                        {userHasCostAccess(props.userSession.selectedOrg) && props.userSession.selectedOrg ? (
                            <Costs
                                onCaseTypeFilterConfigurationCleared={onCaseTypeFilterCleared}
                                onCaseFilterKeyChanged={setCaseTypeFilterKey}
                                orgHasDisputes={orgHasDisputes}
                                selectedCaseType={selectedCaseType}
                                userCostAccess={props.userSession.selectedOrg?.costAccess ?? null}
                                userSession={props.userSession}
                                renewalRegistrationActive={props.userSession.selectedOrg?.renewalRegistrationActive ?? false}
                            />
                        ) : (
                            <Redirect to="/" />
                        )}
                    </Route>
                    <Route path="/disputes">
                        {props.userSession.selectedOrg ? (
                            <Disputes
                                onCaseTypeFilterConfigurationCleared={onCaseTypeFilterCleared}
                                onCaseFilterKeyChanged={setCaseTypeFilterKey}
                                onDisputesEntered={setDisputesIsActive}
                                selectedCaseType={selectedCaseType}
                                userSession={props.userSession}
                                renewalRegistrationActive={props.userSession.selectedOrg?.renewalRegistrationActive ?? false}
                            />
                        ) : (
                            <Redirect to="/" />
                        )}
                    </Route>
                    <Route path="/admin" exact>
                        <Admin />
                    </Route>
                    <Route path="/admin/organisations">
                        <Organisations onSelectedOrgCostAccessChanged={props.onSelectedOrgCostAccessChanged} />
                    </Route>
                    <Route path="/admin/users">
                        <Users />
                    </Route>
                    <Route path="/price-calculator">
                        <PriceCalculator
                            onCaseTypeFilterConfigurationCleared={onCaseTypeFilterCleared}
                            onCaseFilterKeyChanged={setCaseTypeFilterKey}
                            onPriceCalculatorEntered={setPriceCalculatorIsActive}
                            selectedCaseType={selectedCaseType}
                            userSession={props.userSession}
                            renewalRegistrationActive={props.userSession.selectedOrg?.renewalRegistrationActive ?? false}
                        />
                    </Route>
                    <Route path="/admin/collection-of-prices/trademarks">
                        <Surveys type={SurveyArea.Trademarks} />
                    </Route>
                    <Route path="/admin/collection-of-prices/designs">
                        <Surveys type={SurveyArea.Designs} />
                    </Route>
                    <Route path="*">Page not found</Route>
                </Switch>
            </div>
        </main>
    );
}

export default Main;
