import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useIsOnMediumScreen } from "../../../../common/hooks/mediaHooks";
import bbLogo from "../../../../core/navMenu/BB logo.svg";
import NecessaryDocuments from "../../../priceCalculator/necessaryDocuments/NecessaryDocuments";
import SurveyCurrency from "../../survey/components/surveyCurrency/SurveyCurrency";
import SurveyHeader from "../../survey/components/surveyHeader/SurveyHeader";
import SurveyRemarks from "../../survey/components/surveyRemarks/SurveyRemarks";
import NewApplicationFees from "../../survey/designSurvey/fees/NewApplicationFees";
import RenewalFees from "../../survey/designSurvey/fees/RenewalFees";
import { useSurveyStyles } from "../../survey/Survey.styles";
import { getPageHaderText, Logo } from "../../survey/trademarkSurvey/Survey";
import { SurveyArea } from "../../Surveys.types";
import { useDesignSurveyPDFDataMapping } from "./DesignSurveyPDF.hooks";

function DesignSurveyPDF() {
    const isOnMediumScreen = useIsOnMediumScreen();
    const styles = useSurveyStyles(isOnMediumScreen);
    const { newApplicationData, renewalData, isLoading } = useDesignSurveyPDFDataMapping();

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={styles.logo}>
                <Logo alt="logo" src={bbLogo} />
            </Box>
            <Box>
                <SurveyHeader isPdf isOnMediumScreen={isOnMediumScreen} backgroundColor={"blue"} header={"Agents Survey - Design Applications"}>
                    <DotLegalHeader headerStyle="extraSmall" fontWeight={500}>
                        {getPageHaderText(1)}
                    </DotLegalHeader>
                </SurveyHeader>

                {isLoading ? (
                    <Box></Box>
                ) : (
                    <>
                        <SurveyCurrency isPdf currency={newApplicationData?.currency} />
                        <NewApplicationFees isPdf data={newApplicationData} onChange={() => {}} />

                        <NecessaryDocuments
                            isPdf
                            documents={newApplicationData?.necessaryDocuments}
                            trademarkSurveyType={newApplicationData?.type}
                            onChange={() => {}}
                            surveyArea={SurveyArea.Designs}
                        />

                        <SurveyRemarks
                            isPdf
                            isNotRenewal
                            onRemarkChange={() => {}}
                            remarks={newApplicationData?.remarks}
                            publicationOrRegistrationRemarks={newApplicationData?.publicationOrRegistrationRemarks}
                        />

                        <div style={{ pageBreakAfter: "always" }}></div>

                        <SurveyHeader isPdf isOnMediumScreen={isOnMediumScreen} backgroundColor={"green"} header={"Agents Survey - Design Renewals"}>
                            <DotLegalHeader headerStyle="extraSmall" fontWeight={500}>
                                {getPageHaderText(2)}
                            </DotLegalHeader>
                        </SurveyHeader>

                        <RenewalFees isPdf data={renewalData} onChange={() => {}} />

                        {renewalData && renewalData.renewalIsPossible && (
                            <>
                                <NecessaryDocuments
                                    isPdf
                                    documents={renewalData?.necessaryDocuments}
                                    trademarkSurveyType={renewalData?.type}
                                    onChange={() => {}}
                                    surveyArea={SurveyArea.Designs}
                                />

                                <SurveyRemarks
                                    isPdf
                                    isNotRenewal={false}
                                    onRemarkChange={() => {}}
                                    remarks={renewalData?.remarks}
                                    publicationOrRegistrationRemarks={newApplicationData?.publicationOrRegistrationRemarks}
                                />
                            </>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
}

export default DesignSurveyPDF;
