import { alpha, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useMainStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            backgroundColor: alpha(theme.palette.grey.A100, 0.5),
            flex: 1,
            minHeight: "100vh",
        },
        content: {
            padding: theme.spacing(theme.customSpacing.contentSpacing),
        },
    })
);

export default useMainStyles;
