import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, CircularProgress, Collapse, IconButton, Theme } from "@mui/material";
import { useState } from "react";
import { DesignFeesIntervals, DesignSurveyViewModel } from "../../Survey.types";
import { DesignSurveyIntervalArea } from "../intervals/interval.types";
import Intervals from "../intervals/Intervals";

export interface PeriodProps {
    data: DesignSurveyViewModel | undefined;
    surveyId: string;
    period: DesignFeesIntervals;
    onPeriodChange: (period: DesignFeesIntervals) => void;
    onCopyPeriod: () => void;
    intervalArea: DesignSurveyIntervalArea;
    errorText?: string;
    canCopyPeriod?: boolean;
    isCopying: boolean;
    isPdf?: boolean;
    disabled?: boolean;
}

export const textButtonStyle = (theme: Theme, displayAsLoading: boolean) => {
    return {
        backgroundColor: "transparent",
        borderColor: "transparent",
        color: displayAsLoading ? "lightgrey" : theme.palette.primary.main,
        padding: 0,
        fontWeight: 300,
        textDecoration: "underline",
        position: "relative",
        cursor: displayAsLoading ? "unset" : "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    };
};

function Period(props: PeriodProps) {
    const [expanded, setExpanded] = useState(true);

    function getPeriodHeader(number: number) {
        let suffix = "";

        if (number === 11 || number === 12 || number === 13) {
            suffix = "th";
        } else if (number === 1) {
            suffix = "st";
        } else if (number === 2) {
            suffix = "nd";
        } else if (number === 3) {
            suffix = "rd";
        } else {
            suffix = "th";
        }

        return `${number}${suffix} period`;
    }

    return (
        <Collapse
            in={expanded}
            collapsedSize={40}
            sx={(theme) => ({
                marginTop: 1,
                backgroundColor: "white",
                border: "1px solid",
                borderRadius: "10px",
                py: 1,
                px: props.isPdf ? 1 : 2,
                marginBottom: "16px",
                color: "inherit",
                pageBreakInside: "avoid",
                borderColor: props.errorText ? theme.palette.error.main : theme.palette.primary.dark,
                "& .MuiCollapse-wrapperInner": {
                    display: "flex",
                    flexDirection: "column",
                },
            })}
        >
            <Box height={40} sx={{ display: "flex", justifyContent: "space-between" }}>
                <DotLegalHeader headerStyle={"small"} marginBottom={0}>
                    {getPeriodHeader(props.period.number)}
                </DotLegalHeader>
                <IconButton
                    sx={(theme) => ({ marginTop: -1, marginBottom: 1, "& svg": { fill: theme.palette.primary.dark } })}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                >
                    <ExpandMoreIcon sx={{ transform: expanded ? "rotate(180deg)" : "rotate(0)" }} />
                </IconButton>
            </Box>
            <Box sx={{ marginRight: props.isPdf ? 0 : 5.5 }}>
                <Intervals
                    data={props.data}
                    period={props.period}
                    onPeriodChange={(p) => {
                        props.onPeriodChange(p);
                    }}
                    intervalArea={props.intervalArea}
                    surveyId={props.surveyId}
                    errorText={props.errorText}
                    disabled={props.isCopying || props.disabled}
                    isPdf={props.isPdf}
                />
            </Box>
            {props.canCopyPeriod && (
                <Button
                    disabled={props.disabled}
                    sx={(theme) => ({ ...textButtonStyle(theme, props.isCopying), alignSelf: "center" })}
                    onClick={() => (props.isCopying ? {} : props.onCopyPeriod())}
                >
                    Copy intervals and prices from 1st period to all periods
                    {props.isCopying && <CircularProgress sx={{ position: "absolute" }} size={18} />}
                </Button>
            )}
        </Collapse>
    );
}

export default Period;
