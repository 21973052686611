import { Box, CircularProgress, TableCell, TableRow } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { setCostAccessLevelOnSelectedOrg } from "../../auth/AuthService";
import DotLegalModal from "../../common/modal/DotLegalModal";
import DotLegalNavigationIcon from "../../common/navigationIcon/DotLegalNavigationIcon";
import DotLegalTable from "../../common/table/DotLegalTable";
import { TableHeader } from "../../common/table/DotLegalTable.types";
import { UsersResponse } from "../users/User.types";
import { getAllUsers } from "../users/UserService";
import ConfigureOrganization from "./ConfigureOrganization";
import ConfigureOrganizationPriceDisclaimer from "./ConfigureOrganizationPriceDisclaimer";
import ConfigureRenewalPrices from "./ConfigureRenewalContacts";
import ConfigureRenewalPrice from "./ConfigureRenewalPrice";
import ConfigureRenewalRegistrationActive from "./ConfigureRenewalRegistrationActive";
import { Organization, OrganizationFilter } from "./Organization.types";
import { getOrganizationsByFilter, saveOrganization } from "./OrganizationService";

type OrganizationTableProps = {
    onSelectedOrgCostAccessChanged: () => void;
    searchText: string;
};

const initialFilter: OrganizationFilter = {
    pageNumber: 1,
    pageSize: 15,
    orderBy: "name",
    orderByDescending: false,
};

function OrganizationTable(props: OrganizationTableProps) {
    const [configureOrganizationModalIsOpen, setConfigureOrganizationModalIsOpen] = useState(false);
    const [organizationFilter, setOrganizationFilter] = useState<OrganizationFilter>(initialFilter);
    const [users, setUsers] = useState<UsersResponse>({
        users: [],
        totalCount: 0,
    });
    const [organizationTable, setOrganizationTable] = useState<{
        organizations: Organization[];
        count: number;
    }>({
        organizations: [],
        count: 0,
    });
    const [organizationTableHeaders] = useState<TableHeader<any>[]>([
        { text: "Name", property: "name", align: "left", width: "300" },
        {
            text: "Costs",
            property: "cost",
            align: "left",
            width: "200",
            notSortable: true,
        },
        { text: "Renewal Price", property: "showRenewalPrices", align: "left" },
        { text: "Renewal Contact(s)", property: "renewalContacts", align: "left" },
        { text: "Renewal Status", property: "renewalRegistrationActive", align: "left" },
        { text: "Email Price Disclaimer", property: "PriceDisclaimer", align: "left" },
        { property: "openEdit", text: "", align: "left" },
    ]);
    const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
    const [waitingForServer, setWaitingForServer] = useState(false);

    const configureOrganization = (organization: Organization): void => {
        setSelectedOrganization(organization);
        setConfigureOrganizationModalIsOpen(true);
    };

    const getUserThenOrganizations = (filter: OrganizationFilter) => {
        setWaitingForServer(true);

        if (!users.totalCount) {
            getAllUsers()
                .then((usersResponse) =>
                    setUsers({
                        users: usersResponse.users,
                        totalCount: usersResponse.totalCount,
                    })
                )
                .then(() => {
                    getOrganizationByFilterPromise(filter);
                })
                .catch(() => setWaitingForServer(false));
        } else {
            getOrganizationByFilterPromise(filter).catch(() => setWaitingForServer(false));
        }
    };

    const getOrganizationByFilterPromise = (filter: OrganizationFilter) => {
        return getOrganizationsByFilter(filter).then((response) => {
            setWaitingForServer(false);
            setOrganizationTable({
                organizations: response.organizations,
                count: response.totalCount,
            });
        });
    };

    const saveConfiguredOrganization = (): void => {
        if (!selectedOrganization || !selectedOrganization.costAccess) return;
        setWaitingForServer(true);
        saveOrganization(selectedOrganization)
            .then(() => {
                setWaitingForServer(false);
                setConfigureOrganizationModalIsOpen(false);
                setCostAccessLevelOnSelectedOrg(selectedOrganization, props.onSelectedOrgCostAccessChanged);
                getUserThenOrganizations(organizationFilter);
            })
            .catch(() => setWaitingForServer(false));
    };

    const onConfigureOrganizationModalClosed = (): void => {
        setConfigureOrganizationModalIsOpen(false);
    };

    const onTableArrangementChanged = (pageNumber: number, orderByDescending: boolean, orderBy: any): void => {
        organizationFilter.pageNumber = pageNumber;
        organizationFilter.orderByDescending = orderByDescending;
        organizationFilter.orderBy = orderBy;
        setOrganizationFilter({ ...organizationFilter });
        getUserThenOrganizations(organizationFilter);
    };

    useEffect(() => {
        const newFilter = { ...organizationFilter };
        newFilter.searchText = props.searchText;
        setOrganizationFilter(newFilter);
        getUserThenOrganizations(newFilter);
        // eslint-disable-next-line
    }, [props.searchText]);

    const priceDisclaimerMap = (): Map<number, string> => {
        const map = new Map<number, string>();
        map.set(0, "Bech-Bruun fee included");
        map.set(1, "Bech-Bruun fee excluded");
        map.set(2, "No disclaimer");
        return map;
    };

    const renewalPriceAccessMap = (): Map<number, string> => {
        const map = new Map<number, string>();
        map.set(0, "Hidden");
        map.set(1, "Employee");
        map.set(2, "Employee and client");
        return map;
    };

    return (
        <>
            <DotLegalTable
                headers={organizationTableHeaders}
                rows={organizationTable.organizations}
                defaultOrderBy="name"
                onTableArrangementChanged={onTableArrangementChanged}
                totalRowCount={organizationTable.count}
                renderRow={(r) => (
                    <TableRow onClick={() => configureOrganization(r)}>
                        <TableCell style={{ paddingLeft: 6 }}>{r.name}</TableCell>
                        <TableCell>{r.costAccess}</TableCell>
                        <TableCell>{renewalPriceAccessMap().get(r.showRenewalPrice)}</TableCell>
                        <TableCell>
                            {users.users
                                .filter((x) => r.renewalContacts.some((y) => y.userId === x.id))
                                .map((u) => u.name)
                                .join(", ")}
                        </TableCell>
                        <TableCell>{r.renewalRegistrationActive ? "Yes" : "No"}</TableCell>
                        <TableCell>{priceDisclaimerMap().get(r.priceDisclaimer)}</TableCell>
                        <TableCell align="right">
                            <DotLegalNavigationIcon />
                        </TableCell>
                    </TableRow>
                )}
            />
            <DotLegalModal
                action={
                    <Button color="secondary" variant={"outlined"} onClick={saveConfiguredOrganization}>
                        Save
                    </Button>
                }
                content={
                    selectedOrganization ? (
                        <Box sx={{ paddingTop: "10px" }}>
                            <ConfigureOrganization onOrganizationChanged={setSelectedOrganization} organization={selectedOrganization} />
                            <Box sx={{ marginTop: "15px" }}>
                                <ConfigureRenewalPrice
                                    onOrganizationChanged={setSelectedOrganization}
                                    organization={selectedOrganization}
                                    renewalPriceAccessMap={renewalPriceAccessMap()}
                                />
                            </Box>
                            <Box sx={{ marginTop: "15px" }}>
                                <ConfigureRenewalRegistrationActive onOrganizationChanged={setSelectedOrganization} organization={selectedOrganization} />
                            </Box>
                            <Box sx={{ marginTop: "15px" }}>
                                <ConfigureRenewalPrices
                                    onOrganizationChanged={setSelectedOrganization}
                                    organization={selectedOrganization}
                                    users={users.users.filter((y) => y.organizations.some((o) => o.id === selectedOrganization.id))}
                                />
                            </Box>
                            <Box sx={{ marginTop: "15px" }}>
                                <ConfigureOrganizationPriceDisclaimer
                                    organization={selectedOrganization}
                                    onOrganizationChanged={setSelectedOrganization}
                                    disclaimerNameMap={priceDisclaimerMap()}
                                />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "15px" }}>
                                {waitingForServer && <CircularProgress />}
                            </Box>
                        </Box>
                    ) : (
                        <div>Something went wrong</div>
                    )
                }
                onClose={onConfigureOrganizationModalClosed}
                open={configureOrganizationModalIsOpen}
                title="Settings"
                subTitle={selectedOrganization?.name}
            />
        </>
    );
}

export default OrganizationTable;
