import { Theme, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { createSxStyles } from "../../common/createSxStyles";

const useTopBarStyles = makeStyles((theme: Theme) =>
    createStyles({
        topBar: {
            "& h1": {
                fontSize: theme.typography.pxToRem(24),
            },
            backgroundColor: theme.customPalette.white,
            boxShadow: "0px -2px 10px #00000029",
            height: 55,
            paddingLeft: theme.spacing(theme.customSpacing.contentSpacing),
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        drawerIcon: {
            marginRight: theme.spacing(2),
        },
        leftSide: {
            display: "flex",
            alignItems: "center",
        },
    })
);

export default useTopBarStyles;

export const useDotLegalBreadCrumbStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        root: {
            "& > * + *": {
                marginTop: 2,
            },
        },
        crumbStyling: {
            ...getBackgroundImage(theme),
            "&:hover": {
                textDecoration: "underline",
            },
        },
        leadCrumb: {
            ...getBackgroundImage(theme),
            color: theme.palette.primary.dark,
        },
        nextIcon: {
            fill: theme.palette.primary.dark,
        },
    });
};

function getBackgroundImage(theme: Theme) {
    return {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey.A400,
        fontSize: theme.typography.pxToRem(16),
        textDecoration: "none",
    };
}
