import { DotLegalRadioButton } from "@dotlegal/dotlegal-ui-components";
import { Box, RadioGroup, Typography } from "@mui/material";

export interface YesNoBoxProps {
    value: boolean | undefined;
    onValueChange: (value: boolean) => void;
    errorText?: string;
}

function YesNoBox(props: YesNoBoxProps) {
    return (
        <>
            <Box
                sx={{
                    "& .MuiFormControlLabel-root": {
                        height: "28px",
                    },
                }}
            >
                <RadioGroup
                    value={props.value?.toNumber() ?? ""}
                    onChange={(e) => {
                        props.onValueChange(Number((e.target as HTMLInputElement).value) === 1 ? true : false);
                    }}
                >
                    <DotLegalRadioButton value={1} label="Yes"></DotLegalRadioButton>
                    <DotLegalRadioButton value={0} label="No"></DotLegalRadioButton>
                </RadioGroup>
            </Box>
            <Typography sx={(theme) => ({ fontSize: 12, color: theme.customPalette.errorRed, marginTop: 0.5 })}>{props.errorText}</Typography>
        </>
    );
}

export default YesNoBox;
