import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const useDotLegalSearchStyles = makeStyles((theme: Theme) =>
    createStyles({
        dotLegalSearch: {
            width: 200,
        },
    })
);

export default useDotLegalSearchStyles;
