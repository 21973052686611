import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { WorldMapCaseStatusFilterProps } from "./WorldMapCaseStatusFilter";
import { getCaseStatusBackgroundColor, getCaseStatusColor } from "./WorldMapService";

const useWorldMapCaseStatusFilterStyles = makeStyles((theme: Theme) =>
    createStyles({
        caseStatusFilter: (props: WorldMapCaseStatusFilterProps) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 185,
            width: 133,
            borderRadius: 10,
            borderWidth: 4,
            backgroundColor: `${getCaseStatusBackgroundColor(props.caseStatus)}`,
            fontSize: theme.spacing(2),
            cursor: "pointer",
            "&.active": {
                borderStyle: "solid",
                borderColor: `${getCaseStatusColor(props.caseStatus)}`,
            },
            "&:hover": {
                borderStyle: "solid",
                borderColor: `${getCaseStatusColor(props.caseStatus)}`,
            },
            div: {
                flex: 1,
                width: "100%",
            },
        }),
        caseCount: {
            fontSize: theme.spacing(4),
            fontWeight: "bold",
            margin: `${theme.spacing(1.5)} 0`,
        },
        icon: (props: WorldMapCaseStatusFilterProps) => ({
            height: 45,
            width: 45,
            background: `${getCaseStatusColor(props.caseStatus)} 0% 0% no-repeat padding-box`,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
        }),
    })
);

export default useWorldMapCaseStatusFilterStyles;
