import { Box } from "@mui/material";
import { useState } from "react";
import DotLegalAutocomplete, { AutocompleteOption } from "../../common/autocomplete/DotLegalAutocomplete";
import { Organization } from "./Organization.types";

export interface IConfigureRenewalRegistrationActive {
    organization: Organization;
    onOrganizationChanged: (organization: Organization) => void;
}

export default function ConfigureRenewalRegistrationActive(props: IConfigureRenewalRegistrationActive) {
    const [OrganizationRenewalOptions] = useState<AutocompleteOption<boolean>[]>([
        { label: "No", value: false },
        { label: "Yes", value: true },
    ]);
    const [selectedRenewalPrice, setSelectedRenewalPrice] = useState<AutocompleteOption<boolean>>(
        OrganizationRenewalOptions.find((option) => option.value === props.organization.renewalRegistrationActive)!
    );

    const onRenewalRegistrationActiveChange = (newValue: AutocompleteOption<boolean>[]) => {
        setSelectedRenewalPrice(newValue[0] ?? { label: "No", value: false });
        props.onOrganizationChanged({
            ...props.organization,
            renewalRegistrationActive: newValue[0]?.value ?? false,
        });
    };

    return (
        <Box sx={(theme) => ({ display: "flex", width: "100%", flexDirection: "column", gap: theme.spacing(5.5), alignItems: "center" })}>
            <Box id="singleSelectDropdown" sx={{ width: 270 }}>
                <DotLegalAutocomplete
                    onChange={onRenewalRegistrationActiveChange}
                    label="Renewal status"
                    options={OrganizationRenewalOptions}
                    singleSelect
                    // @ts-ignore
                    value={selectedRenewalPrice}
                    width={"100%"}
                />
            </Box>
        </Box>
    );
}
