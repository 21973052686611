import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { NavMenuHeaderProps } from "./NavMenuHeader";

const useNavMenuLogoStyles = makeStyles((theme: Theme) =>
    createStyles({
        backButton: (props: NavMenuHeaderProps) => ({
            width: 25,
            height: 25,
            backgroundColor: theme.palette.grey.A100,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            "&:hover": {
                cursor: "pointer",
                borderColor: theme.customPalette.darkBlue,
                borderWidth: 1,
                borderStyle: "solid",
            },
            "& img": {
                width: 7,
            },
            position: props.isMinimized ? "absolute" : undefined,
            right: props.isMinimized ? "-12px" : undefined,
        }),

        logo: {
            height: 41,
            cursor: "pointer",
        },
        logoWrapper: (props: NavMenuHeaderProps) => ({
            marginBottom: theme.spacing(3.5),
            display: "flex",
            justifyContent: props.isMinimized ? "center" : "space-between",
            alignItems: "center",
            height: 41,
            paddingLeft: props.isMinimized ? theme.spacing(0) : theme.spacing(1),
        }),
        size: {
            width: 240 * 0.6,
        },
        sizeIsMinimized: {
            width: 33,
        },
    })
);

export default useNavMenuLogoStyles;
