import { Box, InputAdornment, TableCell, TextField, Tooltip } from "@mui/material";
import React, { Fragment, useCallback, useState } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { useDebounceFunction } from "../../common/hooks/useDebounceFunction";
import DotLegalTableCellWithImageTooltip from "../../common/table/DotLegalTableCellWithImageTooltip";
import copyrightsIcon from "../icons/Copyrights.svg";
import designsIcon from "../icons/Designs.svg";
import trademarksIcon from "../icons/Trademarks.svg";
import { getDesignatedText } from "./Case.functions";
import { Case, CaseType, CaseWithTooltip } from "./Case.types";
import { formatDate, getDevice } from "./CaseService";
import { CaseSummarization } from "./CaseSummarizationPage";
import renewalRemarkIcon from "./renewalRemark.svg";
import RenewalStatusWidget, { CaseWithMandatoryRenewalStatus } from "./RenewalStatusWidget/RenewalStatusWidget";
import useCaseTableRowStyles from "./UseCaseTableRowStyles";

const generateCaseImageIcon = (caseType: CaseType): React.ReactElement => {
    switch (caseType) {
        case CaseType.Trademark:
            return <img alt="Trademark" src={trademarksIcon} />;
        case CaseType.Copyright:
            return <img alt="Copyright" src={copyrightsIcon} />;
        case CaseType.Design:
            return <img alt="Design" src={designsIcon} />;
        default:
            return <></>;
    }
};

export const applicantTableCellWidth = 200;
export const defaultTableCellWidth = 100;
export const dateTableCellWidth = 160;
export const noTableCellWidth = 150;
const renewalStatusWidth = 100;
const renewalRemarkWidth = 120;

const DashboardTableCells = (props: CaseTableRowProps): React.ReactElement =>
    props.caseType === CaseType.Copyright ? (
        <>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} style={{ paddingLeft: 6 }}>
                {props.case.name}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={defaultTableCellWidth}>
                {props.case.image && (
                    <DotLegalTableCellWithImageTooltip hoveredCellId={props.case.id} onHover={() => getDevice(props.case.image!, props.case.id)}>
                        {generateCaseImageIcon(props.caseType)}
                    </DotLegalTableCellWithImageTooltip>
                )}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })}>{`${props.case.country}${getDesignatedText(
                props.case
            )}`}</TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={dateTableCellWidth}>
                {props.case.renewalDate && formatDate(props.case.renewalDate)}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={noTableCellWidth}>
                {props.case.matterId}
            </TableCell>
        </>
    ) : (
        <>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} style={{ paddingLeft: 6 }}>
                {props.case.name}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={defaultTableCellWidth}>
                {props.case.image && (
                    <DotLegalTableCellWithImageTooltip hoveredCellId={props.case.id} onHover={() => getDevice(props.case.image!, props.case.id)}>
                        {generateCaseImageIcon(props.caseType)}
                    </DotLegalTableCellWithImageTooltip>
                )}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })}>{`${props.case.country}${getDesignatedText(
                props.case
            )}`}</TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={dateTableCellWidth}>
                {props.case.renewalDate && formatDate(props.case.renewalDate)}
            </TableCell>
            {props.renewalRegistrationActive && (
                <TableCell width={noTableCellWidth}>
                    <RenewalStatusWidget
                        editSetRenewalStatus={props.editSetRenewalStatus}
                        onUpdatedCase={props.onCaseUpdated}
                        selectedCase={props.case as CaseWithMandatoryRenewalStatus}
                    />
                </TableCell>
            )}
        </>
    );

const DefaultTableCells = (props: CaseTableRowProps): React.ReactElement => {
    const classes = useCaseTableRowStyles();
    const [caseIsCopyright] = useState(props.caseType === CaseType.Copyright);
    const [formattedClasses] = useState(props.case.classes?.map((classTooltip) => classTooltip.value).join(", "));
    const [formattedClassTooltipTitle] = useState(classesTooltipTitle(props.case.classes));

    return (
        <>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} style={{ paddingLeft: 6 }}>
                {props.case.name}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={defaultTableCellWidth}>
                {props.case.image && (
                    <DotLegalTableCellWithImageTooltip hoveredCellId={props.case.id} onHover={() => getDevice(props.case.image!, props.case.id)}>
                        {generateCaseImageIcon(props.caseType)}
                    </DotLegalTableCellWithImageTooltip>
                )}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={defaultTableCellWidth}>
                {props.case.caseNo}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={defaultTableCellWidth}>
                {props.case.matterId}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={noTableCellWidth}>
                {props.case.registrationNo}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })}>{`${props.case.country}${getDesignatedText(
                props.case
            )}`}</TableCell>
            {!caseIsCopyright && (
                <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })}>
                    <Tooltip classes={{ tooltip: classes.tooltip }} title={formattedClassTooltipTitle} placement="right">
                        <div style={{ float: "left" }}>{formattedClasses}</div>
                    </Tooltip>
                </TableCell>
            )}
        </>
    );
};

const DisputeTableCells = (props: CaseTableRowProps): React.ReactElement => {
    const classes = useCaseTableRowStyles();

    const [formattedClasses] = useState(props.case.classes?.map((classTooltip) => classTooltip.value).join(", "));
    const [formattedClassTooltipTitle] = useState(classesTooltipTitle(props.case.classes));

    return props.caseSummarization === CaseSummarization.Disputes ? (
        <>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} style={{ paddingLeft: 6 }}>
                {props.case.name}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={defaultTableCellWidth}>
                {props.case.image && (
                    <DotLegalTableCellWithImageTooltip hoveredCellId={props.case.id} onHover={() => getDevice(props.case.image!, props.case.id)}>
                        {generateCaseImageIcon(props.caseType)}
                    </DotLegalTableCellWithImageTooltip>
                )}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={defaultTableCellWidth}>
                {props.case.type}
            </TableCell>
            <TableCell
                width={200}
                onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })}
                style={{
                    color: props.case.dispute === "Client" ? "#F7447A" : "inherit",
                }}
            >
                {props.case.dispute}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={noTableCellWidth}>
                {props.case.matterId}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })}>
                {`${props.case.country}${getDesignatedText(props.case)}`}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })}>
                <Tooltip classes={{ tooltip: classes.tooltip }} title={formattedClassTooltipTitle} placement="right">
                    <div style={{ float: "left" }}>{formattedClasses}</div>
                </Tooltip>
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })}>{props.case.disputeDecision}</TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={noTableCellWidth}>
                {props.case.applicationNo}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={noTableCellWidth}>
                {formatDate(props.case.dateOfOrder)}
            </TableCell>
            <TableCell onClick={() => props.onCaseSelected({ caseId: props.case.id, country: props.case.country })} width={noTableCellWidth}>
                {props.case.counterParty}
            </TableCell>
        </>
    ) : (
        <></>
    );
};
const IsValidDecimal = (value: string) => {
    const re = new RegExp(/^\d*\.?\d*$/);
    return re.test(value) || value === "";
};

const handleInput = (value: string) => {
    if (value === "" || value === undefined) return null;

    if (value.includes(",")) return parseFloat(value.replace(",", ".")).toFixed(2);

    return parseFloat(value).toFixed(2);
};

const getDecimalValue = (price: number | null) => {
    if (price === undefined || price === null) {
        return "";
    } else if (price!.toString().includes(".")) {
        return price!.toString().split(".")[0];
    }

    return price?.toString() ?? "";
};

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator=","
            decimalSeparator={"."}
            allowNegative={false}
            decimalScale={0}
            fixedDecimalScale
            valueIsNumericString
        />
    );
});

const RenewalTableCells = (props: CaseTableRowProps): React.ReactElement => {
    const [text, setText] = useState<string | undefined>(getDecimalValue(props.case?.price));
    const handlePriceChange = useCallback(
        (e: any) => {
            if (IsValidDecimal(e.target.value.toString())) {
                setText(e.target.value);
                props!.onRenewalPriceChange!({
                    internalId: props.case!.internalId,
                    price: handleInput(e.target.value),
                    remark: props.case!.remark ?? "",
                    currencyCode: props.case!.currencyCode ?? "DKK",
                });
            }
        },
        [props]
    );
    const debouncedHandlePriceChange = useDebounceFunction(handlePriceChange, 500);

    return props.caseSummarization === CaseSummarization.Renewals ? (
        <>
            {props.showRenewalPrice && (
                <TableCell sx={{ "& .MuiInputBase-root": { paddingLeft: "0px" } }} width={noTableCellWidth}>
                    {
                        <Fragment>
                            <TextField
                                id={props.case!.internalId}
                                variant="standard"
                                sx={{
                                    width: "90%",
                                    "& fieldset": { border: "none" },
                                    font: "#002677",
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#002677 !important",
                                    },
                                    "& .MuiInputAdornment-root": {
                                        "& .MuiTypography-root": {
                                            color: "#032677",
                                            fontSize: "0.75rem",
                                        },
                                    },
                                    "& .MuiInputBase-input": {
                                        fontSize: "0.75rem",
                                        textAlign: "right",
                                        marginRight: "5px",
                                    },
                                    "& .MuiInput-underline": {
                                        ":after": {
                                            borderBottomColor: "#032677",
                                        },
                                        ":focus": {
                                            borderBottomColor: "#032677",
                                        },
                                        ":before": {
                                            border: "none",
                                        },
                                    },
                                }}
                                disabled={!props.editRenewalPrice}
                                onChange={debouncedHandlePriceChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment sx={{ color: "#002677" }} position="start">
                                            {text ? props.case?.currencyCode ?? "DKK" : ""}
                                        </InputAdornment>
                                    ),
                                    inputComponent: NumericFormatCustom as any,
                                    disableUnderline: !props.editRenewalPrice,
                                }}
                                inputProps={{
                                    maxLength: 13,
                                }}
                                value={text}
                            />
                        </Fragment>
                    }
                </TableCell>
            )}
            <TableCell
                onClick={(event) => {
                    props.onCaseSelected({ caseId: props.case.id, country: props.case.country });
                    event.stopPropagation();
                }}
                width={applicantTableCellWidth}
            >
                {props.case.applicant}
            </TableCell>
            <TableCell
                onClick={(event) => {
                    props.onCaseSelected({ caseId: props.case.id, country: props.case.country });
                    event.stopPropagation();
                }}
                width={dateTableCellWidth}
            >
                {formatDate(props.case.renewalDate)}
            </TableCell>
            {props.renewalRegistrationActive && (
                <React.Fragment>
                    <TableCell
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        width={renewalStatusWidth}
                    >
                        <RenewalStatusWidget
                            editSetRenewalStatus={props.editSetRenewalStatus}
                            onUpdatedCase={props.onCaseUpdated}
                            selectedCase={props.case as CaseWithMandatoryRenewalStatus}
                        />
                    </TableCell>
                    <TableCell
                        onClick={(event) => {
                            props.onRenewalRemarkSelected(props.case.internalId);
                            event.stopPropagation();
                        }}
                        width={renewalRemarkWidth}
                    >
                        {props.case.renewalRemark ? (
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <Tooltip title={`${props.case.renewalRemark}`}>
                                    <img src={renewalRemarkIcon} alt={"renewalRemarkIcon"} />
                                </Tooltip>
                            </Box>
                        ) : (
                            ""
                        )}
                    </TableCell>
                </React.Fragment>
            )}
        </>
    ) : (
        <></>
    );
};

const classesTooltipTitle = (caseClasses?: CaseWithTooltip<string>[]): React.ReactChild => {
    const tooltipTitle = caseClasses?.map((classTooltip) => classTooltip.tooltip).join("");
    return tooltipTitle && tooltipTitle !== "" ? <div>{tooltipTitle}</div> : "";
};

export type CaseTableRowProps = {
    case: Case;
    onCaseSelected: (caseSelection: { caseId: number; country: string }) => void;
    onRenewalRemarkSelected: (internalId: string) => void;
    onRenewalPriceChange?: (selectedCase: { internalId: string; price: string | null; remark: string; currencyCode: string }) => void;
    onCaseUpdated?: (updatedCase: Case) => void;
    caseType: CaseType;
    caseSummarization?: CaseSummarization;
    showRenewalPrice?: boolean;
    editRenewalPrice?: boolean;
    editSetRenewalStatus: boolean;
    renewalRegistrationActive: boolean;
};

function CaseTableRow(props: CaseTableRowProps) {
    switch (props.caseSummarization) {
        case CaseSummarization.Dashboard:
            return <DashboardTableCells {...props} />;
        case CaseSummarization.Disputes:
            return <DisputeTableCells {...props} />;
        default:
            return (
                <>
                    <DefaultTableCells {...props} />
                    {!props.caseSummarization && (
                        <>
                            <TableCell
                                onClick={(event) => {
                                    props.onCaseSelected({ caseId: props.case.id, country: props.case.country });
                                    event.stopPropagation();
                                }}
                                width={defaultTableCellWidth}
                            >
                                {props.case.status}
                            </TableCell>
                            <TableCell
                                onClick={(event) => {
                                    props.onCaseSelected({ caseId: props.case.id, country: props.case.country });
                                    event.stopPropagation();
                                }}
                                width={applicantTableCellWidth}
                            >
                                {props.case.applicant}
                            </TableCell>
                            {props.caseType === CaseType.Trademark && <TableCell width={dateTableCellWidth}>{formatDate(props.case.useDate)}</TableCell>}
                            <TableCell
                                onClick={(event) => {
                                    props.onCaseSelected({ caseId: props.case.id, country: props.case.country });
                                    event.stopPropagation();
                                }}
                                width={dateTableCellWidth}
                            >
                                {formatDate(props.case.applicationDate)}
                            </TableCell>
                        </>
                    )}
                    <RenewalTableCells {...props} />
                </>
            );
    }
}

export default CaseTableRow;
