import { useEffect, useState } from "react";
import { HttpRequestError } from "../../auth/HttpInterceptor";
import { CaseType } from "../cases/Case.types";
import BarChart from "../charts/BarChart";
import BarChartSkeleton from "../dashboard/BarChartSkeleton";
import DashboardWidget from "../dashboard/DashboardWidget";
import WidgetLegend from "../dashboard/DashboardWidgetLegend";
import { generateNoDataDisplay, generateServiceUnavailableDisplay } from "../dashboard/DashboardWidgetService";
import { YearlyCosts } from "./Cost.types";
import useCostsStyles from "./Costs.styles";
import { costsIndexBy, getYearlyCostChartKeys, getYearlyCosts, getYearlyCostYDigits } from "./CostService";

const generateCostChart = (yearlyCosts: YearlyCosts[]) =>
    yearlyCosts.length === 0 ? (
        generateNoDataDisplay()
    ) : (
        <BarChart
            colors="#002677"
            data={yearlyCosts}
            digitsForLeftMargin={getYearlyCostYDigits(yearlyCosts)}
            indexBy={costsIndexBy}
            keys={getYearlyCostChartKeys(yearlyCosts)}
            unit="kr."
            xAxisTitle="Years"
            yAxisTitle="Amount in DKK"
        />
    );

type CostGraphProps = {
    costSelection: { selectedCaseType: CaseType; disputeIsToggled: boolean };
    searchText: string;
};

function CostChart(props: CostGraphProps) {
    const classes = useCostsStyles();
    const [costHasBeenLoadedOnce, setCostHasBeenLoadedOnce] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [costServiceIsAvailable, setCostServiceIsAvailable] = useState(true);
    const [yearlyCosts, setYearlyCosts] = useState<YearlyCosts[]>([]);

    const getCostsByYear = (setLoadingState = false) => {
        if (setLoadingState) setIsLoading(true);
        getYearlyCosts(props.costSelection.selectedCaseType, props.costSelection.disputeIsToggled, props.searchText)
            .then((yearlyCostsResponse) => {
                setYearlyCosts(yearlyCostsResponse);
                setIsLoading(false);
            })
            .catch((e: HttpRequestError) => {
                if (e.statusCode >= 500) setCostServiceIsAvailable(false);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getCostsByYear(!costHasBeenLoadedOnce);
        setCostHasBeenLoadedOnce(false);
        // eslint-disable-next-line
    }, [props.costSelection, props.searchText]);

    return (
        <DashboardWidget widgetCardHeader="" widgetCardShadow={false}>
            <div className={classes.widgetContainer}>
                <div className={classes.widgetContentContainer}>
                    {costServiceIsAvailable ? <>{isLoading ? <BarChartSkeleton /> : generateCostChart(yearlyCosts)}</> : generateServiceUnavailableDisplay()}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                        width: "100%",
                    }}
                >
                    <WidgetLegend
                        isLoading={isLoading}
                        colors={["#002677"]}
                        legends={isLoading || yearlyCosts.length > 0 ? [`Total ${props.costSelection.selectedCaseType.toLocaleLowerCase()} cost`] : []}
                    />
                    {!isLoading && yearlyCosts.length > 0 && (
                        <>
                            <div style={{ color: "#707070", fontSize: "12px" }}>
                                *The costs are not inclusive of VAT and do not cover costs for authorities and liaisons. The costs correspond to the invoiced
                                amount and may, consequently, include amounts not yet paid.
                            </div>
                            {props.costSelection.disputeIsToggled && (
                                <div style={{ color: "#707070", fontSize: "12px" }}>
                                    *Be aware that the disputes are created from 2022 and forward. Disputes before this date will not be shown
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </DashboardWidget>
    );
}

export default CostChart;
