import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useIsOnMediumScreen } from "../../../common/hooks/mediaHooks";
import bbLogo from "../../../core/navMenu/BB logo.svg";
import NecessaryDocuments from "../../priceCalculator/necessaryDocuments/NecessaryDocuments";
import FeesSection from "../survey/components/feesSection/FeesSection";
import SurveyCurrency from "../survey/components/surveyCurrency/SurveyCurrency";
import SurveyHeader from "../survey/components/surveyHeader/SurveyHeader";
import SurveyRemarks from "../survey/components/surveyRemarks/SurveyRemarks";
import { useSurveyStyles } from "../survey/Survey.styles";
import { getPageHaderText, Logo } from "../survey/trademarkSurvey/Survey";
import { SurveyArea } from "../Surveys.types";
import { useSurveyPDFDataMapping } from "./SurveyPDF.hooks";

function SurveyPDF() {
    const isOnMediumScreen = useIsOnMediumScreen();
    const styles = useSurveyStyles(isOnMediumScreen);
    const { newApplicationData, renewalData } = useSurveyPDFDataMapping();

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={styles.logo}>
                <Logo alt="logo" src={bbLogo} />
            </Box>
            <Box>
                <SurveyHeader isPdf isOnMediumScreen={isOnMediumScreen} backgroundColor={"green"} header={"Agents Survey - Trademark Applications"}>
                    <DotLegalHeader headerStyle="extraSmall" fontWeight={500}>
                        {getPageHaderText(1)}
                    </DotLegalHeader>
                </SurveyHeader>
                <SurveyCurrency isPdf currency={newApplicationData?.currency} />

                <FeesSection isPdf data={newApplicationData} onChange={() => {}} />

                <NecessaryDocuments
                    isPdf
                    documents={newApplicationData?.necessaryDocuments}
                    trademarkSurveyType={newApplicationData?.type}
                    onChange={() => {}}
                    surveyArea={SurveyArea.Trademarks}
                />

                <SurveyRemarks
                    isPdf
                    isNotRenewal
                    onRemarkChange={() => {}}
                    remarks={newApplicationData?.remarks}
                    publicationOrRegistrationRemarks={newApplicationData?.publicationOrRegistrationRemarks}
                />

                <div style={{ pageBreakAfter: "always" }}></div>

                <SurveyHeader isPdf isOnMediumScreen={isOnMediumScreen} backgroundColor={"blue"} header={"Agents Survey - Trademark Renewals"}>
                    <DotLegalHeader headerStyle="extraSmall" fontWeight={500}>
                        {getPageHaderText(2)}
                    </DotLegalHeader>
                </SurveyHeader>

                <SurveyCurrency isPdf currency={renewalData?.currency} />

                <FeesSection isPdf data={renewalData} onChange={() => {}} />

                <NecessaryDocuments
                    isPdf
                    documents={renewalData?.necessaryDocuments}
                    trademarkSurveyType={renewalData?.type}
                    onChange={() => {}}
                    surveyArea={SurveyArea.Trademarks}
                />

                <SurveyRemarks
                    isPdf
                    isNotRenewal={false}
                    onRemarkChange={() => {}}
                    remarks={newApplicationData?.remarks}
                    publicationOrRegistrationRemarks={newApplicationData?.publicationOrRegistrationRemarks}
                />
            </Box>
        </Box>
    );
}

export default SurveyPDF;
