import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { DotLegalTableProps } from "./DotLegalTable";

const useDotLegalTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        dotLegalTable: (props: DotLegalTableProps<any>) => ({
            "& th": {
                borderBottom: "none",
                ...theme.typography.h5,
                padding: `${theme.spacing(1)} 0`,
            },
            "& tbody tr:hover": {
                backgroundColor: theme.customPalette.lightBlue,
                cursor: "pointer",
            },
            "& tr": {
                height: 30,
            },
            "& td": {},
        }),
        alignCenter: {
            textAlign: "center",
        },
    })
);

export default useDotLegalTableStyles;
