import { useQueryClient } from "react-query";
import { ajaxQueue } from "../api/ajaxQuery";
import { Result } from "../api/result";

export function useOptimisticUpdate() {
    const queryClient = useQueryClient();

    async function updateQueryData<T>(queryKey: string, object: T, updateCallback: (object: T) => void): Promise<T> {
        const o = { ...object };
        updateCallback(o);
        await setQueryData(queryKey, o);
        return o;
    }

    async function setQueryData<T>(queryKey: string, data: T) {
        /* Sørger for at cancel refetch queries ved window focus. Queries der hentes første gang (isLoading) cancles ikke */
        await queryClient.cancelQueries({
            predicate: (q) => {
                return q.state.status === "success";
            },
        });

        queryClient.setQueryData(queryKey, data);
    }

    async function addToQueue<TVariables, TResponse>(req: (vars: TVariables) => Promise<Result<TResponse | unknown>>, vars: TVariables, queryKey?: string) {
        const queue = ajaxQueue(queryKey);
        const response = await queue.addRequest(req, vars);
        if (!queue.isItemsOnQueue()) {
            if (!!response && queryKey) {
                queryClient.setQueryData(queryKey, response.value());
            }
        }
    }

    function isQueueEmpty(queryKey?: string) {
        const queue = ajaxQueue(queryKey);

        return !queue.isItemsOnQueue();
    }

    return {
        putOnQueueAndSetQueryData: async function update<TData, TVariables>(
            tasks: TData,
            queryKey: string,
            req: (vars: TVariables) => Promise<Result<unknown>>,
            vars: TVariables
        ) {
            await setQueryData(queryKey, tasks);
            await addToQueue(req, vars, queryKey);
        },
        setQueryData: setQueryData,
        updateQueryData,
        isQueueEmpty,
    };
}
