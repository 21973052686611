import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { get, put } from "../../../../common/api/apiShared";
import { Result } from "../../../../common/api/result";
import { useOptimisticUpdate } from "../../../../common/hooks/useOptimisticUpdate";
import { useStateUrlParamsNumber } from "../../../../common/hooks/useStateUrlParams";
import { useValidator } from "../../../../common/hooks/validation";
import { SurveyType } from "../../../priceCalculator/PriceCalculator.types";
import { DesignInterval, DesignSurveyViewModel } from "../Survey.types";

export const fillingFeeError = "There was an error in your intervals or you may have an empty fee";
export function useDesignSurvey() {
    const snackbar = useDotLegalSnackbar();

    const { newaapplicationid } = useParams<{ newaapplicationid: string }>();
    const { renewalid } = useParams<{ renewalid: string }>();
    const [isSaving, setIsSaving] = useState(false);

    const [page, setPage] = useStateUrlParamsNumber("pageNo", 1);
    const { putOnQueueAndSetQueryData, isQueueEmpty } = useOptimisticUpdate();
    const history = useHistory();

    const key = `${page === 1 ? newaapplicationid : renewalid}`;
    const { isLoading, data } = useQuery(key, () => get<DesignSurveyViewModel>(`/api/survey/design/${key}`));

    if (data) {
        if (data.isSubmitted) {
            history.push(`/survey-submitted/${newaapplicationid}/${renewalid}`);
        }
    }

    const updateMutation = useMutation(updateModel);
    function updateModel(model: DesignSurveyViewModel) {
        let tempModel = { ...model! };
        tempModel.renewalSurveyId = renewalid;
        return put<{}>(`/api/survey/design`, tempModel);
    }

    const submitMutation = useMutation(submitSurveyModel, {
        onSuccess: (response: Result<any>) => {
            if (response.value()) {
                history.push(`/survey-submitted/${newaapplicationid}/${renewalid}/design`);
            } else {
                setIsSaving(false);
                snackbar.show("Not all required fields has been filled out", "error");
            }
        },
    });

    function submitSurveyModel() {
        return put<{ isValid: boolean }>(`/api/survey/design/submit/${newaapplicationid}/${renewalid}`, null);
    }

    async function updateSurvey(model: DesignSurveyViewModel) {
        let temp = { ...data! };
        temp = model;

        putOnQueueAndSetQueryData(temp, key, updateMutation.mutateAsync, temp);
    }

    function handleNextClick() {
        if (page === 1 && surveyValidator.anyHasErrors) {
            surveyValidator.setShowErrors(true);
            scrollToTop();
            return;
        }

        if (page === 2 && surveyRenewalValidator.anyHasErrors) {
            surveyRenewalValidator.setShowErrors(true);
            scrollToTop();
            return;
        }

        if (page === 1) {
            scrollToTop();
            setPage(2);
            surveyValidator.setShowErrors(false);
        } else {
            handleSubmit();
        }
    }

    function handleSubmit() {
        if (isQueueEmpty(key)) {
            setIsSaving(true);
            submitMutation.mutateAsync();
        } else {
            snackbar.show(`We are currently still saving your data. Please try and submit your survey again`, "warning");
        }
    }

    const surveyValidator = useValidator<DesignSurveyViewModel>((validators) => {
        return {
            currency: validators.validateNotEmpty(
                (item) => item.currency,
                "Field",
                (item) => item.type === SurveyType.Renewal
            ),
            isDefermentPossible: validators.validateCheckedIsNotNull((item) => item.isDefermentPossible, "Field"),
            multipleApplicationsPossible: validators.validateCheckedIsNotNull((item) => item.multipleApplicationsPossible ?? null, "Field"),
            additionalCostsForOfficialFeesForFirstDesign: validators.validateNotEmpty((item) => item.additionalCostsForOfficialFeesForFirstDesign, "Field"),
            additionalCostsForAttorneyFeesForFirstDesign: validators.validateNotEmpty((item) => item.additionalCostsForAttorneyFeesForFirstDesign, "Field"),
            additionalCostsForOfficialFeesInEachAdditionalDesign: validators.validateNotEmpty(
                (item) => item.additionalCostsForOfficialFeesInEachAdditionalDesign,
                "Field"
            ),
            additionalCostsForAttorneyFeesInEachAdditionalDesign: validators.validateNotEmpty(
                (item) => item.additionalCostsForAttorneyFeesInEachAdditionalDesign,
                "Field"
            ),
            officialPublicationAndRegistrationFeesFirstDesign: validators.validateNotEmpty(
                (item) => item.officialPublicationAndRegistrationFeesFirstDesign,
                "Field"
            ),
            attorneyPublicationAndRegistrationFeesFirstDesign: validators.validateNotEmpty(
                (item) => item.attorneyPublicationAndRegistrationFeesFirstDesign,
                "Field"
            ),
            officialPublicationAndRegistrationFeesInEachAdditionalDesign: validators.validateNotEmpty(
                (item) => item.officialPublicationAndRegistrationFeesInEachAdditionalDesign,
                "Field"
            ),
            attorneyPublicationAndRegistrationFeesInEachAdditionalDesign: validators.validateNotEmpty(
                (item) => item.attorneyPublicationAndRegistrationFeesInEachAdditionalDesign,
                "Field"
            ),
            endOfDurationAmount: validators.validateNotEmpty((item) => item.endOfDurationAmount, "Field"),
            singleFilingFees: (item) => {
                if (page === 1) {
                    let failedPeriods: Array<string> = [];
                    item?.singleFilingFees?.periods.forEach((period) => {
                        if (!validateIntervalGaps(period.intervals, true)) {
                            failedPeriods.push(period.id);
                        }
                    });

                    if (failedPeriods.length === 0) {
                        return undefined;
                    }
                    return failedPeriods.join("|");
                }
                return undefined;
            },
            multipleFilingFees: (item) => {
                if (page === 1) {
                    if (!item.multipleApplicationsPossible) {
                        return undefined;
                    } else {
                        let failedPeriods: Array<string> = [];
                        item?.multipleFilingFees?.periods.forEach((period) => {
                            if (!validateIntervalGaps(period.intervals, true)) {
                                failedPeriods.push(period.id);
                            }
                        });

                        if (failedPeriods.length === 0) {
                            return undefined;
                        }
                        return failedPeriods.join("|");
                    }
                }
                return undefined;
            },
            defermentFees: (item) => {
                if (item.isDefermentPossible) {
                    let intervals = item?.defermentFees?.intervals;
                    if (!intervals) {
                        return undefined;
                    }
                    if (validateIntervalGaps(intervals, false)) {
                        return undefined;
                    }
                    return fillingFeeError;
                }
                return undefined;
            },
        };
    });

    const surveyRenewalValidator = useValidator<DesignSurveyViewModel>((validators) => {
        return {
            renewalIsPossible: validators.validateCheckedIsNotNull((item) => item.renewalIsPossible, "Field"),
            isGracePeriodRenewalPossible: validators.validateCheckedIsNotNull((item) => {
                if (item.renewalIsPossible) {
                    return item.isGracePeriodRenewalPossible;
                }
                return undefined;
            }, "Field"),
            multipleFilingFees: (item) => {
                if (item.renewalIsPossible) {
                    if (!item.multipleApplicationsPossible) {
                        return undefined;
                    } else {
                        let failedPeriods: Array<string> = [];
                        item?.multipleFilingFees?.periods.forEach((period) => {
                            if (!validateIntervalGaps(period.intervals, true)) {
                                failedPeriods.push(period.id);
                            }
                        });

                        if (failedPeriods.length === 0) {
                            return undefined;
                        }
                        return failedPeriods.join("|");
                    }
                }

                return undefined;
            },
            singleFilingFees: (item) => {
                if (item.renewalIsPossible) {
                    let failedPeriods: Array<string> = [];
                    item?.singleFilingFees?.periods.forEach((period) => {
                        if (!validateIntervalGaps(period.intervals, true)) {
                            failedPeriods.push(period.id);
                        }
                    });

                    if (failedPeriods.length === 0) {
                        return undefined;
                    }
                    return failedPeriods.join("|");
                }
                return undefined;
            },
            additionalCostsForOfficialFeesForFirstDesign: validators.validateNotEmpty(
                (item) => item.additionalCostsForOfficialFeesForFirstDesign,
                "Field",
                (item) => !item.renewalIsPossible || !item.isGracePeriodRenewalPossible
            ),
            additionalCostsForAttorneyFeesForFirstDesign: validators.validateNotEmpty(
                (item) => item.additionalCostsForAttorneyFeesForFirstDesign,
                "Field",
                (item) => !item.renewalIsPossible || !item.isGracePeriodRenewalPossible
            ),
            additionalCostsForOfficialFeesInEachAdditionalDesign: validators.validateNotEmpty(
                (item) => item.additionalCostsForOfficialFeesInEachAdditionalDesign,
                "Field",
                (item) => !item.renewalIsPossible || !item.isGracePeriodRenewalPossible
            ),
            additionalCostsForAttorneyFeesInEachAdditionalDesign: validators.validateNotEmpty(
                (item) => item.additionalCostsForAttorneyFeesInEachAdditionalDesign,
                "Field",
                (item) => !item.renewalIsPossible || !item.isGracePeriodRenewalPossible
            ),
        };
    });

    let scrollRef = React.createRef<HTMLDivElement>();
    const scrollToTop = () => {
        scrollRef!.current!.scrollIntoView();
    };

    return { isLoading, data, updateSurvey, setPage, page, handleNextClick, surveyValidator, isSaving, scrollRef, scrollToTop, surveyRenewalValidator };
}

export function validateIntervalGaps(intervals: Array<DesignInterval>, shouldValidteAttorney: boolean) {
    var orderedIntervals = intervals.sort((a, b) => a.order - b.order);
    let previousTo: number | null = null;
    for (var i = 0; i < orderedIntervals.length; i++) {
        var interval = orderedIntervals[i];

        if (interval.order === 2 && interval.to !== null && interval.from! > interval.to) {
            return false;
        }

        if ((shouldValidteAttorney && interval.attorneyFees === null) || interval.officialFees === null) {
            return false;
        }

        if (i === 0) {
            //the first interval must be from 1 to 1
            if (interval.from !== 1 || interval.to !== 1) {
                return false;
            }
        } else {
            if (interval.from && interval.to) {
                if (interval.from !== previousTo! + 1) {
                    return false;
                }
            }
            // Last element, if this is valid, everything else is
            else if (i === orderedIntervals.length - 1) {
                if (interval.from !== previousTo! + 1) {
                    return false;
                }
                return interval.to === null;
            } else {
                return false;
            }
        }

        previousTo = interval.to;
    }
    return false;
}
