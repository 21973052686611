import { Drawer } from "@mui/material";
import React from "react";
import { UserSessionInfo } from "../../auth/AuthService";
import { useIsOnSmallScreen } from "../../common/hooks/mediaHooks";
import NavMenu from "../navMenu/NavMenu";
import { useDrawerMenuStyles } from "./DrawerMenu.styles";

export interface IDrawerMenuProps {
    mobileDrawerIsOpen: boolean;
    onMobileDrawerClose(): void;
    userSession: UserSessionInfo;
    onOrgSelectorIsOpenChange: (isOpen: boolean) => void;
}

function DrawerMenu(props: IDrawerMenuProps) {
    const [drawerIsMinimized, setDrawerIsMinimized] = React.useState(false);
    const classes = useDrawerMenuStyles();
    const isOnSmallScreen = useIsOnSmallScreen();

    const onCloseDrawerClick = () => {
        if (isOnSmallScreen) {
            props.onMobileDrawerClose();
        } else {
            setDrawerIsMinimized(!drawerIsMinimized);
        }
    };

    const navMenu = (
        <NavMenu
            isMinimized={isOnSmallScreen ? false : drawerIsMinimized}
            onClose={onCloseDrawerClick}
            onOrgSelectorIsOpenChange={props.onOrgSelectorIsOpenChange}
            userSession={props.userSession}
        />
    );

    return (
        <>
            {isOnSmallScreen && (
                <>
                    <Drawer
                        className={classes.drawer}
                        variant="temporary"
                        anchor="left"
                        onClose={props.onMobileDrawerClose}
                        open={props.mobileDrawerIsOpen}
                        classes={{ paper: classes.drawer }}
                    >
                        {navMenu}
                    </Drawer>
                </>
            )}

            {!isOnSmallScreen && (
                <>
                    <Drawer
                        className={`${classes.drawer} ${drawerIsMinimized ? classes.drawerMinimized : ""}`}
                        variant="permanent"
                        anchor="left"
                        classes={{
                            paper: `${classes.drawer} ${drawerIsMinimized ? classes.drawerMinimized : ""}`,
                        }}
                    >
                        {navMenu}
                    </Drawer>
                </>
            )}
        </>
    );
}

export default DrawerMenu;
